import "../styles/logheader.css";
import { Link, useLocation } from "react-router-dom";
import {
  FaHome,
  FaShoppingCart,
  FaBlog,
  FaQuestion,
  FaUserCircle,
  FaRecycle,
} from "react-icons/fa";
import { MdMiscellaneousServices } from "react-icons/md";
import { io } from "socket.io-client";
import { FaUserPlus, FaGear } from "react-icons/fa6";
import { RiCustomerService2Line } from "react-icons/ri";
import { AppContext } from "../../context/AppContext";
import React, { useContext, useState, useEffect } from "react";
import { MdOutlinePayment } from "react-icons/md";
import axios from "axios";
import { deleteData, getData } from "../../utils/indexedDB";

function LogHeader() {
  const {
    myStyles,
    darkMode,
    panelId,
    domain,
    siteUrl,
    setLoading,
    logoUrl,
    currentUser,
  } = useContext(AppContext);
  const location = useLocation();
  const [userLoggedIn, setUserLoggedIn] = useState(null);
  const [enabledPages, setEnabledPages] = useState([]);
  const [ticketsLength, setTicketLength] = useState(0);
  const skeletonClass = darkMode ? "dark-skeleton" : "skeleton";

  useEffect(() => {
    if (currentUser) {
      const getEnabledPages = async () => {
        const response = await axios.post(`${siteUrl}/pages/enabled`, {
          panelId: panelId,
          key: currentUser.apiKey,
        });
        const data = response.data.enabled;
        setEnabledPages(data);
        setUserLoggedIn(!!currentUser);
      };
      getEnabledPages();
    }
  }, [panelId, currentUser, siteUrl]);

  useEffect(() => {
    if (userLoggedIn === null) {
      return;
    }
    const mainDomain = domain === "localhost:3000" ? "localhost" : domain;
    const socket = io(
      `${
        mainDomain === "localhost"
          ? `http://${mainDomain}`
          : `https://${mainDomain}:4001`
      }`
    );
    const onAuth = async () => {
      const inUserDt = await getData("user_auth");
      const cres = await axios.post(`${siteUrl}/panel/get/current-user`, {
        panelId: panelId,
        uid: inUserDt?.uid,
      });
      const newCurrentUser = cres.data;
      if (newCurrentUser && newCurrentUser.uid) {
        const checkUser = async () => {
          try {
            if (newCurrentUser.status === "banned") {
              await deleteData("user_auth");
              return;
            } else {
              let isBanned = false;
              const checkBannedStatus = async () => {
                if (newCurrentUser.status === "banned") {
                  isBanned = true;
                }
              };
              checkBannedStatus();
              if (!isBanned) {
                socket.on("connect", () => {
                  socket.emit("initConnection", {
                    uid: newCurrentUser.uid,
                    panelId: panelId,
                  });
                });
              }

              return () => {
                socket.close();
              };
            }
          } catch (error) {}
        };
        checkUser();
      }
    };
    setLoading(false);
    onAuth();
  }, [userLoggedIn, panelId, setLoading, siteUrl, domain]);

  useEffect(() => {
    if (currentUser) {
      const getTicketLength = async () => {
        const response = await axios.post(
          `${siteUrl}/support/get-unread-tickets-length`,
          {
            panelId: panelId,
            key: currentUser.apiKey,
          }
        );
        const length = response.data.length;
        setTicketLength(length);
      };
      getTicketLength();
    }
  }, [panelId, currentUser, siteUrl]);

  const patchMatchRoute = (Route) => {
    if (Route === location.pathname) {
      return true;
    }
  };

  // Modify the link classes based on whether the current route matches "/viewchat/:id"
  const isViewChatActive = location.pathname.startsWith("/viewchat/");
  const isViewFaqActive = location.pathname.startsWith("/faq/");
  const isOrdersPage = location.pathname.startsWith("/orders/");
  const isBlogPage = location.pathname.startsWith("/blog/");

  return (
    <aside style={myStyles} className={darkMode ? "dark-aside" : "logaside"}>
      <div className="logo d-flex justify-content-center ">
        <img src={logoUrl} alt="" />
      </div>
      <div className="imp-links">
        {userLoggedIn === null ? (
          <div className="clskeletoncon">
            <div className={skeletonClass}></div>
            <div className={skeletonClass}></div>
            <div className={skeletonClass}></div>
            <div className={skeletonClass}></div>
            <div className={skeletonClass}></div>
          </div>
        ) : (
          <ul className="hjfgh">
            {userLoggedIn && (
              <li>
                <Link
                  className={
                    patchMatchRoute("/dashboard") && darkMode
                      ? " dark-active-link"
                      : patchMatchRoute("/dashboard") && !darkMode
                      ? "light-link-cd"
                      : darkMode
                      ? "dark-link-cd"
                      : "link-cd"
                  }
                  to="/dashboard"
                >
                  <FaHome
                    className={
                      patchMatchRoute("/dashboard")
                        ? " active-icon"
                        : "icons-li"
                    }
                  />{" "}
                  Home
                </Link>
              </li>
            )}
            {userLoggedIn && (
              <li>
                <Link
                  className={
                    patchMatchRoute("/order") && darkMode
                      ? " dark-active-link"
                      : patchMatchRoute("/order") && !darkMode
                      ? "light-link-cd"
                      : darkMode
                      ? "dark-link-cd"
                      : "link-cd"
                  }
                  to="/order"
                >
                  <svg
                    viewBox="0 0 20 20"
                    className="clorderaddic"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2584_43712)">
                      <path
                        d="M0 10C0 11.9778 0.58649 13.9112 1.6853 15.5557C2.78412 17.2002 4.3459 18.4819 6.17316 19.2388C8.00043 19.9957 10.0111 20.1937 11.9509 19.8078C13.8907 19.422 15.6725 18.4696 17.0711 17.0711C18.4696 15.6725 19.422 13.8907 19.8078 11.9509C20.1937 10.0111 19.9957 8.00043 19.2388 6.17316C18.4819 4.3459 17.2002 2.78412 15.5557 1.6853C13.9112 0.58649 11.9778 0 10 0C7.34875 0.00299199 4.80695 1.05752 2.93224 2.93224C1.05752 4.80695 0.00299199 7.34875 0 10ZM4.34783 9.56522C4.34783 9.33459 4.43944 9.11342 4.60252 8.95034C4.76559 8.78727 4.98677 8.69565 5.21739 8.69565H8.47826C8.53592 8.69565 8.59121 8.67275 8.63198 8.63198C8.67275 8.59121 8.69565 8.53592 8.69565 8.47826V5.21739C8.69565 4.98677 8.78727 4.76559 8.95034 4.60252C9.11342 4.43944 9.33459 4.34783 9.56522 4.34783H10.4348C10.6654 4.34783 10.8866 4.43944 11.0497 4.60252C11.2127 4.76559 11.3043 4.98677 11.3043 5.21739V8.47826C11.3043 8.53592 11.3272 8.59121 11.368 8.63198C11.4088 8.67275 11.4641 8.69565 11.5217 8.69565H14.7826C15.0132 8.69565 15.2344 8.78727 15.3975 8.95034C15.5606 9.11342 15.6522 9.33459 15.6522 9.56522V10.4348C15.6522 10.6654 15.5606 10.8866 15.3975 11.0497C15.2344 11.2127 15.0132 11.3043 14.7826 11.3043H11.5217C11.4641 11.3043 11.4088 11.3272 11.368 11.368C11.3272 11.4088 11.3043 11.4641 11.3043 11.5217V14.7826C11.3043 15.0132 11.2127 15.2344 11.0497 15.3975C10.8866 15.5606 10.6654 15.6522 10.4348 15.6522H9.56522C9.33459 15.6522 9.11342 15.5606 8.95034 15.3975C8.78727 15.2344 8.69565 15.0132 8.69565 14.7826V11.5217C8.69565 11.4641 8.67275 11.4088 8.63198 11.368C8.59121 11.3272 8.53592 11.3043 8.47826 11.3043H5.21739C4.98677 11.3043 4.76559 11.2127 4.60252 11.0497C4.43944 10.8866 4.34783 10.6654 4.34783 10.4348V9.56522Z"
                        fill="url(#paint0_linear_2584_43712)"
                      ></path>
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_2584_43712"
                        x1="10"
                        y1="0"
                        x2="10"
                        y2="20"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="var(--sitecolor)"></stop>
                        <stop
                          offset="1"
                          stopColor="var(--stbaseactcolor)"
                        ></stop>
                      </linearGradient>
                      <clipPath id="clip0_2584_43712">
                        <rect
                          width="20"
                          height="20"
                          fill="var(--stbasehvcolor)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  New order
                </Link>
              </li>
            )}
            {userLoggedIn && (
              <li>
                <Link
                  className={
                    isOrdersPage && darkMode
                      ? " dark-active-link"
                      : isOrdersPage && !darkMode
                      ? "light-link-cd"
                      : darkMode
                      ? "dark-link-cd"
                      : "link-cd"
                  }
                  to="/orders/all"
                >
                  <FaShoppingCart
                    className={isOrdersPage ? " active-icon" : "icons-li"}
                  />
                  Orders
                </Link>
              </li>
            )}
            {userLoggedIn && enabledPages.includes("refills") && (
              <li>
                <Link
                  className={
                    patchMatchRoute("/refills") && darkMode
                      ? " dark-active-link"
                      : patchMatchRoute("/refills") && !darkMode
                      ? "light-link-cd"
                      : darkMode
                      ? "dark-link-cd"
                      : "link-cd"
                  }
                  to="/refills"
                >
                  <FaRecycle
                    className={
                      patchMatchRoute("/refills") ? " active-icon" : "icons-li"
                    }
                  />
                  Refills
                </Link>
              </li>
            )}
            <li>
              <Link
                className={
                  patchMatchRoute("/services") && darkMode
                    ? " dark-active-link"
                    : patchMatchRoute("/services") && !darkMode
                    ? "light-link-cd"
                    : darkMode
                    ? "dark-link-cd"
                    : "link-cd"
                }
                to="/services"
              >
                <FaGear
                  className={
                    patchMatchRoute("/services") ? " active-icon" : "icons-li"
                  }
                />{" "}
                Services{" "}
              </Link>
            </li>
            {userLoggedIn && (
              <li id="mtg">
                <Link
                  className={
                    patchMatchRoute("/pay") && darkMode
                      ? " dark-active-link"
                      : patchMatchRoute("/pay") && !darkMode
                      ? "light-link-cd"
                      : darkMode
                      ? "dark-link-cd"
                      : "link-cd"
                  }
                  to="/pay"
                >
                  <MdOutlinePayment
                    className={
                      patchMatchRoute("/pay") ? " active-icon" : "icons-li"
                    }
                  />
                  Add Funds
                </Link>
              </li>
            )}
            {userLoggedIn && enabledPages.includes("affiliate") && (
              <li>
                <Link
                  className={
                    patchMatchRoute("/affiliate") && darkMode
                      ? " dark-active-link"
                      : patchMatchRoute("/affiliate") && !darkMode
                      ? "light-link-cd"
                      : darkMode
                      ? "dark-link-cd"
                      : "link-cd"
                  }
                  to="/affiliate"
                >
                  <FaUserPlus
                    className={
                      patchMatchRoute("/affiliate")
                        ? " active-icon"
                        : "icons-li"
                    }
                  />
                  Refer & Earn
                </Link>
              </li>
            )}
            <li>
              <Link
                className={
                  (patchMatchRoute("/blogs") || isBlogPage) && darkMode
                    ? " dark-active-link"
                    : (patchMatchRoute("/blogs") || isBlogPage) && !darkMode
                    ? "light-link-cd"
                    : darkMode
                    ? "dark-link-cd"
                    : "link-cd"
                }
                to="/blogs"
              >
                <FaBlog
                  className={
                    patchMatchRoute("/blogs") || isBlogPage
                      ? " active-icon"
                      : "icons-li"
                  }
                />
                Blogs
              </Link>
            </li>
            <li>
              <Link
                className={
                  (patchMatchRoute("/faqs") || isViewFaqActive) && darkMode
                    ? " dark-active-link"
                    : (patchMatchRoute("/faqs") || isViewFaqActive) && !darkMode
                    ? "light-link-cd"
                    : darkMode
                    ? "dark-link-cd"
                    : "link-cd"
                }
                to="/faqs"
              >
                <FaQuestion
                  className={
                    patchMatchRoute("/faqs") || isViewFaqActive
                      ? " active-icon"
                      : "icons-li"
                  }
                />
                FAQS
              </Link>
            </li>
            {userLoggedIn && (
              <li>
                <Link
                  className={
                    (patchMatchRoute("/support") || isViewChatActive) &&
                    darkMode
                      ? "dark-active-link clnavsupport"
                      : (patchMatchRoute("/support") || isViewChatActive) &&
                        !darkMode
                      ? "light-link-cd clnavsupport"
                      : darkMode
                      ? "dark-link-cd clnavsupport"
                      : "link-cd clnavsupport"
                  }
                  to="/support"
                >
                  <div>
                    <RiCustomerService2Line
                      className={
                        patchMatchRoute("/support") || isViewChatActive
                          ? " active-icon"
                          : "icons-li"
                      }
                    />
                    Support
                  </div>
                  {ticketsLength !== 0 && (
                    <div className="clnavuntick">{ticketsLength}</div>
                  )}
                </Link>
              </li>
            )}
            <li>
              <Link
                className={
                  patchMatchRoute("/api") && darkMode
                    ? " dark-active-link"
                    : patchMatchRoute("/api") && !darkMode
                    ? "light-link-cd"
                    : darkMode
                    ? "dark-link-cd"
                    : "link-cd"
                }
                to="/api"
              >
                <MdMiscellaneousServices
                  className={
                    patchMatchRoute("/api") ? " active-icon" : "icons-li"
                  }
                />
                API
              </Link>
            </li>
            {userLoggedIn && (
              <li>
                <Link
                  className={
                    patchMatchRoute("/account") && darkMode
                      ? " dark-active-link"
                      : patchMatchRoute("/account") && !darkMode
                      ? "light-link-cd"
                      : darkMode
                      ? "dark-link-cd"
                      : "link-cd"
                  }
                  to="/account"
                >
                  <FaUserCircle
                    className={
                      patchMatchRoute("/account") ? " active-icon" : "icons-li"
                    }
                  />
                  Account
                </Link>
              </li>
            )}
          </ul>
        )}
      </div>
    </aside>
  );
}

export default LogHeader;
