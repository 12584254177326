import { Link, useNavigate } from "react-router-dom";
import { FaUserPlus, FaGear } from "react-icons/fa6";
import { FaBlog, FaSignInAlt, FaTimes } from "react-icons/fa";
import "../styles/header.css";
import { AppContext } from "../../context/AppContext";
import { useContext, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";

function Header() {
  const { myStyles, darkMode, logoUrl } = useContext(AppContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleMobileMenu = () => {
    setIsOpen(!isOpen);
  };

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="clmainhdcon" style={myStyles}>
      <nav className={darkMode ? "clddarkheader" : "cllightheader"}>
        <div className="clheadercon">
          <div className="logo" onClick={goHome}>
            <img src={logoUrl} alt="logo" />
          </div>
          <div className="clhdnavlinks">
            <Link to="/api" className="clhdnavlink">
              <FaGear className="clhdnavlkicon" />
              API
            </Link>
            <Link to="/blogs" className="clhdnavlink">
              <FaBlog className="clhdnavlkicon" />
              Blog
            </Link>
          </div>
          <Link to="/services" className="clhdservlink">
            <FaGear className="clhdnavlkicon" />
            Services
          </Link>
          <div className="clhdnavlinks">
            <Link to="/signin" className="clhdnavlink">
              <FaSignInAlt className="clhdnavlkicon" />
              Sign In
            </Link>
            <Link to="/signup" className="clhdnavlink">
              <FaUserPlus className="clhdnavlkicon" />
              Signup
            </Link>
          </div>
          {!isOpen ? (
            <div className="clhdhambgcon" onClick={handleMobileMenu}>
              <GiHamburgerMenu className="clhdhambg" />
            </div>
          ) : (
            <div className="clhdhambgcon" onClick={handleMobileMenu}>
              <FaTimes className="clhdhambg" />
            </div>
          )}
        </div>
      </nav>
      {isOpen && (
        <div className={darkMode ? "cldarkhdmbmenu" : "cllighthdmbmenu"}>
          <div className="clhdmbmencon">
            <div className="clhdmbnavlinks">
              <Link to="/signin" className="clmbhdnavlink">
                <FaSignInAlt className="clmbhdnavlkicon" />
                Sign In
              </Link>
              <Link to="/signup" className="clmbhdnavlink">
                <FaUserPlus className="clmbhdnavlkicon" />
                Signup
              </Link>
              <Link to="/api" className="clmbhdnavlink">
                <FaGear className="clmbhdnavlkicon" />
                API
              </Link>
              <Link to="/blogs" className="clmbhdnavlink">
                <FaBlog className="clmbhdnavlkicon" />
                Blog
              </Link>
            </div>
            <Link to="/services" className="clmbhdservlink">
              <FaGear className="clmbhdnavlkicon" />
              Services
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
