import { AppContext } from "../../context/AppContext";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import "../styles/users.css";
import { FaUsers } from "react-icons/fa";
import Pagination from "../shared/Pagination";
import { FaPlusCircle } from "react-icons/fa";
import { FaUserSlash } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { FaUserClock } from "react-icons/fa6";
import axios from "axios";
import { MdCancel } from "react-icons/md";
import getNextId from "../shared/idIncrement";
import AllUsers from "../components/AllUsers";
import ActiveUsers from "../components/ActiveUsers";
import { v4 as uuidv4 } from "uuid";
import BannedUsers from "../components/BannedUsers";
import bcrypt from "bcryptjs";
import Loading from "../shared/Loading";
import FetchingData from "../shared/FetchingData";

function Users() {
  const {
    myAdminStyles,
    siteTitle,
    loading,
    setLoading,
    siteUrl,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    panelId,
    currentAdmin,
    siteData,
  } = useContext(AppContext);
  const [usersDetails, setUsersDetails] = useState([]);
  const [mainUsers, setMainUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedUsersAction, setSelectedUsersAction] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [headText, setHeadText] = useState("");
  const [usUsername, setusUsername] = useState("");
  const [usEmail, setusEmail] = useState("");
  const [usPassword, setusPassword] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const [fetchUsers, setFetchUsers] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showSearchUser, setShowSearchUser] = useState(false);
  const [usidCounter, setUsIdCounter] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [updatingActions, setUpdatingActions] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState(true);
  const [fetchStatSize, setFetchStatSize] = useState(false);
  const { status } = useParams();
  const location = useLocation();
  const [bannedUsers, setBannedUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 25;
  const navigate = useNavigate();
  const patchMatchRoute = (Route) => {
    if (Route === location.pathname) {
      return true;
    }
  };
  const fetchUsersComponent = (status) => {
    // Define an object mapping statuses to components
    const orderStatusComponents = {
      all: AllUsers,
      active: ActiveUsers,
      banned: BannedUsers,
    };

    // Use the status parameter to look up the component from the object
    return orderStatusComponents[status]; // DefaultComponent is a placeholder for unknown statuses
  };
  const UsersComponent = fetchUsersComponent(status);
  useEffect(() => {
    const fetchUsersDetails = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "users",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const filteredUsers = response.data.sort((a, b) => b.id - a.id);
          setUsersDetails(filteredUsers);
          setMainUsers(filteredUsers);
        }
        setFetchingUsers(false);
      } catch (error) {
        setFetchingUsers(false);
      }
    };
    fetchUsersDetails();
  }, [siteUrl, panelId, fetchUsers]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const SearchValue = searchParams.get("search");
    if (SearchValue !== null) {
      const handleSearchValue = () => {
        const parsedValue =
          SearchValue.trim() === ""
            ? ""
            : isNaN(SearchValue)
            ? String(SearchValue)
            : parseInt(SearchValue, 10);
        setSearchValue(parsedValue);
        const dupUsers = [...mainUsers];
        setUsersDetails(
          dupUsers.filter((user) => {
            if (typeof parsedValue === "string") {
              return (
                user.username
                  .toLowerCase()
                  .includes(parsedValue.toLowerCase()) ||
                user.email.toLowerCase().includes(parsedValue.toLowerCase())
              );
            } else if (typeof parsedValue === "number") {
              return String(user.id).includes(String(parsedValue));
            }
            return false;
          })
        );
      };
      handleSearchValue();
      setShowSearchUser(true);
    }
  }, [location.search, mainUsers]);

  const showUserActions = (User) => {
    setSelectedUser(User);
  };

  const handleOutClick = (event) => {
    // Check if the click occurred outside the content
    if (event.target.classList.contains("adusoverlay")) {
      setShowAddUser(false);
      setusEmail("");
      setusPassword("");
      setusUsername("");
    }
  };

  const handleCancelOutClick = () => {
    setShowAddUser(false);
    setusEmail("");
    setusPassword("");
    setusUsername("");
  };

  const hideConfirmAction = (event) => {
    // Check if the click occurred outside the content
    if (event.target.classList.contains("caoverlay")) {
      setShowConfirmAction(false);
    }
  };

  const handleShowAddUser = () => {
    setShowAddUser(true);
    UsIdIncrement();
  };

  const cancelConfirmAction = () => {
    setShowConfirmAction(false);
  };

  const handleShowSearch = () => {
    setShowSearchUser(true);
  };

  const containerRef = useRef();
  const bodyRef = useRef();
  const tableRef = useRef();
  const actionsRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (
        !bodyRef.current?.contains(e.target) &&
        !containerRef.current?.contains(e.target) &&
        !tableRef.current?.contains(e.target) &&
        !actionsRef.current?.contains(e.target)
      ) {
        setShowSearchUser(false);
        setSearchValue("");
        searchParams.delete("search");
        const newUrl = `${window.location.pathname}`;
        navigate(newUrl);
        setFetchUsers(!fetchUsers);
      }
    };
    if (showSearchUser) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const searchParams = new URLSearchParams(location.search);
  const handleSearchValue = (e) => {
    const value = e.target.value;
    const parsedValue =
      value.trim() === ""
        ? ""
        : isNaN(value)
        ? String(value)
        : parseInt(value, 10);
    if (parsedValue === "") {
      searchParams.delete("search");
    } else {
      searchParams.set("search", parsedValue);
    }
    const newSearchParamsString = searchParams.toString();
    const newUrl = `${window.location.pathname}${
      newSearchParamsString ? `?${newSearchParamsString}` : ""
    }`;
    navigate(newUrl);
    setSearchValue(parsedValue);
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = (currentPage + 1) * itemsPerPage;
  const sortedUserDetails = usersDetails.slice(startIndex, endIndex);
  const pageCount = Math.ceil(usersDetails.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(selected);
    navigate(`/control-panel/users/${status}?page=${newPage}`);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(pageFromUrl - 1);
  }, []);

  const UsIdIncrement = async () => {
    try {
      const newID = await getNextId("users", panelId, siteUrl);
      setUsIdCounter(newID);
    } catch (error) {
      setUsIdCounter(1);
    }
  };

  useEffect(() => {
    setLoading(false);
    document.title = `Users | ${siteTitle}`;
    const fetchTotalUsers = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "users",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          setTotalUsers(response.data.length);
        }
      } catch (error) {
        console.error("Error getting users:", error);
      }
    };

    const fetchActiveUsers = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "users",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const filteredUsers = response.data.filter(
            (user) => user.status === "online"
          );
          setActiveUsers(filteredUsers.length);
        }
      } catch (error) {
        console.error("Error getting users:", error);
      }
    };

    const fetchBannedUsers = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "users",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const filteredUsers = response.data.filter(
            (user) => user.status === "banned"
          );
          setBannedUsers(filteredUsers.length);
        }
      } catch (error) {
        console.error("Error getting users:", error);
      }
    };
    fetchTotalUsers();
    fetchActiveUsers();
    fetchBannedUsers();
  }, [siteTitle, setLoading, panelId, siteUrl, fetchStatSize, fetchUsers]);

  if (loading || siteData === null) {
    return <Loading />;
  }

  const handleUserSelect = (userId) => {
    const isSelected = selectedUsers.includes(userId);
    if (isSelected) {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((selectedId) => selectedId !== userId)
      );
    } else {
      setSelectedUsers((prevSelected) => [...prevSelected, userId]);
    }
  };

  const handleSelectAll = () => {
    const allUserIds = usersDetails.map((user) => user.uid);
    setSelectedUsers((prevSelected) =>
      prevSelected.length === usersDetails.length ? [] : allUserIds
    );
  };

  const handleCreateUserSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!usEmail || !usPassword || !usUsername) {
        throw new Error("Kindly fill in all fields");
      }

      const saltRounds = 10;
      const salt = await bcrypt.genSalt(saltRounds);
      const hashedPassword = await bcrypt.hash(usPassword, salt);
      const url = `${siteUrl}/user/create`;
      const userUid = uuidv4();
      const data = {
        email: usEmail,
        id: usidCounter,
        username: usUsername,
        password: hashedPassword,
        timestamp: new Date(),
        lastSeen: new Date(),
        balance: 0,
        spent: 0,
        panelId: panelId,
        uid: userUid,
        currency: "USD",
        status: "offline",
        apiKey: userUid,
      };
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setFetchUsers(!fetchUsers);
      setShowAddUser(false);
      setusEmail("");
      setusPassword("");
      setusUsername("");
      Notify("success", response.data.success);
    } catch (error) {
      if (!usEmail || !usPassword || !usUsername) {
        Notify("error", error.message);
      }
      if (usEmail && usPassword && usUsername) {
        Notify("error", error.response.data.error);
      }
    }
  };

  const handlebanusers = () => {
    setConfirmAction("ban the selected users");
    setButtonName("Ban");
    setHeadText("Ban users");
    setSelectedUsersAction("banUsers");
    setShowConfirmAction(true);
  };

  const handleunbanusers = () => {
    setConfirmAction("unban the selected users");
    setButtonName("UnBan");
    setHeadText("UnBan users");
    setSelectedUsersAction("UnbanUsers");
    setShowConfirmAction(true);
  };

  const handleUsUsernameInput = (e) => {
    const value = e.target.value;
    setusUsername(value);
  };

  const handleUspasswordInput = (e) => {
    const value = e.target.value;
    setusPassword(value);
  };

  const handleUsEmailInput = (e) => {
    const value = e.target.value;
    setusEmail(value);
  };

  const handleSelectedUsers = () => {
    if (selectedUsersAction === "banUsers") {
      setUpdatingActions(true);
      const handleBanUsers = async () => {
        try {
          for (const userId of selectedUsers) {
            await axios.post(`${siteUrl}/crud/update/doc`, {
              collection: "users",
              panelId: panelId,
              uid: userId,
              data: { status: "banned" },
            });
            setFetchUsers(!fetchUsers);
            setFetchStatSize(!fetchStatSize);
            const postData = async () => {
              const url = `${siteUrl}/user/ban`;
              const data = {
                uid: userId,
                panelId: panelId,
                key: currentAdmin.apiKey,
              };
              try {
                await axios.post(url, data, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
              } catch (error) {
                Notify("error", `Error banning out selected users :` + error);
              }
            };
            postData();
          }
          setFetchUsers(!fetchUsers);
          setUpdatingActions(false);
          setSelectedUsers([]); // Clear the selected users after deletion
          Notify("success", "Selected users banned successfully");
        } catch (error) {
          Notify("error", "Error deleting selected users:", error);
        }
      };
      handleBanUsers();
      setShowConfirmAction(false);
    }
    if (selectedUsersAction === "UnbanUsers") {
      setUpdatingActions(true);
      const handleUnbanUsers = async () => {
        try {
          for (const userId of selectedUsers) {
            await axios.post(`${siteUrl}/crud/update/doc`, {
              collection: "users",
              panelId: panelId,
              uid: userId,
              data: { status: "offline" },
            });
            setFetchUsers(!fetchUsers);
            setFetchStatSize(!fetchStatSize);
          }
          setFetchUsers(!fetchUsers);
          setSelectedUsers([]);
          setUpdatingActions(false);
          Notify("success", "Selected users unbanned successfully");
          setShowConfirmAction(false);
        } catch (error) {
          Notify("error", "Error deleting selected users:", error);
        }
      };
      handleUnbanUsers();
    }
  };

  const hideSearchUser = async () => {
    setShowSearchUser(false);
    setSearchValue("");
    searchParams.delete("search");
    const newUrl = `${window.location.pathname}`;
    navigate(newUrl);
    setFetchUsers(!fetchUsers);
  };

  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="adusers" style={myAdminStyles}>
        <div className="aduserscon">
          <div className="aduserdt">
            <div className="adusersstatcon">
              <Link
                className={
                  patchMatchRoute("/control-panel/users/all")
                    ? "adtotalusers activeusersroute"
                    : "adtotalusers"
                }
                to="/control-panel/users/all"
              >
                <div className="adstatsecon-violet">
                  <FaUsers className="aduserstaticon" />
                </div>
                <strong>All Users</strong>
                <h4 className="aduserstat">{totalUsers}</h4>
              </Link>
              <Link
                className={
                  patchMatchRoute("/control-panel/users/active")
                    ? "adactiveusers activeusersroute"
                    : "adactiveusers"
                }
                to="/control-panel/users/active"
              >
                <div className="adstatsecon-green">
                  <FaUserClock className="aduserstaticon" />
                </div>
                <strong>Active Users</strong>
                <h4 className="aduserstat">{activeUsers}</h4>
              </Link>
              <Link
                className={
                  patchMatchRoute("/control-panel/users/banned")
                    ? "adbannedusers activeusersroute"
                    : "adbannedusers"
                }
                to="/control-panel/users/banned"
              >
                <div className="adstatsecon-red">
                  <FaUserSlash className="aduserstaticon" />
                </div>
                <strong>Banned Users</strong>
                <h4 className="aduserstat">{bannedUsers}</h4>
              </Link>
            </div>
            <div className="adusfunction">
              <div className="aduserfunc" ref={containerRef}>
                {showSearchUser ? (
                  <div className="adusfucser" ref={bodyRef}>
                    <input
                      type="text"
                      name="search"
                      id="searchfield"
                      placeholder="Search by Id or username"
                      value={searchValue}
                      onChange={handleSearchValue}
                    />
                    {showSearchUser && (
                      <MdCancel
                        className="aduscancelsearchIcon"
                        onClick={hideSearchUser}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    <button className="adadduser" onClick={handleShowAddUser}>
                      <FaPlusCircle className="adadduserIcon" />
                      <span className="adadduserfloating-text">
                        Create User
                      </span>
                    </button>
                    <button
                      className="adadduser ms-2"
                      onClick={handleShowSearch}
                    >
                      <FaSearch className="adadduserIcon" />
                      <span className="adadduserfloating-text">
                        Search User
                      </span>
                    </button>
                  </>
                )}
              </div>
              {showAddUser && (
                <div
                  className="adusoverlay"
                  onClick={(event) => handleOutClick(event)}
                >
                  <div className="aduscon">
                    <div className="adustige">
                      <b>Create a user</b>
                      <form
                        className="adusform"
                        onSubmit={handleCreateUserSubmit}
                      >
                        <label className="aduslabel" htmlFor="adusemail">
                          Email
                        </label>
                        <input
                          type="email"
                          name="adusemail"
                          id="adusemail"
                          value={usEmail}
                          onChange={handleUsEmailInput}
                        />
                        <label className="aduslabel" htmlFor="adususername">
                          Username
                        </label>
                        <input
                          type="text"
                          name="adususername"
                          id="adususername"
                          value={usUsername}
                          onChange={handleUsUsernameInput}
                        />
                        <label className="aduslabel" htmlFor="aduspassword">
                          Password
                        </label>
                        <input
                          type="password"
                          name="aduspassword"
                          id="aduspassword"
                          value={usPassword}
                          onChange={handleUspasswordInput}
                        />
                        <button type="submit" className="aduscantbut mt-3">
                          Create User
                        </button>
                      </form>
                      <button
                        className="aduscantbut mt-3"
                        onClick={handleCancelOutClick}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {fetchingUsers ? (
              <FetchingData />
            ) : (
              <>
                <UsersComponent
                  sortedUserDetails={sortedUserDetails}
                  selectedUsers={selectedUsers}
                  tableRef={tableRef}
                  handleSelectAll={handleSelectAll}
                  usersDetails={usersDetails}
                  handleunbanusers={handleunbanusers}
                  actionsRef={actionsRef}
                  handlebanusers={handlebanusers}
                  handleUserSelect={handleUserSelect}
                  showUserActions={showUserActions}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  updatingActions={updatingActions}
                  fetchUsers={fetchUsers}
                  setFetchUsers={setFetchUsers}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  showConfirmAction={showConfirmAction}
                  cancelConfirmAction={cancelConfirmAction}
                  hideConfirmAction={hideConfirmAction}
                  headText={headText}
                  confirmAction={confirmAction}
                  buttonName={buttonName}
                  handleSelectedUsers={handleSelectedUsers}
                />
              </>
            )}
          </div>
          <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Users;
