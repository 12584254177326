import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import "../styles/categories.css";
import { IoAddCircle } from "react-icons/io5";
import { TbCategory2 } from "react-icons/tb";
import AdminHeader from "../components/AdminHeader";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import { FaGear } from "react-icons/fa6";
import { MdCancel, MdOutlineCategory } from "react-icons/md";
import { AppContext } from "../../context/AppContext";
import ActiveCategories from "../components/ActiveCategories";
import DisabledCategories from "../components/DisabledCategories";
import { FaEye, FaSearch } from "react-icons/fa";
import { GiSightDisabled } from "react-icons/gi";
import FetchingData from "../shared/FetchingData";
import getNextId from "../shared/idIncrement";
import axios from "axios";
import Switch from "../shared/Switch";
import Loading from "../shared/Loading";

function Categories() {
  const { status } = useParams();
  const navigate = useNavigate();
  const {
    panelId,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    siteUrl,
    setLoading,
    myAdminStyles,
    siteTitle,
    loading,
    siteData,
  } = useContext(AppContext);
  const [searchValue, setSearchValue] = useState("");
  const [fetchingData, setFetchingData] = useState(true);
  const [showSearchCategory, setShowSearchCategory] = useState(false);
  const [categoryStatus, setCategoryStatus] = useState(true);
  const [fetchCategories, setFetchCategories] = useState(true);
  const [addingCategory, setAddingCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [addCategory, setAddCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const [activeServices, setActiveServices] = useState(0);
  const [disabledServices, setDisabledServices] = useState(0);
  const [idCounter, setIdCounter] = useState(1);
  const [activeCategories, setActiveCategories] = useState(0);
  const [disabledCategories, setDisabledCategories] = useState(0);
  const tableRef = useRef();
  const containerRef = useRef();
  const actionsRef = useRef();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const SearchValue = searchParams.get("search");

  const patchMatchRoute = (Route) => {
    if (Route === location.pathname) {
      return true;
    }
  };

  const hideAddCategory = (event) => {
    // Check if the click occurred outside the content
    if (event.target.classList.contains("edseroverlay")) {
      setAddCategory(null);
    }
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  useEffect(() => {
    const fetchActiveServices = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "services",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const servLength = response.data.filter(
            (serv) => serv.status === "active"
          ).length;
          setActiveServices(servLength);
        }
      } catch (error) {}
    };
    const fetchDisabledServices = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "services",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const servLength = response.data.filter(
            (serv) => serv.status === "disabled"
          ).length;
          setDisabledServices(servLength);
        }
      } catch (error) {}
    };
    const fetchActiveCategories = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "categories",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const servLength = response.data.filter(
            (serv) => serv.status === "active"
          ).length;
          setActiveCategories(servLength);
        }
      } catch (error) {}
    };
    const fetchDisabledCategories = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "categories",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const servLength = response.data.filter(
            (serv) => serv.status === "disabled"
          ).length;
          setDisabledCategories(servLength);
        }
      } catch (error) {}
    };
    fetchActiveCategories();
    fetchDisabledCategories();
    fetchActiveServices();
    fetchDisabledServices();
  }, [panelId, siteUrl, fetchCategories]);

  useEffect(() => {
    document.title = `Categories | ${siteTitle}`;
    setLoading(false);
  }, [siteTitle, setLoading]);

  useEffect(() => {
    if (SearchValue !== null) {
      const handleSearchValue = () => {
        const parsedValue =
          SearchValue.trim() === ""
            ? ""
            : isNaN(SearchValue)
            ? String(SearchValue)
            : parseInt(SearchValue, 10);
        setSearchValue(parsedValue);
        const dupCategories = [...mainCategories];
        setCategories(
          dupCategories.filter((category) => {
            if (typeof parsedValue === "string") {
              return category.name
                .toLowerCase()
                .includes(parsedValue.toLowerCase());
            } else if (typeof parsedValue === "number") {
              return String(category.id).includes(String(parsedValue));
            }
            return false;
          })
        );
      };
      handleSearchValue();
      setShowSearchCategory(true);
    }
  }, [location.search, searchValue, SearchValue, mainCategories]);

  useEffect(() => {
    let handler = (e) => {
      if (
        !containerRef.current?.contains(e.target) &&
        !actionsRef.current?.contains(e.target) &&
        !tableRef.current?.contains(e.target)
      ) {
        setShowSearchCategory(false);
        setSearchValue("");
        searchParams.delete("search");
        const newUrl = `${window.location.pathname}`;
        navigate(newUrl);
        setCategories(mainCategories);
      }
    };
    if (showSearchCategory) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "categories",
        panelId: panelId,
      });
      const data = response.data.sort((a, b) => a.position - b.position);
      setCategories(data);
      setMainCategories(data);
      setFetchingData(false);
    };

    fetchCategories();
  }, [panelId, siteUrl, fetchCategories]);

  const fetchServicesComponent = (status) => {
    // Define an object mapping statuses to components
    const servicesStatusComponent = {
      disabled: DisabledCategories,
      active: ActiveCategories,
    };

    // Use the status parameter to look up the component from the object
    return servicesStatusComponent[status]; // DefaultComponent is a placeholder for unknown statuses
  };

  const ServicesComponent = fetchServicesComponent(status);

  if (loading || siteData === null) {
    return <Loading />;
  }

  const handleStatusUpdate = (newValue) => {
    setCategoryStatus(newValue);
  };

  const toggleStatus = async () => {
    const newStatus = !categoryStatus;
    setCategoryStatus(newStatus);
    handleStatusUpdate(newStatus);
  };

  const categoryNameChange = (e) => {
    const value = e.target.value;
    setCategoryName(value);
  };

  const idIncrement = async () => {
    try {
      const newID = await getNextId("categories", panelId, siteUrl);
      setIdCounter(newID);
    } catch (error) {
      setIdCounter(1);
    }
  };

  const showAddCategory = () => {
    setAddCategory(true);
    idIncrement();
  };

  const handleAddCategory = async () => {
    setAddingCategory(true);
    if (categoryName.trim() === "") {
      Notify("error", "Kindly enter a name");
      setAddingCategory(false);
      return;
    }
    try {
      const categoryData = {
        name: categoryName,
        status: categoryStatus ? "active" : "disabled",
        position: idCounter,
        id: idCounter,
        timestamp: new Date(),
      };
      await axios.post(`${siteUrl}/crud/add/doc`, {
        collection: "categories",
        panelId: panelId,
        data: categoryData,
      });
      setAddingCategory(false);
      setAddCategory(null);
      setCategoryName("");
      setFetchCategories(!fetchCategories);
      Notify("success", "Added Successfully");
    } catch (error) {
      setAddingCategory(false);
      setAddCategory(null);
      Notify("error", "Unable to add category");
    }
  };

  const handleSearchInput = (e) => {
    const value = e.target.value;
    const parsedValue =
      value.trim() === ""
        ? ""
        : isNaN(value)
        ? String(value)
        : parseInt(value, 10);
    if (parsedValue === "") {
      setCategories(mainCategories);
      searchParams.delete("search");
    } else {
      searchParams.set("search", parsedValue);
    }
    const newSearchParamsString = searchParams.toString();
    const newUrl = `${window.location.pathname}${
      newSearchParamsString ? `?${newSearchParamsString}` : ""
    }`;
    navigate(newUrl);
    setSearchValue(parsedValue);
  };

  const handleCancelSearchCategory = () => {
    setShowSearchCategory(false);
    setSearchValue("");
    searchParams.delete("search");
    const newUrl = `${window.location.pathname}`;
    navigate(newUrl);
  };

  const handleShowSearch = () => {
    setShowSearchCategory(true);
  };

  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="adcategories" style={myAdminStyles}>
        <div className="adcategoriesmaincon">
          <div className="adservicesmainstatcon">
            <Link
              to="/control-panel/services/active"
              className="adordersstatcon"
            >
              <div className="orstatsecon-active">
                <FaGear className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/services/active")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Active Services
              </strong>
              <h4 className="aduserstat">{activeServices}</h4>
            </Link>
            <Link
              to="/control-panel/services/disabled"
              className="adordersstatcon"
            >
              <div className="adtotalservicesiconcon">
                <GiSightDisabled className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/services/disabled")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Disabled Services
              </strong>
              <h4 className="aduserstat">{disabledServices}</h4>
            </Link>
            <Link
              to="/control-panel/categories/active"
              className="adordersstatcon"
            >
              <div className="orstatsecon-active">
                <TbCategory2 className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/categories/active")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Active Categories
              </strong>
              <h4 className="aduserstat">{activeCategories}</h4>
            </Link>
            <Link
              to="/control-panel/categories/disabled"
              className="adordersstatcon"
            >
              <div className="adtotalservicesiconcon">
                <MdOutlineCategory className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/categories/disabled")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Disabled Categories
              </strong>
              <h4 className="aduserstat">{disabledCategories}</h4>
            </Link>
          </div>
          <div className="adsearchorders" ref={containerRef}>
            {showSearchCategory ? (
              <div className="searchorderDiv">
                <input
                  type="text"
                  name="ordersearch"
                  id="adordersearchfield"
                  value={searchValue}
                  onChange={handleSearchInput}
                  placeholder="Search by ID or Name"
                />
                <MdCancel
                  className="adorcancelsearch"
                  onClick={handleCancelSearchCategory}
                />
              </div>
            ) : (
              <React.Fragment>
                <button className="adcategorybtnadd" onClick={showAddCategory}>
                  <IoAddCircle className="adorsearIcon" />
                  <span className="fw-semibold">Add Category</span>
                </button>
                <button className="adservicesupbtn" onClick={handleShowSearch}>
                  <FaSearch className="adorsearIcon" />
                  <span className="fw-semibold">Search Category</span>
                </button>
              </React.Fragment>
            )}
          </div>
          {addCategory ? (
            <div
              className="edseroverlay"
              onClick={(event) => hideAddCategory(event)}
            >
              <div className="editservcon">
                <div className="adedmethodhead">
                  <h6>Add Category</h6>
                </div>
                <div className="adedmthbba">
                  <label htmlFor="editservnname" className="fw-semibold">
                    Name
                  </label>
                  <input
                    type="text"
                    name="editservnname"
                    placeholder="Followers"
                    id="editprovselect"
                    onChange={categoryNameChange}
                    value={categoryName}
                  />
                  <div className="adedpaymethstatus">
                    <span>
                      <FaEye className="adrefillicon" />
                      Status
                    </span>
                    <Switch
                      syncValue={categoryStatus}
                      onToggle={toggleStatus}
                    />
                  </div>
                </div>
                <div className="adeditminmaxpaymth">
                  <button className="adeditsavebtn" onClick={handleAddCategory}>
                    {addingCategory ? (
                      <div className="adeditservspinner"></div>
                    ) : (
                      "Add"
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {fetchingData ? (
            <FetchingData />
          ) : (
            <>
              {ServicesComponent ? (
                <ServicesComponent
                  status={status}
                  categories={categories}
                  fetchCategories={fetchCategories}
                  setFetchCategories={setFetchCategories}
                  tableRef={tableRef}
                  actionsRef={actionsRef}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Categories;
