import "../styles/orderstable.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Pagination from "./Pagination";
import { FaCheckCircle, FaSpinner } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { MdPending } from "react-icons/md";
import { MdSmsFailed } from "react-icons/md";
import { formatDate } from "../shared/FormatDate";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import axios from "axios";
import { convertCurrency } from "../shared/ConvertCurrency";
import ViewOrder from "./ViewOrder";
import ViewService from "./ViewService";

const OrdersTable = ({ orders, tableRef, actionsRef }) => {
  const {
    darkMode,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    selectedRate,
    currentUser,
    siteUrl,
    panelId,
    ratesData,
  } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [refillAvailability, setRefillAvailability] = useState({});
  const [showService, setShowService] = useState(null);
  const [windowWidth, setWindowWidth] = useState(1200);
  const itemsPerPage = 25;
  const navigate = useNavigate();

  const pageCount = Math.ceil(orders.length / itemsPerPage);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = (currentPage + 1) * itemsPerPage;
  const sortedOrderDetails = orders.slice(startIndex, endIndex);
  const { status } = useParams();

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(selected);
    navigate(`/orders/${status}?page=${newPage}`);
  };
  const handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(pageFromUrl - 1);
  }, []);
  useEffect(() => {
    const handleResize = () => {
      const ordersTableCon = document.querySelector(".clorderstablecon");
      if (ordersTableCon) {
        setWindowWidth(ordersTableCon.offsetWidth);
      }
    };

    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const fetchRefillAvailability = async () => {
      if (currentUser) {
        try {
          const serviceNames = orders.map((order) => order.service);
          const response = await axios.post(
            `${siteUrl}/refill/get/availability`,
            {
              key: currentUser.apiKey,
              panelId: panelId,
              serviceNames: serviceNames,
            }
          );
          const servicesData = response.data.data;
          setRefillAvailability(servicesData);
        } catch (error) {}
      }
    };

    fetchRefillAvailability();
  }, [orders, siteUrl, currentUser, panelId]);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        Notify("success", "Successfully copied ID");
      } else {
        Notify("error", "Failed to copy ID");
      }
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  const calculateProgress = (order) => {
    const remainingAmount = order.number - order.remains;
    return (remainingAmount / order.number) * 100;
  };

  const refillOrder = async (order) => {
    try {
      await axios.post(`${siteUrl}/refill/send`, {
        key: currentUser.apiKey,
        panelId: panelId,
        orderId: order.id,
      });
      Notify("success", "Sent for refill");
    } catch (error) {
      console.log(error);
      Notify("error", "Error sending order for refill");
    }
  };

  return (
    <div className="clorderstablecon" data-theme={darkMode ? "dark" : "light"}>
      {windowWidth > 1200 ? (
        <div className={darkMode ? "dark-table" : "light-table"} ref={tableRef}>
          <table className="uru">
            <thead>
              <tr>
                <th className="orders-table-head bd-id">ID</th>
                <th className="orders-table-head">Price</th>
                <th className="orders-table-head">Link</th>
                <th className="orders-table-head">Service</th>
                <th className="orders-table-head">Quantity</th>
                <th className="orders-table-head orders-date">
                  Balance Before
                </th>
                <th className="orders-table-head orders-date">Balance After</th>
                <th className="orders-table-head">Start</th>
                <th className="orders-table-head">Progress</th>
                <th className="orders-table-head bd-date">Date</th>
              </tr>
            </thead>
            <tbody>
              {sortedOrderDetails.map((order) => (
                <React.Fragment key={order.id}>
                  <tr id={darkMode ? "mydarkborder" : "mylightborder"}>
                    <td
                      className="orders-table-data"
                      onClick={() => copyToClipboard(order.id)}
                    >
                      <div className={darkMode ? "dark-cp-id" : "cp-id"}>
                        {order.id}
                      </div>
                    </td>
                    <td className="orders-table-data">
                      <div className="bd-dx">
                        ≈ {getCurrencySymbol(selectedRate?.label)}
                        {convertCurrency(
                          order.price,
                          "USD",
                          selectedRate?.label,
                          ratesData
                        )}
                      </div>
                    </td>
                    <td className="order-lin">
                      <Link
                        to={order.url}
                        target="_blank"
                        className="orders-url"
                      >
                        <strong>{order.url}</strong>
                      </Link>
                    </td>
                    <td className="orders-table-data orders-service">
                      <strong
                        className="clmbservice"
                        onClick={() => setShowService({ id: order.serviceID })}
                      >
                        {order.serviceID} - {order.service}
                      </strong>
                    </td>
                    <td className="orders-table-data">
                      <div className="bd-dx">{order.number}</div>
                    </td>
                    <td className="orders-table-data">
                      <div className="bd-dx">
                        ≈ {getCurrencySymbol(selectedRate?.label)}
                        {convertCurrency(
                          order.userInitialBalance,
                          "USD",
                          selectedRate?.label,
                          ratesData
                        )}
                      </div>
                    </td>
                    <td className="orders-table-data">
                      <div className="bd-dx">
                        ≈ {getCurrencySymbol(selectedRate?.label)}
                        {convertCurrency(
                          order.userFinalBalance,
                          "USD",
                          selectedRate?.label,
                          ratesData
                        )}
                      </div>
                    </td>
                    <td className="orders-table-data">
                      <div className="bd-dx">
                        {order.start ? order.start : 0}
                      </div>
                    </td>
                    <td className="orders-table-data orders-service">
                      {order.status === "In progress" ? (
                        <>
                          <div className="d-flex justify-content-between">
                            <strong>
                              {order ? order.number - order.remains || 0 : 0} of{" "}
                              {order.number}
                            </strong>
                            <span className="orderstablegreenstat">
                              {order.status}
                            </span>
                          </div>
                          <div className="clprogresscon">
                            <div
                              className="greenprogress"
                              style={{
                                width: `${calculateProgress(order)}%`,
                              }}
                            ></div>
                          </div>
                        </>
                      ) : order.status === "Partial" ? (
                        <>
                          <div className="d-flex justify-content-between">
                            <span>
                              {order ? order.number - order.remains || 0 : 0} of{" "}
                              {order.number}
                            </span>
                            <span className="orderstableredstat">
                              {order.status}
                            </span>
                          </div>
                          <div className="clprogresscon">
                            <div
                              className="redprogress"
                              style={{
                                width: `${calculateProgress(order)}%`,
                              }}
                            ></div>
                          </div>
                        </>
                      ) : order.status === "Pending" ? (
                        <div className="adordetbbstatmain">
                          <span className="orderstableyellowstat">
                            <MdPending className="orderstablestatsicon" />
                            Pending
                          </span>
                        </div>
                      ) : order.status === "Completed" ? (
                        <div className="d-flex align-content-center justify-content-between">
                          <strong>
                            {order ? order.number - order.remains || 0 : 0} of{" "}
                            {order.number}
                          </strong>
                          <span className="orderstablegreenstat">
                            <FaCheckCircle className="orderstablestatsicon" />
                            {order.status}
                          </span>
                        </div>
                      ) : order.status === "Failed" ? (
                        <div className="d-flex align-content-center justify-content-between">
                          <strong>
                            {order ? order.number - order.remains || 0 : 0} of{" "}
                            {order.number}
                          </strong>
                          <div className="adordetbbstatmain">
                            <span className="orderstableredstat">
                              <MdSmsFailed className="orderstablestatsicon" />
                              {order.status}
                            </span>
                          </div>
                        </div>
                      ) : order.status === "Canceled" ? (
                        <div className="d-flex align-content-center justify-content-between">
                          <strong>
                            {order ? order.number - order.remains || 0 : 0} of{" "}
                            {order.number}
                          </strong>
                          <span className="orderstableredstat">
                            <FcCancel className="orderstablestatsicon" />
                            Cancelled
                          </span>
                        </div>
                      ) : order.status === "Processing" ? (
                        <div className="d-flex align-content-center justify-content-between">
                          <strong>0 of {order.number}</strong>
                          <span className="orderstablebluestat">
                            <FaSpinner className="orderstablestatsicon" />
                            {order.status}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="orders-table-data orders-date">
                      <strong>{formatDate(order.timestamp)}</strong>
                    </td>
                    {refillAvailability[order.service] && (
                      <td className="orders-table-data">
                        <button
                          className="clordersrefillbtn"
                          onClick={() => refillOrder(order)}
                        >
                          Refill
                        </button>
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="clmborderscon" ref={tableRef}>
          {sortedOrderDetails.map((order) => (
            <div
              className="clmborderdet"
              key={order.id}
              onClick={() => setSelectedOrder(order)}
            >
              <div className="clmborderdethead">
                <span
                  className={darkMode ? "dark-cp-id" : "cp-id"}
                  onClick={(e) => {
                    copyToClipboard(order.id);
                    handleStopPropagation(e);
                  }}
                >
                  {order.id}
                </span>
                <Link to={order.url} target="_blank" className="clmborderurl">
                  <strong>{order.url}</strong>
                </Link>
                <strong>{order.number}</strong>
              </div>
              <div className="clmborderdetbody">
                <strong
                  className="clmbservice"
                  onClick={(e) => {
                    setShowService({ id: order.serviceID });
                    handleStopPropagation(e);
                  }}
                >
                  <span className={darkMode ? "dark-cp-id" : "cp-id"}>
                    {order.serviceID}
                  </span>{" "}
                  {order.service}
                </strong>
                <strong>
                  {getCurrencySymbol(selectedRate.label)}
                  {convertCurrency(
                    order.price,
                    "USD",
                    selectedRate.label,
                    ratesData
                  )}
                </strong>
              </div>
            </div>
          ))}
        </div>
      )}
      {selectedOrder && (
        <ViewOrder
          order={selectedOrder}
          setOrder={setSelectedOrder}
          componentRef={actionsRef}
        />
      )}
      {showService && (
        <ViewService
          selectedService={showService}
          setSelectedService={setShowService}
          componentRef={actionsRef}
        />
      )}
      <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
    </div>
  );
};

export default OrdersTable;
