import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "../styles/detailsoverlay.css";

function DetailsOverlay({
  head,
  body,
  setShowDetailsOverlay,
  actionsRef,
  animateIn,
}) {
  const hideDetailsOverlay = (event) => {
    if (event.target.classList.contains("details-overlay")) {
      setShowDetailsOverlay(false);
    }
  };
  return (
    <div
      className="details-overlay"
      onClick={(event) => hideDetailsOverlay(event)}
      ref={actionsRef}
    >
      <ScrollAnimation
        className="details-overlay-con"
        delay={300}
        animateIn={animateIn ? animateIn : "zoomIn"}
      >
        <strong className="detailsHeadtext">{head}</strong>
        <pre className="detailsBodyText">{body}</pre>
      </ScrollAnimation>
    </div>
  );
}

export default DetailsOverlay;
