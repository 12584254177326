import React from "react";
import { PaystackButton } from "react-paystack";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { convertCurrency } from "../shared/ConvertCurrency";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import GetNextId from "../shared/IdIncrement";

const PaystackPayment = ({ amount, publicKey, email }) => {
  const {
    ratesData,
    panelId,
    siteUrl,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    currentUser,
    setFetchCurrentUser,
    fetchCurrentUser,
    setNotifyDuration,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const config = {
    reference: new Date(),
    email: email,
    amount: amount * 100,
    publicKey: publicKey,
  };

  const [idCounter, setIdCounter] = useState(1);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  useEffect(() => {
    const IdIncrement = async () => {
      try {
        const newID = await GetNextId("transactions", panelId, siteUrl);
        setIdCounter(newID);
      } catch (error) {
        setIdCounter(1);
      }
    };
    IdIncrement();
  }, [panelId, siteUrl]);

  const onSuccess = (reference) => {
    const handleSuccessPayment = async () => {
      try {
        const transData = {
          status: "success",
          amount: amount,
          paymentMethod: "Paystack",
          transactionID: reference.reference,
          currency: "NGN",
          userId: currentUser.uid,
          timestamp: new Date(),
          id: idCounter,
        };
        await axios.post(`${siteUrl}/crud/add/doc`, {
          panelId: panelId,
          collection: "transactions",
          data: transData,
        });
        const userBalance = currentUser.balance;
        const convertedcurrency = convertCurrency(
          amount,
          "NGN",
          "USD",
          ratesData
        ).replace(/,/g, "");
        const usdPrice =
          parseFloat(userBalance) + parseFloat(convertedcurrency);
        const mainPrice = usdPrice.toFixed(3);
        await axios.post(`${siteUrl}/crud/update/doc`, {
          panelId: panelId,
          collection: "users",
          uid: currentUser.uid,
          data: {
            balance: parseFloat(mainPrice),
          },
        });
        await axios.post(`${siteUrl}/payment/send/email`, {
          panelId: panelId,
          key: currentUser.apiKey,
          method: "Paystack",
          amount: parseFloat(convertedcurrency),
          timestamp: new Date(),
          username: currentUser.username,
          currency: "NGN",
        });
        setFetchCurrentUser(!fetchCurrentUser);
      } catch (error) {
        Notify("error", "Error during payment");
      }
      navigate("/pay");
    };
    handleSuccessPayment();
  };

  const onClose = () => {
    const handleFailedPayments = async () => {
      const transData = {
        status: "cancelled",
        amount: amount,
        paymentMethod: "Paystack",
        currency: "NGN",
        userId: currentUser.uid,
        timestamp: new Date(),
        id: idCounter,
      };
      await axios.post(`${siteUrl}/crud/add/doc`, {
        panelId: panelId,
        collection: "transactions",
        key: currentUser.apiKey,
        data: transData,
      });
    };
    navigate("/pay");
    handleFailedPayments();
  };

  const componentProps = {
    ...config,
    text: "Pay With Paystack",
    onSuccess: (reference) => onSuccess(reference),
    onClose: onClose,
  };

  return <PaystackButton {...componentProps} />;
};

export default PaystackPayment;
