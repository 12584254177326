import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import { formatDate } from "../shared/FormatDate";
import Pagination from "../shared/Pagination";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  FaMoneyCheckAlt,
  FaShoppingCart,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { ImStatsDots } from "react-icons/im";
import "../styles/blogs.css";
import { AppContext } from "../../context/AppContext";
import "../styles/statistics.css";
import Loading from "../shared/Loading";
import { getCurrencySymbol } from "../shared/CurrencySymbol.jsx";
import { convertCurrency } from "../shared/ConvertCurrency.jsx";

function Statistics() {
  const navigate = useNavigate();
  const {
    currentAdmin,
    siteUrl,
    ratesData,
    selectedAdminRate,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    panelId,
    siteTitle,
    myAdminStyles,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const itemsPerPage = 25;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = (currentPage + 1) * itemsPerPage;
  const paginatedTransactions = transactions.slice(startIndex, endIndex);
  const pageCount = Math.ceil(paginatedTransactions.length / itemsPerPage);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  useEffect(() => {
    document.title = `Statistics | ${siteTitle}`;
    const fetchStatistics = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/statistics/get`, {
            panelId: panelId,
            key: currentAdmin.apiKey,
          });
          setMonthlyStats(response.data);
          setTransactions(response.data.transactions);
          setLoading(false);
        } catch (error) {
          console.log(error.message);
        }
      }
    };
    fetchStatistics();
  }, [siteTitle, siteUrl, panelId, currentAdmin]);

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(selected);
    navigate(`/control-panel/statistics?page=${newPage}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(pageFromUrl - 1);
  }, []);

  const convertRevenue = (data) => {
    if (data && Array.isArray(data)) {
      return data.map((doc) => ({
        ...doc,
        revenue: parseFloat(
          convertCurrency(
            doc.revenue,
            "USD",
            selectedAdminRate.label,
            ratesData
          ).replace(/,/g, "")
        ),
      }));
    }
  };

  if (loading) {
    return <Loading />;
  }

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        Notify("success", "Successfully copied ID");
      } else {
        Notify("error", "Failed to copy ID");
      }
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  return (
    <React.Fragment>
      <AdminHeader />
      <AdminSessionChecker />
      <div className="adstatistics" style={myAdminStyles}>
        <div className="adstatisticsmaincon">
          <div className="adstatschart-section">
            <div className="adstatsheadtxt">
              <ImStatsDots className="icon" /> <h3>Monthly Statistics</h3>
            </div>
            <div className="adstatschart-wrapper">
              <div className="adstatschart">
                <h3>
                  <FaShoppingCart className="icon" /> Users Registered
                </h3>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={monthlyStats.registeredUsers}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "var(--adbasebgcolor)",
                        borderRadius: "10px",
                        border: "2px solid var(--adbaseactcolor)",
                      }}
                      labelStyle={{
                        color: "var(--adbaseactcolor)",
                        fontWeight: "bold",
                      }}
                      itemStyle={{ color: "var(--adbasehvcolor)" }}
                      wrapperClassName="custom-tooltip"
                    />
                    <Line
                      type="monotone"
                      dataKey="users"
                      stroke="var(--adbasehvcolor)"
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="adstatschart">
                <h3>
                  <FaShoppingCart className="icon" /> Orders Placed
                </h3>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={monthlyStats.ordersPlaced}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip
                      wrapperClassName="custom-tooltip"
                      contentStyle={{
                        backgroundColor: "var(--adbasebgcolor)",
                        borderRadius: "10px",
                        border: "2px solid var(--adbaseactcolor)",
                      }}
                      labelStyle={{
                        color: "var(--adbaseactcolor)",
                        fontWeight: "bold",
                      }}
                      itemStyle={{ color: "var(--adbasehvcolor)" }}
                    />
                    <Line
                      type="monotone"
                      dataKey="orders"
                      stroke="var(--adbaseactcolor)"
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="adstatschart">
                <h3>
                  <FaMoneyCheckAlt className="icon" /> Income Received
                </h3>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={convertRevenue(monthlyStats.incomeReceived)}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip
                      wrapperClassName="custom-tooltip"
                      contentStyle={{
                        backgroundColor: "var(--adbasebgcolor)",
                        borderRadius: "10px",
                        border: "2px solid var(--adbaseactcolor)",
                      }}
                      labelStyle={{
                        color: "var(--adbaseactcolor)",
                        fontWeight: "bold",
                      }}
                      itemStyle={{ color: "var(--adbasehvcolor)" }}
                    />
                    <Line
                      type="monotone"
                      dataKey="revenue"
                      stroke="var(--adbasehvcolor)"
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="adstatschart">
                <h3>
                  <FaCheckCircle className="icon" /> Completed Orders
                </h3>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={monthlyStats.completedOrders}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip
                      wrapperClassName="custom-tooltip"
                      contentStyle={{
                        backgroundColor: "var(--adbasebgcolor)",
                        borderRadius: "10px",
                        border: "2px solid var(--adbaseactcolor)",
                      }}
                      labelStyle={{
                        color: "var(--adbaseactcolor)",
                        fontWeight: "bold",
                      }}
                      itemStyle={{ color: "var(--adbasehvcolor)" }}
                    />
                    <Line
                      type="monotone"
                      dataKey="orders"
                      stroke="green"
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="adstatschart">
                <h3>
                  <FaTimesCircle className="icon" /> Cancelled Orders
                </h3>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={monthlyStats.cancelledOrders}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip
                      wrapperClassName="custom-tooltip"
                      contentStyle={{
                        backgroundColor: "var(--adbasebgcolor)",
                        borderRadius: "10px",
                        border: "2px solid var(--adbaseactcolor)",
                      }}
                      labelStyle={{
                        color: "var(--adbaseactcolor)",
                        fontWeight: "bold",
                      }}
                      itemStyle={{ color: "#ff4d4d" }}
                    />
                    <Line
                      type="monotone"
                      dataKey="orders"
                      stroke="#ff4d4d"
                      strokeWidth={2}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="adstatstransaction-section">
            <h2>
              <FaMoneyCheckAlt className="icon" /> Transactions
            </h2>
            <table className="adstatstransaction-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>User</th>
                  <th>Amount</th>
                  <th>Payment Method</th>
                  <th>Transaction ID</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {paginatedTransactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>
                      <span
                        className="adorderservID"
                        onClick={() => copyToClipboard(transaction.id)}
                      >
                        {transaction.id}
                      </span>
                    </td>
                    <td>
                      <Link
                        to={`/control-panel/users/all?search=${transaction.user}`}
                        className="adordersusername"
                      >
                        <strong>{transaction.user}</strong>
                      </Link>
                    </td>
                    <td>
                      <strong>
                        {getCurrencySymbol(selectedAdminRate.label)}
                        {convertCurrency(
                          transaction.amount,
                          transaction.currency || "USD",
                          selectedAdminRate.label,
                          ratesData
                        )}
                      </strong>
                    </td>
                    <td>
                      <strong>{transaction.paymentMethod}</strong>
                    </td>
                    <td>
                      {transaction.transactionID ? (
                        <span
                          className="adorderservID"
                          onClick={() =>
                            copyToClipboard(transaction.transactionID || "")
                          }
                        >
                          {transaction.transactionID}
                        </span>
                      ) : (
                        <span className={transaction.balanceAction}>
                          {transaction.balanceAction === "add"
                            ? "Credited"
                            : "Debited"}
                        </span>
                      )}
                    </td>
                    <td
                      className={
                        transaction.status === "success"
                          ? "status-completed"
                          : "status-failed"
                      }
                    >
                      {transaction.status}
                    </td>
                    <td>
                      <strong>{formatDate(transaction.timestamp)}</strong>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Statistics;
