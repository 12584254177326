import "../styles/adminlogin.css";
import { useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Loading from "../shared/Loading";
import bdge from "../../assets/images/bgde.jpg";
import bcrypt from "bcryptjs";
import { addData } from "../../utils/indexedDB";
import axios from "axios";

function AdminLogin() {
  const {
    loading,
    setLoading,
    siteUrl,
    panelId,
    siteTitle,
    currentAdmin,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
  } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Login | ${siteTitle}`;
  }, [siteTitle]);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  };
  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        Notify("error", "Kindly fill in all the fields");
        return;
      }
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "admins",
        panelId: panelId,
      });
      const adminSnapshot = response.data.filter(
        (admin) => admin.email === email
      );
      const adminData = adminSnapshot[0];
      const isMatchPassword = bcrypt.compareSync(password, adminData.password);
      if (isMatchPassword) {
        Notify("success", "Admin login successful");
        navigate("/control-panel/users/all");
        await addData({
          id: "admin_auth",
          email: email,
          uid: adminData.uid,
        });
      } else {
        Notify("error", "Incorrect login details");
      }
    } catch (error) {
      Notify("error", error.message);
    }
  };
  useEffect(() => {
    setLoading(false);
    if (currentAdmin) {
      navigate("/control-panel/users/all");
    }
  }, [currentAdmin, navigate, setLoading]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="adlog">
      <img src={bdge} alt="" className="bdge" />
      <div className="adbig">
        <div className="adcon">
          <form onSubmit={handleLogin} className="adloginform">
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="adEmail"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <div className="passwordInputDiv">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="adPassword"
                  value={password}
                  onChange={handlePasswordChange}
                />
                {showPassword && password.trim() !== "" && (
                  <FaEyeSlash
                    className="clsishowPassword"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
                {!showPassword && password.trim() !== "" && (
                  <FaEye
                    className="clsishowPassword"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </div>
            </div>
            <button type="submit" className="adSubmit">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
