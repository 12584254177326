import "../styles/adminheader.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Loading from "../shared/Loading";
import { FaShoppingCart } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { ImStatsDots } from "react-icons/im";
import { LiaBlogSolid } from "react-icons/lia";
import { MdOutlineSupportAgent } from "react-icons/md";
import axios from "axios";
import { AiOutlineControl } from "react-icons/ai";

function AdminHeader() {
  const {
    loading,
    setLoading,
    myAdminStyles,
    siteData,
    logoUrl,
    siteUrl,
    panelId,
    currentAdmin,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [ticketsLength, setTicketLength] = useState(0);
  const location = useLocation();
  useEffect(() => {
    setLoading(false);
  }, [navigate, setLoading]);

  useEffect(() => {
    if (currentAdmin) {
      const getTicketLength = async () => {
        const response = await axios.post(
          `${siteUrl}/support/get-unread-tickets-length`,
          {
            panelId: panelId,
            key: currentAdmin.apiKey,
          }
        );
        const length = response.data.length;
        setTicketLength(length);
      };
      getTicketLength();
    }
  }, [panelId, currentAdmin, siteUrl]);
  if (loading || siteData === null) {
    return <Loading />;
  }

  const patchMatchRoute = (Route) => {
    if (Route === location.pathname) {
      return true;
    }
  };
  const isOrdersPage = location.pathname.startsWith("/control-panel/orders/");
  const isUsersPage = location.pathname.startsWith("/control-panel/users/");
  const isServicesPage = location.pathname.startsWith(
    "/control-panel/services/"
  );
  const isCategoriesPage = location.pathname.startsWith(
    "/control-panel/categories/"
  );
  const isChatPage = location.pathname.startsWith("/control-panel/viewchat/");

  return (
    <>
      <aside className="adnav" style={myAdminStyles}>
        <div className="adhead">
          <div className="ad-logo">
            <img src={logoUrl} alt="" className="adlogoimg" />
          </div>
          <div className="addetails">
            <div className="adlinks">
              <ul className="adulLi">
                <li className="admLi">
                  <Link
                    to="/control-panel/users/all"
                    className={isUsersPage ? "activeAddLi" : "addLi"}
                  >
                    <FaUsers className="addLiIcon" />
                    Users
                  </Link>
                </li>
                <li className="admLi">
                  <Link
                    to="/control-panel/orders/all"
                    className={isOrdersPage ? "activeAddLi" : "addLi"}
                  >
                    <FaShoppingCart className="addLiIcon" />
                    Orders
                  </Link>
                </li>
                <li className="admLi">
                  <Link
                    to="/control-panel/services/active"
                    className={
                      isServicesPage || isCategoriesPage
                        ? "activeAddLi"
                        : "addLi"
                    }
                  >
                    <FaGear className="addLiIcon" />
                    Services
                  </Link>
                </li>
                <li className="admLi">
                  <Link
                    to="/control-panel/support"
                    className={
                      patchMatchRoute("/control-panel/support") || isChatPage
                        ? "activeAddLi adnavsupport"
                        : "addLi adnavsupport"
                    }
                  >
                    <div>
                      <MdOutlineSupportAgent className="addLiIcon" />
                      Support
                    </div>
                    {ticketsLength !== 0 && (
                      <div className="adnavuntick">{ticketsLength}</div>
                    )}
                  </Link>
                </li>
                <li className="admLi">
                  <Link
                    to="/control-panel/blogs"
                    className={
                      patchMatchRoute("/control-panel/blogs")
                        ? "activeAddLi"
                        : "addLi"
                    }
                  >
                    <LiaBlogSolid className="addLiIcon" />
                    Blogs
                  </Link>
                </li>
                <li className="admLi">
                  <Link
                    to="/control-panel/statistics"
                    className={
                      patchMatchRoute("/control-panel/statistics")
                        ? "activeAddLi"
                        : "addLi"
                    }
                  >
                    <ImStatsDots className="addLiIcon" />
                    Statistics
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="admore">
          <Link to="/control-panel/general" className="admoreLi">
            <AiOutlineControl className="admoreLiIcon" />
            Control Panel
          </Link>
        </div>
      </aside>
      <div className="admbnav">
        <ul className="admbnavUl">
          <li className="admbnavLi">
            <Link
              to="/control-panel/users/all"
              className={isUsersPage ? "admbnavactLink" : "adnavmblink"}
            >
              <FaUsers className="adnavmbIcon" />{" "}
              <span className="admbnavtext">Users</span>
            </Link>
          </li>
          <li className="admbnavLi">
            <Link
              to="/control-panel/orders/all"
              className={isOrdersPage ? "admbnavactLink" : "adnavmblink"}
            >
              <FaShoppingCart className="adnavmbIcon" />{" "}
              <span className="admbnavtext">Orders</span>
            </Link>
          </li>
          <li className="admbnavLi">
            <Link
              to="/control-panel/services/active"
              className={
                isServicesPage || isCategoriesPage
                  ? "admbnavactLink"
                  : "adnavmblink"
              }
            >
              <FaGear className="adnavmbIcon" />{" "}
              <span className="admbnavtext">Services</span>
            </Link>
          </li>
          <li className="admbnavLi">
            <Link
              to="/control-panel/support"
              className={
                patchMatchRoute("/control-panel/support") || isChatPage
                  ? "admbnavactLink"
                  : "adnavmblink"
              }
            >
              <MdOutlineSupportAgent className="adnavmbIcon" />
              <span className="admbnavtext">Support</span>
              {ticketsLength !== 0 && (
                <div className="admbnavuntick">{ticketsLength}</div>
              )}
            </Link>
          </li>
          <li className="admbnavLi">
            <Link
              to="/control-panel"
              className={
                patchMatchRoute("/control-panel")
                  ? "admbnavactLink"
                  : "adnavmblink"
              }
            >
              <AiOutlineControl className="adnavmbIcon" />
              <span className="admbnavtext">Menu</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default AdminHeader;
