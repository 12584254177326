import "../styles/lognavlinks..css";
import { Link, useLocation } from "react-router-dom";
import { FaHome, FaShoppingCart } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { AppContext } from "../../context/AppContext";
import { useContext } from "react";
import AddIcon from "../../assets/svg/add-circle-svgrepo-com.svg";
import { TiThMenuOutline } from "react-icons/ti";
import { ReactSVG } from "react-svg";

function LogNavLinks() {
  const { myStyles, darkMode } = useContext(AppContext);
  const location = useLocation();
  const patchMatchRoute = (Route) => {
    if (Route === location.pathname) {
      return true;
    }
  };
  const isOrdersPage = location.pathname.startsWith("/orders/");

  return (
    <div className="jcjgc" style={myStyles}>
      <div className={darkMode ? "dark-navigation" : "navigation"}>
        <ul className="lognav-ul">
          <li
            className={
              patchMatchRoute("/dashboard")
                ? "acv-navfj lognav-li"
                : "lognav-li"
            }
          >
            <Link className="a-links" to="/dashboard">
              <FaHome className="icon" />
              <span className="txt-mb">Home</span>
            </Link>
          </li>
          <li className={isOrdersPage ? "acv-navfj lognav-li" : "lognav-li"}>
            <Link className="a-links" to="/orders/all">
              <FaShoppingCart className="icon" />
              <span className="txt-mb">Orders</span>
            </Link>
          </li>
          <li
            className={
              patchMatchRoute("/order") ? "acv-navfj lognav-li" : "lognav-li"
            }
          >
            <Link className="a-links" to="/order">
              <ReactSVG src={AddIcon} className="log-order" />
            </Link>
          </li>
          <li
            className={
              patchMatchRoute("/services") ? "acv-navfj lognav-li" : "lognav-li"
            }
          >
            <Link className="a-links" to="/services">
              <FaGear className="icon" />
              <span className="txt-mb">Services</span>
            </Link>
          </li>
          <li
            className={
              patchMatchRoute("/menu") ? "acv-navfj lognav-li" : "lognav-li"
            }
          >
            <Link className="a-links" to="/menu">
              <TiThMenuOutline className="icon" />
              <span className="txt-mb">Menu</span>
            </Link>
          </li>
          <div
            className="indica"
            id={darkMode ? "darkIndica" : "lightIndica"}
          ></div>
        </ul>
      </div>
    </div>
  );
}

export default LogNavLinks;
