import "../styles/signin.css";
import { AppContext } from "../../context/AppContext";
import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import bcrypt from "bcryptjs";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import Integrations from "../shared/Integrations";
import getNextId from "../shared/IdIncrement";
import { v4 as uuidv4 } from "uuid";
import { addData, getData } from "../../utils/indexedDB";
import axios from "axios";
import Loading from "../shared/Loading";

function SignUp() {
  const {
    myStyles,
    siteTitle,
    setCurrentUser,
    darkMode,
    siteUrl,
    loading,
    setLoading,
    panelId,
    siteData,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
  } = useContext(AppContext);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    timestamp: new Date(),
  });
  const [idCounter, setIdCounter] = useState(1);
  const navigate = useNavigate();
  const { email, password, username } = formData;
  const [showPassword, setShowpassword] = useState(false);
  const [refId, setRefId] = useState(0);
  const [hashedPassword, setHashedPassword] = useState("");

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration && duration > 0) setNotifyDuration(duration);
  };
  useEffect(() => {
    const hashPassword = async () => {
      const saltRounds = 10;
      const salt = await bcrypt.genSalt(saltRounds);
      const hashed = await bcrypt.hash(password, salt);
      setHashedPassword(hashed);
    };
    hashPassword();
  }, [password]);

  useEffect(() => {
    const searchUrlParams = new URLSearchParams(window.location.search);
    const refValue = searchUrlParams.get("ref");
    if (refValue && refValue !== "") {
      localStorage.setItem("ref", refValue);
      setRefId(refValue);
      searchUrlParams.delete("ref");
    }
    const refID = localStorage.getItem("ref");
    if (refID && refID !== "") {
      setRefId(parseInt(refValue));
    }
  }, []);

  useEffect(() => {
    const IdIncrement = async () => {
      try {
        const newID = await getNextId("users", panelId, siteUrl);
        setIdCounter(newID);
      } catch (error) {
        setIdCounter(1);
      }
    };
    IdIncrement();
  }, [panelId, siteUrl]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email || !username || !password) {
      Notify("error", "Kindly fill in all fields");
      return;
    }
    if (
      email.trim() === "" ||
      username.trim() === "" ||
      password.trim() === ""
    ) {
      Notify("error", "Fields must not be empty");
      return;
    }
    try {
      const userUid = uuidv4();
      const modifiedFormData = {
        ...formData,
        id: idCounter,
        panelId: panelId,
        password: hashedPassword,
        apiKey: userUid,
        uid: userUid,
        balance: 0,
        spent: 0,
        lastSeen: new Date(),
        status: "online",
        currency: "USD",
      };
      if (refId) {
        modifiedFormData.ref = parseInt(refId);
      }
      await axios.post(`${siteUrl}/user/create`, modifiedFormData);
      await addData({
        email: email,
        uid: userUid,
        created_at: new Date(),
        id: "user_auth",
      });
      navigate("/order");
      setCurrentUser(modifiedFormData);
      Notify("success", "Signed Up Successfully");
    } catch (error) {
      if (error.response.data && error.response.data.error) {
        Notify("error", error.response.data.error);
      }
    }
  };

  useEffect(() => {
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (currentUser && currentUser.uid) {
        navigate("/order");
      }
    };
    onAuth();
    setLoading(false);
  }, [navigate, setLoading]);

  const onChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    document.title = `Signup | ${siteTitle}`;
  }, [siteTitle]);

  if (loading || siteData === null) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      <Header />
      <Integrations />
      <div
        className={darkMode ? "cldarksignin" : "cllightsignin"}
        style={myStyles}
      >
        <div className="clsignform">
          <form onSubmit={onSubmit} className="clloginform">
            <div>
              <label htmlFor="email" className="mb-2 fw-semibold">
                Email
              </label>
              <input
                type="email"
                name="email"
                className="clsiemail"
                id="email"
                value={email}
                onChange={onChange}
                placeholder="Email"
              />
            </div>
            <div>
              <label htmlFor="username" className="mb-2 fw-semibold">
                Username
              </label>
              <input
                type="text"
                name="username"
                className="clsiusername"
                id="username"
                value={username}
                onChange={onChange}
                placeholder="Username"
              />
            </div>
            <div>
              <label htmlFor="password" className="mb-2 fw-semibold">
                Password
              </label>
              <div className="passwordInputDiv">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  className="clsipassword"
                  placeholder="Password"
                  value={password}
                  onChange={onChange}
                />
                {showPassword && password.trim() !== "" && (
                  <FaEyeSlash
                    className="clsishowPassword"
                    onClick={() => setShowpassword(!showPassword)}
                  />
                )}
                {!showPassword && password.trim() !== "" && (
                  <FaEye
                    className="clsishowPassword"
                    onClick={() => setShowpassword(!showPassword)}
                  />
                )}
              </div>
            </div>
            <button type="submit" className="clloginbtn">
              Sign Up
            </button>
          </form>
          <div className="not-reg">
            <p className="fw-bold mt-4 mb-4">Already have an account?</p>
            <Link to="/signin" className="kfj">
              Sign in
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default SignUp;
