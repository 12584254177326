import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import { io } from "socket.io-client";
import { formatDate } from "../shared/FormatDate";
import TipTapEditor from "../shared/TipTapEditor";
import Select from "react-select";
import FetchingData from "../shared/FetchingData";
import EditUser from "../components/EditUser";
import parse from "html-react-parser";
import "../styles/adminviewchat.css";
import { AppContext } from "../../context/AppContext";
import { getCurrencySymbol } from "../shared/CurrencySymbol.jsx";
import { convertCurrency } from "../shared/ConvertCurrency.jsx";

const AdminViewChat = () => {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const {
    currentAdmin,
    siteUrl,
    panelId,
    domain,
    ratesData,
    selectedAdminRate,
    siteTitle,
  } = useContext(AppContext);
  const [messages, setMessages] = useState([]);
  const [ticketDetails, setTicketDetails] = useState({});
  const [user, setUser] = useState({});
  const [fetchMessages, setFetchMessages] = useState(false);
  const [showUserAction, setShowUserAction] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [userStatus, setUserStatus] = useState("offline");
  const [sender, setSender] = useState({ value: "admin", label: "Admin" });
  const [customSender, setCustomSender] = useState("");
  const [userLastSeen, setUserLastSeen] = useState("");
  const [fetchingTickets, setFetchingTickets] = useState(true);
  const [typing, setTyping] = useState("");
  const containerRef = useRef(null);
  const mainDomain = domain === "localhost:3000" ? "localhost" : domain;
  const socket = io(
    `${
      mainDomain === "localhost"
        ? `http://${mainDomain}`
        : `https://${mainDomain}:4001`
    }`
  );

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const mainDomain = domain === "localhost:3000" ? "localhost" : domain;
    const socket = io(
      `${
        mainDomain === "localhost"
          ? `http://${mainDomain}`
          : `https://${mainDomain}:4001`
      }`
    );
    socket.connect();

    socket.on("newTicketMessage", (msg) => {
      setFetchMessages((prevState) => !prevState);
    });

    socket.on("userTyping", (msg) => {
      setTyping(msg);
    });

    const handleBeforeUnload = () => {
      socket.disconnect();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      socket.disconnect();
    };
  }, [domain]);

  useEffect(() => {
    document.title = `Chat | ${siteTitle}`;
    const fetchTicketDetails = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(
            `${siteUrl}/support/ticket/details`,
            {
              key: currentAdmin.apiKey,
              panelId: panelId,
              ticketId: ticketId,
            }
          );
          setTicketDetails(response.data);
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }
    };

    const fetchUserStatus = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/support/user-status`, {
            key: currentAdmin.apiKey,
            panelId: panelId,
            ticketId: ticketId,
          });
          setUserStatus(response.data.status);
          setUserLastSeen(response.data.lastSeen);
        } catch (error) {
          console.error("Error fetching user status:", error);
        }
      }
    };

    fetchUserStatus();
    fetchTicketDetails();
  }, [ticketId, panelId, currentAdmin, siteUrl, siteTitle, fetchMessages]);

  useEffect(() => {
    const markAsRead = async () => {
      if (currentAdmin && ticketDetails && ticketDetails.uid) {
        try {
          await axios.post(`${siteUrl}/crud/update/doc`, {
            collection: "supports",
            key: currentAdmin.apiKey,
            panelId: panelId,
            uid: ticketDetails.uid,
            data: { isReadByAdmin: true },
          });
        } catch (error) {
          console.error("Error updating ticket:", error);
        }
      }
    };
    markAsRead();
  }, [siteUrl, currentAdmin, panelId, ticketDetails, fetchMessages]);

  useEffect(() => {
    const getUserByTicketID = async () => {
      if (currentAdmin && ticketDetails && ticketDetails.uid) {
        try {
          const response = await axios.post(`${siteUrl}/support/get-user`, {
            key: currentAdmin.apiKey,
            panelId: panelId,
            ticketId: ticketDetails.id,
          });
          setUser(response.data);
        } catch (error) {
          console.error("Error getting user:", error);
        }
      }
    };
    getUserByTicketID();
  }, [siteUrl, currentAdmin, panelId, ticketDetails, fetchMessages]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/support/messages`, {
            key: currentAdmin.apiKey,
            panelId: panelId,
            ticketId: ticketId,
          });
          setMessages(response.data);
          setFetchingTickets(false);
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }
    };
    fetchMessages();
  }, [ticketId, panelId, currentAdmin, siteUrl, fetchMessages]);

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      borderColor: "var(--adbasebgcolor)",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--adbaseactcolor)"
          : "var(--adbasebgcolor)",
        color: "white",
      };
    },
  };

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        await axios.post(`${siteUrl}/support/send-message`, {
          key: currentAdmin.apiKey,
          panelId: panelId,
          ticketId: ticketId,
          message: newMessage,
          uid: ticketDetails.uid,
          sender: sender.value === "customSender" ? customSender : sender.label,
        });
        setFetchMessages(!fetchMessages);
        setNewMessage("");
        socket.emit("newTicketMessage", "newAdminMessage");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleDeleteTicket = async () => {
    if (currentAdmin && ticketDetails && ticketDetails.uid) {
      try {
        await axios.post(`${siteUrl}/support/delete-tickets`, {
          key: currentAdmin.apiKey,
          panelId: panelId,
          ticketIds: [ticketId],
        });
        navigate("/control-panel/support");
      } catch (error) {
        console.error("Error deleting ticket:", error);
      }
    }
  };

  const handleMarkAsSolved = async () => {
    if (currentAdmin && ticketDetails && ticketDetails.uid) {
      try {
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "supports",
          key: currentAdmin.apiKey,
          panelId: panelId,
          uid: ticketDetails.uid,
          data: { status: "Solved" },
        });
        setFetchMessages(!fetchMessages);
      } catch (error) {
        console.error("Error marking ticket as solved:", error);
      }
    }
  };

  const handleMarkAsReopened = async () => {
    if (currentAdmin && ticketDetails && ticketDetails.uid) {
      try {
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "supports",
          key: currentAdmin.apiKey,
          panelId: panelId,
          uid: ticketDetails.uid,
          data: { status: "Reopened" },
        });
        setFetchMessages(!fetchMessages);
      } catch (error) {
        console.error("Error marking ticket as Reopened:", error);
      }
    }
  };

  const senderOptions = [
    { value: "admin", label: "Admin" },
    { value: "support", label: "Support" },
    { value: "technical", label: "Technical Team" },
    { value: "billing", label: "Billing" },
    { value: "customSender", label: "Custom Sender" },
  ];

  return (
    <React.Fragment>
      <AdminHeader />
      <AdminSessionChecker />
      <div className="advcviewchat">
        <div className="adviewcmaincon">
          {fetchingTickets ? (
            <FetchingData />
          ) : (
            <div className="advcviewchcol">
              <div className="advcviewchat-container">
                <div className="advcchat-header">
                  <h2>Chat with Ticket #{ticketId}</h2>
                  <div className={`advcuser-status ${userStatus}`}>
                    Status: {userStatus === "online" ? "Online" : "Offline"}
                    <br />
                    {userStatus === "offline" &&
                      `Last Seen: ${formatDate(userLastSeen)}`}
                  </div>
                </div>

                <div className="advcchat-messages" ref={containerRef}>
                  <div className="advcmessage received">
                    <div className="advcmessage-content">
                      {Object.entries(ticketDetails)
                        .filter(
                          ([key, _]) =>
                            key !== "timestamp" &&
                            key !== "isReadByAdmin" &&
                            key !== "uid" &&
                            key !== "isReadByUser" &&
                            key !== "status" &&
                            key !== "id"
                        )
                        .map(([key, value]) =>
                          parse(
                            `<strong>${key.replace(/(\b[a-z](?!\s))/g, (x) =>
                              x.toUpperCase()
                            )}</strong>: ${value}<br/><br/>`
                          )
                        )}
                    </div>
                    <div className="advcmessage-timestamp">
                      {formatDate(ticketDetails.timestamp)}
                    </div>
                  </div>
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`advcmessage ${
                        msg.sender === "user" ? "received" : "sent"
                      }`}
                    >
                      <div className="advcmessage-content">
                        {parse(msg.content)}
                      </div>
                      <div className="advcmessage-timestamp">
                        {formatDate(msg.timestamp)}
                      </div>
                    </div>
                  ))}
                  {typing !== "" && (
                    <div className="advcmessage received">
                      <div className="advcmessage-content">
                        {parse(typing)}
                        <div className="typing-indicator">
                          <div className="dot"></div>
                          <div className="dot"></div>
                          <div className="dot"></div>
                        </div>
                      </div>
                      <div className="advcmessage-timestamp">now</div>
                    </div>
                  )}
                </div>
                {ticketDetails.status !== "Solved" && (
                  <div className="advcchat-input">
                    <TipTapEditor
                      content={newMessage}
                      setContent={setNewMessage}
                      useage="supports"
                    />
                    <Select
                      options={senderOptions}
                      value={sender}
                      onChange={setSender}
                      styles={selectStyles}
                      placeholder="Select Sender"
                      className="advc-sender-select"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          primary: "var(--adbasebgcolor)",
                        },
                      })}
                    />
                    {sender.value === "customSender" && (
                      <input
                        type="text"
                        value={customSender}
                        onChange={(e) => setCustomSender(e.target.value)}
                        placeholder="Enter custom sender name"
                        className="advc-sender-input"
                        autoComplete="false"
                      />
                    )}
                    <button
                      onClick={handleSendMessage}
                      className="advcsend-button"
                    >
                      Send
                    </button>
                  </div>
                )}
              </div>
              <div className="advc-right-container">
                <div className="advc-ticket-container">
                  <h3>Ticket Settings</h3>
                  <button
                    type="button"
                    className="advc-ticket-action"
                    onClick={handleDeleteTicket}
                  >
                    Delete Ticket
                  </button>
                  {ticketDetails.status !== "Solved" ? (
                    <button
                      type="button"
                      className="advc-ticket-action"
                      onClick={handleMarkAsSolved}
                    >
                      Mark as Solved
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="advc-ticket-action"
                      onClick={handleMarkAsReopened}
                    >
                      Reopen Ticket
                    </button>
                  )}
                </div>
                <div className="adv-user-details-container">
                  <h3>User Details</h3>
                  <div className="adv-user-detail">
                    <strong>Username:</strong>{" "}
                    <Link
                      to={`/control-panel/users/all?search=${user.username}`}
                      className="adordersusername"
                    >
                      <strong>{user.username}</strong>
                    </Link>
                  </div>
                  <div className="adv-user-detail">
                    <strong>Balance:</strong>{" "}
                    {getCurrencySymbol(selectedAdminRate.label)}
                    {convertCurrency(
                      user.balance,
                      "USD",
                      selectedAdminRate.label,
                      ratesData
                    )}
                  </div>
                  <div className="adv-user-detail">
                    <strong>Spent:</strong>{" "}
                    {getCurrencySymbol(selectedAdminRate.label)}
                    {convertCurrency(
                      user.spent,
                      "USD",
                      selectedAdminRate.label,
                      ratesData
                    )}
                  </div>
                  <button
                    type="button"
                    className="advc-user-action"
                    onClick={() => setShowUserAction(true)}
                  >
                    Action
                  </button>
                </div>
              </div>
            </div>
          )}
          {showUserAction && (
            <EditUser
              selectedUser={{ uid: user.uid }}
              setSelectedUser={setShowUserAction}
              setFetchUsers={setFetchMessages}
              fetchUsers={fetchMessages}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminViewChat;
