import "../styles/signin.css";
import { AppContext } from "../../context/AppContext";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import Integrations from "../shared/Integrations";
import { getData, addData } from "../../utils/indexedDB";
import axios from "axios";
import Loading from "../shared/Loading";

function Signin() {
  const {
    myStyles,
    siteTitle,
    darkMode,
    loading,
    setCurrentUser,
    siteUrl,
    setLoading,
    panelId,
    fetchCurrentUser,
    setFetchCurrentUser,
    siteData,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
  } = useContext(AppContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;

  const onChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const navigate = useNavigate();
  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration && duration > 0) setNotifyDuration(duration);
  };
  useEffect(() => {
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (currentUser && currentUser.uid) {
        navigate("/order");
      }
    };
    onAuth();
    setLoading(false);
  }, [navigate, setLoading]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      Notify("error", "Kindly fill in all fields");
      return;
    }
    if (email.trim() === "" || password.trim() === "") {
      Notify("error", "Fields must not be empty");
      return;
    }
    try {
      const response = await axios.post(`${siteUrl}/user/authenticate`, {
        email: email,
        password: password,
        panelId: panelId,
      });
      const user = response.data.user;
      await addData({
        email: email,
        uid: user.uid,
        created_at: user.timestamp,
        id: "user_auth",
      });
      setCurrentUser(user);
      setFetchCurrentUser(!fetchCurrentUser);
      navigate("/order");
      Notify("success", "Logged In Successfully");
    } catch (error) {
      console.log(error);
      Notify("error", error.response.data.error);
    }
  };

  const [showPassword, setShowpassword] = useState(false);

  useEffect(() => {
    document.title = `Signin | ${siteTitle}`;
  }, [siteTitle]);

  if (loading || siteData === null) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Header />
      <Integrations />
      <div
        className={darkMode ? "cldarksignin" : "cllightsignin"}
        style={myStyles}
      >
        <div className="clsignform">
          <form onSubmit={onSubmit} className="clloginform">
            <div>
              <label htmlFor="email" className="mb-2 fw-semibold">
                Email
              </label>
              <input
                type="email"
                name="email"
                className="clsiemail"
                id="email"
                value={email}
                onChange={onChange}
                placeholder="Email"
              />
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <label htmlFor="password" className="mb-2 fw-semibold">
                  Password
                </label>
                <Link to="/forget-password" className="fpass">
                  Forget Password?
                </Link>
              </div>
              <div className="passwordInputDiv">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  className="clsipassword"
                  placeholder="Password"
                  value={password}
                  onChange={onChange}
                />
                {showPassword && password.trim() !== "" && (
                  <FaEyeSlash
                    className="clsishowPassword"
                    onClick={() => setShowpassword(!showPassword)}
                  />
                )}
                {!showPassword && password.trim() !== "" && (
                  <FaEye
                    className="clsishowPassword"
                    onClick={() => setShowpassword(!showPassword)}
                  />
                )}
              </div>
            </div>
            <button type="submit" className="clloginbtn">
              Sign In
            </button>
          </form>
          <div className="not-reg">
            <p className="fw-bold mt-4 mb-4">No account yet?</p>
            <Link to="/signup" className="kfj">
              Sign up
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Signin;
