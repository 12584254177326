import React, { useEffect, useContext, useState } from "react";
import LogNavLinks from "../shared/LogNavLinks";
import "../styles/api.css";
import LogHeader from "../shared/LogHeader";
import { AppContext } from "../../context/AppContext";
import TopNav from "../components/TopNav";
import Integrations from "../shared/Integrations";
import Header from "../shared/Header";
import Loading from "../shared/Loading";
import ValidTag from "../../utils/ValidTag";

function API() {
  const {
    siteTitle,
    myStyles,
    darkMode,
    loading,
    setLoading,
    domain,
    currentUser,
    siteData,
  } = useContext(AppContext);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    document.title = `API | ${siteTitle}`;
    setLoading(false);
  }, [setLoading, siteTitle]);

  const servicesValue = [
    {
      service: 1,
      description: "Hello world",
      name: "Tiktok Likes",
      category: "Tiktok",
      rate: 10,
      min: 50,
      max: 5000,
    },
    {
      service: 2,
      description: "Stable Services",
      name: "🔋 Instagram Followers",
      category: "Instagram",
      rate: 8794,
      min: 43,
      max: 1000000,
    },
    {
      service: 3,
      description: "Guarantee: 365 Days Refill",
      name: "Facebook Followers",
      category: "Facebook",
      rate: 5644,
      min: 90,
      max: 600000,
    },
  ];
  const servicesString = JSON.stringify(servicesValue, null, 9);

  const orderValue = {
    order: 4,
    category: "Tiktok",
    service: "Tiktok Likes",
    link: "https://facebook.com",
    quantity: 900,
    price: "9.000",
  };
  const orderString = JSON.stringify(orderValue, null, 9);

  const statusValue = {
    charge: "7712.338",
    status: "Pending",
    start_count: 0,
    remains: 0,
    currency: "USD",
  };
  const statusString = JSON.stringify(statusValue, null, 9);

  const statusesValue = {
    1: {
      charge: "7712.338",
      start_count: 0,
      status: "Pending",
      remains: 0,
      currency: "USD",
    },
    4: {
      charge: "9.000",
      start_count: 0,
      status: "Pending",
      remains: 0,
      currency: "USD",
    },
    99: {
      error: "Incorrect order ID",
    },
  };
  const statusesString = JSON.stringify(statusesValue, null, 9);

  const refillValue = {
    refill: 5,
  };

  const refillString = JSON.stringify(refillValue, null, 9);

  const refillsValue = [
    {
      order: 3,
      refill: 6,
    },
    {
      order: 4,
      refill: 7,
    },
    {
      order: 6,
      refill: {
        error: "Incorrect order ID",
      },
    },
  ];

  const refillsString = JSON.stringify(refillsValue, null, 9);

  const refillstatusValue = {
    status: "Pending",
  };

  const refillStatusString = JSON.stringify(refillstatusValue, null, 9);

  const refillsstatusValue = [
    {
      refill: 1,
      status: "Pending",
    },
    {
      refill: 3,
      status: "Pending",
    },
    {
      refill: 8,
      status: "Pending",
    },
  ];

  const refillsStatusString = JSON.stringify(refillsstatusValue, null, 9);

  const cancelValue = [
    {
      order: 9,
      cancel: {
        error: "Incorrect order ID",
      },
    },
    {
      order: 2,
      cancel: 1,
    },
  ];

  const cancelString = JSON.stringify(cancelValue, null, 9);

  const balanceValue = {
    balance: 6543.8373,
    currency: "USD",
  };

  const balanceString = JSON.stringify(balanceValue, null, 9);

  if (loading || siteData === null) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {currentUser ? <LogNavLinks /> : isSmallScreen ? <Header /> : ""}
      <LogHeader />
      <Integrations />
      <div className={darkMode ? "dark-api" : "api"} style={myStyles}>
        <TopNav />
        <div className="api-container">
          <div className={darkMode ? "dark-api-details" : "api-details"}>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">API Docs</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <tbody>
                  <tr>
                    <td>HTTP METHOD</td>
                    <td>POST</td>
                  </tr>
                  <tr>
                    <td>API URL</td>
                    <td>
                      <em className="apiurl">{`https://${domain}/api/v2`}</em>
                    </td>
                  </tr>
                  <tr>
                    <td>Response Format</td>
                    <td>JSON</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">Services List</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <thead>
                  <tr>
                    <th>Parameters</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>key</td>
                    <td>Your API key</td>
                  </tr>
                  <tr>
                    <td>action</td>
                    <td>services</td>
                  </tr>
                </tbody>
              </table>
              <strong>Example Response</strong>
              <pre id={darkMode ? "darkApiRes" : "apiRes"}>
                {servicesString}
              </pre>
            </div>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">Add Order</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <thead>
                  <tr>
                    <th>Parameters</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>key</td>
                    <td>Your API key</td>
                  </tr>
                  <tr>
                    <td>action</td>
                    <td>add</td>
                  </tr>
                  <tr>
                    <td>service</td>
                    <td>Service ID</td>
                  </tr>
                  <tr>
                    <td>link</td>
                    <td>Link to page</td>
                  </tr>
                  <tr>
                    <td>quantity</td>
                    <td>Needed Quantity</td>
                  </tr>
                  <tr>
                    <td>runs(optional)</td>
                    <td>Runs to deliver</td>
                  </tr>
                  <tr>
                    <td>interval(optional)</td>
                    <td>Interval in minutes</td>
                  </tr>
                </tbody>
              </table>
              <strong>Example Response</strong>
              <pre id={darkMode ? "darkApiRes" : "apiRes"}>{orderString}</pre>
            </div>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">Order status</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <thead>
                  <tr>
                    <th>Parameters</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>key</td>
                    <td>Your API key</td>
                  </tr>
                  <tr>
                    <td>action</td>
                    <td>status</td>
                  </tr>
                  <tr>
                    <td>order</td>
                    <td>Order ID</td>
                  </tr>
                </tbody>
              </table>
              <strong>Example Response</strong>
              <pre id={darkMode ? "darkApiRes" : "apiRes"}>{statusString}</pre>
            </div>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">Multiple order status</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <thead>
                  <tr>
                    <th>Parameters</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>key</td>
                    <td>Your API key</td>
                  </tr>
                  <tr>
                    <td>action</td>
                    <td>status</td>
                  </tr>
                  <tr>
                    <td>order</td>
                    <td>Order IDs (separated by a comma, up to 100 IDs)</td>
                  </tr>
                </tbody>
              </table>
              <strong>Example Response</strong>
              <pre id={darkMode ? "darkApiRes" : "apiRes"}>
                {statusesString}
              </pre>
            </div>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">Create refill</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <thead>
                  <tr>
                    <th>Parameters</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>key</td>
                    <td>Your API key</td>
                  </tr>
                  <tr>
                    <td>action</td>
                    <td>refill</td>
                  </tr>
                  <tr>
                    <td>order</td>
                    <td>Order ID</td>
                  </tr>
                </tbody>
              </table>
              <strong>Example Response</strong>
              <pre id={darkMode ? "darkApiRes" : "apiRes"}>{refillString}</pre>
            </div>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">Create multiple refill</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <thead>
                  <tr>
                    <th>Parameters</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>key</td>
                    <td>Your API key</td>
                  </tr>
                  <tr>
                    <td>action</td>
                    <td>refill</td>
                  </tr>
                  <tr>
                    <td>order</td>
                    <td>Order IDs (separated by a comma, up to 100 IDs)</td>
                  </tr>
                </tbody>
              </table>
              <strong>Example Response</strong>
              <pre id={darkMode ? "darkApiRes" : "apiRes"}>{refillsString}</pre>
            </div>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">Multiple order status</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <thead>
                  <tr>
                    <th>Parameters</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>key</td>
                    <td>Your API key</td>
                  </tr>
                  <tr>
                    <td>action</td>
                    <td>refill_status</td>
                  </tr>
                  <tr>
                    <td>refill</td>
                    <td>Refill ID</td>
                  </tr>
                </tbody>
              </table>
              <strong>Example Response</strong>
              <pre id={darkMode ? "darkApiRes" : "apiRes"}>
                {refillStatusString}
              </pre>
            </div>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">Multiple refill status</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <thead>
                  <tr>
                    <th>Parameters</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>key</td>
                    <td>Your API key</td>
                  </tr>
                  <tr>
                    <td>action</td>
                    <td>refill_status</td>
                  </tr>
                  <tr>
                    <td>order</td>
                    <td>Refill IDs (separated by a comma, up to 100 IDs)</td>
                  </tr>
                </tbody>
              </table>
              <strong>Example Response</strong>
              <pre id={darkMode ? "darkApiRes" : "apiRes"}>
                {refillsStatusString}
              </pre>
            </div>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">Create Cancel</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <thead>
                  <tr>
                    <th>Parameters</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>key</td>
                    <td>Your API key</td>
                  </tr>
                  <tr>
                    <td>action</td>
                    <td>cancel</td>
                  </tr>
                  <tr>
                    <td>orders</td>
                    <td>Orders IDs (separated by a comma, up to 100 IDs)</td>
                  </tr>
                </tbody>
              </table>
              <strong>Example Response</strong>
              <pre id={darkMode ? "darkApiRes" : "apiRes"}>{cancelString}</pre>
            </div>
            <div className={darkMode ? "dark-api-head" : "api-head"}>
              <h5 className="fw-bold">User balance</h5>
            </div>
            <div className="api-format">
              <table
                className="table"
                id={darkMode ? "dark-table-brd" : "table-brd"}
              >
                <thead>
                  <tr>
                    <th>Parameters</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>key</td>
                    <td>Your API key</td>
                  </tr>
                  <tr>
                    <td>action</td>
                    <td>balance</td>
                  </tr>
                </tbody>
              </table>
              <strong>Example Response</strong>
              <pre id={darkMode ? "darkApiRes" : "apiRes"}>{balanceString}</pre>
            </div>
          </div>
          <ValidTag />
        </div>
      </div>
    </React.Fragment>
  );
}

export default API;
