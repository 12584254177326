import React, { useEffect } from "react";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { convertCurrency } from "../shared/ConvertCurrency";
import { useNavigate } from "react-router-dom";
import GetNextId from "../shared/IdIncrement";
import logo from "../../assets/images/logo.png";
import axios from "axios";

export default function FlutterwavePayment({
  currency,
  amount,
  public_key,
  name,
  email,
  phone_number,
  title,
  description,
}) {
  const {
    ratesData,
    panelId,
    currentUser,
    siteUrl,
    selectedRate,
    setFetchCurrentUser,
    fetchCurrentUser,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const config = {
    public_key: public_key,
    tx_ref: new Date(),
    amount: amount,
    currency: currency,
    payment_options:
      "card, banktransfer, ussd, qr, mpesa, barter, paga, mobilemoney",
    customer: {
      email: email,
      phone_number: phone_number,
      name: name,
    },
    customizations: {
      title: title,
      description: description,
      logo: logo,
    },
  };

  const [idCounter, setIdCounter] = useState(1);

  useEffect(() => {
    const IdIncrement = async () => {
      try {
        const newID = await GetNextId("transactions", panelId, siteUrl);
        setIdCounter(newID);
      } catch (error) {
        setIdCounter(1);
      }
    };

    IdIncrement();
  }, [panelId, siteUrl]);

  const fwConfig = {
    ...config,
    text: "Pay with Flutterwave!",
    callback: (response) => {
      if (response.status === "completed") {
        const handleSuccessPayment = async () => {
          try {
            const transData = {
              status: "success",
              amount: response.amount,
              paymentMethod: "Flutterwave",
              transactionID: response.transaction_id,
              currency: response.currency,
              chargedAmount: response.charged_amount,
              userId: currentUser.uid,
              timestamp: new Date(),
              id: idCounter,
            };
            await axios.post(`${siteUrl}/crud/add/doc`, {
              panelId: panelId,
              collection: "transactions",
              data: transData,
            });
            const userBalance = currentUser.balance;
            const convertedcurrency = convertCurrency(
              amount,
              selectedRate?.label,
              "USD",
              ratesData
            ).replace(/,/g, "");
            const usdPrice =
              parseFloat(userBalance) + parseFloat(convertedcurrency);
            const mainPrice = usdPrice.toFixed(3);
            await axios.post(`${siteUrl}/crud/update/doc`, {
              panelId: panelId,
              collection: "users",
              uid: currentUser.uid,
              data: {
                balance: parseFloat(mainPrice),
              },
            });
            await axios.post(`${siteUrl}/payment/send/email`, {
              panelId: panelId,
              key: currentUser.apiKey,
              method: "Flutterwave",
              amount: parseFloat(convertedcurrency),
              timestamp: new Date(),
              username: currentUser.username,
              currency: selectedRate?.label,
            });
            setFetchCurrentUser(!fetchCurrentUser);
          } catch (error) {
            console.log(error);
          }
          navigate("/pay");
        };
        handleSuccessPayment();
      } else {
        const handleFailedPayments = async () => {
          const transData = {
            status: response.status,
            amount: response.amount,
            paymentMethod: "Flutterwave",
            transactionID: response.transaction_id,
            currency: response.currency,
            chargedAmount: response.charged_amount,
            userId: currentUser.uid,
            timestamp: new Date(),
            id: idCounter,
          };
          await axios.post(`${siteUrl}/crud/add/doc`, {
            panelId: panelId,
            key: currentUser.apiKey,
            collection: "transactions",
            data: transData,
          });
        };
        navigate("/pay");
        handleFailedPayments();
      }
      closePaymentModal(); // this will close the modal programmatically
    },
    onClose: (response) => {
      const handleFailedPayments = async () => {
        const transData = {
          status: "cancelled",
          amount: response.amount,
          paymentMethod: "Flutterwave",
          transactionID: response.transaction_id,
          currency: response.currency,
          chargedAmount: response.charged_amount,
          userId: currentUser.uid,
          timestamp: new Date(),
          id: idCounter,
        };
        await axios.post(`${siteUrl}/crud/add/doc`, {
          panelId: panelId,
          key: currentUser.apiKey,
          collection: "transactions",
          data: transData,
        });
      };
      navigate("/pay");
      handleFailedPayments();
    },
  };

  return <FlutterWaveButton {...fwConfig} />;
}
