import "../styles/checkboxs.css";

function CheckboxS({ onChange, checked }) {
  return (
    <label className="adCheckboxs">
      <input checked={checked} type="checkbox" onChange={onChange} />
      <div className="checkmark"></div>
    </label>
  );
}

export default CheckboxS;
