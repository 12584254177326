import CategoriesTable from "./CategoriesTable";
import { useState, useEffect } from "react";
import NotFound from "../shared/NotFound";

function DisabledCategories({
  categories,
  tableRef,
  fetchCategories,
  setFetchCategories,
  bodyRef,
  actionsRef,
}) {
  const [filteredCategories, setfilteredCategories] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get("search")) {
      const filtered = categories.filter(
        (category) => category.status === "disabled"
      );
      setfilteredCategories(filtered);
    } else {
      setfilteredCategories(categories);
    }
  }, [categories]);

  return filteredCategories.length === 0 ? (
    <NotFound
      value={
        searchParams.get("search")
          ? "No matching categories found with the given name or ID"
          : "No disabled category found."
      }
    />
  ) : (
    <CategoriesTable
      categories={categories}
      tableRef={tableRef}
      bodyRef={bodyRef}
      fetchCategories={fetchCategories}
      setFetchCategories={setFetchCategories}
      actionsRef={actionsRef}
    />
  );
}

export default DisabledCategories;
