import UsersTable from "./UsersTable";
import NotFound from "../shared/NotFound";

function AllUsers({
  sortedUserDetails,
  selectedUsers,
  tableRef,
  handleSelectAll,
  usersDetails,
  handleunbanusers,
  handlebanusers,
  handleUserSelect,
  showUserActions,
  selectedUser,
  setSelectedUser,
  showPassword,
  setShowPassword,
  showConfirmAction,
  fetchUsers,
  cancelConfirmAction,
  hideConfirmAction,
  setFetchUsers,
  headText,
  confirmAction,
  buttonName,
  handleSelectedUsers,
  updatingActions,
  actionsRef,
}) {
  return sortedUserDetails.length === 0 ? (
    <NotFound value={"No User Found"} />
  ) : (
    <>
      <UsersTable
        sortedUserDetails={sortedUserDetails}
        actionsRef={actionsRef}
        selectedUsers={selectedUsers}
        tableRef={tableRef}
        handleSelectAll={handleSelectAll}
        usersDetails={usersDetails}
        handleunbanusers={handleunbanusers}
        handlebanusers={handlebanusers}
        handleUserSelect={handleUserSelect}
        showUserActions={showUserActions}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        showConfirmAction={showConfirmAction}
        cancelConfirmAction={cancelConfirmAction}
        hideConfirmAction={hideConfirmAction}
        headText={headText}
        confirmAction={confirmAction}
        buttonName={buttonName}
        handleSelectedUsers={handleSelectedUsers}
        updatingActions={updatingActions}
        setFetchUsers={setFetchUsers}
        fetchUsers={fetchUsers}
      />
    </>
  );
}

export default AllUsers;
