import { useNavigate, Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import React, { useContext, useState } from "react";
import Footer from "../shared/Footer";
import "../styles/homepage.css";
import socprev from "../../assets/images/socprev.png";
import apiSec from "../../assets/images/apiSec.png";
import bagSec from "../../assets/images/bagSec.png";
import trophySec from "../../assets/images/trophySec.png";
import securePay from "../../assets/images/securePay.png";
import { useEffect } from "react";
import axios from "axios";
import { FaBolt } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { IoChatbubble } from "react-icons/io5";
import { FaLaptop } from "react-icons/fa";
import { FaMoneyBill } from "react-icons/fa";
import Integrations from "../shared/Integrations";
import ScrollAnimation from "react-animate-on-scroll";
import { getData } from "../../utils/indexedDB";
import Header from "../shared/Header";
import Loading from "../shared/Loading";

function HomePage() {
  const [homeData, setHomeData] = useState(null);
  const {
    myStyles,
    darkMode,
    siteTitle,
    loading,
    siteData,
    setLoading,
    panelId,
    siteUrl,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [activeFAQ, setActiveFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setActiveFAQ(activeFAQ === index ? null : index);
  };

  const faqs = [
    {
      id: 1,
      question: "What is an SMM panel?",
      answer:
        "An SMM panel is a platform where users can purchase social media services such as followers, likes, views, and more to enhance their online presence.",
    },
    {
      id: 2,
      question: "How do I sign up for an account?",
      answer:
        "To sign up, click on the 'Sign Up' button on our homepage, fill in the required details, and verify your email address.",
    },
    {
      id: 3,
      question: "Is it safe to use your services?",
      answer:
        "Yes, our services are safe and secure. We use advanced encryption and secure payment gateways to protect your data and transactions.",
    },
    {
      id: 4,
      question: "How quickly will I receive my order?",
      answer:
        "Orders are typically delivered instantly or within a few minutes, depending on the service and quantity ordered.",
    },
    {
      id: 5,
      question: "Can I track my order status?",
      answer:
        "Yes, you can track the status of your orders in real-time through your account dashboard.",
    },
    {
      id: 6,
      question: "What should I do if I encounter an issue with my order?",
      answer:
        "If you experience any issues, please contact our 24/7 customer support team via live chat or email for prompt assistance.",
    },
    {
      id: 7,
      question: "Can I resell your services?",
      answer:
        "Yes, our platform is designed to support resellers. You can use our API to integrate our services into your own system and offer them to your clients.",
    },
    {
      id: 8,
      question: "How do I join the affiliate program?",
      answer:
        "To join our affiliate program, sign up for an account, and navigate to the 'Affiliate' section in your dashboard for your unique referral link and promotional materials.",
    },
  ];

  useEffect(() => {
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (currentUser && currentUser.uid) {
        navigate("/order");
      }
    };
    onAuth();
    setLoading(false);
  }, [navigate, setLoading]);

  useEffect(() => {
    document.title = `${homeData?.title || ""}`;
  }, [homeData]);

  useEffect(() => {
    const gethomeData = async () => {
      try {
        const response = await axios.post(`${siteUrl}/pages/home/get`, {
          panelId: panelId,
        });
        const homeDoc = response.data.data;
        setHomeData(homeDoc);
      } catch (error) {}
    };
    gethomeData();
  }, [panelId, siteUrl]);

  if (loading || siteData === null) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Integrations />
      <Header />
      <main
        style={myStyles}
        className={darkMode ? "cldarkhpcon" : "cllighthpcon"}
      >
        <div className="clhpheader">
          <div className="clhphdtxt">
            <h1 className="hdtxt">
              The Best <span>SMM</span> Panel You Can Ask For.
            </h1>
            <h3>
              With {siteTitle}, you'll revolutionize your social media growth.
              Together, we lead, they follow!
            </h3>
            <span>
              In the competitive digital landscape, having the right tools to
              elevate your online presence is crucial. That's why we offer
              top-tier and budget-friendly SMM panel services designed for
              industry trailblazers like you. Our platform ensures that you will
              drive unparalleled website traffic and social media engagement,
              solidifying your position as a leader in your field. Join us and
              harness the power of the best SMM panel to transform your online
              reach and influence.
            </span>
            <Link to="/signup">SIGN UP NOW</Link>
          </div>
          <img src={socprev} alt="" />
        </div>
        {homeData && homeData.tutorial !== "" && (
          <div className="hometutorialcon">
            <iframe
              className="tutorialHomePage"
              src={homeData.tutorial}
              title="Tutorial"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        )}
        <div className="clhpfeatures">
          <div className="flex-center">
            <h1>Get Ready To Experience</h1>
          </div>
          <div className="clhpfeats">
            <div className="clhpfeat">
              <div className="clhpfeaticon">
                <FaBolt className="clhpfticon" />
              </div>
              <div className="flex-center">
                <span className="clhpfthdtxt">Instant Delivery</span>
              </div>
              <div className="clhpfeattext">
                Get followers and likes delivered instantly after purchase.
              </div>
            </div>
            <div className="clhpfeat">
              <div className="clhpfeaticon">
                <FaStar className="clhpfticon" />
              </div>
              <div className="flex-center">
                <span className="clhpfthdtxt">High-Quality Services</span>
              </div>
              <div className="clhpfeattext">
                Purchase genuine, high-quality services that'll make your
                content shine.
              </div>
            </div>
            <div className="clhpfeat">
              <div className="clhpfeaticon">
                <IoChatbubble className="clhpfticon" />
              </div>
              <div className="flex-center">
                <span className="clhpfthdtxt">24/7 Customer Support</span>
              </div>
              <div className="clhpfeattext">
                Our support team is available around the clock to assist you.
              </div>
            </div>
            <div className="clhpfeat">
              <div className="clhpfeaticon">
                <FaMoneyBill className="clhpfticon" />
              </div>
              <div className="flex-center">
                <span className="clhpfthdtxt">Budget-Friendly Pricing</span>
              </div>
              <div className="clhpfeattext">
                Access high-quality and amazing services at unbeatable prices,
                perfect for any budget.
              </div>
            </div>
            <div className="clhpfeat">
              <div className="clhpfeaticon">
                <FaLaptop className="clhpfticon" />
              </div>
              <div className="flex-center">
                <span className="clhpfthdtxt">User-Friendly Interface</span>
              </div>
              <div className="clhpfeattext">
                Navigate our platform effortlessly with a simple and intuitive
                interface.
              </div>
            </div>
          </div>
        </div>
        <div className="clhpapiint">
          <div className="clhpapiintcon">
            <div className="clhpapitxt">
              <h1>Seamless API Integration</h1>
              <span>
                Integrate our API effortlessly into your existing systems and
                applications, streamlining your social media marketing efforts.
                Our well-documented API ensures smooth communication between
                your platform and ours, allowing you to automate processes,
                access real-time data, and scale your operations with ease. With
                comprehensive documentation and dedicated developer support,
                harnessing the power of our API has never been simpler. Unlock
                the full potential of your social media strategies with our
                seamless API integration solution.
              </span>
              <ScrollAnimation animateIn="backInUp" className="clhpanimlink">
                <Link to="/api">View API</Link>
              </ScrollAnimation>
            </div>
            <ScrollAnimation animateIn="fadeIn" className="clhpanimimg">
              <img src={apiSec} alt="api" />
            </ScrollAnimation>
          </div>
        </div>
        <div className="clhpapiint">
          <div className="clhpapiintcon">
            <ScrollAnimation animateIn="fadeIn" className="clhpanimimg">
              <img src={securePay} alt="api" />
            </ScrollAnimation>
            <div className="clhpapitxt">
              <h1>Secure Payment System</h1>
              <span>
                Enjoy peace of mind with our highly secure payment system. We
                prioritize your security by using advanced encryption
                technologies and secure payment gateways, ensuring that your
                transactions are safe and protected. Our system supports
                multiple payment methods, providing you with flexibility and
                convenience. Trust in our robust security measures to keep your
                financial information safe while you focus on growing your
                social media presence.
              </span>
              <ScrollAnimation animateIn="backInUp" className="clhpanimlink">
                <Link to="/signup">Add Funds Now!</Link>
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div className="clhpapiint">
          <div className="clhpapiintcon">
            <div className="clhpapitxt">
              <h1>Guaranteed Results</h1>
              <span>
                Achieve your social media goals with our guaranteed results. We
                stand by the effectiveness of our services, ensuring that you
                see tangible improvements in your online presence and
                engagement. Our team uses proven methods and cutting-edge tools
                to deliver real, measurable outcomes, whether you're looking to
                increase followers, enhance post visibility, or boost overall
                engagement. With our guaranteed results, you can trust that your
                investment in social media marketing will yield positive and
                impactful returns. Experience the confidence and success that
                comes with a service guarantee designed to meet your highest
                expectations.
              </span>
              <ScrollAnimation animateIn="backInUp" className="clhpanimlink">
                <Link to="/signup">Order Now!</Link>
              </ScrollAnimation>
            </div>
            <ScrollAnimation animateIn="fadeIn" className="clhpanimimg">
              <img src={trophySec} alt="api" className="clhphptropd" />
            </ScrollAnimation>
          </div>
        </div>
        <div className="clhpapiint">
          <div className="clhpapiintcon">
            <ScrollAnimation animateIn="fadeIn" className="clhpanimimg">
              <img src={bagSec} alt="api" />
            </ScrollAnimation>
            <div className="clhpapitxt">
              <h1>Lucrative Affiliate Program</h1>
              <span>
                Join our lucrative affiliate program and earn commissions by
                promoting our services. Our easy-to-use affiliate system allows
                you to track referrals, monitor earnings, and receive prompt
                payouts. With competitive commission rates and a dedicated
                support team, partnering with us is a rewarding opportunity to
                generate passive income. Share our platform with your network
                and enjoy the benefits of being part of a trusted and successful
                SMM panel.
              </span>
              <ScrollAnimation animateIn="backInUp" className="clhpanimlink">
                <Link to="/signup">Create An Account Now!</Link>
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div className="clhpfaqs">
          <div className="flex-center">
            <h1>Frequently Asked Questions</h1>
          </div>
          <div className="clhpfaqscon">
            {faqs.map((faq) => (
              <div className="clhpfaq" key={faq.id}>
                <div className="clhpfaquest" onClick={() => toggleFAQ(faq.id)}>
                  {activeFAQ === faq.id ? (
                    <FaAngleUp className="clhpfaqicon" />
                  ) : (
                    <FaAngleDown className="clhpfaqicon" />
                  )}
                  {faq.question}
                </div>
                {activeFAQ === faq.id && (
                  <div className="clhpfaqans">{faq.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </main>
    </React.Fragment>
  );
}

export default HomePage;
