import React, { useContext } from "react";
import Checkbox from "../shared/Checkbox";
import SelectedAllActions from "../shared/SelectedAllActions";
import { FaAngleDown } from "react-icons/fa";
import ConfirmAction from "../shared/ConfirmAction";
import { FaBan } from "react-icons/fa";
import { IoEllipseSharp } from "react-icons/io5";
import { formatDate } from "../shared/FormatDate";
import { AppContext } from "../../context/AppContext";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import { convertCurrency } from "../shared/ConvertCurrency";
import EditUser from "./EditUser";

function UsersTable({
  sortedUserDetails,
  selectedUsers,
  tableRef,
  handleSelectAll,
  usersDetails,
  handleunbanusers,
  actionsRef,
  handlebanusers,
  handleUserSelect,
  showUserActions,
  selectedUser,
  setSelectedUser,
  updatingActions,
  showConfirmAction,
  fetchUsers,
  setFetchUsers,
  cancelConfirmAction,
  hideConfirmAction,
  headText,
  confirmAction,
  buttonName,
  handleSelectedUsers,
}) {
  const {
    selectedAdminRate,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    ratesData,
  } = useContext(AppContext);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        Notify("success", "Successfully copied ID");
      } else {
        Notify("error", "Failed to copy ID");
      }
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };
  return (
    <div>
      <div className="adusertb">
        <table className="aduserstable" ref={tableRef}>
          <thead>
            {selectedUsers.length === 0 ? (
              <tr>
                <th className="aduserstb-head adinpfic">
                  <Checkbox
                    onChange={() => handleSelectAll()}
                    checked={selectedUsers.length === usersDetails.length}
                  />
                </th>
                <th className="aduserstb-head">ID</th>
                <th className="aduserstb-head">Username</th>
                <th className="aduserstb-head">E-mail</th>
                <th className="aduserstb-head">Balance</th>
                <th className="aduserstb-head">Spent</th>
                <th className="aduserstb-head adusersthd">Registration Date</th>
                <th className="aduserstb-head adusersthd">Last Seen</th>
                <th className="aduserstb-head">Status</th>
              </tr>
            ) : (
              <SelectedAllActions
                onChange={() => handleSelectAll()}
                checked={selectedUsers.length === usersDetails.length}
                selectedUsers={selectedUsers}
                handleBanUsers={handlebanusers}
                handleUnbanUsers={handleunbanusers}
              />
            )}
          </thead>
          <tbody>
            {sortedUserDetails.map((user) => (
              <React.Fragment key={user.id}>
                <tr>
                  <td className="aduserstb-data adinpfic">
                    <Checkbox
                      onChange={() => handleUserSelect(user.uid)}
                      checked={selectedUsers.includes(user.uid)}
                    />
                  </td>
                  <td
                    className="aduserstb-data"
                    onClick={() => copyToClipboard(user.id)}
                  >
                    <span className="adorderservID">{user.id}</span>
                  </td>
                  <td className="aduserstb-data fw-800">
                    <strong>{user.username}</strong>
                  </td>
                  <td className="aduserstb-data">
                    <strong className="ademdd">{user.email}</strong>
                  </td>
                  <td className="aduserstb-data">
                    <strong>
                      {getCurrencySymbol(selectedAdminRate.label)}
                      {convertCurrency(
                        user.balance,
                        "USD",
                        selectedAdminRate.label,
                        ratesData
                      )}
                    </strong>
                  </td>
                  <td className="aduserstb-data">
                    <strong>
                      {getCurrencySymbol(selectedAdminRate.label)}
                      {convertCurrency(
                        user.spent,
                        "USD",
                        selectedAdminRate.label,
                        ratesData
                      )}
                    </strong>
                  </td>
                  <td className="aduserstb-data adusersthd">
                    <strong className="ademdd">
                      {formatDate(user.timestamp)}
                    </strong>
                  </td>
                  <td className="aduserstb-data adusersthd">
                    <strong className="ademdd">
                      {formatDate(user.lastSeen)}
                    </strong>
                  </td>
                  <td className={`aduserstb-data ${user.status}`}>
                    {user.status === "online" ? (
                      <React.Fragment>
                        <IoEllipseSharp className="adstatusgr" /> Online
                      </React.Fragment>
                    ) : user.status === "offline" ? (
                      <React.Fragment>
                        <IoEllipseSharp className="adstatusre" /> Offline
                      </React.Fragment>
                    ) : user.status === "banned" ? (
                      <React.Fragment>
                        <FaBan className="adstatusre" /> Banned
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <IoEllipseSharp className="adstatusre" /> Unknown Status
                      </React.Fragment>
                    )}
                  </td>
                  <td className="aduserstb-data adusactx">
                    <button
                      className="adusersactions"
                      onClick={() => showUserActions(user)}
                    >
                      Actions
                      <FaAngleDown className="adusacIcon" />
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {selectedUser && (
        <EditUser
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          fetchUsers={fetchUsers}
          setFetchUsers={setFetchUsers}
          actionsRef={actionsRef}
        />
      )}
      {showConfirmAction ? (
        <ConfirmAction
          cancelConfirmAction={cancelConfirmAction}
          hideConfirmAction={hideConfirmAction}
          headText={headText}
          actionText={confirmAction}
          buttonName={buttonName}
          updatingActions={updatingActions}
          buttonAction={handleSelectedUsers}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default UsersTable;
