import "../styles/controlpanel.css";
import { AppContext } from "../../context/AppContext";
import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import Settings from "../../assets/images/settings.png";
import paymentgateways from "../../assets/images/paymentgatways.png";
import pages from "../../assets/images/pages.png";
import fix from "../../assets/images/fix.png";
import blogs from "../../assets/images/blogs.png";
import stack from "../../assets/images/stack.png";
import magic from "../../assets/images/magic.png";
import question from "../../assets/images/question.png";
import statistics from "../../assets/images/statistics.png";
import Loading from "../shared/Loading";

function ControlPanel() {
  const { siteTitle, setLoading, loading, siteData } = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    setLoading(false);
    if (
      location.pathname === "/control-panel" ||
      location.pathname === "/control-panel/"
    ) {
      document.title = `Control | ${siteTitle}`;
    }
  }, [siteTitle, setLoading, location.pathname]);

  if (loading || siteData === null) {
    return <Loading />;
  }

  const patchMatchRoute = (Route) => {
    return Route === location.pathname;
  };

  return (
    <React.Fragment>
      {(location.pathname === "/control-panel" ||
        location.pathname === "/control-panel/") && (
        <React.Fragment>
          <AdminSessionChecker />
          <AdminHeader />
        </React.Fragment>
      )}
      <div
        className="adcpmbmaincon"
        id={
          location.pathname === "/control-panel" ||
          location.pathname === "/control-panel/"
            ? "adidcpmbmaincon"
            : ""
        }
      >
        <div className="adcontrolpanel">
          <div className="adcontrolpanelmaincon">
            <div className="adcpheadtxt">
              <h6>Control Panel</h6>
            </div>
            <div className="adcpbodycon">
              <ul>
                <li>
                  <Link
                    to="/control-panel/payment-methods"
                    className={
                      patchMatchRoute("/control-panel/payment-methods")
                        ? "adcplkactive"
                        : "adcplk"
                    }
                  >
                    <img src={paymentgateways} alt="" className="adcdsic" />
                    Payment Methods
                  </Link>
                </li>
                <li>
                  <Link
                    to="/control-panel/general"
                    className={
                      patchMatchRoute("/control-panel/general")
                        ? "adcplkactive"
                        : "adcplk"
                    }
                  >
                    <img src={Settings} alt="" className="adcdsic" />
                    General
                  </Link>
                </li>
                <li>
                  <Link
                    to="/control-panel/providers"
                    className={
                      patchMatchRoute("/control-panel/providers")
                        ? "adcplkactive"
                        : "adcplk"
                    }
                  >
                    <img src={stack} alt="" className="adcdsic" />
                    Providers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/control-panel/pages"
                    className={
                      patchMatchRoute("/control-panel/pages")
                        ? "adcplkactive"
                        : "adcplk"
                    }
                  >
                    <img src={pages} alt="" className="adcdsic" />
                    Pages
                  </Link>
                </li>
                <li>
                  <Link
                    to="/control-panel/faqs"
                    className={
                      patchMatchRoute("/control-panel/faqs")
                        ? "adcplkactive"
                        : "adcplk"
                    }
                  >
                    <img src={question} alt="" className="adcdsic" /> FAQS
                  </Link>
                </li>
                <li>
                  <Link
                    to="/control-panel/design"
                    className={
                      patchMatchRoute("/control-panel/design")
                        ? "adcplkactive"
                        : "adcplk"
                    }
                  >
                    <img src={magic} alt="" className="adcdsic" />
                    Design
                  </Link>
                </li>
                <li>
                  <Link
                    to="/control-panel/integration"
                    className={
                      patchMatchRoute("/control-panel/integration")
                        ? "adcplkactive"
                        : "adcplk"
                    }
                  >
                    <img src={fix} alt="" className="adcdsic" />
                    Integrations
                  </Link>
                </li>
                <li className="adcpmbnav">
                  <Link
                    to="/control-panel/blogs"
                    className={
                      patchMatchRoute("/control-panel/blogs")
                        ? "adcplkactive"
                        : "adcplk"
                    }
                  >
                    <img src={blogs} alt="" className="adcdsic" />
                    Blogs
                  </Link>
                </li>
                <li className="adcpmbnav">
                  <Link
                    to="/control-panel/statistics"
                    className={
                      patchMatchRoute("/control-panel/statistics")
                        ? "adcplkactive"
                        : "adcplk"
                    }
                  >
                    <img src={statistics} alt="" className="adcdsic" />
                    Statistics
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ControlPanel;
