import parse from "html-react-parser";
import "../styles/servicestable.css";
import { useContext, useState, useEffect } from "react";
import React from "react";
import { AppContext } from "../../context/AppContext";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import axios from "axios";
import { convertCurrency } from "../shared/ConvertCurrency";

function ViewService({ selectedService, setSelectedService, componentRef }) {
  const {
    darkMode,
    selectedRate,
    ratesData,
    logoUrl,
    currentUser,
    siteUrl,
    panelId,
  } = useContext(AppContext);
  const [serviceData, setServiceData] = useState(null);

  const hideDescription = (event) => {
    if (event.target.classList.contains("overlay")) {
      setSelectedService(null);
    }
  };

  useEffect(() => {
    const getService = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(
            `${siteUrl}/service/get-service-id`,
            {
              key: currentUser.apiKey,
              panelId: panelId,
              serviceId: selectedService.id,
            }
          );
          setServiceData(response.data.service);
        } catch (error) {
          console.log(error.message);
        }
      }
    };
    getService();
  }, [currentUser, siteUrl, panelId, selectedService]);

  const cancelDescription = () => {
    setSelectedService(null);
  };

  return (
    selectedService &&
    serviceData && (
      <div
        className="overlay"
        onClick={(event) => hideDescription(event)}
        ref={componentRef}
      >
        <div
          className={
            darkMode ? "dark-description-message" : "description-message"
          }
        >
          <div className="logo-container">
            <img src={logoUrl} alt="Logo" className="logo-in-description" />
          </div>
          <div className="clservname mb-3">
            <div className="clservnamehead">
              <span>Name</span>
            </div>
            <p className="clservnametxt">{serviceData.name}</p>
          </div>
          <div className="clservname mb-3">
            <div className="clservnamehead">
              <span>Category</span>
            </div>
            <p className="clservnametxt">{serviceData.category}</p>
          </div>
          {serviceData.description !== "" && (
            <div className="clservdesc">
              <div className="clservdeschead">
                <span>Description</span>
              </div>
              <pre className="p-3">
                {parse(serviceData.description.replace(/&&n/g, "\n"))}
              </pre>
            </div>
          )}
          <div className="clservquant">
            <div className="charge">
              <span>Price</span>
              <span>
                ≈ {getCurrencySymbol(selectedRate?.label)}
                {convertCurrency(
                  serviceData.price,
                  serviceData.providerCurrency,
                  selectedRate?.label,
                  ratesData
                )}
              </span>
            </div>
            <div className="min">
              <span>Min</span>
              <span>{serviceData.min}</span>
            </div>
            <div className="max">
              <span>Max</span>
              <span>{serviceData.max}</span>
            </div>
          </div>
          <div className="p-3 flex-center">
            <button className="cancel-button" onClick={cancelDescription}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ViewService;
