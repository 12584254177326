import "../styles/account.css";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import bcrypt from "bcryptjs";
import { AppContext } from "../../context/AppContext";
import Select from "react-select";
import TopNav from "../components/TopNav";
import { getCurrencyName } from "../shared/GetCountryName";
import ValidTag from "../../utils/ValidTag";
import Integrations from "../shared/Integrations";
import LogNavLinks from "../shared/LogNavLinks";
import LogHeader from "../shared/LogHeader";
import { FaExchangeAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { getData, deleteData, updateData } from "../../utils/indexedDB";
import Loading from "../shared/Loading";

function Account() {
  const options = useMemo(
    () => [
      { value: "default", label: "System Default" },
      { value: "dark", label: "Dark Mode" },
      { value: "light", label: "Light Mode" },
    ],
    []
  );
  const [username, setUsername] = useState([]);
  const [hashedPassword, setHashedPassword] = useState("");
  const [email, setEmail] = useState([]);
  const {
    siteTitle,
    myStyles,
    setCurrentUser,
    darkMode,
    setDarkMode,
    siteUrl,
    currentUser,
    panelId,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    loading,
    siteData,
    setLoading,
    selectedRate,
    setSelectedRate,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]); // Set the default option
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [rates, setRates] = useState([]);
  const [apiKey, setApiKey] = useState("");

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const onUsernameChange = (e) => {
    const { value } = e.target;
    setUsername(value);
  };
  const { oldPassword, newPassword } = formData;
  const onChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    const storedTheme = localStorage.getItem("themePreference");
    if (storedTheme) {
      const matchedOption = options.find(
        (option) => option.value === storedTheme
      );
      setSelectedOption(matchedOption || options[0]);
      if (matchedOption?.value === "default") {
        setDarkMode(prefersDarkMode);
      } else if (matchedOption?.value === "light") {
        setDarkMode(false);
      } else if (matchedOption?.value === "dark") {
        setDarkMode(true);
      }
    }
  }, [options, setDarkMode]);

  useEffect(() => {
    document.title = `Account | ${siteTitle}`;
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (!currentUser) {
        navigate("/");
      }
    };
    onAuth();
    setLoading(false);
  }, [navigate, setLoading, siteTitle]);

  useEffect(() => {
    const hashPassword = async () => {
      const saltRounds = 10;
      const salt = await bcrypt.genSalt(saltRounds);
      const hashed = await bcrypt.hash(newPassword, salt);
      setHashedPassword(hashed);
    };
    hashPassword();
  }, [newPassword]);

  useEffect(() => {
    if (currentUser) {
      setUsername(currentUser.username);
      setEmail(currentUser.email);
      setApiKey(currentUser.apiKey);
    }
  }, [currentUser, panelId, siteUrl]);

  useEffect(() => {
    async function fetchExchangeRates() {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "exchange_rates",
        });
        if (Array.isArray(response.data)) {
          const exdata = response.data.filter((ex) => ex.uid === "latest");
          const data = exdata[0];
          if (data) {
            return data.quotes;
          }
        }
      } catch (error) {
        return null;
      }
    }
    const fetchRates = async () => {
      const data = await fetchExchangeRates();
      if (data) {
        const formattedRates = Object.keys(data).map((currency) => ({
          label: `${currency} - ${getCurrencyName(currency)}`,
          value: data[currency],
        }));
        const sortedRates = formattedRates.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        setRates(sortedRates);
      }
    };

    fetchRates();
  }, [panelId, siteUrl]);

  if (loading || siteData === null) {
    return <Loading />;
  }

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const handleUsernameSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currentUser) {
        if (username.trim() === "") {
          Notify("error", "Username cannot be empty");
          return;
        }
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "users",
          panelId: panelId,
        });
        const filteredUsers = response.data.some(
          (user) => user.username === username
        );
        if (filteredUsers) {
          Notify("error", "Username already exists");
          return;
        }
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "users",
          panelId: panelId,
          uid: currentUser.uid,
          data: { username: username },
        });
        await updateData("user_auth", { username: username });
        Notify("success", "Username changed successfully!");
      }
    } catch (error) {
      Notify("error", error.response.data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currentUser) {
        if (!oldPassword || !newPassword) {
          Notify("error", "Kindly fill in both fields");
          return;
        }
        const isMatchPassword = bcrypt.compareSync(
          oldPassword,
          currentUser.password
        );
        if (!isMatchPassword) {
          Notify("error", "Incorrect Password");
          return;
        }
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "users",
          panelId: panelId,
          uid: currentUser.uid,
          data: { password: hashedPassword },
        });
        Notify("success", "Password changed successfully!");
      }
    } catch (error) {
      Notify("error", error.response.data);
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    if (selectedOption.value === "default") {
      setDarkMode(prefersDarkMode);
    } else {
      setDarkMode(!darkMode);
    }
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "10px", // Add border-radius to the control (main container)
      borderColor: "var(--stbasehvcolor)",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--stbaseactcolor)"
          : darkMode
          ? "rgb(34, 34, 34)"
          : "white",
        color: isFocused ? "white" : darkMode ? "white" : "black",
      };
    },
  };

  const onLogout = async () => {
    try {
      await axios.post(`${siteUrl}/crud/update/doc`, {
        data: { status: "offline", lastSeen: new Date() },
        panelId: panelId,
        collection: "users",
        uid: currentUser.uid,
      });
      await deleteData("user_auth");
      setCurrentUser(null);
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleRateChange = (selectedOption) => {
    setSelectedRate(selectedOption);
  };

  const generateUUID = () => {
    return uuidv4();
  };

  const handleChangeApiKey = async () => {
    const apiKey = generateUUID();
    try {
      await axios.post(`${siteUrl}/crud/update/doc`, {
        collection: "users",
        panelId: panelId,
        uid: currentUser.uid,
        data: { apiKey: apiKey },
      });
      setApiKey(apiKey);
      Notify("success", "Changed successfully");
    } catch (error) {
      Notify("error", error.response.data);
    }
  };

  const copyAPiKey = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      Notify("success", "Copied successfully");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <LogHeader />
      <Integrations />
      <LogNavLinks />
      <div className={darkMode ? "dark-accouunt" : "accouunt"} style={myStyles}>
        <TopNav />
        <div className="accut">
          <div className="claccmaincon">
            <div className="account-details">
              <form className="acc-form" onSubmit={handleSubmit}>
                <h5 className="mb-3 mt-5">Your Email and Password</h5>
                <div>
                  <label htmlFor="username">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="claccusername"
                    disabled
                    value={email}
                  />
                </div>
                <div>
                  <label htmlFor="oldPassword">Old Password</label>
                  <div className="claccpassdiv">
                    <input
                      type={showOldPassword ? "text" : "password"}
                      name="oldPassword"
                      id="oldPassword"
                      className="claccoldpasswd"
                      placeholder="Old Password"
                      value={oldPassword}
                      onChange={onChange}
                    />
                    {showOldPassword && oldPassword.trim() !== "" && (
                      <FaEyeSlash
                        className="claccpasswicon"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      />
                    )}
                    {!showOldPassword && oldPassword.trim() !== "" && (
                      <FaEye
                        className="claccpasswicon"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <label htmlFor="newPassword">New Password</label>
                  <div className="claccpassdiv">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      name="newPassword"
                      id="newPassword"
                      className="claccnewpasswd"
                      placeholder="New Password"
                      value={newPassword}
                      onChange={onChange}
                    />
                    {showNewPassword && newPassword.trim() !== "" && (
                      <FaEyeSlash
                        className="claccpasswicon"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    )}
                    {!showNewPassword && newPassword.trim() !== "" && (
                      <FaEye
                        className="claccpasswicon"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    )}
                  </div>
                </div>
                <button type="submit" className="changePassword">
                  Change Password
                </button>
              </form>
            </div>
            <div
              className="acc-email"
              id={darkMode ? "mydarkborder" : "mylightborder"}
            >
              <h4 className="mb-3 mt-5">Username Setting</h4>
              <form onSubmit={handleUsernameSubmit} className="acc-form">
                <label htmlFor="email">Username</label>
                <input
                  type="text"
                  name="username"
                  id="ciemail"
                  className="claccemail"
                  onChange={onUsernameChange}
                  value={username}
                />
                <button type="submit" className="c-email">
                  Change Username
                </button>
              </form>
            </div>
            <div
              className="acc-email"
              id={darkMode ? "mydarkborder" : "mylightborder"}
            >
              <p className="fw-semibold mt-5">API Key</p>
              <div className="accggenapi" onClick={copyAPiKey}>
                {apiKey}
                <FaExchangeAlt
                  onClick={handleChangeApiKey}
                  className="accapiiicon"
                />
              </div>
            </div>
            <div
              className="acc-preferences"
              id={darkMode ? "mydarkborder" : "mylightborder"}
            >
              <p className="fw-semibold mt-5">Theme</p>
              <Select
                id="themeSelect"
                className="mb-4"
                options={options}
                value={selectedOption}
                isSearchable={false}
                onChange={handleChange}
                styles={selectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    primary: "var(--stbaseactcolor)",
                  },
                })}
              />
            </div>
            <div
              className="acc-preferences"
              id={darkMode ? "mydarkborder" : "mylightborder"}
            >
              <p className="fw-semibold mt-5">Currency</p>
              <Select
                id="themeSelect"
                options={rates}
                value={selectedRate}
                onChange={handleRateChange}
                styles={selectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    primary: "var(--stbaseactcolor)",
                  },
                })}
              />
            </div>
            <div
              className="acc-logout"
              id={darkMode ? "mydarkborder" : "mylightborder"}
            >
              <button
                type="button"
                onClick={onLogout}
                className="lg-out mt-5 mb-5"
              >
                Logout
              </button>
            </div>
          </div>
          <ValidTag />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Account;
