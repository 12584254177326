import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import "../styles/viewchat.css";
import { FaUserCircle } from "react-icons/fa";
import { FaHeadset } from "react-icons/fa";
import { formatDate } from "../shared/FormatDate";
import TopNav from "../components/TopNav";
import FetchingData from "../shared/FetchingData";
import LogHeader from "../shared/LogHeader";
import LogNavLinks from "../shared/LogNavLinks";
import Integrations from "../shared/Integrations";
import parse from "html-react-parser";
import { getData } from "../../utils/indexedDB";
import axios from "axios";
import Loading from "../shared/Loading";
import { io } from "socket.io-client";
import TipTapEditor from "../shared/TipTapEditor";
import ValidTag from "../../utils/ValidTag";

function ViewChat() {
  const { id } = useParams();
  const [ticketDetails, setTicketDetails] = useState(null);
  const {
    siteTitle,
    myStyles,
    darkMode,
    loading,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    setLoading,
    currentUser,
    panelId,
    siteData,
    domain,
    siteUrl,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState([]);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [changedData, setChangedData] = useState(false);
  const mainDomain = domain === "localhost:3000" ? "localhost" : domain;
  const socket = io(
    `${
      mainDomain === "localhost"
        ? `http://${mainDomain}`
        : `https://${mainDomain}:4001`
    }`
  );

  const containerRef = useRef(null);
  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const mainDomain = domain === "localhost:3000" ? "localhost" : domain;
    const socket = io(
      `${
        mainDomain === "localhost"
          ? `http://${mainDomain}`
          : `https://${mainDomain}:4001`
      }`
    );
    socket.connect();

    // Listen for newTicketMessage from the server
    socket.on("newTicketMessage", (msg) => {
      setChangedData((prevState) => !prevState);
    });

    const handleBeforeUnload = () => {
      socket.disconnect();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      socket.disconnect();
    };
  }, [domain]);

  useEffect(() => {
    document.title = `Chat | ${siteTitle}`;
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (!currentUser) {
        navigate("/");
      }
    };
    onAuth();
    setLoading(false);
  }, [navigate, setLoading, siteTitle]);

  useEffect(() => {
    const markAsRead = async () => {
      if (currentUser && ticketDetails && ticketDetails.uid) {
        try {
          await axios.post(`${siteUrl}/crud/update/doc`, {
            collection: "supports",
            key: currentUser.apiKey,
            panelId: panelId,
            uid: ticketDetails.uid,
            data: { isReadByUser: true },
          });
        } catch (error) {
          console.error("Error updating ticket:", error);
        }
      }
    };
    markAsRead();
  }, [siteUrl, currentUser, panelId, ticketDetails, changedData]);

  useEffect(() => {
    const fetchTicketDetails = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(
            `${siteUrl}/support/user-get-messages`,
            {
              key: currentUser.apiKey,
              ticketId: id,
              panelId: panelId,
            }
          );
          const support = response.data;
          setMessages(support.messages);
          setTicketDetails(support.ticketDetails);
          setUsername(currentUser.username);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchTicketDetails();
  }, [id, currentUser, panelId, siteUrl, changedData]);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (isButtonDisabled) {
      return;
    }
    setIsButtonDisabled(true);
    try {
      if (!message || message.trim() === "") {
        Notify("error", "Kindly enter your message");
        return;
      }
      const newMessage = {
        content: message,
        sender: "user",
        timestamp: new Date(),
        ticketId: parseInt(id),
      };
      await axios.post(`${siteUrl}/support/user-send-message`, {
        panelId: panelId,
        key: currentUser.apiKey,
        data: newMessage,
        uid: ticketDetails.uid,
      });
      setMessage("");
      socket.emit("newTicketMessage", "newUserMessage");
    } catch (error) {
      Notify("error", "Error sending message...");
    }
  };

  if (loading || siteData === null) {
    return <Loading />;
  }

  const handleMessageInput = (input) => {
    setMessage(input);
    setIsButtonDisabled(false);
    if (input === "") {
      socket.emit("userTyping", "");
    }
    socket.emit("userTyping", input);
  };

  const ticketSubjectMapping = ticketDetails && {
    Order: [
      { title: "Subject", content: ticketDetails.subject },
      { title: "Order Request", content: ticketDetails.request },
      { title: "Order ID", content: ticketDetails.orderId },
      { title: "Message", content: ticketDetails.message },
    ],
    Payment: [
      { title: "Subject", content: ticketDetails.subject },
      { title: "Payment Request", content: ticketDetails.request },
      { title: "Transaction ID", content: ticketDetails.transactionId },
      { title: "Email", content: ticketDetails.email },
      { title: "Amount", content: ticketDetails.amount },
      { title: "Message", content: ticketDetails.message },
    ],
    API: [
      { title: "Subject", content: ticketDetails.subject },
      {
        title: "Website URL",
        content: (
          <Link to={ticketDetails.websiteUrl} target="blank">
            {ticketDetails.websiteUrl}
          </Link>
        ),
      },
      {
        title: "Discount Service ID",
        content: ticketDetails.discountServiceId,
      },
      { title: "Whatsapp", content: ticketDetails.whatsapp },
      { title: "Message", content: ticketDetails.message },
    ],
    Service: [
      { title: "Subject", content: ticketDetails.subject },
      { title: "Message", content: ticketDetails.message },
    ],
    "Bug Report": [
      { title: "Subject", content: ticketDetails.subject },
      { title: "Bug Request", content: ticketDetails.request },
      { title: "Message", content: ticketDetails.message },
    ],
    Request: [
      { title: "Subject", content: ticketDetails.subject },
      { title: "Request", content: ticketDetails.request },
      { title: "Message", content: ticketDetails.message },
    ],
    "Child Panel": [
      { title: "Subject", content: ticketDetails.subject },
      { title: "Child Panel Request", content: ticketDetails.request },
      { title: "Message", content: ticketDetails.message },
    ],
    Other: [
      { title: "Subject", content: ticketDetails.subject },
      { title: "Message", content: ticketDetails.message },
    ],
  };

  const MessageDisplay = ({ title, content }) => (
    <React.Fragment>
      <div className="vc-rcrr4d">
        <strong>{title}</strong>: {parse(content)}
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <LogHeader />
      <LogNavLinks />
      <Integrations />
      <div
        className={darkMode ? "dark-view-chat" : "view-chat"}
        style={myStyles}
      >
        <TopNav />
        <div className="view-chat-container">
          {ticketDetails ? (
            <div className={darkMode ? "dark-viewchat" : "viewchat"}>
              <div
                className="viewchat-head"
                id={darkMode ? "mydarkbottomborder" : "mylightbottomborder"}
              >
                <h4 className="fw-bold">
                  {ticketDetails.subject}{" "}
                  {ticketDetails.request && ` - ${ticketDetails.request}`}
                </h4>
              </div>
              <div className="viewchat-con" ref={containerRef}>
                <div className="viewchatCl">
                  <div className="d-flex">
                    <div className="viewchatCl-msg">
                      {ticketSubjectMapping[ticketDetails.subject].map(
                        ({ title, content }, index) => (
                          <MessageDisplay
                            key={index}
                            title={title}
                            content={content}
                          />
                        )
                      )}
                    </div>
                    <FaUserCircle className="vc-mu" />
                  </div>
                  <p>
                    <strong>
                      {username.length > 0 ? username[0].username : ""}
                    </strong>
                    {"  "}
                    {formatDate(ticketDetails.timestamp)}
                  </p>
                </div>

                <div className="viewchat-messages">
                  {messages.map((msg) => (
                    <div
                      key={msg.timestamp}
                      className={`vc-message ${
                        msg.sender === "user" ? "user" : "chatSupport"
                      }`}
                    >
                      {msg.sender === "user" ? (
                        <>
                          <div className="d-flex">
                            <div className="vc-mscb">{parse(msg.content)}</div>
                            <FaUserCircle className="vc-mu" />
                          </div>
                          <p>
                            <strong>
                              {username.length > 0 ? username[0].username : ""}
                            </strong>
                            {"  "}
                            {msg.timestamp && formatDate(msg.timestamp)}
                          </p>
                        </>
                      ) : (
                        <>
                          <div className="d-flex">
                            <FaHeadset className="vc-su" />
                            <div className="vc-adminmscb">
                              {parse(msg.content)}
                            </div>
                          </div>
                          <p>
                            <strong>{msg.sender}</strong>
                            {"  "}
                            {msg.timestamp && formatDate(msg.timestamp)}
                          </p>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {ticketDetails.status !== "Solved" && (
                <React.Fragment>
                  <div className="viewchat-input mt-2">
                    <p>Message</p>
                    <TipTapEditor
                      content={message}
                      useage="supports"
                      setContent={handleMessageInput}
                      leaveExtenstions={[
                        "strike",
                        "bold",
                        "link",
                        "image",
                        "underline",
                      ]}
                      placeholder="Type your message..."
                    />
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button
                      onClick={handleSendMessage}
                      className="vc-subMesg"
                      disabled={isButtonDisabled}
                    >
                      Send Message
                    </button>
                  </div>
                </React.Fragment>
              )}
            </div>
          ) : (
            <div className="clmaxwifomob">
              <FetchingData />
            </div>
          )}
          <ValidTag />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewChat;
