import "../styles/fetchingdata.css";

function FetchingData() {
  return (
    <div className="fetchingDataCon">
      <div className="fdcontent">
        <div className="adeditservspinner"></div>
      </div>
    </div>
  );
}

export default FetchingData;
