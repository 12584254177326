import search from "../../assets/images/search.png";
import "../styles/notfound.css";

function NotFound({ value }) {
  return (
    <div className="notFoundCon">
      <div className="not-found">
        <div className="kgffgkf">
          <img src={search} alt="" className="kvkbf" />
          <p className="fw-bold mt-3">{value}</p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
