import { useState, useEffect, useContext } from "react";
import "../styles/editprovider.css";
import axios from "axios";
import { AppContext } from "../../context/AppContext";
import Switch from "../shared/Switch";

function EditProvider({
  selectedProvider,
  setSelectedProvider,
  adminApiKey,
  siteUrl,
}) {
  const [provider, setProvider] = useState(null);
  const [sync, setSync] = useState(null);
  const [providerPercentage, setProviderPercentage] = useState(0);
  const {
    panelId,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
  } = useContext(AppContext);
  const [updatingProvider, setUpdatingProvider] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [providerKey, setProviderKey] = useState("");

  const hideEditProvider = (event) => {
    // Check if the click occurred outside the content
    if (event.target.classList.contains("edseroverlay")) {
      setSelectedProvider(null);
    }
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };
  useEffect(() => {
    const fetchProviders = async () => {
      if (adminApiKey) {
        try {
          const url = `${siteUrl}/providers/get/id`;
          const data = {
            uid: selectedProvider.uid,
            panelId: panelId,
            adminKey: adminApiKey,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setProvider(response.data);
          setProviderName(response.data.url);
          setSync(response.data.sync);
          setProviderPercentage(response.data.percentage);
        } catch (error) {}
      }
    };
    fetchProviders();
  }, [siteUrl, adminApiKey, selectedProvider, panelId]);

  const providerNameChange = (e) => {
    const value = e.target.value;
    setProviderName(value);
  };

  const providerKeyChange = (e) => {
    const value = e.target.value;
    setProviderKey(value);
  };

  const updateProvider = async () => {
    setUpdatingProvider(true);
    if (adminApiKey) {
      try {
        const url = `${siteUrl}/providers/update`;
        const data = {
          uid: selectedProvider.uid,
          panelId: panelId,
          adminKey: adminApiKey,
          sync: sync,
          percentage: providerPercentage,
          url: providerName,
          key: providerKey,
        };
        await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setUpdatingProvider(false);
        setSelectedProvider(null);
        Notify("success", "Updated Successfully");
      } catch (error) {
        setUpdatingProvider(false);
        Notify("error", error.message);
      }
    }
  };

  const handleSyncQuantityUpdate = (newValue) => {
    setSync(newValue);
  };

  const toggleSyncQuantity = async () => {
    const newValue = !sync;
    setSync(newValue); // Update local state immediately
    handleSyncQuantityUpdate(newValue); // Update database
  };

  const providerPercentageChange = (e) => {
    const value = e.target.value;
    const mainValue = value.trim() === "" ? 0 : parseInt(value);
    setProviderPercentage(mainValue);
  };

  return provider ? (
    <div className="edseroverlay" onClick={(event) => hideEditProvider(event)}>
      <div className="editservcon">
        <div className="adedmethodhead">
          <h6>Editing {provider.url}</h6>
        </div>
        <div className="adedmthbba">
          <label htmlFor="editservnname" className="fw-semibold">
            Domain
          </label>
          <input
            type="text"
            placeholder="example.com"
            name="editservnname"
            id="editprovselect"
            onChange={providerNameChange}
            value={providerName}
          />
          <label htmlFor="editservicedescription" className="fw-semibold mt-3">
            Key
          </label>
          <input
            type="text"
            name="editservnname"
            id="editprovselect"
            placeholder="Your API Key"
            onChange={providerKeyChange}
            value={providerKey}
          />
          <div className="adsyncproviders">
            <strong>Sync services from provider</strong>
            <Switch syncValue={sync} onToggle={toggleSyncQuantity} />
          </div>
          {sync ? (
            <>
              <label
                htmlFor="editservicedescription"
                className="fw-semibold mt-3"
              >
                Percentage Profit
              </label>
              <input
                type="text"
                name="editservnname"
                id="editprovselect"
                placeholder="Your percentage profit"
                onChange={providerPercentageChange}
                value={providerPercentage}
              />
            </>
          ) : (
            ""
          )}
        </div>
        <div className="adeditminmaxpaymth">
          <button className="adeditsavebtn" onClick={updateProvider}>
            {updatingProvider ? (
              <div className="adeditservspinner"></div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default EditProvider;
