import "../styles/footer.css";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTiktok, FaTwitter } from "react-icons/fa"; // Assuming FaTwitter is the correct icon
import { AppContext } from "../../context/AppContext";
import ValidTag from "../../utils/ValidTag";
import { useContext } from "react";

function Footer() {
  const { myStyles, darkMode } = useContext(AppContext);

  return (
    <footer
      style={myStyles}
      className={darkMode ? "cldarkfoot" : "cllightfoot"}
    >
      <div className="clfootcon">
        <div className="clfootfcol">
          <img src={logo} alt="Logo" />
          <span>
            Empower your social media journey with the No.1 SMM Panel where
            excellence meets results. As the leading social media services
            provider, We deliver top-notch solutions to elevate your online
            presence. Choose success, choose the best.
          </span>
        </div>
        <div className="clfootnavlinks">
          <h5 className="fw-bold">Quick Links</h5>
          <Link to="/">Home</Link>
          <Link to="/services">Services</Link>
          <Link to="/api">API</Link>
          <Link to="/signin">Signin</Link>
          <Link to="/signup">Sign Up</Link>
          <Link to="/terms">Terms & Conditions</Link>
        </div>
        <div className="clfootservices">
          <h5 className="fw-bold">Services</h5>
          <Link to="/services?search=facebook">Facebook</Link>
          <Link to="/services?search=instagram">Instagram</Link>
          <Link to="/services?search=youtube">Youtube</Link>
          <Link to="/services?search=twitter">X (Formerly Twitter)</Link>
          <Link to="/services?search=linkedin">Linkedin</Link>
          <Link to="/services?search=tiktok">Tiktok</Link>
        </div>
      </div>
      <div className="clfootsocials">
        <div className="clfootsocialscon">
          <div className="clftsocials">
            <Link target="blank" to="https://instagram.com/">
              <FaInstagram />
            </Link>
            <Link target="blank" to="https://facebook.com/">
              <FaFacebook />
            </Link>
            <Link target="blank" to="https://tiktok.com/">
              <FaTiktok />
            </Link>
            <Link target="blank" to="https://twitter.com/">
              <FaTwitter />
            </Link>
          </div>
          <span>&copy; Copyright. All Rights Reserved.</span>
        </div>
      </div>
      <ValidTag />
    </footer>
  );
}

export default Footer;
