import React, { useEffect, useState, useContext } from "react";
import "../styles/faqs.css";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import TopNav from "../components/TopNav";
import NotFound from "../components/NotFound";
import FetchingData from "../shared/FetchingData";
import Header from "../shared/Header";
import Integrations from "../shared/Integrations";
import LogHeader from "../shared/LogHeader";
import LogNavLinks from "../shared/LogNavLinks";
import axios from "axios";
import Loading from "../shared/Loading";
import ValidTag from "../../utils/ValidTag";

function Faqs() {
  const {
    siteTitle,
    myStyles,
    currentUser,
    darkMode,
    loading,
    setLoading,
    panelId,
    siteData,
    siteUrl,
  } = useContext(AppContext);
  const [faqsDetails, setFaqsDetails] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    document.title = `FAQS | ${siteTitle}`;
    setLoading(false);
  }, [setLoading, siteTitle]);

  useEffect(() => {
    const fetchFaqsDetails = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "faqs",
          panelId: panelId,
        });
        let faqsArray = response.data;
        if (faqsArray.length !== 0) {
          faqsArray = faqsArray.sort((a, b) => a.position - b.position);
          const faqData = faqsArray.map((doc) => doc);
          setFaqsDetails(faqData);
        }
        setFetchingData(false);
      } catch (error) {}
    };
    fetchFaqsDetails();
  }, [panelId, siteUrl]);

  if (loading || siteData === null || fetchingData) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      {currentUser ? <LogNavLinks /> : isSmallScreen ? <Header /> : ""}
      <LogHeader />
      <Integrations />
      <div className={darkMode ? "darkFaqsFull" : "faqsFull"} style={myStyles}>
        <TopNav />
        <div className="faqs-container">
          {fetchingData ? (
            <div className="clfaqsmaxwfet">
              <FetchingData />
            </div>
          ) : (
            <>
              {faqsDetails.length === 0 ? (
                <div className="clfaqsmaxwfet">
                  <NotFound value={"No FAQ Found"} />
                </div>
              ) : (
                <div
                  className={darkMode ? "dark-faqs-details" : "faqs-details"}
                >
                  <div
                    id={darkMode ? "mydarkbottomborder" : "mylightbottomborder"}
                  >
                    <h1 className="fw-bold text-center mb-4">
                      Frequently Asked Questions
                    </h1>
                  </div>
                  {faqsDetails.map((faq) => (
                    <React.Fragment key={faq.id}>
                      <ul className="apiUL">
                        <li className="apiLI">
                          <Link className="apiA" to={`/faq/${faq.id}`}>
                            {faq.question}
                          </Link>
                        </li>
                      </ul>
                    </React.Fragment>
                  ))}
                </div>
              )}{" "}
            </>
          )}{" "}
          <ValidTag />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Faqs;
