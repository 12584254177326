import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import AddIcon from "../../assets/svg/addbal.svg";
import RemoveIcon from "../../assets/svg/removebal.svg";
import moneypay from "../../assets/svg/moneypay.svg";
import { formatDate } from "../shared/FormatDate";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import { MdAccountBalanceWallet } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import search from "../../assets/images/search.png";
import bcrypt from "bcryptjs";
import { AppContext } from "../../context/AppContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { convertCurrency } from "../shared/ConvertCurrency";
import { FaUserGear } from "react-icons/fa6";
import { TbPasswordUser } from "react-icons/tb";
import { FaUserSlash, FaShoppingCart } from "react-icons/fa";
import axios from "axios";

function EditUser({
  selectedUser,
  setSelectedUser,
  fetchUsers,
  actionsRef,
  setFetchUsers,
}) {
  const {
    selectedAdminRate,
    setNotifyType,
    setNotifyMessage,
    siteUrl,
    panelId,
    currentAdmin,
    setNotifyVisibility,
    setNotifyDuration,
    ratesData,
  } = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const [eUsername, setEUsername] = useState("");
  const [balanceAction, setBalanceAction] = useState("");
  const [balanceInput, setBalanceInput] = useState("");
  const [specificUserDetails, setSpecificUserDetails] = useState(null);
  const [eEmail, setEEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [fetchUser, setFetchUser] = useState(false);
  const [idCounter, setIdCounter] = useState(1);
  const [transHist, setTransHist] = useState([]);
  const [password, setPassword] = useState("");

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  useEffect(() => {
    if (selectedUser) {
      const fetchSpecificUserDetails = async () => {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "users",
            panelId: panelId,
          });
          if (Array.isArray(response.data)) {
            const filteredUsers = response.data.find((user) =>
              selectedUser.uid
                ? user.uid === selectedUser.uid
                : user.uid === selectedUser
            );
            setSpecificUserDetails(filteredUsers);
          }
        } catch (error) {
          setSpecificUserDetails(null);
        }
      };

      const fetchTransHis = async () => {
        try {
          const url = `${siteUrl}/crud/get/docs`;
          const data = {
            panelId: panelId,
            collection: "transactions",
          };
          const response = await axios.post(url, data);
          const filteredTransactions = response.data
            .filter((trans) =>
              selectedUser.uid
                ? trans.userId === selectedUser.uid
                : trans.userId === selectedUser
            )
            .filter((trans) =>
              selectedUser.uid
                ? trans.status === "success"
                : trans.status === "success"
            )
            .sort((a, b) => b.id - a.id);
          setTransHist(filteredTransactions);
        } catch (error) {
          setTransHist([]);
        }
      };

      fetchTransHis();
      fetchSpecificUserDetails();
    }
  }, [selectedUser, panelId, siteUrl, fetchUser]);

  const handleEditUserSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!eEmail && !eUsername) {
        throw new Error("Kindly enter a username or email");
      }
      if (eEmail.trim() === "" && eUsername.trim() === "") {
        throw new Error("Kindly enter a username or email");
      }
      if (eEmail) {
        const postData = async () => {
          const url = `${siteUrl}/user/edit/email`;
          const data = {
            uid: specificUserDetails.uid,
            email: eEmail,
            panelId: panelId,
            key: currentAdmin.apiKey,
          };
          try {
            const response = await axios.post(url, data, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            if (response.data.code === "update-success") {
              setFetchUser(!fetchUser);
              setFetchUsers(!fetchUsers);
              Notify(
                "success",
                `Updated ${specificUserDetails.username} Successfully`
              );
            }
          } catch (error) {
            Notify("error", error.response.data);
          }
        };
        postData();
      }
      if (eUsername) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "users",
            panelId: panelId,
          });
          const filteredUsers = response.data.some(
            (user) => user.username === eUsername
          );
          if (filteredUsers) {
            Notify("error", "Username already exists");
            return;
          }
          await axios.post(`${siteUrl}/crud/update/doc`, {
            collection: "users",
            panelId: panelId,
            uid: specificUserDetails.uid,
            data: { username: eUsername },
          });
          setFetchUser(!fetchUser);
          setFetchUsers(!fetchUsers);
          Notify(
            "success",
            `Updated ${specificUserDetails.username} Successfully`
          );
        } catch (error) {
          Notify(
            "error",
            `Error updating ${specificUserDetails.username}: ${error}`
          );
        }
      }
      setEEmail("");
      setEUsername("");
      setSelectedUser(null);
    } catch (error) {
      Notify("error", error.message);
    }
  };

  const idIncrement = async (collectionName) => {
    try {
      const url = `${siteUrl}/get/nextid`;
      const data = {
        collection: collectionName,
        panelId: panelId,
        key: currentAdmin.apiKey,
      };
      const response = await axios.post(url, data);
      setIdCounter(response.data.id);
    } catch (error) {
      setIdCounter(1);
    }
  };

  const banUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${siteUrl}/crud/update/doc`, {
        collection: "users",
        panelId: panelId,
        uid: specificUserDetails.uid,
        data: { status: "banned" },
      });
      setFetchUser(!fetchUser);
      setFetchUsers(!fetchUsers);
      setSelectedUser(null);
      Notify("success", `${specificUserDetails.username} Banned Successfully`);
    } catch (error) {
      Notify("error", error.response.data);
    }
  };

  const cancelUserActions = () => {
    setSelectedUser(null);
    setSpecificUserDetails(null);
    setTransHist([]);
  };

  const unbanUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${siteUrl}/crud/update/doc`, {
        collection: "users",
        panelId: panelId,
        uid: specificUserDetails.uid,
        data: { status: "offline" },
      });
      setFetchUser(!fetchUser);
      setFetchUsers(!fetchUsers);
      setSelectedUser(null);
      Notify(
        "success",
        `${specificUserDetails.username} Unbanned Successfully`
      );
    } catch (error) {
      Notify("error", error.message);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!password || password.trim() === "") {
        throw new Error("Kindly enter a password");
      }
      if (password) {
        const saltRounds = 10;
        const salt = await bcrypt.genSalt(saltRounds);
        const hashedPassword = await bcrypt.hash(password, salt);
        const postData = async () => {
          const url = `${siteUrl}/user/edit/password`;
          const data = {
            uid: specificUserDetails.uid,
            password: hashedPassword,
            panelId: panelId,
            key: currentAdmin.apiKey,
          };
          try {
            const response = await axios.post(url, data, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            if (response.data.code === "update-success") {
              setFetchUser(!fetchUser);
              setFetchUsers(!fetchUsers);
              setPassword("");
              setSelectedUser(null);
              Notify(
                "success",
                `Updated ${specificUserDetails.username} Successfully`
              );
            }
          } catch (error) {
            Notify("error", "Unexpected error occurred.");
          }
        };
        postData();
      }
    } catch (error) {
      Notify("error", error.response.data);
    }
  };

  const handleBalanceInput = (e) => {
    const value = e.target.value;
    setBalanceInput(value === "" ? "" : parseFloat(value));
    setIsButtonDisabled(false);
  };

  const handleEUsernameInput = (e) => {
    const value = e.target.value;
    setEUsername(value);
  };

  const handlepasswordInput = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleEEmailInput = (e) => {
    const value = e.target.value;
    setEEmail(value);
  };

  const handleManageBalance = () => {
    const useractions = document.querySelector(".adushide");
    useractions.classList.add("hide");
    const managebalanceCon = document.querySelector(".adusshoba");
    managebalanceCon.classList.remove("hide");
    idIncrement("transactions");
  };

  const handleEditUser = () => {
    const useractions = document.querySelector(".adushide");
    useractions.classList.add("hide");
    const editUserCon = document.querySelector(".adedituser");
    editUserCon.classList.remove("hide");
  };

  const handleEditPassword = () => {
    const useractions = document.querySelector(".adushide");
    useractions.classList.add("hide");
    const editUserCon = document.querySelector(".adeditpassword");
    editUserCon.classList.remove("hide");
  };

  const handleBalanceSubmit = async (e) => {
    e.preventDefault();
    if (specificUserDetails && balanceInput !== "" && balanceAction !== "") {
      const addBalance = balanceAction === "add";
      if (Object.keys(specificUserDetails).length > 0) {
        const convertedInput = convertCurrency(
          parseFloat(balanceInput),
          selectedAdminRate.label.substring(0, 3),
          "USD",
          ratesData
        );
        const noCommaInput = convertedInput.replace(/,/g, "");
        const usdInput = parseFloat(noCommaInput);
        const updatedBalance = addBalance
          ? specificUserDetails.balance + usdInput
          : specificUserDetails.balance - usdInput;

        if (isButtonDisabled) {
          return;
        }
        setIsButtonDisabled(true);
        try {
          const url = `${siteUrl}/user/update/balance`;
          const data = {
            updatedBalance: updatedBalance,
            balanceAction: balanceAction,
            balanceInput: usdInput,
            selectedUser: specificUserDetails,
            idCounter: idCounter,
            panelId: panelId,
            key: currentAdmin.apiKey,
          };

          const response = await axios.post(url, data);
          setFetchUsers(!fetchUsers);
          setIsButtonDisabled(true);
          idIncrement("transactions");
          setFetchUser(!fetchUser);
          setBalanceInput("");
          Notify("success", response.data);
        } catch (error) {
          Notify("error", "Error updating balance: ", error);
          console.log(error);
        }
      } else {
        Notify("error", "No user details found");
      }
    }
  };

  const hideUserActions = (event) => {
    if (event.target.classList.contains("adoverlay")) {
      setSelectedUser(null);
      setSpecificUserDetails(null);
      setTransHist([]);
      setEEmail("");
      setEUsername("");
      setBalanceAction("");
      setBalanceInput("");
      setPassword("");
    }
  };
  return (
    specificUserDetails && (
      <div
        className="adoverlay"
        onClick={(event) => hideUserActions(event)}
        ref={actionsRef}
      >
        <div className="aduscfn">
          <div className="adushide">
            <div className="mb-3">
              <strong>{specificUserDetails.username}'s actions</strong>
            </div>
            <div className="adusacts">
              <button className="adusagdm" onClick={handleManageBalance}>
                <MdAccountBalanceWallet className="aduscaicon" />
                Manage Balance
              </button>
              <button className="adusagdm" onClick={handleEditUser}>
                <FaUserGear className="aduscaicon" />
                Edit User
              </button>

              {specificUserDetails.status === "banned" ? (
                <button className="adusagdm" onClick={unbanUser}>
                  <FaUserCheck className="aduscaicon" /> Unban User
                </button>
              ) : (
                <button className="adusagdm" onClick={banUser}>
                  <FaUserSlash className="aduscaicon" />
                  Ban User
                </button>
              )}
              <button className="adusagdm" onClick={handleEditPassword}>
                <TbPasswordUser className="aduscaicon" />
                Change Password
              </button>
              <Link
                className="adusagdm"
                to={`/control-panel/orders/all?search=${specificUserDetails.username}`}
              >
                <FaShoppingCart className="aduscaicon" />
                User Orders
              </Link>
            </div>
          </div>
          <div className="adusshoba hide">
            <div className="d-flex justify-content-between mb-3">
              <strong>Edit {specificUserDetails.username}'s balance</strong>
              <div className="adusbale">
                <MdAccountBalanceWallet className="adbalicon" />
                {getCurrencySymbol(selectedAdminRate.label)}
                {convertCurrency(
                  specificUserDetails.balance,
                  "USD",
                  selectedAdminRate.label,
                  ratesData
                )}
              </div>
            </div>
            <form onSubmit={handleBalanceSubmit}>
              <div className="adbalformac">
                <input
                  type="number"
                  name="balance"
                  id="mgbalance"
                  placeholder="Enter Amount"
                  value={balanceInput}
                  onChange={handleBalanceInput}
                />
                <div className="mgbalanceactions">
                  <button
                    type="submit"
                    className="mgbaladd"
                    onClick={() => setBalanceAction("add")}
                    disabled={isButtonDisabled}
                  >
                    Add
                  </button>
                  <button
                    type="submit"
                    className="mgbalremove"
                    onClick={() => setBalanceAction("remove")}
                    disabled={isButtonDisabled}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </form>
            <div className="adtranshis mt-3">
              {transHist.length > 0 ? (
                <>
                  <h4 className="fw-bold">Transaction History</h4>
                  <table className="adtranstb">
                    <tbody>
                      {transHist.map((trans) => (
                        <React.Fragment key={trans.id}>
                          <tr>
                            <td className="adtranstd">
                              {trans.balanceAction === "add" ? (
                                <ReactSVG src={AddIcon} />
                              ) : trans.balanceAction === "remove" ? (
                                <ReactSVG src={RemoveIcon} />
                              ) : (
                                <ReactSVG src={moneypay} />
                              )}
                            </td>
                            <td className="adtranstd">
                              <div className="adhgfq">
                                <div className="fw-bold">
                                  {trans.balanceAction === "add"
                                    ? "+"
                                    : trans.balanceAction === "remove"
                                    ? "-"
                                    : "+"}
                                  {getCurrencySymbol(selectedAdminRate.label)}
                                  {convertCurrency(
                                    trans.amount,
                                    trans.currency || "USD",
                                    selectedAdminRate.label,
                                    ratesData
                                  )}
                                </div>
                                <p className="adtransdate">
                                  {formatDate(trans.timestamp)}
                                </p>
                              </div>
                            </td>
                            <td className="adtranstd fw-semibold">
                              <div className="adcredtext">
                                {trans.balanceAction === "add"
                                  ? "Credited by admin"
                                  : trans.balanceAction === "remove"
                                  ? "Debited by admin"
                                  : `Credited from ${trans.paymentMethod}`}
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <img src={search} alt="search" className="kvkbf mt-3" />
                  <p className="fw-bold mt-3">No transactions found.</p>
                </>
              )}
            </div>
          </div>
          <div className="adedituser hide">
            <strong>Editing {specificUserDetails.username}</strong>
            <form className="adedtform mt-2" onSubmit={handleEditUserSubmit}>
              <div>
                <label htmlFor="uname" className="text-start mb-2 fw-semibold">
                  Username
                </label>
                <input
                  type="text"
                  id="uname"
                  name="uname"
                  placeholder={specificUserDetails.username}
                  value={eUsername}
                  onChange={handleEUsernameInput}
                />
              </div>
              <div>
                <label
                  htmlFor="adEemail"
                  className="text-start mb-2 fw-semibold"
                >
                  Email
                </label>
                <input
                  type="email"
                  placeholder={specificUserDetails.email}
                  id="adEemail"
                  name="adEemail"
                  value={eEmail}
                  onChange={handleEEmailInput}
                />
              </div>
              <button type="submit" className="adeddsave">
                Save
              </button>
            </form>
          </div>
          <div className="adeditpassword hide">
            <strong>Editing {specificUserDetails.username}</strong>
            <form className="adedtform mt-2" onSubmit={handlePasswordSubmit}>
              <div>
                <label
                  htmlFor="password"
                  className="text-start mb-2 fw-semibold"
                >
                  New Password
                </label>
                <div className="claccpassdiv">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Kindly enter a password"
                    id="adpass"
                    name="adpass"
                    value={password}
                    onChange={handlepasswordInput}
                  />
                  {showPassword && password.trim() !== "" && (
                    <FaEyeSlash
                      className="claccpasswicon"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                  {!showPassword && password.trim() !== "" && (
                    <FaEye
                      className="claccpasswicon"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </div>
              </div>
              <button type="submit" className="adeddsave">
                Save
              </button>
            </form>
          </div>
          <button className="adcanbut mt-3" onClick={cancelUserActions}>
            Cancel
          </button>
        </div>
      </div>
    )
  );
}

export default EditUser;
