import "../styles/pagenotfound.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { MdOutlineErrorOutline } from "react-icons/md"; // Import the icon

function PageNotFound() {
  const navigate = useNavigate();
  const { siteTitle } = useContext(AppContext);

  useEffect(() => {
    document.title = `404 | ${siteTitle}`;
  }, [siteTitle]);

  const goHome = () => {
    navigate("/");
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="pagenotfound-container">
      <div className="pagenotfound-content">
        <div className="pagenotfound-icon">
          <MdOutlineErrorOutline size={120} color="#ff5a5f" />
        </div>
        <div className="pagenotfound-text">
          <h1 className="pagenotfound-title">404</h1>
          <h2 className="pagenotfound-subtitle">Oops! Page Not Found</h2>
          <p className="pagenotfound-message">
            Sorry, the page you're looking for doesn't exist. It might have been
            removed or is temporarily unavailable.
          </p>
          <button className="pagenotfound-button" onClick={goHome}>
            Return to Home
          </button>
          <button className="pagenotfound-goback-button" onClick={goBack}>
            Go Back
          </button>
        </div>
      </div>
      <div className="pagenotfound-background"></div>
    </div>
  );
}

export default PageNotFound;
