import TopNav from "../components/TopNav";
import "../styles/pay.css";
import React, { useState, useContext, useEffect } from "react";
import Integrations from "../shared/Integrations";
import LogNavLinks from "../shared/LogNavLinks";
import LogHeader from "../shared/LogHeader";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import Select from "react-select";
import axios from "axios";
import { FaArrowRightLong } from "react-icons/fa6";
import { convertCurrency } from "../shared/ConvertCurrency";
import { getData } from "../../utils/indexedDB";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import Loading from "../shared/Loading";
import ValidTag from "../../utils/ValidTag";

function Pay() {
  const {
    myStyles,
    siteData,
    darkMode,
    siteTitle,
    siteUrl,
    currentUser,
    selectedRate,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    ratesData,
    panelId,
  } = useContext(AppContext);
  const [user, setUser] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [fetchingGateway, setFetchingGateway] = useState(true);
  const [paymentQuantity, setPaymentQuantity] = useState(0);
  const [tutorialLink, setTutorialLink] = useState("");
  const [gateways, setGateways] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  useEffect(() => {
    document.title = `Pay | ${siteTitle}`;
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (!currentUser) {
        navigate("/");
      }
    };
    onAuth();
    setLoading(false);
  }, [navigate, setLoading, siteTitle]);

  useEffect(() => {
    const getTutorialLink = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "pages",
            key: currentUser.apiKey,
            panelId: panelId,
          });
          const payDoc = response.data.find((page) => page.uid === "pay");
          setTutorialLink(payDoc.tutorial);
        } catch (error) {}
      }
    };
    getTutorialLink();
  }, [panelId, currentUser, siteUrl]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (currentUser && currentUser.uid) {
        const roundedBalance = currentUser.balance.toFixed(3);
        setUser({ ...currentUser, balance: roundedBalance });
      }
    };
    fetchUserDetails();
  }, [currentUser]);

  useEffect(() => {
    const fetchPaymentGateways = async () => {
      if (currentUser && currentUser.uid) {
        try {
          const url = `${siteUrl}/payment/gateways/get`;
          const data = {
            uid: currentUser.uid,
            panelId: panelId,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setGateways(response.data);
          setFetchingGateway(false);
        } catch (error) {
          setFetchingGateway(false);
        }
      }
    };
    fetchPaymentGateways();
  }, [siteUrl, currentUser, panelId]);

  if (loading || siteData === null || fetchingGateway) {
    return <Loading />;
  }

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust z-index as needed
    control: (styles) => ({
      ...styles,
      borderRadius: "10px", // Add border-radius to the control (main container)
      borderColor: "var(--stbasebgcolor)",
      color: darkMode ? "white" : "black",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--stbaseactcolor)"
          : "var(--stbasebgcolor)",
        color: "white",
        fontSize: "var(--selectfs)",
      };
    },
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    const parsedValue = parseFloat(value);
    setPaymentQuantity(parsedValue);
  };

  const handlePay = async () => {
    const convertedMin = convertCurrency(
      selectedOption.min,
      "USD",
      selectedRate?.label,
      ratesData
    );
    const convertedMax = convertCurrency(
      selectedOption.max,
      "USD",
      selectedRate?.label,
      ratesData
    );
    const noCommaMin = convertedMin.replace(/,/g, "");
    const noCommaMax = convertedMax.replace(/,/g, "");
    if (
      paymentQuantity < parseFloat(noCommaMin) ||
      paymentQuantity > parseFloat(noCommaMax)
    ) {
      Notify(
        "error",
        `Amount should be between ${convertedMin} and ${convertedMax}`
      );
      return;
    }
    if (selectedOption.value === "flutterwave") {
      navigate("/pay/flutterwave", {
        state: {
          public_key: selectedOption.public_key,
          amount: paymentQuantity,
          currency: selectedRate?.label,
          email: user.email,
          name: user.username,
          phone_number: user.phoneNumber,
          title: siteTitle,
          description: selectedOption.paymentDescription.replace(/&&n/g, "\n"),
        },
      });
    }

    if (selectedOption.value === "paystack") {
      navigate("/pay/paystack", {
        state: {
          publicKey: selectedOption.public_key,
          amount: paymentQuantity,
          email: user.email,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <LogHeader />
      <LogNavLinks />
      <Integrations />
      <div className={darkMode ? "dark-cpay" : "cpay"} style={myStyles}>
        <TopNav />
        <div className="pay-con">
          <div className={darkMode ? "dark-cpayCon" : "cpayCon"}>
            <div className="cpaymeentgateways">
              <Select
                className="gatewaysOptions"
                options={gateways}
                value={selectedOption}
                onChange={handleChange}
                styles={selectStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    primary: "var(--sitecolor)",
                  },
                })}
              />
            </div>
            {selectedOption ? (
              <div className="cpaymentdesc">
                <pre className="cpgdescription">
                  {selectedOption.description.replace(/&&n/g, "\n")}
                </pre>
                <div className="cppaymentquantity">
                  <span>
                    Min:{" "}
                    <span>
                      {getCurrencySymbol(selectedRate?.label)}
                      {convertCurrency(
                        selectedOption.min,
                        "USD",
                        selectedRate?.label,
                        ratesData
                      )}
                    </span>
                  </span>
                  <span>
                    Max:{" "}
                    <span>
                      {getCurrencySymbol(selectedRate?.label)}
                      {convertCurrency(
                        selectedOption.max,
                        "USD",
                        selectedRate?.label,
                        ratesData
                      )}
                    </span>
                  </span>
                </div>
                {selectedOption.value === "manual" ? (
                  ""
                ) : (
                  <input
                    type="number"
                    name="paymentQuantity"
                    id="paymentQuantity"
                    value={paymentQuantity}
                    onChange={handleQuantityChange}
                  />
                )}
                {paymentQuantity <= 0 ||
                isNaN(paymentQuantity) ||
                selectedOption.value === "manual" ? (
                  ""
                ) : (
                  <button className="cpgpaybtn" onClick={handlePay}>
                    Pay <FaArrowRightLong className="cpgpaybtnicon" />
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          {tutorialLink && tutorialLink !== "" ? (
            <div className="cllogtutorialcon">
              <iframe
                className="cllogtutorial"
                src={tutorialLink}
                title="Tutorial"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            ""
          )}
          <div></div>
          <ValidTag />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Pay;
