import { createContext, useState, useEffect } from "react";
import { getData } from "../utils/indexedDB";
import axios from "axios";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [panelId, setPanelId] = useState(0);
  const [ratesData, setRatesData] = useState({});
  const [siteData, setSiteData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [myAdminStyles, setMyAdminStyles] = useState(null);
  const [myStyles, setMyStyles] = useState(null);
  const [fetchCurrentUser, setFetchCurrentUser] = useState(false);
  const [fetchSiteData, setFetchSiteData] = useState(false);
  const [fetchCurrentAdmin, setFetchCurrentAdmin] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [logoUrl, setLogoUrl] = useState("");
  const storedCurrency = JSON.parse(localStorage.getItem("currency")) || null;
  const storedAdminCurrency = JSON.parse(
    localStorage.getItem("currencyAdmin")
  ) || {
    value: 1,
    label: "USD - United States Dollar",
  };

  const siteColor = siteData?.siteColor || "purple";
  const themeColor = siteColor;
  const storedDarkMode = localStorage.getItem("darkMode");
  const prefersDarkScheme = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;
  const initialDarkMode =
    storedDarkMode !== null ? JSON.parse(storedDarkMode) : prefersDarkScheme;
  const [darkMode, setDarkMode] = useState(initialDarkMode);
  const [selectedRate, setSelectedRate] = useState(storedCurrency);
  const [notifyMessage, setNotifyMessage] = useState("");
  const [notifyVisibility, setNotifyVisibility] = useState(false);
  const [notifyType, setNotifyType] = useState("");
  const [notifyDuration, setNotifyDuration] = useState(4000);
  const [selectedAdminRate, setSelectedAdminRate] =
    useState(storedAdminCurrency);
  const localcurrency = localStorage.getItem("currency");
  const currentUrl = window.location.href.replace(/^https?:\/\//, "");
  const isAdmin = window.location.pathname.includes("/control-panel");
  const bodyStyle = document.body.style;
  let domain = currentUrl.split("/")[0];
  if (domain.startsWith("www.")) {
    domain = domain.slice(4);
  }
  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
    localStorage.setItem("themePreference", darkMode ? "dark" : "light");
  }, [darkMode]);

  useEffect(() => {
    localStorage.setItem("currency", JSON.stringify(selectedRate));
  }, [selectedRate]);

  useEffect(() => {
    localStorage.setItem("currencyAdmin", JSON.stringify(selectedAdminRate));
  }, [selectedAdminRate]);

  useEffect(() => {
    const lightStyle = myStyles ? myStyles["--bglightcolor"] : "#fdfaff";
    const darkStyle = myStyles ? myStyles["--bgdarkcolor"] : "#0c0c0c";
    const adminStyle = myAdminStyles
      ? myAdminStyles["--addarkbgcolor"]
      : "#1a0029";
    if (isAdmin) {
      bodyStyle.setProperty("--addarkbgcolor", adminStyle);
      bodyStyle.backgroundColor = "var(--addarkbgcolor)";
    } else if (!isAdmin && darkMode) {
      bodyStyle.setProperty("--bgdarkcolor", darkStyle);
      bodyStyle.backgroundColor = "var(--bgdarkcolor)";
    } else {
      bodyStyle.setProperty("--bglightcolor", lightStyle);
      bodyStyle.backgroundColor = "var(--bglightcolor)";
    }
  }, [isAdmin, darkMode, bodyStyle, myStyles, myAdminStyles]);

  const env = process.env.NODE_ENV;

  const siteTitle = siteData?.title || "Panel";

  const siteUrl =
    env === "production"
      ? `https://${domain}/sys/api`
      : "http://localhost:4001";

  useEffect(() => {
    if (siteData && siteData.logoUrl) {
      async function setIcons() {
        const faviconLink = document.createElement("link");
        faviconLink.rel = "icon";
        faviconLink.type = "image/png";
        faviconLink.href = siteData.faviconUrl;
        document.head.appendChild(faviconLink);
        setLogoUrl(siteData.logoUrl);
      }
      setIcons();
    }
  }, [siteData]);
  const style = document.createElement("style");
  useEffect(() => {
    const cssText = isAdmin
      ? `
      ::-webkit-scrollbar {
        width: 7px;
        height: 12px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: var(--adbaseactcolor);
      }
      ::-webkit-scrollbar-track {
        background-color: var(--adbasebgcolor);
      }`
      : darkMode
      ? `
      ::-webkit-scrollbar {
        width: 7px;
        height: 12px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: var(--stbasebgcolor);
      }
      ::-webkit-scrollbar-track {
        background-color: var(--bgdarkcolor);
      }`
      : `::-webkit-scrollbar {
        width: 7px;
        height: 12px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: var(--bglightcolor);
      }
      ::-webkit-scrollbar-track {
        background-color: white;
      }`;
    style.appendChild(document.createTextNode(cssText));
    document.body.appendChild(style);

    return () => {
      document.body.removeChild(style);
    };
  }, [isAdmin, darkMode, style]);

  useEffect(() => {
    async function fetchPanelId() {
      try {
        const response = await axios.post(`${siteUrl}/panel/get/panelId`, {
          domain: domain,
        });
        setPanelId(response.data.panelId);
      } catch (error) {
        return null;
      }
    }
    fetchPanelId();
  }, [domain, siteUrl]);

  useEffect(() => {
    if (panelId) {
      async function fetchSiteColors() {
        try {
          const response = await axios.post(`${siteUrl}/panel/get/styles`, {
            panelId: panelId,
          });
          const stdata = response.data;
          setMyAdminStyles(stdata.adminStyles);
          setMyStyles(stdata.clientStyles);
        } catch (error) {
          return null;
        }
      }
      async function fetchSiteDetails() {
        try {
          const response = await axios.post(`${siteUrl}/panel/get/site/data`, {
            panelId: panelId,
          });
          const stdata = response.data;
          setSiteData(stdata);
        } catch (error) {
          return null;
        }
      }

      fetchSiteColors();
      fetchSiteDetails();
    }
  }, [panelId, siteUrl, fetchSiteData]);

  useEffect(() => {
    async function fetchExchangeRates() {
      if (panelId) {
        try {
          const response = await axios.post(`${siteUrl}/panel/get/rates`);
          const stdata = response.data;
          setRatesData(stdata);
        } catch (error) {
          return null;
        }
      }
    }
    fetchExchangeRates();
  }, [panelId, siteUrl]);

  useEffect(() => {
    if (siteData && siteData.defaultCurrency) {
      if (localcurrency === "null") {
        const defaultCurrency = {
          value: siteData.defaultCurrency.value,
          label: siteData.defaultCurrency.label,
        };
        setSelectedRate(defaultCurrency);
        localStorage.setItem("currency", JSON.stringify(defaultCurrency));
      }
    }
  }, [localcurrency, siteData, siteUrl]);

  useEffect(() => {
    const getUserAuth = async () => {
      const inUserDt = await getData("user_auth");

      if (inUserDt) {
        const response = await axios.post(`${siteUrl}/panel/get/current-user`, {
          panelId: panelId,
          uid: inUserDt.uid,
        });
        setCurrentUser(response.data);
      }
    };
    getUserAuth();
  }, [panelId, siteUrl, fetchCurrentUser]);

  useEffect(() => {
    const getAdminAuth = async () => {
      const inUserDt = await getData("admin_auth");
      if (inUserDt) {
        const response = await axios.post(
          `${siteUrl}/panel/get/current-admin`,
          {
            uid: inUserDt.uid,
            panelId: panelId,
          }
        );
        setCurrentAdmin(response.data);
      }
    };
    getAdminAuth();
  }, [panelId, siteUrl, fetchCurrentAdmin]);

  return (
    <AppContext.Provider
      value={{
        domain,
        logoUrl,
        myAdminStyles,
        currentUser,
        setCurrentUser,
        myStyles,
        loading,
        setLoading,
        storedCurrency,
        themeColor,
        fetchCurrentAdmin,

        fetchCurrentUser,
        setFetchCurrentAdmin,
        setFetchCurrentUser,
        fetchSiteData,
        setFetchSiteData,
        darkMode,
        siteUrl,
        ratesData,
        selectedAdminRate,
        setSelectedAdminRate,
        panelId,
        setDarkMode,
        selectedRate,
        setSelectedRate,
        siteData,
        currentAdmin,
        setCurrentAdmin,
        siteColor,
        siteTitle,
        notifyType,
        setNotifyType,
        setNotifyMessage,
        notifyVisibility,
        setNotifyVisibility,
        notifyMessage,
        setNotifyDuration,
        notifyDuration,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
