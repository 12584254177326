import { useLocation } from "react-router-dom";
import FlutterwavePayment from "../modules/FlutterwavePayment";

function FlutterwaveMethod() {
  const location = useLocation();
  const {
    public_key,
    amount,
    currency,
    email,
    name,
    phone_number,
    title,
    description
  } = location.state;
  const formattedCurrency = currency.substring(0, 3);
  return (
    <FlutterwavePayment
      public_key={public_key}
      amount={amount}
      currency={formattedCurrency}
      email={email}
      name={name}
      phone_number={phone_number}
      title={title}
      description={description}
    />
  );
}

export default FlutterwaveMethod;
