import { useLocation } from "react-router-dom";
import PaystackPayment from "../modules/PaystackPayment";

function PaystackMethod() {
  const location = useLocation();
  const { publicKey, amount, email } = location.state;

  return (
    <PaystackPayment publicKey={publicKey} amount={amount} email={email} />
  );
}

export default PaystackMethod;
