import axios from "axios";

async function getNextId(collection, panelId, siteUrl) {
  if (panelId) {
    try {
      const response = await axios.post(`${siteUrl}/get/nextId`, {
        collection: collection,
        panelId: panelId,
      });
      return response.data.id;
    } catch (error) {
      console.error(`Error fetching latest ${collection} ID:`, error);
      return 1;
    }
  }
}

export default getNextId;
