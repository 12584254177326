import "../styles/servicestable.css";
import { useContext, useState } from "react";
import React, { useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import { convertCurrency } from "../shared/ConvertCurrency";
import Select from "react-select";
import NotFound from "./NotFound";
import ViewService from "./ViewService";

function ServicesTable({ services, actionsRef, tableRef }) {
  const {
    darkMode,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    selectedRate,
    ratesData,
  } = useContext(AppContext);
  const [selectedService, setSelectedService] = useState(null);
  const [windowWidth, setWindowWidth] = useState(1200);
  const sortOptionValue = localStorage.getItem("sortOptionValue");
  const sortOptionLabel = localStorage.getItem("sortOptionLabel");

  useEffect(() => {
    const handleResize = () => {
      const ordersTableCon = document.querySelector(".services-table");
      if (ordersTableCon) {
        setWindowWidth(ordersTableCon.offsetWidth);
      }
    };

    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };
  const mainOption = {
    value: sortOptionValue || "sort",
    label: sortOptionLabel || "Sort...",
  };
  const sortOptions = [
    { value: "default", label: "Sort By Default" },
    { value: "category", label: "Sort By Category" },
    { value: "name", label: "Sort Alphabetically" },
    { value: "id", label: "Sort By ID" },
    { value: "date", label: "Sort With Date" },
    // Add more options as needed
  ];
  const [selectedOption, setSelectedOption] = useState(mainOption); // Set the default option
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // Save the selected sort option to localStorage
    localStorage.setItem("sortOptionValue", selectedOption.value);
    localStorage.setItem("sortOptionLabel", selectedOption.label);
  };

  const showDescription = (service) => {
    setSelectedService(service);
  };

  const sortingFunctions = {
    default: (a, b) => a.position - b.position,
    date: (a, b) => b.timestamp - a.timestamp,
    name: (a, b) => a.name.localeCompare(b.name),
    id: (a, b) => a.id - b.id,
    category: (a, b) => b.category.localeCompare(a.category),
  };

  const sortedServices = [...services].sort(
    sortingFunctions[selectedOption?.value]
  );

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust z-index as needed
    control: (styles) => ({
      ...styles,
      borderRadius: "10px", // Add border-radius to the control (main container)
      borderColor: "var(--stbasebgcolor)",
      color: darkMode ? "white" : "black",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--stbaseactcolor)"
          : "var(--stbasebgcolor)",
        color: "white",
      };
    },
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      Notify("success", "Successfully copied ID");
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  return (
    <React.Fragment>
      {services.length === 0 ? (
        <NotFound value={"No Service Found"} />
      ) : (
        <React.Fragment>
          <div className={darkMode ? "dark-servicessort" : "servicessort"}>
            <Select
              className="sortOptions"
              options={sortOptions}
              value={selectedOption}
              isSearchable={false}
              onChange={handleChange}
              styles={selectStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  primary: "var(--sitecolor)",
                },
              })}
            />
          </div>
          {windowWidth > 1200 ? (
            <div className="services-scrollX" ref={tableRef}>
              <table className={darkMode ? "dark-tb" : "light-tb"}>
                <thead>
                  <tr>
                    <th className="services-table-header sd-id">ID</th>
                    <th className="services-table-header">Category</th>
                    <th className="services-table-header">Service</th>
                    <th className="services-table-header">Price Per 1000</th>
                    <th className="services-table-header">Min</th>
                    <th className="services-table-header">Max</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedServices.map((service) => (
                    <React.Fragment key={service.id}>
                      <tr id={darkMode ? "mydarkborder" : "mylightborder"}>
                        <td className="services-table-data">
                          <span
                            className={darkMode ? "dark-cp-id" : "cp-id"}
                            onClick={() => copyToClipboard(service.id)}
                          >
                            {service.id}
                          </span>
                        </td>
                        <td className="services-table-data sd-name">
                          <strong>{service.category}</strong>
                        </td>
                        <td className="services-table-data sd-name">
                          <strong>{service.name}</strong>
                        </td>
                        <td className="services-table-data">
                          <span className="bd-dx">
                            ≈ {getCurrencySymbol(selectedRate?.label)}
                            {convertCurrency(
                              service.price,
                              service.providerCurrency,
                              selectedRate?.label,
                              ratesData
                            )}
                          </span>
                        </td>
                        <td className="services-table-data">
                          <span className="bd-dx">{service.min}</span>
                        </td>
                        <td className="services-table-data">
                          <span className="bd-dx">{service.max}</span>
                        </td>
                        <td className="services-table-data">
                          <span
                            className="show-desc"
                            onClick={() => showDescription(service)}
                          >
                            View More
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="clmborderscon" ref={tableRef}>
              {sortedServices.map((service) => (
                <div
                  className="clmborderdet"
                  key={service.id}
                  onClick={() => setSelectedService(service)}
                >
                  <div className="clmborderdethead">
                    <span
                      className={darkMode ? "dark-cp-id" : "cp-id"}
                      onClick={(e) => {
                        copyToClipboard(service.id);
                      }}
                    >
                      {service.id}
                    </span>
                    <strong className="clmbservicena">
                      {service.category}
                    </strong>
                    <strong>
                      {getCurrencySymbol(selectedRate.label)}
                      {convertCurrency(
                        service.price,
                        service.providerCurrency,
                        selectedRate.label,
                        ratesData
                      )}
                    </strong>
                  </div>
                  <div className="clmborderdetbody">
                    <strong className="clmbservicena">{service.name}</strong>
                    <strong>{service.min}</strong>
                    <strong>{service.max}</strong>
                  </div>
                </div>
              ))}
            </div>
          )}
          {selectedService && (
            <ViewService
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              componentRef={actionsRef}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ServicesTable;
