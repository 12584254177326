import "../styles/general.css";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import AdminHeader from "../components/AdminHeader";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import Select from "react-select";
import axios from "axios";
import Switch from "../shared/Switch";
import { getCurrencyName } from "../shared/GetCountryName";
import ControlPanel from "./ControlPanel";
import Loading from "../shared/Loading";

function General() {
  const {
    myAdminStyles,
    siteData,
    setLoading,
    loading,
    panelId,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    domain,
    siteUrl,
    currentAdmin,
    selectedAdminRate,
    setSelectedAdminRate,
    siteTitle,
  } = useContext(AppContext);
  const [siteInpTitle, setSiteInpTitle] = useState("");
  const [updatingSite, setUpdatingSite] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [updatingFavicon, setUpdatingFavicon] = useState(false);
  const [updatingLogo, setUpdatingLogo] = useState(false);
  const [rates, setRates] = useState([]);
  const [selectedFaviconFile, setSelectedFaviconFile] = useState(null);
  const [defaultRate, setDefaultRate] = useState(null);
  const [selectedLogoFile, setSelectedLogoFile] = useState(null);

  useEffect(() => {
    setLoading(false);
    if (siteInpTitle === "") {
      document.title = `General | ${siteTitle}`;
    } else {
      document.title = `General | ${siteInpTitle}`;
    }
  }, [siteInpTitle, siteTitle, setLoading]);

  useEffect(() => {
    async function fetchExchangeRates() {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "exchange_rates",
        });

        const data = response.data.filter((exc) => exc.uid === "latest");
        if (data) {
          return data[0].quotes;
        }
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
        return null;
      }
    }
    const fetchRates = async () => {
      const data = await fetchExchangeRates();
      if (data) {
        const formattedRates = Object.keys(data).map((currency) => ({
          label: `${currency} - ${getCurrencyName(currency)}`,
          value: data[currency],
        }));
        const sortedRates = formattedRates.sort((a, b) =>
          a.label.localeCompare(b.label)
        ); // Sort alphabetically by currency
        setRates(sortedRates);
      }
    };

    fetchRates();
  }, [siteUrl]);

  useEffect(() => {
    if (siteData && siteData.defaultCurrency) {
      setDefaultRate(siteData.defaultCurrency);
      setShowBanner(siteData.showBanner);
    }
  }, [siteData]);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };
  if (loading || siteData === null) {
    return <Loading />;
  }

  const toggleShowBanner = async () => {
    const newValue = !showBanner;
    setShowBanner(newValue);
  };

  const saveSiteData = async () => {
    setUpdatingSite(true);
    try {
      const data = {
        title: siteInpTitle,
        showBanner: showBanner,
        defaultCurrency: {
          label: defaultRate.label,
          value: defaultRate.value,
        },
      };
      await axios.post(`${siteUrl}/crud/update/doc`, {
        collection: "general",
        panelId: panelId,
        uid: "site",
        data: data,
      });
      Notify("success", "Updated Successfully");
      setUpdatingSite(false);
    } catch (error) {
      Notify("error", error.response.data);
      setUpdatingSite(false);
    }
  };

  const handleFaviconFileChange = (event) => {
    setSelectedFaviconFile(event.target.files[0]);
  };

  const handleFaviconUpload = async () => {
    if (!selectedFaviconFile || !panelId) return; // Make sure panelId is defined
    setUpdatingFavicon(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFaviconFile);
      formData.append("panelId", panelId);
      formData.append("domain", domain);
      await axios.post(`${siteUrl}/upload/favicon`, formData);
      Notify("success", "Favicon uploaded successfully");
      setUpdatingFavicon(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setUpdatingFavicon(false);
    }
  };

  const handleLogoFileChange = (event) => {
    setSelectedLogoFile(event.target.files[0]);
  };

  const handleLogoUpload = async () => {
    if (!selectedLogoFile || !panelId) return; // Make sure panelId is defined
    setUpdatingLogo(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedLogoFile);
      formData.append("domain", domain);
      formData.append("panelId", panelId); // Include panelId in the form data
      await axios.post(`${siteUrl}/upload/logo`, formData);
      Notify("success", "Logo uploaded successfully");
      setUpdatingLogo(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setUpdatingLogo(false);
    }
  };

  const handleStiteTitleChange = (e) => {
    const value = e.target.value;
    setSiteInpTitle(value);
  };

  const handleRateChange = async (selectedOption) => {
    setDefaultRate(selectedOption);
    await axios.post(`${siteUrl}/crud/update/doc`, {
      collection: "general",
      key: currentAdmin.apiKey,
      panelId: panelId,
      uid: "site",
      data: { defaultCurrency: selectedOption },
    });
  };

  const handleAdminRateChange = (selectedOption) => {
    setSelectedAdminRate(selectedOption);
  };

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust z-index as needed
    control: (styles) => ({
      ...styles,
      borderRadius: "10px", // Add border-radius to the control (main container)
      borderColor: "var(--adbasebgcolor)",
      color: "white",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--adbaseactcolor)"
          : "var(--adbasebgcolor)",
        fontSize: "var(--selectfs)",
        color: "white",
      };
    },
  };

  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="adgeneral" style={myAdminStyles}>
        <div className="adgeneralmaincon">
          <ControlPanel />
          <div className="adcpbdycon">
            <div className="adgeneralhead">
              <h6>General Settings</h6>
            </div>
            <div className="adgeneralbody">
              <div className="adgenheadcon">
                <h5>Site</h5>
                <button className="adsavegeneral" onClick={saveSiteData}>
                  {updatingSite ? (
                    <div className="adeditservspinner"></div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
              <div className="adgeneralsite">
                <div className="adgeneralst">
                  <div className="adgensttitle">
                    <div>
                      <span>Site Title</span>
                      <input
                        type="text"
                        name="siteInpTitle"
                        id="adgensitetitle"
                        value={siteInpTitle}
                        onChange={handleStiteTitleChange}
                      />
                    </div>
                  </div>

                  <div className="adgensttitle">
                    <div>
                      <span>Site Favicon</span>
                      <input
                        type="file"
                        onChange={handleFaviconFileChange}
                        accept="image/png"
                        className="fileuploadfavicon"
                      />
                      <button
                        className="fileuploadfaviconbtn"
                        onClick={handleFaviconUpload}
                        disabled={!selectedFaviconFile}
                      >
                        {updatingFavicon ? (
                          <div className="clsavingicon"></div>
                        ) : (
                          "Upload"
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="adgenstcolor">
                    <div>
                      <span>Site Logo</span>
                      <input
                        type="file"
                        onChange={handleLogoFileChange}
                        className="fileuploadfavicon"
                        accept="image/png,image/jpeg"
                      />
                      <button
                        className="fileuploadfaviconbtn"
                        onClick={handleLogoUpload}
                        disabled={!selectedLogoFile}
                      >
                        {updatingLogo ? (
                          <div className="clsavingicon"></div>
                        ) : (
                          "Upload"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="adgeneraladminstyle">
                <h5>Currency</h5>
                <div className="adgeneralst">
                  <div className="adgenndefcurr">
                    <span>Client Default Currency</span>
                    <Select
                      className="defaultCurrencyOption"
                      options={rates}
                      value={defaultRate}
                      onChange={handleRateChange}
                      styles={selectStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          primary: "var(--adbasebgcolor)",
                        },
                      })}
                    />
                  </div>
                  <div
                    className="adgenndefcurr"
                    style={{ borderTop: "1px solid var(--adbaseactcolor)" }}
                  >
                    <span>Admin Currency</span>
                    <Select
                      className="defaultCurrencyOption"
                      options={rates}
                      value={selectedAdminRate}
                      onChange={handleAdminRateChange}
                      styles={selectStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          primary: "var(--adbasebgcolor)",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="adgenndefcurr" style={{ marginTop: "20px" }}>
                <span>Show Banner</span>
                <Switch syncValue={showBanner} onToggle={toggleShowBanner} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default General;
