import UsersTable from "./UsersTable";
import NotFound from "../shared/NotFound";
import { useState, useEffect } from "react";

function ActiveUsers({
  sortedUserDetails,
  selectedUsers,
  tableRef,
  handleSelectAll,
  usersDetails,
  handleunbanusers,
  handlebanusers,
  handleUserSelect,
  showUserActions,
  selectedUser,
  setSelectedUser,
  showPassword,
  setShowPassword,
  showConfirmAction,
  cancelConfirmAction,
  hideConfirmAction,
  headText,
  confirmAction,
  buttonName,
  handleSelectedUsers,
  fetchUsers,
  setFetchUsers,
}) {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get("search")) {
      const filtered = sortedUserDetails.filter(
        (allUsers) => allUsers.status === "online"
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(sortedUserDetails);
    }
  }, [sortedUserDetails]);

  return filteredUsers.length === 0 ? (
    <NotFound
      value={
        searchParams.get("search")
          ? "No matching user found with the given username or ID"
          : "No active user found."
      }
    />
  ) : (
    <>
      <UsersTable
        sortedUserDetails={filteredUsers}
        selectedUsers={selectedUsers}
        tableRef={tableRef}
        handleSelectAll={handleSelectAll}
        usersDetails={usersDetails}
        handleunbanusers={handleunbanusers}
        handlebanusers={handlebanusers}
        handleUserSelect={handleUserSelect}
        showUserActions={showUserActions}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        showConfirmAction={showConfirmAction}
        cancelConfirmAction={cancelConfirmAction}
        hideConfirmAction={hideConfirmAction}
        headText={headText}
        confirmAction={confirmAction}
        buttonName={buttonName}
        handleSelectedUsers={handleSelectedUsers}
        setFetchUsers={setFetchUsers}
        fetchUsers={fetchUsers}
      />
    </>
  );
}

export default ActiveUsers;
