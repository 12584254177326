import "../styles/switch.css";
import { useState, useEffect } from "react";

function Switch({ syncValue, onToggle }) {
  const [isChecked, setIsChecked] = useState(syncValue);

  useEffect(() => {
    setIsChecked(syncValue);
  }, [syncValue]);

  const handleCheckboxChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    onToggle(newValue); // Call the onToggle function passed from parent component
  };

  return (
    <div className="switch-container">
      <label className="switch">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default Switch;
