import "../styles/pagination.css";
import { useContext} from "react";
import { AppContext } from "../../context/AppContext";
import ReactPaginate from "react-paginate";

const Pagination = ({ pageCount, handlePageClick, basePath }) => {
  const {  darkMode } = useContext(AppContext);

    // Check if there is only one page or less
  const shouldHidePagination = pageCount <= 1;

  // Render null if pagination should be hidden
  if (shouldHidePagination) {
    return null;
  }
  return (
    <ReactPaginate
      previousLabel={"←"}
      nextLabel={"→"}
      breakLabel={"..."}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      subContainerClassName={"pages pagination"}
      pageClassName={darkMode ? "dark-page-item" : "page-item"}
      previousClassName={darkMode ? "dark-prv-item" : "prv-item"}
      nextClassName={darkMode ? "dark-next-item" : "next-item"}
      previousLinkClassName="prv-link"
      pageLinkClassName="prv-link"
      nextLinkClassName="next-link"
      activeClassName={"acv"}
      initialPage={0}
      disabledClassName="disabled-pag"
      disableInitialCallback={true}
      pageLinkBuilder={(data) => `${basePath}?page=${data.selected + 1}`}
    />
  );
};

export default Pagination;
