import "../styles/topnav.css";
import { FaMoneyBill, FaPlusCircle } from "react-icons/fa";
import React, { useState, useEffect, useContext, useRef } from "react";
import AddIcon from "../../assets/svg/addbal.svg";
import { ReactSVG } from "react-svg";
import moneypay from "../../assets/svg/moneypay.svg";
import RemoveIcon from "../../assets/svg/removebal.svg";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { convertCurrency } from "../shared/ConvertCurrency";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import ThemeToggle from "../shared/ThemeToggle";
import { getCurrencyName } from "../shared/GetCountryName";
import { formatDate } from "../shared/FormatDate";
import { IoChevronDown } from "react-icons/io5";
import { FcCurrencyExchange } from "react-icons/fc";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { AppContext } from "../../context/AppContext";
import axios from "axios";

function TopNav() {
  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [showChangeCurrency, setShowChangeCurrency] = useState(false);
  const [showBalanceHistory, setShowBalanceHistory] = useState(false);
  const [showDropFeatures, setShowDropFeatures] = useState(false);
  const [isZoomingOut, setIsZoomingOut] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);
  const containerRef = useRef();
  const buttonRef = useRef();
  const {
    darkMode,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    ratesData,
    currentUser,
    logoUrl,
    selectedRate,
    panelId,
    siteUrl,
    setSelectedRate,
  } = useContext(AppContext);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        !containerRef.current?.contains(e.target) &&
        !buttonRef.current?.contains(e.target)
      ) {
        setIsFadingOut(true);
        setTimeout(() => setShowDropFeatures(false), 500);
      }
    };

    if (showDropFeatures) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showDropFeatures]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "exchange_rates",
      });
      if (Array.isArray(response.data)) {
        const data = response.data[0].quotes;
        if (data) {
          const formattedRates = Object.keys(data).map((currency) => ({
            label: `${currency} - ${getCurrencyName(currency)}`,
            value: data[currency],
          }));
          const sortedRates = formattedRates.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          setCurrencies(sortedRates);
        }
      }
    };

    fetchCurrencies();
  }, [siteUrl, panelId]);

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      setIsLoggedIn(true);
      const roundedBalance = currentUser.balance.toFixed(3);
      setUser({ ...currentUser, balance: roundedBalance });
    }
  }, [currentUser, panelId]);

  useEffect(() => {
    if (showChangeCurrency) {
      setIsZoomingOut(false);
    }
  }, [showChangeCurrency]);

  const toggleDropDown = () => {
    if (showDropFeatures) {
      setIsFadingOut(true);
      setTimeout(() => setShowDropFeatures(!showDropFeatures), 500);
    } else {
      setIsFadingOut(false);
      setShowDropFeatures(!showDropFeatures);
    }
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const handleSelectCurrency = (rate) => {
    setSelectedRate(rate);
    setShowChangeCurrency(false);
    Notify("success", "Changed successfully");
  };

  const hideOverlay = (event) => {
    if (event.target.classList.contains("stchangecurrencycon")) {
      setIsZoomingOut(true);
      setTimeout(() => setShowChangeCurrency(false), 500);
      setTimeout(() => setShowBalanceHistory(false), 500);
    } else {
      setIsZoomingOut(false);
    }
  };

  const getTransactions = async () => {
    try {
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "transactions",
        panelId: panelId,
      });
      const trandData = response.data
        .filter((trans) => trans.userId === currentUser.uid)
        .filter((trans) => trans.status === "success")
        .sort((a, b) => b.id - a.id);
      setTransactions(trandData);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const showBalanceHistoryOverlay = () => {
    setIsZoomingOut(false);
    getTransactions();
    setShowBalanceHistory(true);
  };

  return (
    <div className={darkMode ? "dttopnav" : "topnav"}>
      {isSmallScreen && !isLoggedIn ? (
        ""
      ) : (
        <React.Fragment>
          <div className={darkMode ? "dark-sttopnavcon" : "sttopnavcon"}>
            <div className="sttopnav">
              {isLoggedIn ? (
                <>
                  <div className="stneworder">
                    <Link to="/order">
                      <FaPlusCircle className="stloicon" />
                      New Order
                    </Link>
                  </div>
                  <div className="stuserbalance">
                    <button
                      className="stuserbalancebtn"
                      onClick={toggleDropDown}
                      ref={buttonRef}
                    >
                      <FaMoneyBill className="stbalicon" />
                      <strong>
                        ≈ {getCurrencySymbol(selectedRate?.label)}
                        {convertCurrency(
                          user.balance,
                          "USD",
                          selectedRate?.label,
                          ratesData
                        )}
                      </strong>
                      <IoChevronDown className="stbaldownicon" />
                    </button>
                    {showDropFeatures && (
                      <div
                        className="stuserdropft"
                        ref={containerRef}
                        style={
                          isFadingOut
                            ? { animation: "fadeOut 0.5s forwards" }
                            : { animation: "fadeIn 0.5s forwards" }
                        }
                      >
                        <div className="stuserdrop">
                          <div className="stuserdropbtns">
                            <button onClick={showBalanceHistoryOverlay}>
                              <HiClipboardDocumentList className="stuserdrpicon" />
                              Balance History
                            </button>
                            <button onClick={() => setShowChangeCurrency(true)}>
                              <FcCurrencyExchange className="stuserdrpicon" />
                              Change Currency
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="staddfunds">
                    <Link to="/pay">
                      <div className="staddfundicon">
                        {getCurrencySymbol(selectedRate?.label)}
                      </div>
                      Add Funds
                    </Link>
                  </div>
                  <div className="stthemetoggle">
                    <ThemeToggle />
                  </div>
                </>
              ) : (
                <div className="stlogin">
                  <Link to="/signin">
                    <FiLogIn className="stloicon" />
                    Sign In
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className={darkMode ? "darkmenuhd" : "menuhd"}>
            <img src={logoUrl} alt="" />
            <div className="stmbbbalacc">
              <button
                className="stuserbalancebtn"
                onClick={toggleDropDown}
                ref={buttonRef}
              >
                <FaMoneyBill className="stbalicon" />
                <strong>
                  ≈ {getCurrencySymbol(selectedRate?.label)}
                  {convertCurrency(
                    user.balance,
                    "USD",
                    selectedRate?.label,
                    ratesData
                  )}
                </strong>
                <IoChevronDown className="stbaldownicon" />
              </button>
              {showDropFeatures && (
                <div
                  className="stuserdropft"
                  ref={containerRef}
                  style={
                    isFadingOut
                      ? { animation: "fadeOut 0.5s forwards" }
                      : { animation: "fadeIn 0.5s forwards" }
                  }
                >
                  <div className="stuserdrop">
                    <div className="stuserdropbtns">
                      <Link to="/pay" className="stuserdrpadun">
                        <div className="stdrpadunicon">
                          {getCurrencySymbol(selectedRate?.label)}
                        </div>
                        Add funds
                      </Link>
                      <button onClick={showBalanceHistoryOverlay}>
                        <HiClipboardDocumentList className="stuserdrpicon" />
                        Balance History
                      </button>
                      <button onClick={() => setShowChangeCurrency(true)}>
                        <FcCurrencyExchange className="stuserdrpicon" />
                        Change Currency
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {(showChangeCurrency || showBalanceHistory) && (
            <div className="stchangecurrencycon" onClick={hideOverlay}>
              <div
                className="stchangecurrency"
                style={
                  isZoomingOut
                    ? { animation: "zoomOut 0.5s forwards" }
                    : { animation: "zoomIn 0.5s forwards" }
                }
              >
                {showChangeCurrency && (
                  <>
                    <span className="stchangecurrencyhd">Select Currency</span>
                    <div className="stcurrencies">
                      {currencies.map((currency, index) => (
                        <div
                          className="stcurrency"
                          key={index}
                          onClick={() => handleSelectCurrency(currency)}
                        >
                          {currency.label}
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {showBalanceHistory && (
                  <>
                    <span className="stchangecurrencyhd">Balance History</span>
                    <table className="stcurrencies">
                      <tbody>
                        {transactions.map((trans, index) => (
                          <tr className="sttransactiontr" key={index}>
                            <td className="sttransaction">
                              {trans.balanceAction === "add" ? (
                                <ReactSVG src={AddIcon} />
                              ) : trans.balanceAction === "remove" ? (
                                <ReactSVG src={RemoveIcon} />
                              ) : (
                                <ReactSVG src={moneypay} />
                              )}
                            </td>
                            <td className="sttransaction">
                              {trans.paymentMethod}
                            </td>
                            <td className="sttransactionamon">
                              <div className="flex-column-center">
                                <strong>
                                  {getCurrencySymbol(selectedRate?.label)}
                                  {convertCurrency(
                                    trans.amount,
                                    trans.currency,
                                    selectedRate?.label,
                                    ratesData
                                  )}
                                </strong>
                                {formatDate(trans.timestamp)}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default TopNav;
