import { Link } from "react-router-dom";
import search from "../../assets/images/search.png";
import "../styles/noorders.css";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

function NotFound({ value, linkName, url, dfStyle }) {
  const { darkMode, myStyles } = useContext(AppContext);
  return (
    <div className={dfStyle}>
      <div
        className={darkMode ? "dark-no-orders" : "no-orders"}
        style={myStyles}
      >
        <div className="kgffgkf">
          <img src={search} alt="" className="kvkbf" />
          <p className="fw-bold mt-3">{value}</p>
          {url && linkName ? (
            <Link to={url} className="jjvss">
              {linkName}
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default NotFound;
