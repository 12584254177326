import React, { useState, useEffect, useRef } from "react";
import { FaUserCheck, FaUserSlash } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import Checkbox from "./Checkbox";
import "../styles/selectedallactions.css";

function SelectedAllActions({
  selectedUsers,
  onChange,
  checked,
  handleBanUsers,
  handleUnbanUsers,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef();
  const menuRef = useRef();

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let handler = (e) => {
      if (
        !menuRef.current?.contains(e.target) &&
        !headerRef.current?.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <tr>
      <th className="aduserstb-head">
        <Checkbox onChange={onChange} checked={checked} />
      </th>
      <th className="aduserstb-head ">
        <span className="adsellent">{`Selected ${selectedUsers.length} Users`}</span>
      </th>
      <th className="aduserstb-head adusersthd">
        <button
          className="adusersactions ms-2"
          onClick={toggleOpen}
          ref={headerRef}
        >
          Actions
          <FaAngleDown className="adusacIcon" />
        </button>
        {isOpen && (
          <div className="adallsel" ref={menuRef}>
            <ul className="adulsel">
              <li className="adliuul">
                <button className="adbtnsel" onClick={handleBanUsers}>
                  <FaUserSlash className="aduselicon" />
                  Ban Users
                </button>
              </li>
              <li className="adliuul">
                <button className="adbtnsel" onClick={handleUnbanUsers}>
                  <FaUserCheck className="aduselicon" />
                  Unban Users
                </button>
              </li>
            </ul>
          </div>
        )}
      </th>
    </tr>
  );
}
export default SelectedAllActions;
