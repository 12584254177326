import React, { useContext, useEffect, useState } from "react";
import "../styles/dashboard.css";
import { AppContext } from "../../context/AppContext";
import Integrations from "../shared/Integrations";
import LogNavLinks from "../shared/LogNavLinks";
import LogHeader from "../shared/LogHeader";
import Loading from "../shared/Loading";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import { useNavigate, Link } from "react-router-dom";
import { getData } from "../../utils/indexedDB";
import { convertCurrency } from "../shared/ConvertCurrency";
import { FaTimesCircle } from "react-icons/fa";
import TopNav from "../components/TopNav";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { formatDate } from "../shared/FormatDate";
import { FaShoppingCart, FaMoneyBillWave, FaBoxes } from "react-icons/fa";
import ValidTag from "../../utils/ValidTag";

function Dashboard() {
  const {
    myStyles,
    siteTitle,
    darkMode,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    currentUser,
    ratesData,
    siteUrl,
    selectedRate,
    panelId,
  } = useContext(AppContext);
  const [ordersTrendsData, setOrdersTrendsData] = useState(null);
  const [revenueGrowthData, setRevenueGrowthData] = useState(null);
  const [fetchingData, setFetchingData] = useState(true);
  const [recentActivity, setRecentActivity] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [panelOrders, setPanelOrders] = useState(0);
  const [failedOrders, setFailedOrders] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const navigate = useNavigate();

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      Notify("success", "Successfully copied ID");
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  useEffect(() => {
    document.title = `Dashboard | ${siteTitle}`;
  }, [siteTitle]);

  useEffect(() => {
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (!currentUser) {
        navigate("/");
      }
    };
    onAuth();
  }, [navigate]);

  useEffect(() => {
    const getDashboardData = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/pages/dashboard-data`, {
            key: currentUser.apiKey,
            panelId: panelId,
            uid: currentUser.uid,
          });
          const dashboardData = response.data;
          setTotalRevenue(dashboardData.userSpent);
          setPanelOrders(dashboardData.panelOrders);
          setTotalOrders(dashboardData.userOrders);
          setFailedOrders(dashboardData.failedOrders);
          setOrdersTrendsData(dashboardData.ordersTrendsData);
          setRecentActivity(dashboardData.recentActivity);
          setRevenueGrowthData(
            dashboardData.revenueGrowthData.map((item) => ({
              ...item,
              amount: convertCurrency(
                item.amount,
                "USD",
                selectedRate?.label,
                ratesData
              ),
            }))
          );
          setFetchingData(false);
        } catch (error) {}
      }
    };
    getDashboardData();
  }, [panelId, siteUrl, currentUser, ratesData, selectedRate]);

  if (fetchingData) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Integrations />
      <LogHeader />
      <LogNavLinks />
      <div
        className="cldashboard"
        style={myStyles}
        data-theme={darkMode ? "dark" : "light"}
      >
        <TopNav />
        <div className="cldashboardmaincon">
          <div className="cldash-stats-grid">
            <div className="cldash-stats-card">
              <FaShoppingCart className="cldash-stats-icon" />
              <h3>Your Orders</h3>
              <p>{totalOrders}</p>
            </div>
            <div className="cldash-stats-card">
              <FaTimesCircle className="cldash-stats-icon" />
              <h3>Failed Orders</h3>
              <p>{failedOrders}</p>
            </div>
            <div className="cldash-stats-card">
              <FaBoxes className="cldash-stats-icon" />
              <h3>Panel Orders</h3>
              <p>{panelOrders}</p>
            </div>
            <div className="cldash-stats-card">
              <FaMoneyBillWave className="cldash-stats-icon" />
              <h3>You've Spent</h3>
              <p>
                {getCurrencySymbol(selectedRate?.label)}
                {convertCurrency(
                  totalRevenue,
                  "USD",
                  selectedRate?.label,
                  ratesData
                )}
              </p>
            </div>
          </div>

          <div className="cldash-charts-container">
            <div className="cldash-chart-card">
              <h3>Order Trends</h3>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={ordersTrendsData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "var(--bgmaincolor)",
                      borderRadius: "10px",
                      border: "2px solid var(--stbaseactcolor)",
                    }}
                    labelStyle={{
                      color: "var(--stbaseactcolor)",
                      fontWeight: "bold",
                    }}
                    itemStyle={{ color: "var(--sttextbgcolor)" }}
                  />
                  <Line
                    type="monotone"
                    dataKey="orders"
                    stroke={darkMode ? "#8884d8" : "#82ca9d"}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="cldash-chart-card">
              <h3>Your Transactions</h3>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={revenueGrowthData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "var(--bgmaincolor)",
                      borderRadius: "10px",
                      border: "2px solid var(--stbaseactcolor)",
                    }}
                    labelStyle={{
                      color: "var(--stbaseactcolor)",
                      fontWeight: "bold",
                    }}
                    itemStyle={{ color: "var(--sttextbgcolor)" }}
                  />
                  <Line
                    type="monotone"
                    dataKey="amount"
                    stroke={darkMode ? "#8884d8" : "#82ca9d"}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="cldash-recent-activity">
            <h3>Recent Activity</h3>
            <table className="cldash-recent-activity-table">
              <tbody>
                {recentActivity.some((act) => act.url) && (
                  <tr>
                    <th className="cldash-recent-th" colSpan="7">
                      Recent Orders
                    </th>
                  </tr>
                )}
                {recentActivity
                  .filter((act) => act.url)
                  .map((act, index) => (
                    <tr key={index}>
                      <td className="cldash-recent-td">
                        <span
                          className={darkMode ? "dark-cp-id" : "cp-id"}
                          onClick={() => copyToClipboard(act.id)}
                        >
                          {act.id}
                        </span>
                      </td>
                      <td className="cldash-recent-td cldash-text">
                        {act.category}
                      </td>
                      <td className="cldash-recent-td cldash-text">
                        {act.service}
                      </td>
                      <td
                        className={`cldash-recent-td  ${
                          act.status === "In progress"
                            ? "inprogress"
                            : act.status
                        }`}
                      >
                        {act.status}
                      </td>
                      <td className="cldash-recent-td">
                        {getCurrencySymbol(selectedRate?.label)}
                        {convertCurrency(
                          act.price,
                          "USD",
                          selectedRate?.label,
                          ratesData
                        )}
                      </td>
                      <td className="cldash-recent-td">
                        {formatDate(act.timestamp)}
                      </td>
                      <td className="cldash-recent-td">
                        <Link
                          to={`/orders/all?search=${act.id}`}
                          className="cldash-link-td"
                        >
                          View Order
                        </Link>
                      </td>
                    </tr>
                  ))}
                {recentActivity.some((act) => act.position) && (
                  <tr>
                    <th className="cldash-recent-th" colSpan="7">
                      Recently Added Services
                    </th>
                  </tr>
                )}
                {recentActivity
                  .filter((act) => act.position)
                  .map((act, index) => (
                    <tr key={index}>
                      <td className="cldash-recent-td">
                        <span
                          className={darkMode ? "dark-cp-id" : "cp-id"}
                          onClick={() => copyToClipboard(act.id)}
                        >
                          {act.id}
                        </span>
                      </td>
                      <td className="cldash-recent-td  cldash-text">
                        {act.category}
                      </td>
                      <td className="cldash-recent-td cldash-text">
                        {act.name}
                      </td>
                      <td className="cldash-recent-td">
                        {getCurrencySymbol(selectedRate?.label)}
                        {convertCurrency(
                          act.price,
                          act.providerCurrency,
                          selectedRate?.label,
                          ratesData
                        )}
                      </td>
                      <td className="cldash-recent-td">{act.type}</td>
                      <td className="cldash-recent-td">
                        {formatDate(act.timestamp)}
                      </td>
                      <td className="cldash-recent-td">
                        <Link
                          to={`/services?search=${act.id}`}
                          className="cldash-link-td"
                        >
                          View Service
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <ValidTag />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
