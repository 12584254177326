import "../styles/orders.css";
import React, { useEffect, useContext, useState, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { FaShoppingCart } from "react-icons/fa";
import { TbShoppingCartBolt, TbShoppingCartPause } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { BsFillCartXFill } from "react-icons/bs";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import InProgressOrders from "../components/InProgressOrders";
import FailedOrders from "../components/FailedOrders";
import LogNavLinks from "../shared/LogNavLinks";
import Integrations from "../shared/Integrations";
import { MdCancel } from "react-icons/md";
import LogHeader from "../shared/LogHeader";
import PartialOrders from "../components/PartialOrders";
import PendingOrders from "../components/PendingOrders";
import CompletedOrders from "../components/CompletedOrders";
import CancelledOrders from "../components/CancelledOrders";
import AllOrders from "../components/AllOrders";
import { getData } from "../../utils/indexedDB";
import { MdRemoveShoppingCart } from "react-icons/md";
import { BsCartCheckFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import TopNav from "../components/TopNav";
import FetchingData from "../shared/FetchingData";
import Loading from "../shared/Loading";
import ValidTag from "../../utils/ValidTag";

const fetchOrdersComponent = (status) => {
  // Define an object mapping statuses to components
  const orderStatusComponents = {
    all: AllOrders,
    active: InProgressOrders,
    pending: PendingOrders,
    partial: PartialOrders,
    failed: FailedOrders,
    cancelled: CancelledOrders,
    completed: CompletedOrders,
  };

  // Use the status parameter to look up the component from the object
  return orderStatusComponents[status]; // DefaultComponent is a placeholder for unknown statuses
};

function Orders() {
  const location = useLocation();
  const patchMatchRoute = (Route) => {
    if (Route === location.pathname) {
      return true;
    }
  };
  const { status } = useParams();
  const OrderComponent = fetchOrdersComponent(status);
  const {
    siteTitle,
    myStyles,
    darkMode,
    loading,
    setLoading,
    currentUser,
    panelId,
    siteUrl,
    siteData,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [showSearchOrder, setShowSearchOrder] = useState(false);
  const [allOrder, setAllOrder] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [allOrders, setAllOrders] = useState([]);
  const [mainOrders, setMainOrders] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [activeOrders, setActiveOrders] = useState(0);
  const [partialOrders, setPartialOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [fetchingOrders, setFetchingOrders] = useState(true);
  const [failedOrders, setFailedOrders] = useState(0);
  const [cancelledOrders, setCancelledOrders] = useState(0);
  const containerRef = useRef();
  const bodyRef = useRef();
  const tableRef = useRef();
  const actionsRef = useRef();
  const remains = status.slice(1);
  const OrderStat = status.charAt(0).toUpperCase() + remains;
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    document.title = `${OrderStat} Orders | ${siteTitle}`;
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (!currentUser) {
        navigate("/");
      }
    };
    onAuth();
    setLoading(false);
  }, [navigate, setLoading, OrderStat, siteTitle]);

  useEffect(() => {
    let handler = (e) => {
      if (
        !bodyRef.current?.contains(e.target) &&
        !tableRef.current?.contains(e.target) &&
        !actionsRef.current?.contains(e.target) &&
        !containerRef.current?.contains(e.target)
      ) {
        setShowSearchOrder(false);
        setSearchValue("");
        searchParams.delete("search");
        navigate(`${location.pathname}`);
        setAllOrders(mainOrders);
      }
    };
    if (showSearchOrder) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    const fetchAllOrdersSize = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "orders",
            panelId: panelId,
          });
          if (Array.isArray(response.data)) {
            const filteredOrders = response.data.filter(
              (order) => order.userUid === currentUser.uid
            );
            const numberOfOrders = filteredOrders.length;
            setAllOrder(numberOfOrders);
          }
        } catch (error) {
          console.error("Error getting users:", error);
        }
      }
    };

    const fetchPendingOrdersSize = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "orders",
            panelId: panelId,
          });
          if (Array.isArray(response.data)) {
            const filteredOrders = response.data
              .filter((order) => order.userUid === currentUser.uid)
              .filter((order) => order.status === "Pending");
            const numberOfOrders = filteredOrders.length;
            setPendingOrders(numberOfOrders);
          }
        } catch (error) {
          console.error("Error getting users:", error);
        }
      }
    };

    const fetchActiveOrdersSize = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "orders",
            panelId: panelId,
          });
          if (Array.isArray(response.data)) {
            const filteredOrders = response.data
              .filter((order) => order.userUid === currentUser.uid)
              .filter((order) => order.status === "In progress");
            const numberOfOrders = filteredOrders.length;
            setActiveOrders(numberOfOrders);
          }
        } catch (error) {
          console.error("Error getting users:", error);
        }
      }
    };

    const fetchPartialOrdersSize = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "orders",
            panelId: panelId,
          });
          if (Array.isArray(response.data)) {
            const filteredOrders = response.data
              .filter((order) => order.userUid === currentUser.uid)
              .filter((order) => order.status === "Partial");
            const numberOfOrders = filteredOrders.length;
            setPartialOrders(numberOfOrders);
          }
        } catch (error) {
          console.error("Error getting users:", error);
        }
      }
    };

    const fetchCancelledOrdersSize = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "orders",
            panelId: panelId,
          });
          if (Array.isArray(response.data)) {
            const filteredOrders = response.data
              .filter((order) => order.userUid === currentUser.uid)
              .filter((order) => order.status === "Canceled");
            const numberOfOrders = filteredOrders.length;
            setCancelledOrders(numberOfOrders);
          }
        } catch (error) {
          console.error("Error getting users:", error);
        }
      }
    };

    const fetchCompletedOrdersSize = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "orders",
            panelId: panelId,
          });
          if (Array.isArray(response.data)) {
            const filteredOrders = response.data
              .filter((order) => order.userUid === currentUser.uid)
              .filter((order) => order.status === "Completed");
            const numberOfOrders = filteredOrders.length;
            setCompletedOrders(numberOfOrders);
          }
        } catch (error) {
          console.error("Error getting users:", error);
        }
      }
    };
    const fetchFailedOrdersSize = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "orders",
            panelId: panelId,
          });
          if (Array.isArray(response.data)) {
            const filteredOrders = response.data
              .filter((order) => order.userUid === currentUser.uid)
              .filter((order) => order.status === "Failed");
            const numberOfOrders = filteredOrders.length;
            setFailedOrders(numberOfOrders);
          }
        } catch (error) {
          console.error("Error getting users:", error);
        }
      }
    };
    fetchCancelledOrdersSize();
    fetchPartialOrdersSize();
    fetchAllOrdersSize();
    fetchPendingOrdersSize();
    fetchFailedOrdersSize();
    fetchCompletedOrdersSize();
    fetchActiveOrdersSize();
  }, [currentUser, panelId, siteUrl]);

  useEffect(() => {
    const fetchAllOrders = async () => {
      try {
        if (currentUser) {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "orders",
            panelId: panelId,
          });
          if (Array.isArray(response.data)) {
            const filteredOrders = response.data
              .filter((order) => order.userUid === currentUser.uid)
              .sort((a, b) => b.id - a.id);
            setAllOrders(filteredOrders);
            setMainOrders(filteredOrders);
          }
        }
        setFetchingOrders(false);
      } catch (error) {
        setAllOrders([]);
      }
    };
    fetchAllOrders();
  }, [currentUser, panelId, siteUrl]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const SearchValue = searchParams.get("search");
    if (SearchValue !== null) {
      const handleSearchValue = () => {
        const parsedValue =
          SearchValue.trim() === ""
            ? ""
            : isNaN(SearchValue)
            ? String(SearchValue)
            : parseInt(SearchValue, 10);
        setSearchValue(parsedValue);
        const dupOrders = [...mainOrders];
        setAllOrders(
          dupOrders.filter((order) => {
            if (typeof parsedValue === "string") {
              return order.url
                .toLowerCase()
                .includes(parsedValue.toLowerCase());
            } else if (typeof parsedValue === "number") {
              return String(order.id).includes(String(parsedValue));
            }
            return false;
          })
        );
      };
      handleSearchValue();
      setShowSearchOrder(true);
    }
  }, [location.search, mainOrders]);

  if (loading || siteData === null || fetchingOrders) {
    return <Loading />;
  }

  const showSearchBar = () => {
    setShowSearchOrder(true);
  };

  const handleSearchInput = (e) => {
    const value = e.target.value;
    const parsedValue =
      value.trim() === ""
        ? ""
        : isNaN(value)
        ? String(value)
        : parseInt(value, 10);
    setSearchValue(parsedValue);
    if (parsedValue !== "") {
      searchParams.set("search", parsedValue);
      const dupOrders = [...mainOrders];
      setAllOrders(
        dupOrders.filter((order) => {
          if (typeof parsedValue === "string") {
            return order.url.toLowerCase().includes(parsedValue.toLowerCase());
          } else if (typeof parsedValue === "number") {
            return String(order.id).includes(String(parsedValue));
          }
          return false;
        })
      );
    } else {
      setAllOrders(mainOrders);
      searchParams.delete("search");
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleCancelSearchOrder = () => {
    setShowSearchOrder(false);
    setSearchValue("");
    searchParams.delete("search");
    const newUrl = `${window.location.pathname}`;
    navigate(newUrl);
    setAllOrders(mainOrders);
  };

  return (
    <React.Fragment>
      <Integrations />
      <LogHeader />
      <LogNavLinks />
      <div className={darkMode ? "ndnuu" : "rrdh"} style={myStyles}>
        <TopNav />
        <div className="orders-nav">
          <div className="ordersstatcon">
            <Link
              to="/orders/all"
              className={darkMode ? "darkorallorders" : "orallorders"}
            >
              <div className="orstatsecon-main">
                <FaShoppingCart className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/orders/all") ? "ordersrouteactivecolor" : ""
                }
              >
                All Orders
              </strong>
              <h4 className="aduserstat">{allOrder}</h4>
            </Link>
            <Link
              to="/orders/active"
              className={darkMode ? "darkoractiveorders" : "oractiveorders"}
            >
              <div className="orstatsecon-active">
                <TbShoppingCartBolt className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/orders/active")
                    ? "ordersrouteactivecolor"
                    : ""
                }
              >
                Active Orders
              </strong>
              <h4 className="aduserstat">{activeOrders}</h4>
            </Link>
            <Link
              to="/orders/pending"
              className={darkMode ? "darkorpendingorders" : "orpendingorders"}
            >
              <div className="orstatsecon-pending">
                <TbShoppingCartPause className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/orders/pending")
                    ? "ordersrouteactivecolor"
                    : ""
                }
              >
                Pending Orders
              </strong>
              <h4 className="aduserstat">{pendingOrders}</h4>
            </Link>
            <Link
              to="/orders/partial"
              className={darkMode ? "darkorpartialorders" : "orpartialorders"}
            >
              <div className="orstatsecon-partial">
                <MdOutlineRemoveShoppingCart className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/orders/partial")
                    ? "ordersrouteactivecolor"
                    : ""
                }
              >
                Partial Orders
              </strong>
              <h4 className="aduserstat">{partialOrders}</h4>
            </Link>
            <Link
              to="/orders/failed"
              className={darkMode ? "darkorfailedorders" : "orfailedorders"}
            >
              <div className="orstatsecon-dred">
                <BsFillCartXFill className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/orders/failed")
                    ? "ordersrouteactivecolor"
                    : ""
                }
              >
                Failed Orders
              </strong>
              <h4 className="aduserstat">{failedOrders}</h4>
            </Link>
            <Link
              to="/orders/cancelled"
              className={
                darkMode ? "darkorcancelledorders" : "orcancelledorders"
              }
            >
              <div className="orstatsecon-red">
                <MdRemoveShoppingCart className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/orders/cancelled")
                    ? "ordersrouteactivecolor"
                    : ""
                }
              >
                Cancelled Orders
              </strong>
              <h4 className="aduserstat">{cancelledOrders}</h4>
            </Link>
            <Link
              to="/orders/completed"
              className={
                darkMode ? "darkorcompletedorders" : "orcompletedorders"
              }
            >
              <div className="orstatsecon-green">
                <BsCartCheckFill className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/orders/completed")
                    ? "ordersrouteactivecolor"
                    : ""
                }
              >
                Completed Orders
              </strong>
              <h4 className="aduserstat">{completedOrders}</h4>
            </Link>
          </div>
          <div
            className={darkMode ? "darkordersearchcon" : "ordersearchcon"}
            ref={containerRef}
          >
            {showSearchOrder ? (
              <div className="searchorderDiv" ref={bodyRef}>
                <input
                  type="text"
                  name="ordersearch"
                  id={darkMode ? "darkordersearch" : "ordersearch"}
                  className={darkMode ? "text-white" : "text-dark"}
                  value={searchValue}
                  onChange={handleSearchInput}
                  placeholder="Search by ID or URL"
                />
                <MdCancel
                  className="adorcancelsearch"
                  onClick={handleCancelSearchOrder}
                />
              </div>
            ) : (
              <button
                className={darkMode ? "darkorderssearchbtn" : "orderssearchbtn"}
                onClick={showSearchBar}
              >
                <FaSearch className="orsearIcon" />
                <p>Search Order</p>
              </button>
            )}
          </div>
          {fetchingOrders ? (
            <div className="mt-3">
              <FetchingData />
            </div>
          ) : (
            <>
              {OrderComponent ? (
                <OrderComponent
                  allOrders={allOrders}
                  tableRef={tableRef}
                  actionsRef={actionsRef}
                />
              ) : null}
            </>
          )}
          <ValidTag />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Orders;
