import "../styles/refills.css";
import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import Integrations from "../shared/Integrations";
import Pagination from "../components/Pagination";
import LogNavLinks from "../shared/LogNavLinks";
import LogHeader from "../shared/LogHeader";
import Loading from "../shared/Loading";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getData } from "../../utils/indexedDB";
import TopNav from "../components/TopNav";
import axios from "axios";
import { formatDate } from "../shared/FormatDate";
import { MdCancel } from "react-icons/md";
import { FaSearch } from "react-icons/fa";

function Refills() {
  const {
    myStyles,
    siteTitle,
    darkMode,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    currentUser,
    siteUrl,
    panelId,
  } = useContext(AppContext);

  const [fetchingData, setFetchingData] = useState(true);
  const [refills, setRefills] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const [showSearchRefill, setShowSearchRefill] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [mainRefills, setMainRefills] = useState([]);
  const itemsPerPage = 25;
  const navigate = useNavigate();
  const bodyRef = useRef();
  const tableRef = useRef();
  const searchParams = new URLSearchParams(location.search);
  const pageCount = Math.ceil(refills.length / itemsPerPage);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = (currentPage + 1) * itemsPerPage;
  const paginatedRefills = refills.slice(startIndex, endIndex);

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(selected);
    navigate(`/refills?page=${newPage}`);
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      Notify("success", "Successfully copied ID");
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };

  useEffect(() => {
    document.title = `Refills | ${siteTitle}`;
  }, [siteTitle]);

  useEffect(() => {
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (!currentUser) {
        navigate("/");
      }
    };
    onAuth();
  }, [navigate]);

  useEffect(() => {
    const getRefills = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/refill/get-docs`, {
            key: currentUser.apiKey,
            panelId: panelId,
          });
          const refills = response.data;
          setRefills(refills);
          setMainRefills(refills);
          setFetchingData(false);
        } catch (error) {}
      }
    };
    getRefills();
  }, [panelId, siteUrl, currentUser]);

  useEffect(() => {
    let handler = (e) => {
      if (
        !bodyRef.current?.contains(e.target) &&
        !tableRef.current?.contains(e.target)
      ) {
        setShowSearchRefill(false);
        setSearchValue("");
        searchParams.delete("search");
        navigate(`${location.pathname}`);
        setRefills(mainRefills);
      }
    };
    if (showSearchRefill) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const SearchValue = searchParams.get("search");
    if (SearchValue !== null) {
      const handleSearchValue = () => {
        const parsedValue =
          SearchValue.trim() === ""
            ? ""
            : isNaN(SearchValue)
            ? String(SearchValue)
            : parseInt(SearchValue, 10);
        setSearchValue(parsedValue);
        const dupRefills = [...mainRefills];
        setRefills(
          dupRefills.filter((refill) => {
            if (typeof parsedValue === "string") {
              return refill.url
                .toLowerCase()
                .includes(parsedValue.toLowerCase());
            } else if (typeof parsedValue === "number") {
              return (
                String(refill.id).includes(String(parsedValue)) ||
                String(refill.orderId).includes(String(parsedValue))
              );
            }
            return false;
          })
        );
      };
      handleSearchValue();
      setShowSearchRefill(true);
    }
  }, [location.search, mainRefills]);

  if (fetchingData) {
    return <Loading />;
  }

  const showSearchBar = () => {
    setShowSearchRefill(true);
  };

  const handleSearchInput = (e) => {
    const value = e.target.value;
    const parsedValue =
      value.trim() === ""
        ? ""
        : isNaN(value)
        ? String(value)
        : parseInt(value, 10);
    setSearchValue(parsedValue);
    if (parsedValue !== "") {
      searchParams.set("search", parsedValue);
    } else {
      setRefills(mainRefills);
      searchParams.delete("search");
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleCancelSearchRefill = () => {
    setShowSearchRefill(false);
    setSearchValue("");
    searchParams.delete("search");
    const newUrl = `${window.location.pathname}`;
    navigate(newUrl);
    setRefills(mainRefills);
  };

  return (
    <React.Fragment>
      <Integrations />
      <LogHeader />
      <LogNavLinks />
      <div
        className="clrefills"
        style={myStyles}
        data-theme={darkMode ? "dark" : "light"}
      >
        <TopNav />
        <div className="clrefillsmaincon">
          <div
            className={darkMode ? "darkordersearchcon" : "ordersearchcon"}
            ref={bodyRef}
          >
            {showSearchRefill ? (
              <div className="searchorderDiv">
                <input
                  type="text"
                  name="ordersearch"
                  id={darkMode ? "darkordersearch" : "ordersearch"}
                  className={darkMode ? "text-white" : "text-dark"}
                  value={searchValue}
                  onChange={handleSearchInput}
                  placeholder="Search by ID, Order ID or URL"
                />
                <MdCancel
                  className="adorcancelsearch"
                  onClick={handleCancelSearchRefill}
                />
              </div>
            ) : (
              <button
                className={darkMode ? "darkorderssearchbtn" : "orderssearchbtn"}
                onClick={showSearchBar}
              >
                <FaSearch className="orsearIcon" />
                <p>Search Refill</p>
              </button>
            )}
          </div>
          <div className="clrefillstable" ref={tableRef}>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Order ID</th>
                  <th>Link</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {paginatedRefills.map((refill) => (
                  <tr key={refill.id}>
                    <td>
                      <span
                        className={darkMode ? "dark-cp-id" : "cp-id"}
                        onClick={() => copyToClipboard(refill.id)}
                      >
                        {refill.id}
                      </span>
                    </td>
                    <td>
                      <Link
                        to={`/orders/all?search=${refill.orderId}`}
                        className="clreforder"
                      >
                        {refill.orderId}
                      </Link>
                    </td>
                    <td className="clreflinktd">
                      <Link to={refill.url} className="clreforder">
                        {refill.url}
                      </Link>
                    </td>
                    <td
                      className={`clrefiltd ${
                        refill.status === "In progress"
                          ? "inprogress"
                          : refill.status
                      }`}
                    >
                      {refill.status}
                    </td>
                    <td>
                      <strong>{formatDate(refill.timestamp)}</strong>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Refills;
