import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/pages.css";
import { AppContext } from "../../context/AppContext";
import ControlPanel from "./ControlPanel";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaCirclePlus, FaUserPlus } from "react-icons/fa6";
import AdminHeader from "../components/AdminHeader";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import { MdEdit, MdOutlinePayment } from "react-icons/md";
import Switch from "../shared/Switch.jsx";
import React from "react";
import { IoArrowBack } from "react-icons/io5";
import Loading from "../shared/Loading";
import axios from "axios";

function Pages() {
  const {
    myAdminStyles,
    siteData,
    setNotifyType,
    setNotifyMessage,
    currentAdmin,
    setNotifyVisibility,
    setNotifyDuration,
    siteUrl,
    setLoading,
    loading,
    siteTitle,
    panelId,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };
  const location = useLocation();
  const [editingAPage, setEditingAPage] = useState(false);
  const [updatingHome, setUpdatingHome] = useState(false);
  const [enableRAE, setEnableRAE] = useState(true);
  const [homeTutLink, setHomeTutLink] = useState("");
  const [orderTutLink, setOrderTutLink] = useState("");
  const [payTutLink, setPayTutLink] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [commissionPercentage, setCommissionPercentage] = useState(0);
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page");
  const remains = page?.slice(1);
  const pageName = page?.charAt(0).toUpperCase() + remains;
  useEffect(() => {
    setLoading(false);
    document.title = `Pages | ${siteTitle}`;
  }, [siteTitle, setLoading]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get("page");
    if (pageParam) {
      setEditingAPage(true);
    } else {
      setEditingAPage(false);
    }
  }, [location.search]);

  useEffect(() => {
    const getHomePageDetails = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "pages",
            key: currentAdmin.apiKey,
            panelId: panelId,
          });
          const homeDoc = response.data.find((doc) => doc.uid === "home");
          setHomeTutLink(homeDoc.tutorial);
          setPageTitle(homeDoc.title);
        } catch (error) {}
      }
    };

    const getOrderPageDetails = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "pages",
            key: currentAdmin.apiKey,
            panelId: panelId,
          });
          const homeDoc = response.data.find((doc) => doc.uid === "order");
          setOrderTutLink(homeDoc.tutorial);
        } catch (error) {}
      }
    };
    const getPayPageDetails = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "pages",
            key: currentAdmin.apiKey,
            panelId: panelId,
          });
          const homeDoc = response.data.find((doc) => doc.uid === "pay");
          setPayTutLink(homeDoc.tutorial);
        } catch (error) {}
      }
    };
    const getRAEDetails = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "pages",
            key: currentAdmin.apiKey,
            panelId: panelId,
          });
          const homeDoc = response.data.find((doc) => doc.uid === "affiliate");
          setCommissionPercentage(homeDoc.percent);
          setEnableRAE(homeDoc.enabled);
        } catch (error) {}
      }
    };
    getRAEDetails();
    getPayPageDetails();
    getHomePageDetails();
    getOrderPageDetails();
  }, [panelId, siteUrl, currentAdmin]);

  if (loading || siteData === null) {
    return <Loading />;
  }

  const saveHomeChanges = async () => {
    setUpdatingHome(true);
    try {
      const data = {
        tutorial: homeTutLink,
        title: pageTitle,
      };
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "pages",
        key: currentAdmin.apiKey,
        panelId: panelId,
      });
      const homeExist = response.data.some((page) => page.uid === "home");
      if (homeExist) {
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "pages",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
          uid: "home",
        });
      } else {
        data.uid = "home";
        await axios.post(`${siteUrl}/crud/add/doc`, {
          collection: "pages",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
        });
      }
      Notify("success", "Saved Successfully");
      setUpdatingHome(false);
    } catch (error) {
      setUpdatingHome(false);
    }
  };

  const saveOrderChanges = async () => {
    setUpdatingHome(true);
    try {
      const data = {
        tutorial: orderTutLink,
      };
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "pages",
        key: currentAdmin.apiKey,
        panelId: panelId,
      });
      const orderExist = response.data.some((page) => page.uid === "order");
      if (orderExist) {
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "pages",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
          uid: "order",
        });
      } else {
        data.uid = "order";
        await axios.post(`${siteUrl}/crud/add/doc`, {
          collection: "pages",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
        });
      }
      Notify("success", "Saved Successfully");
      setUpdatingHome(false);
    } catch (error) {
      setUpdatingHome(false);
    }
  };

  const savePayChanges = async () => {
    setUpdatingHome(true);
    try {
      const data = {
        tutorial: payTutLink,
      };
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "pages",
        key: currentAdmin.apiKey,
        panelId: panelId,
      });
      const payExist = response.data.some((page) => page.uid === "pay");
      if (payExist) {
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "pages",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
          uid: "pay",
        });
      } else {
        data.uid = "pay";
        await axios.post(`${siteUrl}/crud/add/doc`, {
          collection: "pages",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
        });
      }
      Notify("success", "Saved Successfully");
      setUpdatingHome(false);
    } catch (error) {
      setUpdatingHome(false);
    }
  };

  const saveRAEChanges = async () => {
    setUpdatingHome(true);
    try {
      const data = {
        enabled: enableRAE,
        percent: commissionPercentage,
      };
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "pages",
        key: currentAdmin.apiKey,
        panelId: panelId,
      });
      const affiliateExist = response.data.some(
        (page) => page.uid === "affiliate"
      );
      if (affiliateExist) {
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "pages",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
          uid: "affiliate",
        });
      } else {
        data.uid = "affiliate";
        await axios.post(`${siteUrl}/crud/add/doc`, {
          collection: "pages",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
        });
      }
      Notify("success", "Saved Successfully");
      setUpdatingHome(false);
    } catch (error) {
      setUpdatingHome(false);
    }
  };

  const toggleEnableRAE = async () => {
    const newValue = !enableRAE;
    setEnableRAE(newValue);
  };

  const gotoPages = () => {
    navigate("/control-panel/pages");
  };

  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="adpages" style={myAdminStyles}>
        <div className="adpagesmaincon">
          <ControlPanel />
          <div className="adcpbdycon">
            {!editingAPage && (
              <React.Fragment>
                <div className="adpageshead">
                  <h6>Pages</h6>
                </div>
                <div className="adpagesbody">
                  <div className="adallpagescon">
                    <div className="adallpagesul">
                      <div className="adallpagesli">
                        <div>
                          <FaHome className="adpageicon" /> Home
                        </div>
                        <Link to="/control-panel/pages?page=home">
                          <MdEdit className="adpageedicon" />
                        </Link>
                      </div>
                      <div className="adallpagesli">
                        <div>
                          <FaCirclePlus className="adpageicon" />
                          New Order
                        </div>
                        <Link to="/control-panel/pages?page=order">
                          <MdEdit className="adpageedicon" />
                        </Link>
                      </div>
                      <div className="adallpagesli">
                        <div>
                          <MdOutlinePayment className="adpageicon" /> Add Funds
                        </div>
                        <Link to="/control-panel/pages?page=pay">
                          <MdEdit className="adpageedicon" />
                        </Link>
                      </div>
                      <div className="adallpagesli">
                        <div>
                          <FaUserPlus className="adpageicon" />
                          Refer And Earn
                        </div>
                        <Link to="/control-panel/pages?page=affiliate">
                          <MdEdit className="adpageedicon" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {editingAPage && (
              <React.Fragment>
                <div className="adeditpagehead">
                  <IoArrowBack
                    className="adpagesgobackbtn"
                    onClick={gotoPages}
                  />
                  <h6>Editing {pageName}</h6>
                </div>
                <div className="adpagesbody">
                  <div className="adeditpagecon">
                    {page === "home" && (
                      <React.Fragment>
                        <div className="adedithmcon">
                          <h4>Home Page</h4>
                          <button
                            className="adsavegeneral"
                            onClick={saveHomeChanges}
                          >
                            {updatingHome ? (
                              <div className="adeditservspinner"></div>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                        <div className="adedithhome">
                          <div className="adedtutlinkcon">
                            <span>Page Title</span>
                            <input
                              type="text"
                              autoComplete="off"
                              id="adgensitetitle"
                              placeholder="Enter the title for the home page"
                              value={pageTitle}
                              onChange={(e) => setPageTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="adedithhome">
                          <h5>Youtube Tutorials</h5>
                          <div className="adedtutlinkcon">
                            <span>Tutorial Link</span>
                            <input
                              type="url"
                              autoComplete="off"
                              id="adgensitetitle"
                              placeholder="The youtube embeded link"
                              value={homeTutLink}
                              onChange={(e) => setHomeTutLink(e.target.value)}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    {page === "order" && (
                      <React.Fragment>
                        <div className="adedithmcon">
                          <h4>New Order</h4>
                          <button
                            className="adsavegeneral"
                            onClick={saveOrderChanges}
                          >
                            {updatingHome ? (
                              <div className="adeditservspinner"></div>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                        <div className="adedithhome">
                          <h5>Youtube Tutorials</h5>
                          <div className="adedtutlinkcon">
                            <span>Tutorial Link</span>
                            <input
                              type="url"
                              autoComplete="off"
                              id="adgensitetitle"
                              placeholder="The youtube embeded link"
                              value={orderTutLink}
                              onChange={(e) => setOrderTutLink(e.target.value)}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    {page === "pay" && (
                      <React.Fragment>
                        <div className="adedithmcon">
                          <h4>Pay</h4>
                          <button
                            className="adsavegeneral"
                            onClick={savePayChanges}
                          >
                            {updatingHome ? (
                              <div className="adeditservspinner"></div>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                        <div className="adedithhome">
                          <h5>Youtube Tutorials</h5>
                          <div className="adedtutlinkcon">
                            <span>Tutorial Link</span>
                            <input
                              type="url"
                              autoComplete="off"
                              id="adgensitetitle"
                              placeholder="The youtube embeded link"
                              value={payTutLink}
                              onChange={(e) => setPayTutLink(e.target.value)}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    {page === "affiliate" && (
                      <React.Fragment>
                        <div className="adedithmcon">
                          <h4>Refer And Earn</h4>
                          <button
                            className="adsavegeneral"
                            onClick={saveRAEChanges}
                          >
                            {updatingHome ? (
                              <div className="adeditservspinner"></div>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                        <div className="adedithhome">
                          <div className="adedtutlinkcon">
                            <span>Enable Refer And Earn</span>
                            <Switch
                              syncValue={enableRAE}
                              onToggle={toggleEnableRAE}
                            />
                          </div>
                          {enableRAE && (
                            <div className="adedtutlinkcon">
                              <span>Commission Percentage</span>
                              <input
                                type="text"
                                autoComplete="off"
                                id="adgensitetitle"
                                placeholder="Enter Commission Percentage"
                                value={commissionPercentage}
                                onChange={(e) =>
                                  setCommissionPercentage(
                                    parseInt(e.target.value)
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Pages;
