import { useContext } from "react";
import "../styles/fetchingdata.css";
import { AppContext } from "../../context/AppContext";

function FetchingData() {
  const { darkMode } = useContext(AppContext);
  return (
    <div className={darkMode ? "darkFetchingDataConCl" : "fetchingDataConCl"}>
      <div className="fdcontentcl">
        <div className="cleditservspinner"></div>
      </div>
    </div>
  );
}

export default FetchingData;
