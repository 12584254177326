import "../styles/adminservices.css";
import React, { useState, useEffect, useContext, useRef } from "react";
import { MdCancel } from "react-icons/md";
import AdminHeader from "../components/AdminHeader";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import { FaGear } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { TbCategory2 } from "react-icons/tb";
import { MdOutlineCategory } from "react-icons/md";
import { AppContext } from "../../context/AppContext";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import ActiveServices from "../components/ActiveServices";
import DisabledServices from "../components/DisabledServices";
import Select from "react-select";
import { GiSightDisabled } from "react-icons/gi";
import { LuImport } from "react-icons/lu";
import FetchingData from "../shared/FetchingData";
import { FaXmark } from "react-icons/fa6";
import FileSend from "../../assets/images/file-doc.png";
import axios from "axios";
import CheckboxS from "../shared/CheckboxS";
import { IoMdArrowRoundBack } from "react-icons/io";
import { convertCurrency } from "../../client/shared/ConvertCurrency";
import { getCurrencySymbol } from "../../client/shared/CurrencySymbol";
import Loading from "../shared/Loading";
import NotFound from "../shared/NotFound";

function AdminServices() {
  const [ImportPercent, setimportPercent] = useState(0);
  const [activeServices, setActiveServices] = useState(0);
  const [disabledServices, setDisabledServices] = useState(0);
  const [activeCategories, setActiveCategories] = useState(0);
  const [disabledCategories, setDisabledCategories] = useState(0);
  const [showSearchService, setShowSearchService] = useState(false);
  const [fetchServices, setFetchServices] = useState(false);
  const [mainServices, setMainServices] = useState([]);
  const [showSearchImportService, setShowSearchImportService] = useState(false);
  const [finalStage, setFinalStage] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [providers, setProviders] = useState([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [fetchingServices, setFetchingServices] = useState(true);
  const [providerCurrency, setProviderCurrency] = useState("");
  const [finalPrices, setFinalPrices] = useState({});
  const [providerOption, setProviderOption] = useState(null);
  const [allServices, setAllServices] = useState([]);
  const [providerServices, setProviderServices] = useState([]);
  const [mainProviderServices, setMainProviderServices] = useState([]);
  const {
    siteTitle,
    setLoading,
    siteUrl,
    myAdminStyles,
    loading,
    panelId,
    siteData,
    setNotifyType,
    currentAdmin,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    ratesData,
    selectedAdminRate,
  } = useContext(AppContext);
  const [selectedServices, setSelectedServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [finalServices, setFinalServices] = useState([]);
  const { status } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [importSearchValue, setImportSearchValue] = useState("");
  const containerRef = useRef();
  const containerImportRef = useRef();
  const tableRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const bodyRef = useRef();
  const bodyImportRef = useRef();
  const actionsRef = useRef();
  const searchParams = new URLSearchParams(location.search);
  const SearchValue = searchParams.get("search");
  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Adjust z-index as needed
    control: (styles) => ({
      ...styles,
      borderRadius: "10px", // Add border-radius to the control (main container)
      borderColor: "var(--adbasebgcolor)",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--adbaseactcolor)"
          : "var(--adbasebgcolor)",
        color: "white",
      };
    },
  };
  const fetchServicesComponent = (status) => {
    // Define an object mapping statuses to components
    const servicesStatusComponent = {
      disabled: DisabledServices,
      active: ActiveServices,
    };

    // Use the status parameter to look up the component from the object
    return servicesStatusComponent[status]; // DefaultComponent is a placeholder for unknown statuses
  };

  const ServicesComponent = fetchServicesComponent(status);

  const fetchProviders = async () => {
    const response = await axios.post(`${siteUrl}/crud/get/docs`, {
      collection: "providers",
      panelId: panelId,
    });
    const providersData = response.data.map((doc) => ({
      value: doc.url,
      label: doc.url,
      key: doc.key,
    }));
    setProviders(providersData);
    setProviderOption(providersData[0]);
  };

  const fetchCategories = async () => {
    const response = await axios.post(`${siteUrl}/crud/get/docs`, {
      collection: "categories",
      panelId: panelId,
    });
    const categoriesData = response.data.map((doc) => ({
      value: doc.name, // Use category name as the value
      label: doc.name,
    }));
    const additionalCategory = {
      value: "createSameCategory",
      label: "Create Same Category",
    };
    const updatedCategoriesData = [additionalCategory, ...categoriesData];
    setCategories(updatedCategoriesData);
    setCategoryOption(updatedCategoriesData[0]);
  };

  useEffect(() => {
    const fetchProviderService = async () => {
      try {
        if (providerOption) {
          const url = `${siteUrl}/providers/services/get`;
          const data = {
            adminKey: currentAdmin.apiKey,
            panelId: panelId,
            provider: providerOption.value,
            key: providerOption.key,
          };
          const response = await axios.post(url, data);
          setProviderServices(response.data);
          setMainProviderServices(response.data);
          setFetchingServices(false);
        }
      } catch (error) {
        setProviderServices([]);
        setFetchingServices(false);
      }
    };
    fetchProviderService();
  }, [providerOption, panelId, currentAdmin, siteUrl]);

  useEffect(() => {
    setLoading(false);
    document.title = `Services | ${siteTitle}`;
  }, [siteTitle, setLoading]);

  useEffect(() => {
    if (selectedServices.length !== 0) {
      const mainImpServices = [...mainProviderServices].filter((serv) =>
        selectedServices.includes(String(serv.service))
      );
      setFinalServices(mainImpServices);
    }
  }, [selectedServices, mainProviderServices]);

  useEffect(() => {
    let handler = (e) => {
      if (
        !bodyRef.current?.contains(e.target) &&
        !containerRef.current?.contains(e.target) &&
        !tableRef.current?.contains(e.target) &&
        !actionsRef.current?.contains(e.target)
      ) {
        setShowSearchService(false);
        setSearchValue("");
        searchParams.delete("search");
        const newUrl = `${window.location.pathname}`;
        navigate(newUrl);
        setAllServices(mainServices);
      }
    };
    if (showSearchService) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    let handler = (e) => {
      if (
        !bodyImportRef.current?.contains(e.target) &&
        !containerImportRef.current?.contains(e.target)
      ) {
        setShowSearchImportService(false);
        setImportSearchValue("");
      }
    };
    if (showSearchImportService) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleSearchInput = (e) => {
    const value = e.target.value;
    const parsedValue =
      value.trim() === ""
        ? ""
        : isNaN(value)
        ? String(value)
        : parseInt(value, 10);
    if (parsedValue === "") {
      setAllServices(mainServices);
      searchParams.delete("search");
    } else {
      searchParams.set("search", parsedValue);
    }
    const newSearchParamsString = searchParams.toString();
    const newUrl = `${window.location.pathname}${
      newSearchParamsString ? `?${newSearchParamsString}` : ""
    }`;
    navigate(newUrl);
    setSearchValue(parsedValue);
  };

  const handleImportSearchInput = (e) => {
    const value = e.target.value;
    const parsedValue =
      value.trim() === ""
        ? ""
        : isNaN(value)
        ? String(value)
        : parseInt(value, 10);
    setImportSearchValue(parsedValue);
    if (parsedValue === "") {
      setProviderServices(mainProviderServices);
    } else {
      setProviderServices(
        providerServices.filter((service) => {
          if (typeof parsedValue === "string") {
            return service.name
              .toLowerCase()
              .includes(parsedValue.toLowerCase());
          } else if (typeof parsedValue === "number") {
            return String(service.service).includes(String(parsedValue));
          }
          return false;
        })
      );
    }
  };

  const patchMatchRoute = (Route) => {
    if (Route === location.pathname) {
      return true;
    }
  };

  const handleCancelSearchOrder = () => {
    setShowSearchService(false);
    setSearchValue("");
    searchParams.delete("search");
    const newUrl = `${window.location.pathname}`;
    navigate(newUrl);
  };

  const handleCancelImportSearchService = () => {
    setProviderServices(mainProviderServices);
    setShowSearchImportService(false);
    setImportSearchValue("");
  };

  useEffect(() => {
    const fetchAllServices = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "services",
          panelId: panelId,
        });
        const data = response.data.sort((a, b) => a.position - b.position);
        setAllServices(data);
        setMainServices(data);
        setFetchingData(false);
      } catch (error) {
        setFetchingData(false);
      }
    };
    fetchAllServices();
  }, [siteUrl, panelId, fetchServices]);

  useEffect(() => {
    const getCurrency = async () => {
      try {
        const response = await axios.post(`${siteUrl}/providers/get/currency`, {
          panelId: panelId,
          key: currentAdmin.apiKey,
          provider: providerOption.label,
        });
        const data = response.data.currency.toUpperCase();
        setProviderCurrency(data);
      } catch (error) {
        setProviderCurrency("USD");
      }
    };
    getCurrency();
  }, [siteUrl, providerOption, panelId, currentAdmin]);

  useEffect(() => {
    if (SearchValue !== null) {
      const handleSearchValue = () => {
        const parsedValue =
          SearchValue.trim() === ""
            ? ""
            : isNaN(SearchValue)
            ? String(SearchValue)
            : parseInt(SearchValue, 10);
        setSearchValue(parsedValue);
        const dupServices = [...mainServices];
        setAllServices(
          dupServices.filter((service) => {
            if (typeof parsedValue === "string") {
              return (
                service.name
                  .toLowerCase()
                  .includes(parsedValue.toLowerCase()) ||
                service.provider
                  .toLowerCase()
                  .includes(parsedValue.toLowerCase())
              );
            } else if (typeof parsedValue === "number") {
              return (
                String(service.id).includes(String(parsedValue)) ||
                String(service.providerID).includes(String(parsedValue))
              );
            }
            return false;
          })
        );
      };
      handleSearchValue();
      setShowSearchService(true);
    }
  }, [location.search, searchValue, SearchValue, mainServices]);

  useEffect(() => {
    const fetchActiveServices = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "services",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const servLength = response.data.filter(
            (serv) => serv.status === "active"
          ).length;
          setActiveServices(servLength);
        }
      } catch (error) {}
    };
    const fetchDisabledServices = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "services",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const servLength = response.data.filter(
            (serv) => serv.status === "disabled"
          ).length;
          setDisabledServices(servLength);
        }
      } catch (error) {}
    };
    const fetchActiveCategories = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "categories",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const servLength = response.data.filter(
            (serv) => serv.status === "active"
          ).length;
          setActiveCategories(servLength);
        }
      } catch (error) {}
    };
    const fetchDisabledCategories = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "categories",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const servLength = response.data.filter(
            (serv) => serv.status === "disabled"
          ).length;
          setDisabledCategories(servLength);
        }
      } catch (error) {}
    };
    fetchActiveCategories();
    fetchDisabledCategories();
    fetchActiveServices();
    fetchDisabledServices();
  }, [panelId, siteUrl, fetchServices]);

  const handleShowSearch = () => {
    setShowSearchService(true);
  };

  const handleShowImportSearch = () => {
    setShowSearchImportService(true);
  };

  const handleShowImport = () => {
    setShowImport(true);
    fetchProviders();
  };

  const hideServicesImport = (event) => {
    if (event.target.classList.contains("adservicesimportcon")) {
      setShowImport(false);
      setSelectedServices([]);
      setFinalStage(false);
      setFinalPrices({});
      setimportPercent(0);
    }
  };

  const cancelServicesImport = () => {
    setShowImport(false);
    setSelectedServices([]);
    setFinalPrices({});
    setFinalStage(false);
    setimportPercent(0);
  };

  const handleProviderChange = (selectedOption) => {
    setProviderServices([]);
    setFetchingServices(true);
    setProviderOption(selectedOption);
  };

  const handleCategoryChange = (selectedOption) => {
    setCategoryOption(selectedOption);
  };

  const handleServiceSelect = (selectedService) => {
    const serviceValue = selectedService.service;
    const isSelected = selectedServices.includes(serviceValue);
    if (isSelected) {
      setSelectedServices((prevSelected) =>
        prevSelected.filter((service) => service !== serviceValue)
      );
    } else {
      setSelectedServices((prevSelected) => [...prevSelected, serviceValue]);
    }
  };

  const handleSelectAll = () => {
    const allServiceValues = providerServices.map((service) => service.service);
    setSelectedServices((prevSelected) =>
      prevSelected.length === providerServices.length ? [] : allServiceValues
    );
  };

  const showFinalStage = () => {
    fetchCategories();
    setFinalStage(true);
  };

  const handleImportPercentChange = (e) => {
    const value = e.target.value;
    const parsedValue = value === "" ? 0 : isNaN(value) ? 0 : parseInt(value);
    setimportPercent(parsedValue);

    // Calculate final price for each service
    const updatedFinalPrices = {};
    mainProviderServices.forEach((service) => {
      const finalPrice = calculateFinalPrice(service, value);
      updatedFinalPrices[service.service] = finalPrice;
    });
    setFinalPrices(updatedFinalPrices);
  };

  const calculateFinalPrice = (service, percent) => {
    // Calculate final price based on the percentage
    const providerPrice = parseFloat(service.rate);
    const extraCharge = (percent * providerPrice) / 100;
    const calPrice = parseFloat(providerPrice + extraCharge);
    const endPrice = calPrice.toFixed(3);
    return endPrice;
  };

  const backToServices = () => {
    setFinalStage(false);
    setimportPercent(0);
    setFinalPrices({});
  };

  if (loading || siteData === null) {
    return <Loading />;
  }

  const saveServices = async () => {
    try {
      Notify("warn", "Services are being imported in the background... Wait!");
      setShowImport(false);
      setFinalStage(false);
      setimportPercent(0);
      setFinalPrices({});

      // Define the chunk size
      const chunkSize = 800;
      // Create a copy of the selected services array
      const services = [...selectedServices];

      // Function to send a batch of services
      const sendBatch = async (batch) => {
        const reqData = {
          selectedServices: batch,
          panelId: panelId,
          importPercent: ImportPercent,
          providerOption: providerOption,
          categoryOption: categoryOption,
          key: currentAdmin.apiKey,
        };
        const reqUrl = `${siteUrl}/service/import`;
        await axios.post(reqUrl, reqData);
      };

      // Loop through the selected services in chunks of chunkSize
      for (let i = 0; i < services.length; i += chunkSize) {
        const batch = services.slice(i, i + chunkSize);
        await sendBatch(batch);
      }

      // Reset the necessary states
      setSelectedServices([]);
      setProviderServices([]);
      setFetchServices(!fetchServices);
      Notify("success", "Imported Successfully");
    } catch (error) {
      Notify("error", error.response.data.error);
    }
  };

  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="adservices" style={myAdminStyles}>
        <div className="adservicesmaincon">
          <div className="adservicesmainstatcon">
            <Link
              to="/control-panel/services/active"
              className="adordersstatcon"
            >
              <div className="orstatsecon-active">
                <FaGear className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/services/active")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Active Services
              </strong>
              <h4 className="aduserstat">{activeServices}</h4>
            </Link>
            <Link
              to="/control-panel/services/disabled"
              className="adordersstatcon"
            >
              <div className="adtotalservicesiconcon">
                <GiSightDisabled className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/services/disabled")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Disabled Services
              </strong>
              <h4 className="aduserstat">{disabledServices}</h4>
            </Link>
            <Link
              to="/control-panel/categories/active"
              className="adordersstatcon"
            >
              <div className="orstatsecon-active">
                <TbCategory2 className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/categories/active")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Active Categories
              </strong>
              <h4 className="aduserstat">{activeCategories}</h4>
            </Link>
            <Link
              to="/control-panel/categories/disabled"
              className="adordersstatcon"
            >
              <div className="adtotalservicesiconcon">
                <MdOutlineCategory className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/categories/disabled")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Disabled Categories
              </strong>
              <h4 className="aduserstat">{disabledCategories}</h4>
            </Link>
          </div>
          <div className="adsearchorders" ref={containerRef}>
            {showSearchService ? (
              <div className="searchorderDiv">
                <input
                  type="text"
                  name="ordersearch"
                  id="adordersearchfield"
                  value={searchValue}
                  onChange={handleSearchInput}
                  placeholder="Search by ID or URL"
                />
                <MdCancel
                  className="adorcancelsearch"
                  onClick={handleCancelSearchOrder}
                />
              </div>
            ) : (
              <>
                <button className="adservicesupbtn" onClick={handleShowImport}>
                  <LuImport className="adorsearIcon" />
                  <span className="fw-semibold">Import Services</span>
                </button>
                <button className="adservicesupbtn" onClick={handleShowSearch}>
                  <FaSearch className="adorsearIcon" />
                  <span className="fw-semibold">Search Service</span>
                </button>
              </>
            )}
          </div>
          {showImport ? (
            <div
              className="adservicesimportcon"
              onClick={(event) => hideServicesImport(event)}
            >
              <div className="adservicesimport">
                {finalStage ? (
                  <div className="adservimportback" onClick={backToServices}>
                    <IoMdArrowRoundBack className="adservimicon" />
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="adservimportcancel"
                  onClick={cancelServicesImport}
                >
                  <FaXmark className="adservimicon" />
                </div>
                <div className="adservicesimporthead">
                  <img
                    src={FileSend}
                    className="adservicesimporthdicon"
                    alt="file-png"
                  />
                  <h3>Select Provider And Service</h3>
                </div>
                <div className="adservicesimportbody">
                  {finalStage ? (
                    <>
                      <span className="adservicesimpfct">
                        Category to add services in
                      </span>
                      <div className="adImportCategoryOptionCon">
                        <Select
                          className="importCategoryOptions"
                          options={categories}
                          value={categoryOption}
                          onChange={handleCategoryChange}
                          styles={selectStyles}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              primary: "var(--adbasebgcolor)",
                            },
                          })}
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Extra charge in %</h5>
                        <input
                          type="text"
                          name="percentage"
                          id="adImportPercent"
                          value={ImportPercent}
                          onChange={handleImportPercentChange}
                        />
                      </div>
                      <div className="adservicesimpfstable">
                        <table>
                          <thead>
                            <tr>
                              <th className="adervfinalimhd">ID</th>
                              <th className="adervfinalimhd">Service</th>
                              <th className="adervfinalimhd">Provider price</th>
                              <th className="adervfinalimhd">Final price</th>
                              <th className="adervfinalimhd">
                                Extra charge in %
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {finalServices.map((service) => (
                              <React.Fragment key={service.service}>
                                <tr>
                                  <td className="adservfinalimtd adservicesimpmaxwi">
                                    {service.service}
                                  </td>
                                  <td className="adservfinalimtd">
                                    {service.name}
                                  </td>
                                  <td className="adservfinalimtd adservicesimpmaxwi">
                                    {getCurrencySymbol(selectedAdminRate.label)}
                                    {convertCurrency(
                                      parseFloat(service.rate),
                                      providerCurrency,
                                      selectedAdminRate.label,
                                      ratesData
                                    )}
                                  </td>
                                  <td className="adservfinalimtd adservicesimpmaxwi">
                                    {getCurrencySymbol(selectedAdminRate.label)}
                                    {convertCurrency(
                                      parseFloat(finalPrices[service.service]),
                                      providerCurrency,
                                      selectedAdminRate.label,
                                      ratesData
                                    )}
                                  </td>
                                  <td className="adservfinalimtd">
                                    <input
                                      type="text"
                                      name="extraCharge"
                                      id="adImportExtraCharge"
                                      value={ImportPercent}
                                      readOnly
                                    />
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-center ">
                        <button
                          className="adserviceseimNS"
                          onClick={saveServices}
                        >
                          Add {selectedServices.length} service
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="adservicesimprov">
                        <Select
                          className="providerOptions"
                          menuPortalTarget={document.body}
                          options={providers}
                          value={providerOption}
                          onChange={handleProviderChange}
                          styles={selectStyles}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              primary: "var(--adbasebgcolor)",
                            },
                          })}
                        />
                      </div>
                      <div className="adsearchimpservc">
                        {showSearchImportService ? (
                          <div className="searchorderDiv" ref={bodyImportRef}>
                            <input
                              type="text"
                              name="ordersearch"
                              id="adordersearchfield"
                              value={importSearchValue}
                              onChange={handleImportSearchInput}
                              placeholder="Search by ID or Name"
                            />
                            <MdCancel
                              className="adorcancelsearch"
                              onClick={handleCancelImportSearchService}
                            />
                          </div>
                        ) : (
                          <button
                            className="adsearchbtnimportserv"
                            onClick={handleShowImportSearch}
                          >
                            <FaSearch className="adorsearIcon" />
                            <span className="fw-semibold">Search Service</span>
                          </button>
                        )}
                      </div>
                      {fetchingServices ? (
                        <div className="adservicesimfetching">
                          <FetchingData />
                        </div>
                      ) : (
                        <>
                          {providerServices.length === 0 ? (
                            <NotFound value="No Services Found" />
                          ) : (
                            <div
                              className="adservicesimpse"
                              ref={containerImportRef}
                            >
                              <table>
                                <thead>
                                  {selectedServices.length === 0 ? (
                                    <tr>
                                      <th className="adservicesimptbth adservicesimpmaxwi">
                                        <CheckboxS
                                          onChange={() => handleSelectAll()}
                                          checked={
                                            selectedServices.length ===
                                            providerServices.length
                                          }
                                        />
                                      </th>
                                      <th className="adservicesimptbth">
                                        Service
                                      </th>
                                      <th className="adservicesimptbth adservicesimpmaxwi">
                                        ID
                                      </th>
                                    </tr>
                                  ) : (
                                    <tr>
                                      <th className="adservicesimptbth adservicesimpmaxwi">
                                        <CheckboxS
                                          onChange={() => handleSelectAll()}
                                          checked={
                                            selectedServices.length ===
                                            providerServices.length
                                          }
                                        />
                                      </th>
                                      <th className="adservicesimptbth">
                                        Selected {selectedServices.length}{" "}
                                        Services
                                      </th>
                                    </tr>
                                  )}
                                </thead>
                                <tbody>
                                  {providerServices.map((service) => (
                                    <React.Fragment key={service.service}>
                                      <tr>
                                        <td className="adservicesimptd adservicesimpmaxwi">
                                          <CheckboxS
                                            checked={selectedServices.some(
                                              (serviceData) =>
                                                serviceData === service.service
                                            )}
                                            onChange={() =>
                                              handleServiceSelect(service)
                                            }
                                          />
                                        </td>
                                        <td className="adservicesimptd adservicesimpservc">
                                          {service.name}
                                        </td>
                                        <td className="adservicesimptd adservicesimpmaxwi">
                                          {service.service}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </>
                      )}
                      {selectedServices.length === 0 ? (
                        ""
                      ) : (
                        <div className="d-flex justify-content-center">
                          <button
                            className="adserviceseimNS"
                            onClick={showFinalStage}
                          >
                            Continue
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {fetchingData ? (
            <FetchingData />
          ) : (
            <>
              {ServicesComponent ? (
                <ServicesComponent
                  services={allServices}
                  tableRef={tableRef}
                  bodyRef={bodyRef}
                  actionsRef={actionsRef}
                  status={status}
                  fetchServices={fetchServices}
                  setFetchServices={setFetchServices}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminServices;
