import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import FlutterwaveMethod from "../components/FlutterwaveMethod";
import PaystackMethod from "../components/PaystackMethod";
import { AppContext } from "../../context/AppContext";

function PaymentMethod() {
  const { loading, siteData, setLoading } = useContext(AppContext);
  useEffect(() => {
    setLoading(false);
  }, [setLoading]);
  const fetchPaymentMethodComponent = (method) => {
    const ordermethodComponents = {
      flutterwave: FlutterwaveMethod,
      paystack: PaystackMethod,
    };
    return ordermethodComponents[method];
  };
  const { method } = useParams();
  const PaymentMethodComponent = fetchPaymentMethodComponent(method);
  if (loading || siteData === null) {
    return "";
  }

  return <PaymentMethodComponent />;
}

export default PaymentMethod;
