import React, { useContext, useEffect, useState } from "react";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import "../styles/forgetpassword.css";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import Integrations from "../shared/Integrations";
import axios from "axios";

function ForgetPassword() {
  const {
    myStyles,
    siteTitle,
    darkMode,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    siteUrl,
    panelId,
  } = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState(
    new Array(6).fill("")
  );
  const [isVerified, setIsVerified] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    document.title = `Forget Password | ${siteTitle}`;
  }, [siteTitle]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${siteUrl}/user/forgot-password`, {
        email: email,
        panelId: panelId,
      });
      if (response.data.success) {
        setCodeSent(true);
        Notify(
          "success",
          "A code has been sent to your email if the account exists."
        );
      }
    } catch (error) {
      Notify("error", error.response.data.error);
    }
  };

  const handleCodeChange = (e, index) => {
    if (e.target.value.length > 1) return;
    const newCode = [...verificationCode];
    newCode[index] = e.target.value;
    setVerificationCode(newCode);

    if (index < 5 && e.target.value) {
      document.getElementById(`code-input-${index + 1}`).focus();
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${siteUrl}/user/forgot-password/confirm`,
        {
          code: parseInt(verificationCode.join("")),
          panelId: panelId,
          email: email,
        }
      );
      if (response.data.success) {
        setIsVerified(true);
        Notify("success", "Code verified! Please enter your new password.");
      }
    } catch (error) {
      Notify("error", error.response.data.error);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${siteUrl}/user/reset-password`, {
        panelId: panelId,
        password: newPassword,
        email: email,
        code: parseInt(verificationCode.join("")),
      });
      Notify("success", "Password reset successfully!");
      setEmail("");
      setNewPassword("");
      setVerificationCode([]);
      navigate("/signin");
    } catch (error) {
      Notify("error", error.response.data.error);
    }
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration && duration > 0) setNotifyDuration(duration);
  };

  return (
    <React.Fragment>
      <Header />
      <Integrations />
      <div
        className="clforgetpasswdcon"
        style={myStyles}
        data-theme={darkMode ? "dark" : "light"}
      >
        <div className="clforgetpasswd">
          {!codeSent && (
            <form onSubmit={handleEmailSubmit}>
              <h2>Reset Your Password</h2>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit">Submit</button>
            </form>
          )}

          {codeSent && !isVerified && (
            <div>
              <div className="message">
                Thanks for submitting your email. A code has been sent to the
                email entered if the account exists on the site. Check your spam
                folder if you don see it in your inbox!
              </div>
              <form onSubmit={handleCodeSubmit}>
                <div className="code-inputs">
                  {verificationCode.map((code, index) => (
                    <input
                      key={index}
                      id={`code-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={code}
                      onChange={(e) => handleCodeChange(e, index)}
                      required
                    />
                  ))}
                </div>
                <button type="submit">Verify Code</button>
              </form>
            </div>
          )}

          {isVerified && (
            <form onSubmit={handlePasswordSubmit}>
              <h2>Enter Your New Password</h2>
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <div className="password-rules">
                <ul>
                  <li>Must contain at least one uppercase letter</li>
                  <li>Must contain at least one lowercase letter</li>
                  <li>Must contain at least one number</li>
                  <li>Must be at least 8 characters long</li>
                </ul>
              </div>
              <button type="submit">Reset Password</button>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ForgetPassword;
