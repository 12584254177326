import "../styles/categoriestable.css";
import Checkbox from "../shared/Checkbox";
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { formatDate } from "../shared/FormatDate";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import EditCategory from "./EditCategory";
import ConfirmAction from "../shared/ConfirmAction";
import DetailsOverlay from "../shared/DetailsOverlay";
import axios from "axios";

function CategoriesTable({
  categories,
  fetchCategories,
  setFetchCategories,
  actionsRef,
  tableRef,
}) {
  const [selectedCategorys, setSelectedCategorys] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showConfirmAction, setShowConfirmAction] = useState(null);
  const [showDetailsOverlay, setShowDetailsOverlay] = useState(false);
  const [catTableWidth, setCatTableWidth] = useState(0);
  const [categoryData, setCategoryData] = useState(null);
  const [updatingAction, setUpdatingAction] = useState(false);
  const [headText, setHeadText] = useState("");
  const [actionText, setActionText] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [detailsHead, setDetailsHead] = useState("");
  const [detailsBody, setDetailsBody] = useState("");
  const [selectedCategoriesAction, setSelectedCategoriesAction] = useState("");
  const {
    panelId,
    setNotifyType,
    setNotifyMessage,
    currentAdmin,
    siteUrl,
    setNotifyVisibility,
    setNotifyDuration,
  } = useContext(AppContext);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const handleSelectAll = () => {
    const allServicesIDs = categories.map((category) => category.id);
    setSelectedCategorys((prevSelected) =>
      prevSelected.length === categories.length ? [] : allServicesIDs
    );
  };

  const handleCategorySelect = (CategoryID) => {
    const isSelected = selectedCategorys.includes(CategoryID);
    if (isSelected) {
      setSelectedCategorys((prevSelected) =>
        prevSelected.filter((selectedId) => selectedId !== CategoryID)
      );
    } else {
      setSelectedCategorys((prevSelected) => [...prevSelected, CategoryID]);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    Notify("warn", "Rearranging categories... Wait!");
    const reorderedCategories = Array.from(categories);
    const [removed] = reorderedCategories.splice(result.source.index, 1);
    reorderedCategories.splice(result.destination.index, 0, removed);

    try {
      const data = reorderedCategories.map((category, index) => ({
        uid: category.uid,
        position: index,
      }));

      // Function to send data in batches
      const sendInBatches = async (data, batchSize) => {
        for (let i = 0; i < data.length; i += batchSize) {
          const batch = data.slice(i, i + batchSize);
          await axios.post(`${siteUrl}/service/category/update/position`, {
            panelId: panelId,
            key: currentAdmin.apiKey,
            data: batch,
          });
        }
      };

      // Send data in batches of 500
      await sendInBatches(data, 500);
      setFetchCategories(!fetchCategories);
      Notify("success", "Rearranged successfully.");
    } catch (error) {
      console.error("Error updating category order:", error);
    }
  };

  const showSelectedCategory = (category) => {
    setSelectedCategory(category);
  };

  const showDeleteConfirm = (category) => {
    setShowConfirmAction(true);
    setButtonName("Delete");
    setCategoryData(category);
    setHeadText("Delete Category");
    setSelectedCategoriesAction("deleteCategory");
    setActionText("delete the category and associated services");
  };

  const showDeleteCategoriesConfirm = () => {
    setShowConfirmAction(true);
    setButtonName("Delete");
    setHeadText("Delete Categories");
    setSelectedCategoriesAction("deleteCategories");
    setActionText("delete the selected categories and associated services");
  };

  const hideConfirmAction = (event) => {
    // Check if the click occurred outside the content
    if (event.target.classList.contains("caoverlay")) {
      setShowConfirmAction(null);
    }
  };

  const cancelConfirmAction = () => {
    setShowConfirmAction(null);
  };

  useEffect(() => {
    const handleResize = () => {
      const categoriesTableCon = document.querySelector(".adcategoriestable");
      if (categoriesTableCon) {
        setCatTableWidth(categoriesTableCon.offsetWidth);
      }
    };

    // Initial calculation on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showCategoryName = (name) => {
    setShowDetailsOverlay(true);
    setDetailsHead("Category Name");
    setDetailsBody(name);
  };

  const handleSelectedCategories = () => {
    setUpdatingAction(true);
    if (selectedCategoriesAction === "deleteCategories") {
      const handleDeleteCategories = async () => {
        for (const categoryId of selectedCategorys) {
          try {
            const catResponse = await axios.post(`${siteUrl}/crud/get/docs`, {
              collection: "categories",
              panelId: panelId,
            });
            const categoryDoc = catResponse.data.find(
              (cat) => cat.id === categoryId
            );
            const servResponse = await axios.post(`${siteUrl}/crud/get/docs`, {
              collection: "categories",
              panelId: panelId,
            });
            const serviceDoc = servResponse.data.filter(
              (serv) => serv.category === categoryDoc.name
            );
            for (const service of serviceDoc) {
              await axios.post(`${siteUrl}/crud/delete/doc`, {
                collection: "services",
                panelId: panelId,
                uid: service.uid,
              });
            }
            await axios.post(`${siteUrl}/crud/delete/doc`, {
              collection: "categories",
              panelId: panelId,
              uid: categoryDoc.uid,
            });
            setShowConfirmAction(null);
            setUpdatingAction(false);
            Notify(
              "success",
              "Categories and associated services has been deleted successfully."
            );
          } catch (error) {
            setShowConfirmAction(null);
            setUpdatingAction(false);
            Notify(
              "error",
              "Error deleting the selected categories and the associated servcies"
            );
          }
        }
      };
      handleDeleteCategories();
    }
    if (selectedCategoriesAction === "deleteCategory") {
      const handleDeleteCategory = async () => {
        try {
          const servResponse = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "services",
            panelId: panelId,
          });
          const serviceDocs = servResponse.data.filter(
            (serv) => serv.category === categoryData.name
          );
          for (const serviceDoc of serviceDocs) {
            await axios.post(`${siteUrl}/crud/delete/doc`, {
              collection: "services",
              panelId: panelId,
              uid: serviceDoc.uid,
            });
          }
          await axios.post(`${siteUrl}/crud/delete/doc`, {
            collection: "categories",
            panelId: panelId,
            uid: categoryData.uid,
          });
          setUpdatingAction(false);
          setShowConfirmAction(null);
          Notify(
            "success",
            "Category and associated services has been deleted successfully."
          );
        } catch (error) {
          setShowConfirmAction(null);
          setUpdatingAction(false);
          Notify(
            "error",
            "Error deleting category and associated services. Please try again later."
          );
        }
      };
      handleDeleteCategory();
    }
  };

  return (
    <div className="adcategoriestable" ref={tableRef}>
      <table>
        <thead>
          {selectedCategorys.length === 0 ? (
            <tr>
              <th className="adcathead adcatmaxwbtn">
                <Checkbox
                  onChange={() => handleSelectAll()}
                  checked={selectedCategorys.length === categories.length}
                />
              </th>
              <th className="adcathead">Name</th>
              <th className={catTableWidth >= 920 ? "adcathead" : "d-none"}>
                Created At
              </th>
            </tr>
          ) : (
            <tr>
              <th className="adcathead adcatmaxwbtn">
                <Checkbox
                  onChange={() => handleSelectAll()}
                  checked={selectedCategorys.length === categories.length}
                />
              </th>
              <th className="adcathead">
                Selected{" "}
                <span style={{ color: "var(--adtextbgcolor)" }}>
                  {selectedCategorys.length}
                </span>{" "}
                Categories
              </th>
              <th className="adcathead">
                <button
                  className="adcatdeletecat"
                  onClick={showDeleteCategoriesConfirm}
                >
                  <AiFillDelete
                    className={catTableWidth <= 355 ? "" : "me-2"}
                  />
                  {catTableWidth <= 355 ? "" : "Delete"}
                </button>
              </th>
            </tr>
          )}
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="categories">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {categories.map((category, index) => (
                  <Draggable
                    key={category.uid}
                    draggableId={category.uid}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        className="adservrow"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <td className="adcatdata adcatmaxwbtn">
                          <Checkbox
                            onChange={() => handleCategorySelect(category.id)}
                            checked={selectedCategorys.includes(category.id)}
                          />
                        </td>
                        <td
                          className="adcatdata adcatdatnamem"
                          style={
                            catTableWidth >= 840
                              ? { maxWidth: "200px" }
                              : { maxWidth: "100px" }
                          }
                        >
                          <strong
                            onClick={() => showCategoryName(category.name)}
                            className="adcatdatname"
                          >
                            {category.name}
                          </strong>
                        </td>
                        <td
                          className={
                            catTableWidth >= 920 ? "adcatdata" : "d-none"
                          }
                        >
                          <strong>{formatDate(category.timestamp)}</strong>
                        </td>
                        <td className="adcatdata adcatmaxwbtn">
                          <button
                            className="adusersactions"
                            onClick={() => showSelectedCategory(category)}
                          >
                            <MdEdit
                              className={catTableWidth <= 640 ? "" : "me-2"}
                              style={{ color: "#ddd" }}
                            />
                            {catTableWidth <= 640 ? "" : "Edit"}
                          </button>
                        </td>
                        <td className="adcatdata adcatmaxwbtn">
                          <button
                            className="adcatdeletecat"
                            onClick={() => showDeleteConfirm(category)}
                          >
                            <AiFillDelete
                              className={catTableWidth <= 640 ? "" : "me-2"}
                            />
                            {catTableWidth <= 640 ? "" : "Delete"}
                          </button>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      {selectedCategory && (
        <EditCategory
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          fetchCategories={fetchCategories}
          setFetchCategories={setFetchCategories}
          actionsRef={actionsRef}
        />
      )}
      {showConfirmAction && (
        <ConfirmAction
          buttonAction={handleSelectedCategories}
          hideConfirmAction={hideConfirmAction}
          cancelConfirmAction={cancelConfirmAction}
          buttonName={buttonName}
          headText={headText}
          actionText={actionText}
          actionsRef={actionsRef}
          updatingAction={updatingAction}
        />
      )}
      {showDetailsOverlay ? (
        <DetailsOverlay
          head={detailsHead}
          body={detailsBody}
          actionsRef={actionsRef}
          setShowDetailsOverlay={setShowDetailsOverlay}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default CategoriesTable;
