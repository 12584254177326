import React, { useEffect, useState, useContext } from "react";
import parse from "html-react-parser";
import Loading from "../shared/Loading";
import { AppContext } from "../../context/AppContext";
import Header from "../shared/Header";
import "../styles/blog.css";
import { useParams } from "react-router-dom";
import Integrations from "../shared/Integrations";
import LogHeader from "../shared/LogHeader";
import LogNavLinks from "../shared/LogNavLinks";
import TopNav from "../components/TopNav";
import axios from "axios";
import ValidTag from "../../utils/ValidTag";

function ViewBlog() {
  const {
    siteTitle,
    myStyles,
    darkMode,
    currentUser,
    panelId,
    siteUrl,
    siteData,
  } = useContext(AppContext);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);
  const [blog, setBlog] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchBlogs = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            key: currentUser.apiKey,
            collection: "blogs",
            panelId: panelId,
          });
          const sortedBlogs = response.data.find(
            (blog) => blog.id === parseInt(id)
          );
          setBlog(sortedBlogs);
        } catch (error) {
          console.error("Error fetching blogs:", error);
        }
      }
    };

    fetchBlogs();
  }, [panelId, siteUrl, currentUser, id]);

  useEffect(() => {
    document.title = `${blog ? blog.title : "View Blog"} | ${siteTitle}`;
  }, [siteTitle, blog]);

  if (!siteData || !blog) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {currentUser ? <LogNavLinks /> : isSmallScreen ? <Header /> : ""}
      <LogHeader />
      <Integrations />
      <div
        className="clblogscon"
        data-theme={darkMode ? "dark" : "light"}
        style={myStyles}
      >
        <TopNav />
        <div className="clblogsmaincon">
          <div className="clblogdetails">
            <div className="clblogtitle">
              <h3>{blog.title}</h3>
            </div>
            <div className="clblogcontent">{parse(blog.content)}</div>
          </div>
          <ValidTag />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewBlog;
