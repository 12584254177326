import { useState, useEffect, useContext } from "react";
import { FaEye } from "react-icons/fa";
import "../styles/editmethod.css";
import axios from "axios";
import Switch from "../shared/Switch";
import { convertCurrency } from "../shared/ConvertCurrency";
import { AppContext } from "../../context/AppContext";

function EditMethod({
  selectedMethod,
  setSelectedMethod,
  adminApiKey,
  siteUrl,
}) {
  const {
    panelId,
    ratesData,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    selectedAdminRate,
  } = useContext(AppContext);

  const [gateway, setGateway] = useState(null);
  const [updatingGateway, setUpdatingGateway] = useState(false);
  const [gatewayStatus, setGatewayStatus] = useState(null);
  const [gatewayDetails, setGatewayDetails] = useState({
    name: "",
    description: "",
    paymentDescription: "",
    publicKey: "",
    platform: "",
    min: 1,
    max: 1,
  });

  useEffect(() => {
    const fetchPaymentGateways = async () => {
      if (!adminApiKey || !selectedMethod) return;

      try {
        const url = `${siteUrl}/payment/gateways/get/id`;
        const data = {
          uid: selectedMethod.uid,
          panelId: panelId,
          adminKey: adminApiKey,
        };
        const response = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const gatewayData = response.data[0];
        setGateway(response.data);
        setGatewayDetails({
          name: gatewayData.name || "",
          description: gatewayData.description || "",
          paymentDescription: gatewayData.paymentDescription || "",
          publicKey: gatewayData.publicKey || "",
          platform: gatewayData.platform,
          min:
            convertCurrency(
              gatewayData.min,
              "USD",
              selectedAdminRate.label,
              ratesData
            ) || 1,
          max:
            convertCurrency(
              gatewayData.max,
              "USD",
              selectedAdminRate.label,
              ratesData
            ) || 1,
        });
        setGatewayStatus(gatewayData.status === "active");
      } catch (error) {
        console.error("Error fetching gateway:", error);
      }
    };

    fetchPaymentGateways();
  }, [
    siteUrl,
    adminApiKey,
    selectedMethod,
    panelId,
    ratesData,
    selectedAdminRate,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setGatewayDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleStatusUpdate = async (newValue) => {
    setGatewayStatus(newValue);
  };

  const toggleStatus = async () => {
    const newStatus = !gatewayStatus;
    setGatewayStatus(newStatus);
    await handleStatusUpdate(newStatus);
  };

  const hideEditMethod = (event) => {
    if (event.target.classList.contains("edseroverlay")) {
      setSelectedMethod(null);
    }
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const updateGateway = async () => {
    setUpdatingGateway(true);
    if (!adminApiKey) return;

    try {
      const convertedMin = convertCurrency(
        gatewayDetails.min.replace(/,/g, ""),
        selectedAdminRate.label.substring(0, 3),
        "USD",
        ratesData
      ).replace(/,/g, "");

      const convertedMax = convertCurrency(
        gatewayDetails.max.replace(/,/g, ""),
        selectedAdminRate.label.substring(0, 3),
        "USD",
        ratesData
      ).replace(/,/g, "");

      const url = `${siteUrl}/payment/gateways/update`;
      const data = {
        uid: selectedMethod.uid,
        panelId: panelId,
        adminKey: adminApiKey,
        name: gatewayDetails.name,
        gatewayPlatform: gatewayDetails.platform,
        min: parseFloat(convertedMin),
        max: parseFloat(convertedMax),
        status: gatewayStatus ? "active" : "disabled",
        description: gatewayDetails.description.replace(/\n/g, "&&n"),
        paymentDescription: gatewayDetails.paymentDescription.replace(
          /\n/g,
          "&&n"
        ),
        public_key: gatewayDetails.publicKey,
      };

      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      Notify("success", "Updated Successfully");
      setSelectedMethod(null);
    } catch (error) {
      Notify("error", error.message);
    } finally {
      setUpdatingGateway(false);
    }
  };

  return gateway ? (
    <div className="edseroverlay" onClick={hideEditMethod}>
      <div className="editservcon">
        <div className="adedmethodhead">
          <h6>Editing {gatewayDetails.name}</h6>
        </div>
        <div className="adedmthbba">
          <label htmlFor="editservnname" className="fw-semibold">
            Name
          </label>
          <input
            type="text"
            name="name"
            id="editprovselect"
            onChange={handleInputChange}
            value={gatewayDetails.name}
          />
          <label htmlFor="editservicedescription" className="fw-semibold mt-3">
            Description
          </label>
          <textarea
            name="description"
            id="editservicedescription"
            value={gatewayDetails.description.replace(/&&n/g, "\n")}
            onChange={handleInputChange}
          ></textarea>
          <div className="adeditminmaxpaymth">
            <div className="d-flex">
              <div>
                <label htmlFor="editprovselect" className="fw-semibold">
                  Minimal Payment
                </label>
                <input
                  type="text"
                  name="min"
                  id="editprovselect"
                  value={gatewayDetails.min}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label htmlFor="editservprov" className="fw-semibold">
                  Maximum Payment
                </label>
                <input
                  type="text"
                  name="max"
                  id="editservprov"
                  value={gatewayDetails.max}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          {gatewayDetails.platform === "flutterwave" ? (
            <>
              <div className="adeditminmaxpaymth">
                <label htmlFor="publicKey" className="fw-semibold">
                  Public Key
                </label>
                <input
                  type="text"
                  name="publicKey"
                  placeholder="Flutterwave public key or test key"
                  id="editprovselect"
                  onChange={handleInputChange}
                  value={gatewayDetails.publicKey}
                />
              </div>
              <div className="adeditminmaxpaymth">
                <label htmlFor="paymentDescription" className="fw-semibold">
                  Payment Description
                </label>
                <textarea
                  name="paymentDescription"
                  id="editservicedescription"
                  placeholder="This is the description that will show on Flutterwave when a user is making payment. It's Optional!"
                  value={gatewayDetails.paymentDescription.replace(
                    /&&n/g,
                    "\n"
                  )}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </>
          ) : gatewayDetails.platform === "paystack" ? (
            <div className="adeditminmaxpaymth">
              <label htmlFor="publicKey" className="fw-semibold">
                Public Key
              </label>
              <input
                type="text"
                name="publicKey"
                placeholder="Paystack public key or test key"
                id="editprovselect"
                onChange={handleInputChange}
                value={gatewayDetails.publicKey}
              />
            </div>
          ) : null}
          <div className="adedpaymethstatus">
            <span>
              <FaEye className="adrefillicon" />
              Status
            </span>
            <Switch syncValue={gatewayStatus} onToggle={toggleStatus} />
          </div>
        </div>
        <div className="adeditminmaxpaymth">
          <button className="adeditsavebtn" onClick={updateGateway}>
            {updatingGateway ? (
              <div className="adeditservspinner"></div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default EditMethod;
