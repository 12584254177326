import { useContext } from "react";
import React from "react";
import { AppContext } from "../../context/AppContext";
import ScrollAnimation from "react-animate-on-scroll";
import "../styles/detailsoverlay.css";

function DetailsOverlay({
  head,
  body,
  setShowDetailsOverlay,
  actionsRef,
  animateIn,
}) {
  const { darkMode } = useContext(AppContext);
  const hideDetailsOverlay = (event) => {
    if (event.target.classList.contains("cldetails-overlay")) {
      setShowDetailsOverlay(false);
    }
  };
  return (
    <div
      className="cldetails-overlay"
      data-theme={darkMode ? "dark" : "light"}
      onClick={(event) => hideDetailsOverlay(event)}
      ref={actionsRef}
    >
      <ScrollAnimation
        className="cldetails-overlay-con"
        delay={300}
        animateIn={animateIn ? animateIn : "zoomIn"}
      >
        <strong className="cldetailsHeadtext">{head}</strong>
        <pre className="cldetailsBodyText">{body}</pre>
      </ScrollAnimation>
    </div>
  );
}

export default DetailsOverlay;
