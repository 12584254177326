import "../styles/design.css";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import AdminHeader from "../components/AdminHeader";
import { IoIosWarning } from "react-icons/io";
import ControlPanel from "./ControlPanel";
import ColorPicker from "../shared/ColorPicker";
import axios from "axios";
import Loading from "../shared/Loading";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import DetailsOverlay from "../shared/DetailsOverlay";

function Design() {
  const {
    myAdminStyles,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    panelId,
    fetchSiteData,
    siteTitle,
    setFetchSiteData,
    currentAdmin,
    siteUrl,
    siteData,
  } = useContext(AppContext);
  const [clientActiveHover, setClientActiveHover] = useState("");
  const [clientActiveLight, setClientActiveLight] = useState("");
  const [clientActiveDark, setClientActiveDark] = useState("");
  const [clientActiveBase, setClientActiveBase] = useState("");
  const [clientActiveText, setClientActiveText] = useState("");
  const [siteColor, setSiteColor] = useState("");
  const [clientActiveBackground, setClientActiveBackground] = useState("");
  const [updatingDesign, setUpdatingDesign] = useState(false);
  const [showColorNote, setShowColorNote] = useState(false);
  const [showAdminColorNote, setShowAdminColorNote] = useState(false);
  const [adminActiveHover, setAdminActiveHover] = useState("");
  const [adminActiveDark, setAdminActiveDark] = useState("");
  const [adminSiteColor, setAdminSiteColor] = useState("");
  const [adminActiveBase, setAdminActiveBase] = useState("");
  const [adminActiveText, setAdminActiveText] = useState("");
  const [adminActiveBackground, setAdminActiveBackground] = useState("");
  const [designData, setDesignData] = useState(null);

  useEffect(() => {
    const getDesign = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "design",
            key: currentAdmin.apiKey,
            panelId: panelId,
          });
          const colorDoc = response.data.find((col) => col.uid === "design");
          setDesignData(colorDoc);
        } catch (error) {}
      }
    };
    getDesign();
  }, [panelId, siteUrl, currentAdmin]);

  useEffect(() => {
    if (designData && designData.clientStyles) {
      setClientActiveBase(designData.clientStyles["--stbaseactcolor"]);
      setClientActiveLight(designData.clientStyles["--bglightcolor"]);
      setClientActiveDark(designData.clientStyles["--bgdarkcolor"]);
      setClientActiveHover(designData.clientStyles["--stbasehvcolor"]);
      setClientActiveText(designData.clientStyles["--sttextbgcolor"]);
      setClientActiveBackground(designData.clientStyles["--stbasebgcolor"]);
      setSiteColor(designData.clientStyles["--sitecolor"]);
    }
    if (designData && designData.adminStyles) {
      setAdminActiveBase(designData.adminStyles["--adbaseactcolor"]);
      setAdminActiveDark(designData.adminStyles["--addarkbgcolor"]);
      setAdminActiveHover(designData.adminStyles["--adbasehvcolor"]);
      setAdminActiveText(designData.adminStyles["--adtextbgcolor"]);
      setAdminSiteColor(designData.adminStyles["--sitecolor"]);
      setAdminActiveBackground(designData.adminStyles["--adbasebgcolor"]);
    }
  }, [designData]);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration >= 0) setNotifyDuration(duration);
  };

  useEffect(() => {
    document.title = `Design | ${siteTitle}`;
  }, [siteTitle]);

  if (siteData === null) {
    return <Loading />;
  }

  const clientColorsNote = `1. Site Color: Applies to the primary site color, reflecting your brand identity.
2. Background Color: Sets the overall background color of your site, complementing your design theme.
3. Base Color: Used across various elements; align it with your preferred color palette.
4. Light Background Color: Background color for light mode; choose a lighter tone that fits well with light mode.
5. Text Color: Determines the color of the text on your site, ensuring readability and matching your branding.
6. Hover Color: Color for elements' hover state, providing distinct visual feedback on hover.
7. Dark Background Color: Background color for dark mode; select a darker tone that suits dark mode design.
`;

  const adminColorsNote = `1. Site Color: Applies to the primary color theme across the admin interface, reflecting the administrative theme.
2. Background Color: Sets the background color for the admin interface, enhancing visibility and aesthetics.
3. Base Color: Used for primary interactive elements in the admin interface; choose a color that enhances usability.
4. Dark Background Color: Background color for darker areas of the admin interface; ideal for contrast and reducing eye strain.
5. Hover Color: Color for hover states on interactive elements, ensuring clear feedback when interacted with.
6. Text Color: Determines the text color, optimizing readability and consistency across the admin panel.
`;

  const saveDesign = async () => {
    setUpdatingDesign(true);
    try {
      const data = {
        clientStyles: {
          "--sttextbgcolor": clientActiveText,
          "--stbasebgcolor": clientActiveBackground,
          "--stbaseactcolor": clientActiveBase,
          "--stbasehvcolor": clientActiveHover,
          "--bglightcolor": clientActiveLight,
          "--sitecolor": siteColor,
          "--bgdarkcolor": clientActiveDark,
        },
        adminStyles: {
          "--adtextbgcolor": adminActiveText,
          "--adbasebgcolor": adminActiveBackground,
          "--adbaseactcolor": adminActiveBase,
          "--adbasehvcolor": adminActiveHover,
          "--addarkbgcolor": adminActiveDark,
          "--sitecolor": adminSiteColor,
        },
      };
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "design",
        key: currentAdmin.apiKey,
        panelId: panelId,
      });
      const designExist = response.data.some((page) => page.uid === "design");
      if (designExist) {
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "design",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
          uid: "design",
        });
      } else {
        data.uid = "design";
        await axios.post(`${siteUrl}/crud/add/doc`, {
          collection: "design",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
        });
      }
      setUpdatingDesign(false);
      setFetchSiteData(!fetchSiteData);
      Notify("success", "Saved Successfully");
    } catch (error) {
      setUpdatingDesign(false);
      Notify("error", "Error Saving Design");
    }
  };

  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="addesign" style={myAdminStyles}>
        <div className="addesignmaincon">
          <ControlPanel />
          <div className="adcpbdycon">
            <div className="addesignhead">
              <h6>Design</h6>
            </div>
            <div className="addesignbody">
              <div className="addesignhd">
                <h4>Colours</h4>
                <button className="adsavegeneral" onClick={saveDesign}>
                  {updatingDesign ? (
                    <div className="adeditservspinner"></div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
              <div className="addesclcon">
                <span>Client Colours</span>
                <button
                  className="addesnotlr"
                  onClick={() => setShowColorNote(true)}
                >
                  <IoIosWarning className="addesnotlricon" />
                  Note
                </button>
                <div className="addesclrs">
                  <ColorPicker color={siteColor} setState={setSiteColor} />
                  <ColorPicker
                    color={clientActiveBackground}
                    setState={setClientActiveBackground}
                  />
                  <ColorPicker
                    color={clientActiveBase}
                    setState={setClientActiveBase}
                  />
                  <ColorPicker
                    color={clientActiveLight}
                    setState={setClientActiveLight}
                  />
                  <ColorPicker
                    color={clientActiveText}
                    setState={setClientActiveText}
                  />
                  <ColorPicker
                    color={clientActiveHover}
                    setState={setClientActiveHover}
                  />
                  <ColorPicker
                    color={clientActiveDark}
                    setState={setClientActiveDark}
                  />
                </div>
              </div>
              <div className="addesclcon">
                <span>Admin Colours</span>
                <button
                  className="addesnotlr"
                  onClick={() => setShowAdminColorNote(true)}
                >
                  <IoIosWarning className="addesnotlricon" />
                  Note
                </button>
                <div className="addesclrs">
                  <ColorPicker
                    color={adminSiteColor}
                    setState={setAdminSiteColor}
                  />
                  <ColorPicker
                    color={adminActiveBackground}
                    setState={setAdminActiveBackground}
                  />
                  <ColorPicker
                    color={adminActiveBase}
                    setState={setAdminActiveBase}
                  />
                  <ColorPicker
                    color={adminActiveDark}
                    setState={setAdminActiveDark}
                  />
                  <ColorPicker
                    color={adminActiveHover}
                    setState={setAdminActiveHover}
                  />
                  <ColorPicker
                    color={adminActiveText}
                    setState={setAdminActiveText}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showColorNote && (
        <DetailsOverlay
          head={"Client Colours"}
          body={clientColorsNote}
          setShowDetailsOverlay={setShowColorNote}
          animateIn={"backInDown"}
        />
      )}
      {showAdminColorNote && (
        <DetailsOverlay
          head={"Admin Colours"}
          body={adminColorsNote}
          setShowDetailsOverlay={setShowAdminColorNote}
          animateIn={"backInDown"}
        />
      )}
    </React.Fragment>
  );
}

export default Design;
