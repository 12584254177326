import { AppContext } from "../../context/AppContext";
import TipTapEditor from "../shared/TipTapEditor";
import "../styles/faqs.css";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

function EditFaq({ uid, showEditFaq, setShowEditFaq, setFetchFaq, fetchFaq }) {
  const {
    setNotifyType,
    currentAdmin,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    panelId,
    siteUrl,
  } = useContext(AppContext);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const hideAddFaq = (event) => {
    if (event.target.classList.contains("edseroverlay")) {
      setShowEditFaq(null);
    }
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration >= 0) setNotifyDuration(duration);
  };

  useEffect(() => {
    const getFaqData = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "faqs",
            key: currentAdmin.apiKey,
            panelId: panelId,
          });
          const faqDoc = response.data.find((faq) => faq.uid === uid);
          setAnswer(faqDoc.answer);
          setQuestion(faqDoc.question);
        } catch (error) {}
      }
    };

    getFaqData();
  }, [panelId, siteUrl, currentAdmin, uid]);

  const updateFaq = async () => {
    try {
      const data = {
        question: question,
        answer: answer,
      };
      await axios.post(`${siteUrl}/crud/update/doc`, {
        collection: "faqs",
        panelId: panelId,
        uid: uid,
        data: data,
        key: currentAdmin.apiKey,
      });
      setFetchFaq(!fetchFaq);
      setShowEditFaq(null);
      Notify("success", "Updated Successfully");
    } catch (error) {
      Notify("error", "Error updating faq");
    }
  };
  return (
    showEditFaq && (
      <div className="edseroverlay" onClick={(event) => hideAddFaq(event)}>
        <div className="editservcon">
          <div className="adfaqaddfrm">
            <div className="adedmethodhead">
              <h6>Edit Faq</h6>
            </div>
            <div>
              <label htmlFor="title">Question</label>
              <input
                type="text"
                id="editprovselect"
                name="title"
                value={question}
                autoComplete="off"
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="description">Answer</label>
              <TipTapEditor
                content={answer}
                setContent={setAnswer}
                useage="faqs"
              />
            </div>
            <button
              type="submit"
              className="adfaqsaddfrmbtn"
              onClick={updateFaq}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default EditFaq;
