import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa"; // Import Instagram icon
import "../styles/integrations.css";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import axios from "axios";

function Integrations() {
  const { siteUrl, panelId } = useContext(AppContext);
  const [integrationDoc, setIntegrationDoc] = useState(null);

  useEffect(() => {
    const getIntegrationDoc = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "integrations",
          panelId: panelId,
        });
        const integrationDoc = response.data.find(
          (intg) => intg.uid === "integrations"
        );
        setIntegrationDoc(integrationDoc);
      } catch (error) {
        // Handle error if necessary
      }
    };
    getIntegrationDoc();
  }, [siteUrl, panelId]);

  return (
    <React.Fragment>
      {integrationDoc &&
        integrationDoc.whatsapp &&
        integrationDoc.whatsapp.number !== "" && (
          <Link
            to={`https://wa.me/${integrationDoc.whatsapp.number}`}
            target="blank"
            className="whatsapp"
            style={{ [integrationDoc.whatsapp.align]: "20px" }}
          >
            <FaWhatsapp className="whatsapp-icon" />
          </Link>
        )}
      {integrationDoc &&
        integrationDoc.instagram &&
        integrationDoc.instagram.username !== "" && (
          <Link
            to={`https://instagram.com/${integrationDoc.instagram.username}`}
            target="blank"
            className="instagram"
            style={{ [integrationDoc.instagram.align]: "20px" }}
          >
            <FaInstagram className="instagram-icon" />
          </Link>
        )}
      {integrationDoc &&
        integrationDoc.other &&
        integrationDoc.other !== "" &&
        parse(integrationDoc.other)}
    </React.Fragment>
  );
}

export default Integrations;
