import { useContext, useState } from "react";
import React from "react";
import { AppContext } from "../../context/AppContext";
import "../styles/vieworder.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { formatDate } from "../shared/FormatDate";
import ViewService from "../components/ViewService";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import axios from "axios";
import { convertCurrency } from "../shared/ConvertCurrency";
import DetailsOverlay from "./DetailsOverlay";
import { FaComments } from "react-icons/fa";
import { FaRecycle } from "react-icons/fa";

function ViewOrder({ order, setOrder, componentRef }) {
  const {
    darkMode,
    selectedRate,
    ratesData,
    siteUrl,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    panelId,
    currentUser,
    logoUrl,
  } = useContext(AppContext);
  const [orderDetails, setOrderDetails] = useState(null);
  const [showService, setShowService] = useState(null);
  const [showComments, setShowComments] = useState(null);
  const [refillAvailability, setRefillAvailability] = useState(false);

  const hideOverlay = (event) => {
    if (event.target.classList.contains("view-orders-overlay")) {
      setOrderDetails(null);
      setOrder(null);
    }
  };

  const cancelOverlay = () => {
    setOrderDetails(null);
    setOrder(null);
  };

  useEffect(() => {
    const getOrder = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/order/get-order-id`, {
            key: currentUser.apiKey,
            panelId: panelId,
            orderId: order.id,
          });
          setOrderDetails(response.data.order);
        } catch (error) {
          console.log(error.message);
        }
      }
    };
    getOrder();
  }, [currentUser, siteUrl, panelId, order]);

  useEffect(() => {
    const fetchRefillAvailability = async () => {
      if (currentUser && orderDetails) {
        try {
          const response = await axios.post(
            `${siteUrl}/refill/get/availability`,
            {
              key: currentUser.apiKey,
              panelId: panelId,
              serviceNames: [orderDetails.service],
            }
          );
          const serviceData = response.data.data[orderDetails.service];
          setRefillAvailability(serviceData);
        } catch (error) {}
      }
    };

    fetchRefillAvailability();
  }, [orderDetails, siteUrl, currentUser, panelId]);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const refillOrder = async () => {
    try {
      await axios.post(`${siteUrl}/refill/send`, {
        key: currentUser.apiKey,
        panelId: panelId,
        orderId: orderDetails.id,
      });
      setOrder(null);
      Notify("success", "Sent for refill");
    } catch (error) {
      console.log(error);
      Notify("error", "Error sending order for refill");
    }
  };

  return (
    orderDetails &&
    order && (
      <div
        className="view-orders-overlay"
        data-theme={darkMode ? "dark" : "light"}
        onClick={(e) => hideOverlay(e)}
        ref={componentRef}
      >
        <div className="view-orders-con">
          <div className="logo-container">
            <img src={logoUrl} alt="Logo" className="logo-in-description" />
          </div>
          <div className="view-order-info-con">
            <div className="view-order-info">
              <div className="view-order-info-head">
                <span>Service</span>
              </div>
              <div className="view-order-info-body">
                <strong
                  className="clmbservice"
                  onClick={() => setShowService({ id: order.serviceID })}
                >
                  {order.serviceID} - {order.service}
                </strong>
              </div>
            </div>
            <div className="view-order-info">
              <div className="view-order-info-head">
                <span>Link</span>
              </div>
              <div className="view-order-info-body">
                <Link to={order.url} target="_blank" className="clmborderurl">
                  <strong>{order.url}</strong>
                </Link>
              </div>
            </div>
            <div className="view-order-info-triple">
              <div className="balance-before">
                <span>Balance Before</span>
                <span>
                  ≈ {getCurrencySymbol(selectedRate?.label)}
                  {convertCurrency(
                    orderDetails.userInitialBalance,
                    "USD",
                    selectedRate?.label,
                    ratesData
                  )}
                </span>
              </div>
              <div className="balance-after">
                <span>Balance After</span>
                <span>
                  ≈ {getCurrencySymbol(selectedRate?.label)}
                  {convertCurrency(
                    orderDetails.userFinalBalance,
                    "USD",
                    selectedRate?.label,
                    ratesData
                  )}
                </span>
              </div>
              <div className="balance-after">
                <span>Start Count</span>
                <span>{orderDetails.start}</span>
              </div>
            </div>
            <div className="view-order-info-triple">
              <div className="charge">
                <span>Status</span>
                <span
                  className={
                    orderDetails.status === "Completed" ||
                    orderDetails.status === "In progress"
                      ? "orderstablegreenstat"
                      : orderDetails.status === "Canceled" ||
                        orderDetails.status === "Failed" ||
                        orderDetails.status === "Partial"
                      ? "orderstableredstat"
                      : orderDetails.status === "Processing"
                      ? "orderstablebluestat"
                      : orderDetails.status === "Pending"
                      ? "orderstableyellowstat"
                      : ""
                  }
                >
                  {orderDetails.status}
                </span>
              </div>
              <div className="quantity">
                <span>Progress</span>
                <span>
                  {orderDetails.number - order.remains} of {order.number}
                </span>
              </div>
              <div className="date">
                <span>Remains</span>
                <span>{orderDetails.remains}</span>
              </div>
            </div>
            <div className="view-order-info-triple">
              <div className="charge">
                <span>Price</span>
                <span>
                  ≈ {getCurrencySymbol(selectedRate?.label)}
                  {convertCurrency(
                    orderDetails.price,
                    "USD",
                    selectedRate?.label,
                    ratesData
                  )}
                </span>
              </div>
              <div className="quantity">
                <span>Quantity</span>
                <span>{orderDetails.number}</span>
              </div>
              <div className="date">
                <span>Date</span>
                <span>{formatDate(orderDetails.timestamp)}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between gap-20 flex-wrap align-items-center">
              {orderDetails.comments && (
                <button
                  className="clviewcommentsbtn"
                  onClick={() => setShowComments(orderDetails.comments)}
                >
                  <FaComments className="icon" />
                  View Comments
                </button>
              )}
              {refillAvailability && (
                <button className="clrefillbtn" onClick={refillOrder}>
                  <FaRecycle className="icon" /> Refill
                </button>
              )}
            </div>
            <div className="flex-center">
              <button
                className="cancel-button"
                onClick={(e) => cancelOverlay(e)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {showComments && (
          <DetailsOverlay
            head={"Comments"}
            body={showComments}
            setShowDetailsOverlay={setShowComments}
            actionsRef={componentRef}
            setSelectedService={setShowService}
          />
        )}
        {showService && (
          <ViewService
            selectedService={showService}
            setSelectedService={setShowService}
          />
        )}
      </div>
    )
  );
}

export default ViewOrder;
