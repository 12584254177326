import OrdersTable from "./OrdersTable";
import NotFound from "../shared/NotFound";
import { useState, useEffect } from "react";

function FailedOrders({ allOrders, tableRef, actionsRef, setFetchOrders }) {
  const [filteredOrders, setFilteredOrders] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get("search")) {
      const filtered = allOrders.filter(
        (allOrders) => allOrders.status === "Failed"
      );
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(allOrders);
    }
  }, [allOrders]);

  return filteredOrders.length === 0 ? (
    <NotFound
      value={
        searchParams.get("search")
          ? "No matching orders found with the given URL or ID"
          : "No failed order found."
      }
    />
  ) : (
    <>
      <OrdersTable
        orders={filteredOrders}
        tableRef={tableRef}
        actionsRef={actionsRef}
        setFetchOrders={setFetchOrders}
      />
    </>
  );
}

export default FailedOrders;
