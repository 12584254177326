import OrdersTable from "./OrdersTable";
import NotFound from "../shared/NotFound";

function AllOrders({ allOrders, tableRef, actionsRef, setFetchOrders }) {
  return allOrders.length === 0 ? (
    <NotFound value={"You didn't create any orders yet"} />
  ) : (
    <>
      <OrdersTable
        orders={allOrders}
        tableRef={tableRef}
        actionsRef={actionsRef}
        setFetchOrders={setFetchOrders}
      />
    </>
  );
}
export default AllOrders;
