import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import Switch from "../shared/Switch.jsx";
import "../styles/editservice.css";
import axios from "axios";
import refill from "../../assets/images/refill.png";
import checked from "../../assets/images/checked.png";
import dripFeedPic from "../../assets/images/dripFeed.png";
import cancel from "../../assets/images/cancel.png";
import { ReactSVG } from "react-svg";
import percentIcon from "../../assets/svg/percentage.svg";
import { AppContext } from "../../context/AppContext.js";
import TipTapEditor from "../shared/TipTapEditor";
import { getCurrencySymbol } from "../../client/shared/CurrencySymbol.jsx";
import { convertCurrency } from "../../client/shared/ConvertCurrency.jsx";

function EditService({
  selectedService,
  fetchServices,
  setFetchServices,
  setSelectedService,
  actionRef,
}) {
  const [serviceData, setServiceData] = useState({
    serviceName: "",
    serviceDescription: "",
    oldServiceName: "",
    providerCurrency: "",
    serviceMax: 0,
    serviceUid: "",
    serviceMin: 0,
    providerPrice: 0,
    provider: 0,
    providerID: 0,
    actuaPrice: 0,
    refillDays: 0,
    pricePercent: 0,
    serviceType: null,
    category: null,
    categoryOption: null,
    syncQuantity: null,
    syncCatAndName: null,
    dripFeed: null,
    cancelBtn: null,
    refillBtn: null,
  });
  const [updatingService, setUpdatingService] = useState(false);

  const {
    panelId,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    selectedAdminRate,
    currentAdmin,
    ratesData,
    siteUrl,
  } = useContext(AppContext);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const serviceTypeOptions = [
    { value: "default", label: "Default" },
    { value: "package", label: "Package" },
    { value: "customComments", label: "Custom Comments" },
    { value: "mentions", label: "Mentions" },
  ];

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      borderRadius: "20px",
      padding: "10px 20px",
      borderColor: "var(--adbasehvcolor)",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused
        ? "var(--adbaseactcolor)"
        : "var(--adbasebgcolor)",
      color: "white",
    }),
  };

  const hideEditService = (event) => {
    if (event.target.classList.contains("edseroverlay")) {
      setSelectedService(null);
    }
  };

  const toggleSyncQuantity = async () => {
    const newValue = !serviceData.syncQuantity;
    setServiceData((prevState) => ({
      ...prevState,
      syncQuantity: newValue,
    }));
  };

  const toggleSyncCatAndName = async () => {
    const newValue = !serviceData.syncCatAndName;
    setServiceData((prevState) => ({
      ...prevState,
      syncCatAndName: newValue,
    }));
  };

  const toggleDripFeed = async () => {
    const newValue = !serviceData.dripFeed;
    setServiceData((prevState) => ({
      ...prevState,
      dripFeed: newValue,
    }));
  };

  const toggleCancel = async () => {
    const newValue = !serviceData.cancelBtn;
    setServiceData((prevState) => ({
      ...prevState,
      cancelBtn: newValue,
    }));
  };

  const toggleRefill = async () => {
    const newValue = !serviceData.refillBtn;
    setServiceData((prevState) => ({
      ...prevState,
      refillBtn: newValue,
    }));
  };

  const handleServiceTypeChange = (selectedOption) => {
    setServiceData((prevState) => ({
      ...prevState,
      serviceType: selectedOption.label,
    }));
  };

  const handleCategoryChange = (selectedOption) => {
    setServiceData((prevState) => ({
      ...prevState,
      categoryOption: selectedOption.label,
    }));
  };

  useEffect(() => {
    const fetchService = async () => {
      if (selectedService) {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "services",
          panelId: panelId,
          query: {
            find: {
              field: "id",
              operator: "===",
              value: selectedService.serviceID || selectedService.id,
            },
          },
        });
        const serviceDoc = response.data;
        setServiceData({
          serviceName: serviceDoc.name,
          oldServiceName: serviceDoc.name,
          serviceDescription: serviceDoc.description.replace(/&&n/g, "\n"),
          serviceMax: serviceDoc.max,
          serviceUid: serviceDoc.uid,
          serviceMin: serviceDoc.min,
          provider: serviceDoc.provider,
          providerID: parseInt(serviceDoc.providerID),
          providerPrice: parseFloat(serviceDoc.providerPrice),
          actuaPrice: calculateActuaPrice(
            parseFloat(serviceDoc.providerPrice),
            parseInt(serviceDoc.percentage)
          ),
          refillDays: serviceDoc.refillDays,
          pricePercent: serviceDoc.percentage,
          serviceType: serviceDoc.type,
          dripFeed: serviceDoc.dripFeed,
          cancelBtn: serviceDoc.cancel,
          refillBtn: serviceDoc.refill,
          categoryOption: serviceDoc.category,
          syncQuantity: serviceDoc.syncQuantity,
          syncCatAndName: serviceDoc.syncCatAndName,
          providerCurrency: serviceDoc.providerCurrency,
        });
      }
    };

    const fetchCategories = async () => {
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "categories",
        panelId: panelId,
      });
      const categoryDoc = response.data.map((doc) => ({
        value: doc.name,
        label: doc.name,
      }));
      setServiceData((prevState) => ({
        ...prevState,
        category: categoryDoc,
      }));
    };

    fetchService();
    fetchCategories();
  }, [selectedService, siteUrl, panelId]);

  useEffect(() => {
    if (serviceData && serviceData.serviceType) {
      if (serviceData.serviceType === "Package") {
        setServiceData((prevState) => ({
          ...prevState,
          serviceMin: 1,
          serviceMax: 1,
        }));
      }
    }
  }, [serviceData]);

  const calculateActuaPrice = (providerPrice, percentage) => {
    const priceIncrement = (providerPrice * percentage) / 100;
    return parseFloat((providerPrice + priceIncrement).toFixed(3));
  };

  const serviceNameChange = (e) => {
    const value = e.target.value;
    setServiceData((prevState) => ({
      ...prevState,
      serviceName: value,
    }));
  };

  const serviceMinChange = (e) => {
    const value = e.target.value;
    setServiceData((prevState) => ({
      ...prevState,
      serviceMin: value === "" ? "" : parseInt(value),
    }));
  };

  const serviceMaxChange = (e) => {
    const value = e.target.value;
    setServiceData((prevState) => ({
      ...prevState,
      serviceMax: value === "" ? "" : parseInt(value),
    }));
  };

  const refillDaysChange = (e) => {
    const value = e.target.value;
    setServiceData((prevState) => ({
      ...prevState,
      refillDays: value === "" ? "" : parseInt(value),
    }));
  };

  const percentageChange = (e) => {
    const value = e.target.value;
    setServiceData((prevState) => {
      const pricePercent = value === "" ? "" : parseInt(value);
      const actuaPrice = calculateActuaPrice(
        prevState.providerPrice,
        pricePercent
      );
      return {
        ...prevState,
        pricePercent,
        actuaPrice,
      };
    });
  };

  const updateService = async () => {
    setUpdatingService(true);
    try {
      const url = `${siteUrl}/service/edit`;
      const data = {
        panelId: panelId,
        key: currentAdmin.apiKey,
        serviceMin: serviceData.serviceMin,
        serviceMax: serviceData.serviceMax,
        serviceName: serviceData.serviceName,
        actualPrice: serviceData.actuaPrice,
        oldServiceName: serviceData.oldServiceName,
        pricePercent: serviceData.pricePercent,
        syncQuantity: serviceData.syncQuantity,
        categoryOption: serviceData.categoryOption,
        dripFeed: serviceData.dripFeed,
        cancel: serviceData.cancelBtn,
        syncCatAndName: serviceData.syncCatAndName,
        refill: serviceData.refillBtn,
        serviceUid: serviceData.serviceUid,
        refillDays: serviceData.refillDays,
        serviceDescription: serviceData.serviceDescription,
        serviceType: serviceData.serviceType,
      };
      await axios.post(url, data);
      setFetchServices(!fetchServices);
      Notify("success", "Updated Successfully");
      setSelectedService(false);
      setUpdatingService(false);
    } catch (error) {
      Notify("error", error.response.data);
      setUpdatingService(false);
    }
  };

  return (
    <>
      {selectedService && serviceData && (
        <div
          className="edseroverlay"
          onClick={(event) => hideEditService(event)}
          ref={actionRef}
        >
          <div className="editservcon">
            <div className="mb-3">
              <strong className="edserheadtext">
                Editing Service{" "}
                {selectedService.serviceID || selectedService.id}
              </strong>
            </div>
            <div className="adeditservicecon">
              <div className="editserprovider">
                <div className="editserproviderinp">
                  <div>
                    <label htmlFor="editprovselect" className="fw-semibold">
                      Provider
                    </label>
                    <input
                      type="text"
                      name="editprovselect"
                      id="editprovselect"
                      disabled
                      readOnly
                      value={serviceData.provider || ""}
                    />
                  </div>
                  <div>
                    <label htmlFor="editservprov" className="fw-semibold">
                      Service ID
                    </label>
                    <input
                      type="text"
                      id="editservprov"
                      name="editservprov"
                      disabled
                      value={serviceData.providerID || ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="adeditdescripser">
                <div className="my-3">
                  <strong className="edserheadtext">Description</strong>
                </div>
                <div className="adeditservdesc">
                  <label htmlFor="editservnname" className="fw-semibold">
                    Service Name
                  </label>
                  <input
                    type="text"
                    name="editservnname"
                    id="editprovselect"
                    onChange={serviceNameChange}
                    value={serviceData.serviceName}
                  />
                  <label htmlFor="editservnname" className="fw-semibold mt-3">
                    Service Type
                  </label>
                  <Select
                    options={serviceTypeOptions}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary: "var(--adtextbgcolor)",
                      },
                    })}
                    placeholder="Select type"
                    styles={selectStyles}
                    value={serviceTypeOptions.find(
                      (option) => option.label === serviceData.serviceType
                    )}
                    onChange={handleServiceTypeChange}
                  />
                  <label htmlFor="editservcate" className="fw-semibold mt-3">
                    Category
                  </label>
                  <Select
                    options={serviceData.category}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        primary: "var(--adtextbgcolor)",
                      },
                    })}
                    placeholder="Select category"
                    styles={selectStyles}
                    value={serviceData.category?.find(
                      (option) => option.label === serviceData.categoryOption
                    )}
                    onChange={handleCategoryChange}
                  />
                  <label
                    htmlFor="editservicedescription"
                    className="fw-semibold mt-3"
                  >
                    Description
                  </label>
                  <TipTapEditor
                    content={serviceData.serviceDescription}
                    setContent={setServiceData}
                    stateKey="serviceDescription"
                    useage="services"
                  />
                </div>
              </div>
              <div className="adeditoptionserv">
                <div className="my-3">
                  <strong className="edserheadtext">Options</strong>
                </div>
                <div className="adeditservdesc">
                  <div className="adeditminmaxserv">
                    <div className="d-flex">
                      <div>
                        <label htmlFor="editprovselect" className="fw-semibold">
                          Minimal Order
                        </label>
                        <input
                          type="text"
                          name="editprovselect"
                          id="editprovselect"
                          disabled={
                            serviceData.serviceType === "Package"
                              ? true
                              : serviceData.syncQuantity
                          }
                          value={serviceData.serviceMin}
                          onChange={serviceMinChange}
                        />
                      </div>
                      <div>
                        <label htmlFor="editservprov" className="fw-semibold">
                          Maximum Order
                        </label>
                        <input
                          type="text"
                          id="editservprov"
                          name="editservprov"
                          disabled={
                            serviceData.serviceType === "Package"
                              ? true
                              : serviceData.syncQuantity
                          }
                          value={serviceData.serviceMax}
                          onChange={serviceMaxChange}
                        />
                      </div>
                    </div>
                    <div className="adsyncminmax">
                      <strong>Sync min/max from provider</strong>
                      <Switch
                        syncValue={serviceData.syncQuantity}
                        onToggle={toggleSyncQuantity}
                      />
                    </div>
                    <div className="adsyncminmax">
                      <strong>Sync name and category from provider</strong>
                      <Switch
                        syncValue={serviceData.syncCatAndName}
                        onToggle={toggleSyncCatAndName}
                      />
                    </div>
                  </div>
                </div>
                <div className="adeditservdesc mt-3">
                  <div className="adeditservrefill">
                    <span>
                      <img
                        src={refill}
                        alt="refill/icon"
                        className="adrefillicon"
                      />
                      Refill
                    </span>
                    <Switch
                      syncValue={serviceData.refillBtn}
                      onToggle={toggleRefill}
                    />
                  </div>
                  {serviceData.refillBtn ? (
                    <div className="adserrefdays">
                      <label htmlFor="refillDays" className="fw-semibold">
                        How many days refill button available
                      </label>
                      <input
                        type="number"
                        placeholder="From 1 to 365 days"
                        name="refillDays"
                        id="adrefillDays"
                        value={serviceData.refillDays}
                        onChange={refillDaysChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="adeditservdesc mt-3">
                  <div className="adeditservrefill">
                    <span>
                      <img
                        src={serviceData.cancelBtn ? checked : cancel}
                        alt="cancel/icon"
                        className="adrefillicon"
                      />
                      Cancel Button
                    </span>
                    <Switch
                      syncValue={serviceData.cancelBtn}
                      onToggle={toggleCancel}
                    />
                  </div>
                </div>
                <div className="adeditservdesc mt-3">
                  <div className="adeditservrefill">
                    <span>
                      <img
                        src={dripFeedPic}
                        alt="dripFeed/icon"
                        className="adrefillicon"
                      />
                      Drip Feed
                    </span>
                    <Switch
                      syncValue={serviceData.dripFeed}
                      onToggle={toggleDripFeed}
                    />
                  </div>
                </div>
              </div>
              <div className="adeditoptionserv">
                <div className="my-3">
                  <strong className="edserheadtext">Price</strong>
                </div>
                <div className="adeditservdesc">
                  <div className="adeditproviderprice">
                    <span>
                      Provider price for{" "}
                      {serviceData.serviceType === "Package" ? "1" : "1000"}{" "}
                      completions
                    </span>
                    <span>
                      {getCurrencySymbol(selectedAdminRate.label)}
                      {convertCurrency(
                        serviceData.providerPrice,
                        serviceData.providerCurrency,
                        selectedAdminRate.label,
                        ratesData
                      )}
                    </span>
                  </div>
                  <div className="adeditservprice">
                    <div className="adeditpercentCon">
                      <span>Your extra price in %</span>
                      <div className="adpercentincon">
                        <input
                          type="number"
                          name="percent"
                          id="servicePercentage"
                          value={serviceData.pricePercent}
                          onChange={percentageChange}
                        />
                        <ReactSVG src={percentIcon} className="percnticon" />
                      </div>
                    </div>
                  </div>
                  <div className="adeditactualprice">
                    <span>Your price on panel</span>
                    <span>
                      {getCurrencySymbol(selectedAdminRate.label)}
                      {convertCurrency(
                        serviceData.actuaPrice,
                        serviceData.providerCurrency,
                        selectedAdminRate.label,
                        ratesData
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <button className="adeditsavebtn" onClick={updateService}>
              {updatingService ? (
                <div className="adeditservspinner"></div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(EditService);
