import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import AdminHeader from "../components/AdminHeader";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CheckBox from "../shared/Checkbox";
import FetchingData from "../shared/FetchingData";
import "../styles/adminsupport.css";
import { AppContext } from "../../context/AppContext";
import Pagination from "../shared/Pagination";
import { FaSearch } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

const AdminSupport = () => {
  const {
    currentAdmin,
    siteUrl,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    myAdminStyles,
    panelId,
    siteTitle,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [tickets, setTickets] = useState([]);
  const [mainTickets, setMainTickets] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [bulkAction, setBulkAction] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchTickets, setFetchTickets] = useState(false);
  const containerRef = useRef();
  const bodyRef = useRef();
  const tableRef = useRef();
  const [fetchingTickets, setFetchingTickets] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const searchUrlParams = new URLSearchParams(location.search);
  const SearchValue = searchUrlParams.get("search");

  useEffect(() => {
    document.title = `Support | ${siteTitle}`;
  }, [siteTitle]);

  useEffect(() => {
    const fetchTickets = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/support/tickets`, {
            key: currentAdmin.apiKey,
            panelId: panelId,
          });
          setTickets(response.data);
          setMainTickets(response.data);
          setFetchingTickets(false);
        } catch (error) {
          console.error("Error fetching tickets:", error);
        }
      }
    };
    fetchTickets();
  }, [fetchTickets, panelId, currentAdmin, siteUrl]);

  useEffect(() => {
    let handler = (e) => {
      if (
        !bodyRef.current?.contains(e.target) &&
        !tableRef.current?.contains(e.target) &&
        !containerRef.current?.contains(e.target)
      ) {
        setSearchActive(false);
        setSearchQuery("");
        searchUrlParams.delete("search");
        const newUrl = `${window.location.pathname}`;
        navigate(newUrl);
        setTickets(mainTickets);
      }
    };
    if (searchActive) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    if (SearchValue !== null) {
      const handleSearchValue = () => {
        const parsedValue =
          SearchValue.trim() === ""
            ? ""
            : isNaN(SearchValue)
            ? String(SearchValue)
            : parseInt(SearchValue, 10);
        setSearchQuery(parsedValue);
        const dupTickets = [...mainTickets];
        setTickets(
          dupTickets.filter((ticket) => {
            if (typeof parsedValue === "string") {
              return ticket.subject
                .toLowerCase()
                .includes(parsedValue.toLowerCase());
            } else if (typeof parsedValue === "number") {
              return String(ticket.id).includes(String(parsedValue));
            }
            return false;
          })
        );
      };
      handleSearchValue();
      setSearchActive(true);
    }
  }, [location.search, searchQuery, SearchValue, mainTickets]);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration >= 0) setNotifyDuration(duration);
  };

  const handleBulkAction = async () => {
    try {
      if (bulkAction === "delete") {
        await axios.post(`${siteUrl}/support/delete-tickets`, {
          key: currentAdmin.apiKey,
          panelId: panelId,
          ticketIds: selectedTickets,
        });
        setFetchTickets(!fetchTickets);
        Notify("success", "Deleted Successfully");
      } else if (bulkAction === "markAsRead") {
        await axios.post(`${siteUrl}/support/mark-tickets-read`, {
          key: currentAdmin.apiKey,
          panelId: panelId,
          ticketIds: selectedTickets,
        });
        setFetchTickets(!fetchTickets);
        Notify("success", "Updated Successfully");
      } else if (bulkAction === "markAsSolved") {
        await axios.post(`${siteUrl}/support/mark-tickets-solved`, {
          key: currentAdmin.apiKey,
          panelId: panelId,
          ticketIds: selectedTickets,
        });
        setFetchTickets(!fetchTickets);
        Notify("success", "Updated Successfully");
      }
      setSelectedTickets([]);
    } catch (error) {
      console.error("Error handling bulk action:", error);
      Notify("error", error.message);
    }
  };

  const handleSelectTicket = (ticketId) => {
    setSelectedTickets((prevSelected) => {
      if (prevSelected.includes(ticketId)) {
        return prevSelected.filter((id) => id !== ticketId);
      } else {
        return [...prevSelected, ticketId];
      }
    });
  };

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      borderColor: "var(--adbasebgcolor)",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--adbaseactcolor)"
          : "var(--adbasebgcolor)",
        color: "white",
      };
    },
  };

  const itemsPerPage = 25;
  const pageCount = Math.ceil(tickets.length / itemsPerPage);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = (currentPage + 1) * itemsPerPage;
  const slicedTickets = tickets.slice(startIndex, endIndex);

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(selected);
    navigate(`/control-panel/support/?page=${newPage}`);
  };

  const handleSelectAll = () => {
    const allTicketsIds = tickets.map((ticket) => ticket.id);
    setSelectedTickets((prevSelected) =>
      prevSelected.length === tickets.length ? [] : allTicketsIds
    );
  };

  const handleCancelSearchTicket = () => {
    setSearchActive(false);
    setSearchQuery("");
    searchUrlParams.delete("search");
    const newUrl = `${window.location.pathname}`;
    navigate(newUrl);
    setTickets(mainTickets);
  };

  const handleSearchToggle = () => {
    setSearchActive(!searchActive);
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    const parsedValue =
      value.trim() === ""
        ? ""
        : isNaN(value)
        ? String(value)
        : parseInt(value, 10);
    if (parsedValue === "") {
      setTickets(mainTickets);
      searchUrlParams.delete("search");
    } else {
      searchUrlParams.set("search", parsedValue);
    }
    const newSearchParamsString = searchUrlParams.toString();
    const newUrl = `${window.location.pathname}${
      newSearchParamsString ? `?${newSearchParamsString}` : ""
    }`;
    navigate(newUrl);
    setSearchQuery(parsedValue);
  };

  return (
    <React.Fragment>
      <AdminHeader />
      <AdminSessionChecker />
      <div className="adasadmin-support" style={myAdminStyles}>
        <div className="adasupportmaincon">
          {fetchingTickets ? (
            <FetchingData />
          ) : (
            <div className="adassupport-container">
              <div className="adassupport-toolbar" ref={bodyRef}>
                <Select
                  styles={selectStyles}
                  options={[
                    { value: "delete", label: "Delete" },
                    { value: "markAsRead", label: "Mark as Read" },
                    { value: "markAsSolved", label: "Mark as Solved" },
                  ]}
                  onChange={(selectedOption) =>
                    setBulkAction(selectedOption.value)
                  }
                  placeholder="Bulk Actions"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      primary: "var(--adbasebgcolor)",
                    },
                  })}
                />
                <div className="adassupportsearch">
                  {searchActive ? (
                    <div className="searchorderDiv" ref={containerRef}>
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        placeholder="Search tickets..."
                        autoComplete="off"
                        className="adassupport-search-input"
                      />
                      <MdCancel
                        className="adorcancelsearch"
                        onClick={handleCancelSearchTicket}
                      />
                    </div>
                  ) : (
                    <FaSearch className="icon" onClick={handleSearchToggle} />
                  )}
                </div>
                <button
                  onClick={handleBulkAction}
                  className="adasbulk-action-button"
                >
                  Apply
                </button>
              </div>

              <div className="adassupport-tickets" ref={tableRef}>
                <table className="adsupporttables">
                  <thead>
                    <tr>
                      <th>
                        <CheckBox
                          onChange={() => handleSelectAll()}
                          checked={selectedTickets.length === tickets.length}
                        />
                      </th>
                      <th>Ticket ID</th>
                      <th>Subject</th>
                      <th>Status</th>
                      <th>User</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {slicedTickets.map((ticket) => (
                      <tr key={ticket.id}>
                        <td>
                          <CheckBox
                            onChange={() => handleSelectTicket(ticket.id)}
                            checked={selectedTickets.includes(ticket.id)}
                          />
                        </td>
                        <td>
                          <strong
                            style={
                              !ticket.isReadByAdmin
                                ? {
                                    color: "var(--adbasehvcolor)",
                                  }
                                : {}
                            }
                          >
                            {ticket.id}
                          </strong>
                        </td>
                        <td>
                          <strong
                            style={
                              !ticket.isReadByAdmin
                                ? {
                                    color: "var(--adbasehvcolor)",
                                  }
                                : {}
                            }
                          >
                            {ticket.subject}
                          </strong>
                        </td>
                        <td>
                          <strong>{ticket.status}</strong>
                        </td>
                        <td>
                          <Link
                            to={`/control-panel/users/all?search=${ticket.username}`}
                            className="adordersusername"
                          >
                            <strong>{ticket.username}</strong>
                          </Link>
                        </td>
                        <td>
                          <Link to={`/control-panel/viewchat/${ticket.id}`}>
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageClick}
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminSupport;
