import TopNav from "../components/TopNav";
import React, { useContext, useEffect, useState } from "react";
import { getData } from "../../utils/indexedDB";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import { convertCurrency } from "../shared/ConvertCurrency";
import Loading from "../shared/Loading";
import "../styles/affiliate.css";
import { AppContext } from "../../context/AppContext";
import Integrations from "../shared/Integrations";
import Pagination from "../components/Pagination";
import LogNavLinks from "../shared/LogNavLinks";
import LogHeader from "../shared/LogHeader";
import { FaCopy, FaUsers, FaDollarSign } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PageNotFound from "./PageNotFound";
import ValidTag from "../../utils/ValidTag";

function Affiliate() {
  const {
    myStyles,
    siteTitle,
    darkMode,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    currentUser,
    ratesData,
    siteUrl,
    selectedRate,
    panelId,
    siteData,
    domain,
  } = useContext(AppContext);

  const [affiliatePercentage, setAffiliatePercentage] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isPageEnabled, setIsPageEnabled] = useState(true);
  const [referralsOrders, setReferralsOrders] = useState([]);
  const navigate = useNavigate();
  const itemsPerPage = 25;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = (currentPage + 1) * itemsPerPage;
  const paginatedOrders = referralsOrders.slice(startIndex, endIndex);
  const pageCount = Math.ceil(paginatedOrders.length / itemsPerPage);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      Notify("success", "Successfully copied affiliate link!");
    } catch (err) {
      Notify("error", "Failed to copy affiliate link: " + err.message);
    }
  };

  useEffect(() => {
    document.title = `Refer And Earn | ${siteTitle}`;
  }, [siteTitle]);

  useEffect(() => {
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (!currentUser) {
        navigate("/");
      }
    };
    onAuth();
  }, [navigate]);

  useEffect(() => {
    const getAffiliateData = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/pages/affiliate/get`, {
            panelId: panelId,
            key: currentUser.apiKey,
          });
          const doc = response.data;
          setAffiliatePercentage(doc.percent);
          setIsPageEnabled(doc.enabled);
        } catch (error) {}
      }
    };
    getAffiliateData();
  }, [panelId, siteUrl, currentUser]);

  useEffect(() => {
    const getReferralsOrders = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/pages/referrals`, {
            key: currentUser.apiKey,
            panelId: panelId,
            userId: currentUser.id,
          });
          const data = response.data;
          setReferralsOrders(data.orders);
          setTotalEarnings(data.totalEarnings);
          setTotalReferrals(data.totalReferrals);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getReferralsOrders();
  }, [panelId, siteUrl, currentUser]);

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(selected);
    navigate(`/affiliate?page=${newPage}`);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(pageFromUrl - 1);
  }, []);

  if (!isPageEnabled) {
    return <PageNotFound />;
  }

  const affiliateLink = `https://${domain}/signup?ref=${currentUser?.id}`;

  if (!currentUser || !siteData) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Integrations />
      <LogHeader />
      <LogNavLinks />
      <div
        className="claffiliate"
        style={myStyles}
        data-theme={darkMode ? "dark" : "light"}
      >
        <TopNav />
        <div className="claffiliatemaincon">
          <div className="claffiliatecon">
            <div className="affiliate-top-container">
              <h2>Join Our Affiliate Program</h2>
              <p className="affiliate-description">
                Earn{" "}
                <span className="affiliate-percentage">
                  {affiliatePercentage}%
                </span>{" "}
                commission for every order placed by your referrals.
              </p>
              <div className="affiliate-link-container">
                <input
                  type="text"
                  value={affiliateLink}
                  readOnly
                  className="affiliate-link"
                />
                <button
                  className="claffcopy-button"
                  onClick={() => copyToClipboard(affiliateLink)}
                >
                  <FaCopy className="claffcopy-icon" /> Copy Link
                </button>
              </div>
            </div>
            <div className="affiliate-stats-container">
              <div className="affiliate-stats">
                <FaUsers className="affiliate-icon" />
                <h3>Total Referrals</h3>
                <p>{totalReferrals}</p>
              </div>
              <div className="affiliate-stats">
                <FaDollarSign className="affiliate-icon" />
                <h3>Total Earnings</h3>
                <p>
                  {getCurrencySymbol(selectedRate?.label)}
                  {convertCurrency(
                    totalEarnings,
                    "USD",
                    selectedRate?.label,
                    ratesData
                  )}
                </p>
              </div>
            </div>
            <div className="affiliate-table-container">
              <h3>Your Referrals</h3>
              <table className="affiliate-table">
                <thead>
                  <tr>
                    <th>Referral Name</th>
                    <th>Order Amount</th>
                    <th>Commission Earned</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedOrders.map((ref, index) => (
                    <tr key={index}>
                      <td>{ref.username}</td>
                      <td>
                        {getCurrencySymbol(selectedRate?.label)}
                        {convertCurrency(
                          ref.price,
                          "USD",
                          selectedRate?.label,
                          ratesData
                        )}
                      </td>
                      <td>
                        {getCurrencySymbol(selectedRate?.label)}
                        {convertCurrency(
                          ref.earned,
                          "USD",
                          selectedRate?.label,
                          ratesData
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              pageCount={pageCount}
              handlePageClick={handlePageClick}
            />
          </div>
          <ValidTag/>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Affiliate;
