import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getData } from "../../utils/indexedDB";

function SessionChecker() {
  const navigate = useNavigate();
  useEffect(() => {
    const onAuth = async () => {
      const currentAdmin = await getData("admin_auth");
      if (!currentAdmin) {
        navigate("/control-panel/login");
      }
    };
    onAuth();
  }, [navigate]);

  useEffect(() => {
    const bodyStyles = {
      backgroundColor: "var(--addarkbgcolor)",
    };
    Object.assign(document.body.style, bodyStyles);

    return () => {
      Object.assign(document.body.style, {});
    };
  }, []);
}

export default SessionChecker;
