import "../styles/pagination.css";
import ReactPaginate from "react-paginate";

const Pagination = ({ pageCount, handlePageClick, basePath }) => {

    // Check if there is only one page or less
  const shouldHidePagination = pageCount <= 1;

  // Render null if pagination should be hidden
  if (shouldHidePagination) {
    return null;
  }
  
  return (
    <ReactPaginate
      previousLabel={"←"}
      nextLabel={"→"}
      breakLabel={"..."}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName={"adpagination"}
      subContainerClassName={"pages adpagination"}
      pageClassName="ad-page-item"
      previousClassName="ad-prv-item"
      nextClassName="ad-next-item"
      previousLinkClassName="prv-link"
      pageLinkClassName="adprv-link"
      nextLinkClassName="adnext-link"
      activeClassName={"acvad"}
      initialPage={0}
      disabledClassName="addisabled-pag"
      disableInitialCallback={true}
      pageLinkBuilder={(data) => `${basePath}?page=${data.selected + 1}`}
    />
  );
};

export default Pagination;
