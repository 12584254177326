import { useState, useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext.js";
import { FaEye } from "react-icons/fa";
import Switch from "../shared/Switch";
import axios from "axios";

function EditCategory({
  selectedCategory,
  fetchCategories,
  setFetchCategories,
  setSelectedCategory,
  actionsRef,
}) {
  const [categoryName, setCategoryName] = useState("");
  const [initialName, setInitialName] = useState("");
  const [categoryUid, setCategoryUid] = useState("");
  const [categoryStatus, setCategoryStatus] = useState(null);
  const [updatingCategory, setUpdatingCategory] = useState("");
  const {
    panelId,
    setNotifyType,
    currentAdmin,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    siteUrl,
  } = useContext(AppContext);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const hideEditService = (event) => {
    if (event.target.classList.contains("edseroverlay")) {
      setSelectedCategory(null);
    }
  };

  const categoryNameChange = (e) => {
    const value = e.target.value;
    setCategoryName(value);
  };

  useEffect(() => {
    const fetchCategory = async () => {
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "categories",
        panelId: panelId,
      });
      const categoryData = response.data.find(
        (category) => category.id === selectedCategory.id
      );
      setInitialName(categoryData.name);
      setCategoryName(categoryData.name);
      setCategoryUid(categoryData.uid);
      setCategoryStatus(categoryData.status === "active");
    };

    fetchCategory();
  }, [selectedCategory, panelId, siteUrl]);

  const updateCategory = async () => {
    setUpdatingCategory(true);
    try {
      const url = `${siteUrl}/service/category/edit`;
      const data = {
        panelId: panelId,
        key: currentAdmin.apiKey,
        initialName: initialName,
        categoryUid: categoryUid,
        categoryName: categoryName,
        categoryStatus: categoryStatus,
      };
      await axios.post(url, data);
      setUpdatingCategory(false);
      setSelectedCategory(null);
      setFetchCategories(!fetchCategories);
      Notify("success", "Updated Successfully");
    } catch (error) {
      setUpdatingCategory(false);
      setSelectedCategory(null);
      Notify("error", "Unable to update category");
    }
  };

  const handleStatusUpdate = (newValue) => {
    setCategoryStatus(newValue);
  };

  const toggleStatus = async () => {
    const newStatus = !categoryStatus;
    setCategoryStatus(newStatus);
    handleStatusUpdate(newStatus);
  };

  return (
    <div
      className="edseroverlay"
      onClick={(event) => hideEditService(event)}
      ref={actionsRef}
    >
      <div className="editservcon">
        <div className="mb-3">
          <strong className="edserheadtext">
            Editing Category {selectedCategory.id}
          </strong>
        </div>
        <div className="adeditservicecon">
          <label htmlFor="editservnname" className="fw-semibold">
            Category Name
          </label>
          <input
            type="text"
            name="editservnname"
            id="editprovselect"
            onChange={categoryNameChange}
            value={categoryName}
          />
        </div>
        <div className="adedpaymethstatus">
          <span>
            <FaEye className="adrefillicon" />
            Status
          </span>
          <Switch syncValue={categoryStatus} onToggle={toggleStatus} />
        </div>
        <button className="adeditsavebtn" onClick={updateCategory}>
          {updatingCategory ? (
            <div className="adeditservspinner"></div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
}

export default EditCategory;
