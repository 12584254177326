import { Link } from "react-router-dom";
import {
  FaHome,
  FaCartPlus,
  FaShoppingCart,
  FaBlog,
  FaQuestion,
  FaUserCircle,
  FaRecycle,
} from "react-icons/fa";
import { MdMiscellaneousServices } from "react-icons/md";
import { FaUserPlus, FaGear } from "react-icons/fa6";
import { RiCustomerService2Line } from "react-icons/ri";
import { MdOutlinePayment } from "react-icons/md";
import LogNavLinks from "./LogNavLinks";
import "../styles/menu.css";
import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { GrFormNext } from "react-icons/gr";
import TopNav from "../components/TopNav";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Loading from "./Loading";
import axios from "axios";
import { deleteData, getData } from "../../utils/indexedDB";

function Menu() {
  const {
    myStyles,
    panelId,
    currentUser,
    darkMode,
    siteData,
    siteUrl,
    setCurrentUser,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [enabledPages, setEnabledPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ticketsLength, setTicketLength] = useState(0);

  useEffect(() => {
    if (currentUser) {
      const getTicketLength = async () => {
        const response = await axios.post(
          `${siteUrl}/support/get-unread-tickets-length`,
          {
            panelId: panelId,
            key: currentUser.apiKey,
          }
        );
        const length = response.data.length;
        setTicketLength(length);
      };
      getTicketLength();
    }
  }, [panelId, currentUser, siteUrl]);

  useEffect(() => {
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (!currentUser) {
        navigate("/");
      }
    };
    onAuth();
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 800) {
        navigate("/");
      }
    };

    // Attach the event listener for window resize
    window.addEventListener("resize", handleResize);

    // Check the initial screen width
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navigate]);

  useEffect(() => {
    if (currentUser) {
      const getEnabledPages = async () => {
        const response = await axios.post(`${siteUrl}/pages/enabled`, {
          panelId: panelId,
          key: currentUser.apiKey,
        });
        const data = response.data.enabled;
        setEnabledPages(data);
        setLoading(false);
      };
      getEnabledPages();
    }
  }, [panelId, currentUser, siteUrl]);

  const logout = async () => {
    try {
      await axios.post(`${siteUrl}/crud/update/doc`, {
        data: { status: "offline", lastSeen: new Date() },
        panelId: panelId,
        collection: "users",
        uid: currentUser.uid,
      });
      await deleteData("user_auth");
      setCurrentUser(null);
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  if (loading || siteData === null) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <LogNavLinks />
      <div
        className={darkMode ? "dark-menustcon" : "menustcon"}
        style={myStyles}
      >
        <TopNav />
        <div className={darkMode ? "dark-menu-links" : "menu-links"}>
          <ul>
            <li className="mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <FaHome className="menu-icon" />
                <Link className="" to="/dashboard">
                  Home
                </Link>
                <GrFormNext className="next" />
              </div>
            </li>
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <FaCartPlus className="menu-icon" />
                <Link to="/order">New order</Link>
                <GrFormNext className="next" />
              </div>
            </li>
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <FaShoppingCart className="menu-icon" />
                <Link className="" to="/orders/all">
                  Orders
                </Link>
                <GrFormNext className="next" />
              </div>
            </li>
            {enabledPages.includes("refills") && (
              <li>
                <div className="d-flex justify-content-between align-items-center">
                  <FaRecycle className="menu-icon" />
                  <Link className="" to="/refills">
                    Refills
                  </Link>
                  <GrFormNext className="next" />
                </div>
              </li>
            )}
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <FaGear className="menu-icon" />
                <Link className="" to="/services">
                  Services
                </Link>
                <GrFormNext className="next" />
              </div>
            </li>
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <MdOutlinePayment className="menu-icon" />
                <Link className="" to="/pay">
                  Add Funds
                </Link>
                <GrFormNext className="next" />
              </div>
            </li>
            {enabledPages.includes("affiliate") && (
              <li>
                <div className="d-flex justify-content-between align-items-center">
                  <FaUserPlus className="menu-icon" />
                  <Link className="" to="/affiliate">
                    Refer & Earn
                  </Link>
                  <GrFormNext className="next" />
                </div>
              </li>
            )}
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <FaBlog className="menu-icon" />
                <Link to="/blogs">Blogs</Link>
                <GrFormNext className="next" />
              </div>
            </li>
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <FaQuestion className="menu-icon" />
                <Link to="/faqs">FAQS</Link>
                <GrFormNext className="next" />
              </div>
            </li>
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <RiCustomerService2Line className="menu-icon" />
                <Link className="" to="/support">
                  Support
                </Link>
                <GrFormNext className="next" />
                {ticketsLength !== 0 && (
                  <div className="clnavuntick">{ticketsLength}</div>
                )}
              </div>
            </li>
            <li>
              <div className="d-flex justify-content-between align-items-center">
                <MdMiscellaneousServices className="menu-icon" />
                <Link to="/api">API</Link>
                <GrFormNext className="next" />
              </div>
            </li>
            <li className="nomag">
              <div className="d-flex justify-content-between align-items-center">
                <FaUserCircle className="menu-icon" />
                <Link className="" to="/account">
                  Account
                </Link>
                <GrFormNext className="next" />
              </div>
            </li>
          </ul>
          <div className="logout container">
            <button className="btn" onClick={logout}>
              <MdLogout className="next" /> Logout
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Menu;
