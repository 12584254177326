import "../styles/confirmaction.css";

function ConfirmAction({
  hideConfirmAction,
  headText,
  actionText,
  cancelConfirmAction,
  buttonAction,
  buttonName,
  actionRef,
  updatingAction,
}) {
  return (
    <div
      className="caoverlay"
      onClick={(event) => hideConfirmAction(event)}
      ref={actionRef}
    >
      <div className="caduscfn">
        <div className="cadetai">
          <h4 className="cafwhi">{headText}</h4>
        </div>
        <span className="caactext">Are you sure you want to {actionText}?</span>
        <div className="d-flex">
          <button className="accanbut mt-3" onClick={buttonAction}>
            {updatingAction ? (
              <div className="adeditservspinner"></div>
            ) : (
              <>{buttonName}</>
            )}
          </button>
          <button className="cacanbut mt-3" onClick={cancelConfirmAction}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmAction;
