import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import "../styles/themetoogle.css";
import { FaLightbulb } from "react-icons/fa6";
import { MdDarkMode } from "react-icons/md";
function ThemeToggle() {
  const { darkMode, setDarkMode } = useContext(AppContext);

  const handleThemeToogle = () => {
    setDarkMode(!darkMode);
  };
  return (
    <div
      className={darkMode ? "dark_theme_toogle" : "theme_toogle"}
      onClick={handleThemeToogle}
    >
      {darkMode ? (
        <MdDarkMode className="thicon" />
      ) : (
        <FaLightbulb className="thicon" />
      )}
    </div>
  );
}

export default ThemeToggle;
