import "../styles/integration.css";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Loading from "../shared/Loading";
import ControlPanel from "./ControlPanel";
import axios from "axios";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import AdminHeader from "../components/AdminHeader";
import { MdIntegrationInstructions } from "react-icons/md";

function Integration() {
  const {
    myAdminStyles,
    setNotifyType,
    setNotifyMessage,
    panelId,
    siteTitle,
    setNotifyVisibility,
    siteData,
    currentAdmin,
    siteUrl,
    setNotifyDuration,
  } = useContext(AppContext);
  const [updatingIntegration, setUpdatingIntegration] = useState(false);
  const [whatsappNo, setWhatsappNo] = useState("");
  const [whatsappAlign, setWhatsappAlign] = useState("");
  const [instagramUsername, setInstagramUsername] = useState("");
  const [instagramAlign, setInstagramAlign] = useState("");
  const [otherIntegration, setOtherIntegration] = useState("");

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration >= 0) setNotifyDuration(duration);
  };

  useEffect(() => {
    document.title = `Integrations | ${siteTitle}`;
  }, [siteTitle]);

  useEffect(() => {
    const getIntegrationDoc = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "integrations",
            key: currentAdmin.apiKey,
            panelId: panelId,
          });
          const integrationDoc = response.data.find(
            (intg) => intg.uid === "integrations"
          );
          setWhatsappAlign(integrationDoc.whatsapp.align);
          setWhatsappNo(integrationDoc.whatsapp.number);
          setInstagramAlign(integrationDoc.instagram.align);
          setInstagramUsername(integrationDoc.instagram.number);
          setOtherIntegration(integrationDoc.other);
        } catch (error) {}
      }
    };
    getIntegrationDoc();
  }, [currentAdmin, panelId, siteUrl]);

  const saveIntegration = async () => {
    setUpdatingIntegration(true);
    try {
      const data = {
        whatsapp: {
          number: whatsappNo,
          align: whatsappAlign,
        },
        instagram: {
          username: instagramUsername,
          align: instagramAlign,
        },
        other: otherIntegration,
      };
      const response = await axios.post(`${siteUrl}/crud/get/docs`, {
        collection: "integrations",
        key: currentAdmin.apiKey,
        panelId: panelId,
      });
      const designExist = response.data.some(
        (page) => page.uid === "integrations"
      );
      if (designExist) {
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "integrations",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
          uid: "integrations",
        });
      } else {
        data.uid = "integrations";
        await axios.post(`${siteUrl}/crud/add/doc`, {
          collection: "integrations",
          panelId: panelId,
          key: currentAdmin.apiKey,
          data: data,
        });
      }
      setUpdatingIntegration(false);
      Notify("success", "Saved Successfully");
    } catch (error) {
      setUpdatingIntegration(false);
      Notify("error", "Error saving integration");
    }
  };

  if (siteData === null) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="adintegration" style={myAdminStyles}>
        <div className="adintegrationmaincon">
          <ControlPanel />
          <div className="adcpbdycon">
            <div className="adintegrationhead">
              <h6>Integrations</h6>
            </div>
            <div className="adintegrationbody">
              <div className="adintegrationhd">
                <h4>Integrations</h4>
                <button className="adsavegeneral" onClick={saveIntegration}>
                  {updatingIntegration ? (
                    <div className="adeditservspinner"></div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
              <div className="adintgclcon">
                <div className="adintghead">
                  <div className="whatsappcon">
                    <FaWhatsapp className="whatsapp-icon" />
                  </div>
                  <span>Whatsapp</span>
                </div>
                <input
                  type="text"
                  name="siteInpTitle"
                  id="adgensitetitle"
                  value={whatsappNo}
                  onChange={(e) => setWhatsappNo(e.target.value)}
                />
                <div className="adintgclside">
                  <button
                    className={whatsappAlign === "left" ? "adintgacside" : ""}
                    onClick={() => setWhatsappAlign("left")}
                  >
                    Left
                  </button>
                  <button
                    className={whatsappAlign === "right" ? "adintgacside" : ""}
                    onClick={() => setWhatsappAlign("right")}
                  >
                    Right
                  </button>
                </div>
              </div>
              <div className="adintgclcon">
                <div className="adintghead">
                  <div className="instagramcon">
                    <FaInstagram className="instagram-icon" />
                  </div>
                  <span>Instagram</span>
                </div>
                <input
                  type="text"
                  name="siteInpTitle"
                  id="adgensitetitle"
                  value={instagramUsername}
                  onChange={(e) => setInstagramUsername(e.target.value)}
                />
                <div className="adintgclside">
                  <button
                    className={instagramAlign === "left" ? "adintgacside" : ""}
                    onClick={() => setInstagramAlign("left")}
                  >
                    Left
                  </button>
                  <button
                    className={instagramAlign === "right" ? "adintgacside" : ""}
                    onClick={() => setInstagramAlign("right")}
                  >
                    Right
                  </button>
                </div>
              </div>
              <div className="adintgclcon">
                <div className="adintghead">
                  <div className="otherintgcon">
                    <MdIntegrationInstructions className="otherintg" />
                  </div>
                  <span>Other</span>
                </div>
                <textarea
                  id="adgensitetitle"
                  value={otherIntegration}
                  onChange={(e) => setOtherIntegration(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Integration;
