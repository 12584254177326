import validpanel from "../assets/images/validpanel.jpg";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";

function ValidTag() {
  const { siteData } = useContext(AppContext);
  return (
    siteData.showBanner && (
      <Link
        className="validpaneltag"
        to="https://validpanel.com"
        style={{
          textDecoration: "none",
          marginTop: "20px",
          marginBottom: "50px",
          marginRight: "20px",
          marginLeft: "auto",
          padding: "10px 20px",
          borderRadius: "20px",
          background: "linear-gradient(135deg, #3a3d5e, #4a4d6b)",
          color: "#f8f8f8",
          border: "2px solid rgba(255, 255, 255, 0.2)",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          width: "max-content",
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-end",
          transition: "transform 0.3s ease",
        }}
        onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
        onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
      >
        <img
          src={validpanel}
          alt="Valid Panel"
          style={{
            borderRadius: "12px",
            width: "40px",
            marginRight: "10px",
          }}
        />
        <strong style={{ whiteSpace: "nowrap" }}>Made In Valid Panel</strong>
      </Link>
    )
  );
}

export default ValidTag;
