import "../styles/paymentmethods.css";
import { AppContext } from "../../context/AppContext";
import React, { useContext, useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import Select from "react-select";
import AdminHeader from "../components/AdminHeader";
import { FaEye } from "react-icons/fa";
import Switch from "../shared/Switch";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import axios from "axios";
import EditMethod from "../components/EditMethod";
import { convertCurrency } from "../shared/ConvertCurrency";
import FetchingData from "../shared/FetchingData";
import ControlPanel from "./ControlPanel";
import Loading from "../shared/Loading";
import NotFound from "../shared/NotFound";

function PaymentMethods() {
  const {
    siteTitle,
    setLoading,
    loading,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    selectedAdminRate,
    ratesData,
    myAdminStyles,
    siteUrl,
    currentAdmin,
    panelId,
    siteData,
  } = useContext(AppContext);
  const [methodName, setMethodName] = useState("");
  const [methodPD, setMethodPD] = useState("");
  const [methodKey, setMethodKey] = useState("");
  const [methodMin, setMethodMin] = useState(1);
  const [methodMax, setMethodMax] = useState(1);
  const [methodDescription, setMethodDescription] = useState("");
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [showAddMethod, setShowAddMethod] = useState(false);
  const [methodStatus, setMethodStatus] = useState(false);
  const [addingMethod, setAddingMethod] = useState(false);
  const [fetchPaym, setFetchPaym] = useState(false);
  const [fetchingGateways, setFetchingGateways] = useState(true);
  const [gateways, setGateways] = useState([]);
  const [selectedGateway, setSelectedGateway] = useState(null);

  useEffect(() => {
    setLoading(false);
    document.title = `Payment Methods | ${siteTitle}`;
  }, [siteTitle, setLoading]);

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (styles) => ({
      ...styles,
      borderRadius: "10px",
      borderColor: "var(--adbasebgcolor)",
      color: "white",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--adbaseactcolor)"
          : "var(--adbasebgcolor)",
        fontSize: "var(--selectfs)",
        color: "white",
      };
    },
  };

  useEffect(() => {
    const fetchPaymentGateways = async () => {
      if (currentAdmin) {
        try {
          const url = `${siteUrl}/payment/gateways/get/all`;
          const data = {
            adminKey: currentAdmin.apiKey,
            panelId: panelId,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setGateways(response.data);
          setFetchingGateways(false);
        } catch (error) {
          setFetchingGateways(false);
        }
      }
    };
    fetchPaymentGateways();
  }, [siteUrl, currentAdmin, panelId, fetchPaym]);

  if (loading || siteData === null) {
    return <Loading />;
  }
  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };
  const handleEditMethod = (method) => {
    setSelectedMethod(method);
  };

  const handleShowAddMethod = () => {
    setShowAddMethod(true);
  };

  const handleChange = (selectedOption) => {
    setSelectedGateway(selectedOption);
    setMethodName("");
    setMethodDescription("");
    setMethodPD("");
    setMethodKey("");
    setMethodMax(1);
    setMethodMin(1);
    setMethodStatus(false);
  };

  const hideEditProvider = (event) => {
    // Check if the click occurred outside the content
    if (event.target.classList.contains("edseroverlay")) {
      setShowAddMethod(false);
    }
  };

  const availableGateways = [
    { label: "Manual", value: "manual" },
    { label: "Flutterwave", value: "flutterwave" },
    { label: "Paystack", value: "paystack" },
  ];

  const handleMethodStatusUpdate = (newValue) => {
    setMethodStatus(newValue);
  };

  const toogleStatus = async () => {
    const newValue = !methodStatus;
    setMethodStatus(newValue);
    handleMethodStatusUpdate(newValue);
  };

  const handleQuantity = (setQuantity, e) => {
    const value = e.target.value;
    setQuantity(value.trim() === "" ? 1 : parseInt(value));
  };

  const addGateway = async () => {
    setAddingMethod(true);
    if (methodName === "") {
      Notify("error", "Kindly enter a name");
      return;
    }
    if (selectedGateway.value !== "manual" && methodKey === "") {
      Notify("error", "Kindly enter a public or test key");
      return;
    }
    try {
      const convertedMin = convertCurrency(
        methodMin,
        selectedAdminRate.label.substring(0, 3),
        "USD",
        ratesData
      );
      const convertedMax = convertCurrency(
        methodMax,
        selectedAdminRate.label.substring(0, 3),
        "USD",
        ratesData
      );
      const usdMin = convertedMin.replace(/,/g, "");
      const usdMax = convertedMax.replace(/,/g, "");
      const url = `${siteUrl}/payment/gateways/create`;
      const data = {
        panelId: panelId,
        platform: selectedGateway.value,
        min: parseFloat(usdMin),
        max: parseFloat(usdMax),
        public_key: methodKey,
        adminKey: currentAdmin.apiKey,
        description: methodDescription,
        name: methodName,
        status: methodStatus ? "active" : "disabled",
        paymentDescription: methodPD,
      };
      await axios.post(url, data);
      setAddingMethod(false);
      setShowAddMethod(false);
      Notify("success", "Added Successfully");
      setFetchPaym((prev) => !prev);
    } catch (error) {
      Notify("error", error.message);
      setAddingMethod(false);
      setShowAddMethod(false);
    }
  };

  const deleteGateway = async (gateway) => {
    setAddingMethod(true);
    try {
      const url = `${siteUrl}/payment/gateways/delete`;
      const data = {
        adminKey: currentAdmin.apiKey,
        panelId: panelId,
        uid: gateway.uid,
      };
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      Notify("success", "Deleted Successfully");
      setFetchPaym((prev) => !prev);
    } catch (error) {
      Notify("error", error.message);
    }
  };

  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="adpaymeths" style={myAdminStyles}>
        <div className="adpaymethmaincon">
          <ControlPanel />
          <div className="adcpbdycon">
            <div className="adpaymethshead">
              <span>Payment Methods</span>
              <button className="adaddprovmet" onClick={handleShowAddMethod}>
                Add Method
              </button>
            </div>
            {fetchingGateways ? (
              <FetchingData />
            ) : (
              <React.Fragment>
                {gateways.length === 0 ? (
                  <NotFound value="No gateway found" />
                ) : (
                  <div className="adpaymethsbody">
                    <div className="adpaymethstable">
                      <table>
                        <tbody>
                          {gateways.map((method) => (
                            <React.Fragment key={method.uid}>
                              <tr className="adoaymethstbtr">
                                <td>
                                  <div className="adoaymethstbtd">
                                    <span>{method.name}</span>
                                  </div>
                                </td>
                                <td className="adprovtbtdminwdtg">
                                  <div className="adoaymethstbtd">
                                    <button
                                      className="adusersactions"
                                      onClick={() => handleEditMethod(method)}
                                    >
                                      <MdEdit className="adpaymentacticon" />
                                      Edit Method
                                    </button>
                                  </div>
                                </td>
                                <td className="adprovtbtdminwdtg">
                                  <div className="adoaymethstbtd">
                                    <button
                                      className="addeleteProv"
                                      onClick={() => deleteGateway(method)}
                                    >
                                      <MdDelete className="adpaymentacticon" />
                                      Delete Method
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {selectedMethod !== null ? (
              <EditMethod
                selectedMethod={selectedMethod}
                setSelectedMethod={setSelectedMethod}
                siteUrl={siteUrl}
                adminApiKey={currentAdmin.apiKey}
              />
            ) : (
              ""
            )}
            {showAddMethod ? (
              <div
                className="edseroverlay"
                onClick={(event) => hideEditProvider(event)}
              >
                <div className="editservcon">
                  <div className="adedmethodhead">
                    <h6>Add Method</h6>
                  </div>
                  <div className="adselpaymmetod">
                    <h6>Select Method</h6>
                    <Select
                      className="gatewaysOptions"
                      menuPortalTarget={document.body}
                      options={availableGateways}
                      value={selectedGateway}
                      onChange={handleChange}
                      styles={selectStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          primary: "var(--adbasebgcolor)",
                        },
                      })}
                    />
                  </div>
                  {selectedGateway ? (
                    <div className="adpaymmethfill">
                      <label htmlFor="editservnname" className="fw-semibold">
                        Name
                      </label>
                      <input
                        type="text"
                        name="editservnname"
                        placeholder="Name"
                        id="editprovselect"
                        onChange={(e) => setMethodName(e.target.value)}
                        value={methodName}
                      />
                      <label
                        htmlFor="editservicedescription"
                        className="fw-semibold mt-3"
                      >
                        Description
                      </label>
                      <textarea
                        name="editservicedescription"
                        id="editservicedescription"
                        placeholder="Your Description"
                        value={methodDescription.replace(/&&n/g, "\n")}
                        onChange={(e) =>
                          setMethodDescription(
                            e.target.value.replace(/\n/g, "&&n")
                          )
                        }
                      ></textarea>
                      {selectedGateway.value === "paystack" ||
                      selectedGateway.value === "flutterwave" ? (
                        <div className="adeditminmaxpaymth">
                          <label
                            htmlFor="editservnname"
                            className="fw-semibold"
                          >
                            Public Key
                          </label>
                          <input
                            type="text"
                            name="editservnname"
                            placeholder="Public or test key"
                            id="editprovselect"
                            onChange={(e) => setMethodKey(e.target.value)}
                            value={methodKey}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="adeditminmaxpaymth">
                        <div className="d-flex">
                          <div>
                            <label
                              htmlFor="editprovselect"
                              className="fw-semibold"
                            >
                              Minimal Payment
                            </label>
                            <input
                              type="text"
                              name="editprovselect"
                              id="editprovselect"
                              value={methodMin}
                              onChange={(e) => handleQuantity(setMethodMin, e)}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="editservprov"
                              className="fw-semibold"
                            >
                              Maximum Payment
                            </label>
                            <input
                              type="text"
                              id="editservprov"
                              name="editservprov"
                              value={methodMax}
                              onChange={(e) => handleQuantity(setMethodMax, e)}
                            />
                          </div>
                        </div>
                      </div>
                      {selectedGateway.value === "flutterwave" ? (
                        <div className="adeditminmaxpaymth">
                          <label
                            htmlFor="editservnname"
                            className="fw-semibold"
                          >
                            Payment Description
                          </label>
                          <textarea
                            name="editservicedescription"
                            id="editservicedescription"
                            placeholder="This is the description that will show on flutterwave when a user is making payment. It's Optional!"
                            value={methodPD.replace(/&&n/g, "\n")}
                            onChange={(e) =>
                              setMethodPD(e.target.value.replace(/\n/g, "&&n"))
                            }
                          ></textarea>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="adedpaymethstatus">
                        <span>
                          <FaEye className="adrefillicon" />
                          Status
                        </span>
                        <Switch
                          syncValue={methodStatus}
                          onToggle={toogleStatus}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {selectedGateway && (
                    <div className="adeditminmaxpaymth">
                      <button className="adeditsavebtn" onClick={addGateway}>
                        {addingMethod ? (
                          <div className="adeditservspinner"></div>
                        ) : (
                          "Add Method"
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PaymentMethods;
