export function getCurrencyName(currencyCode) {
  if (currencyCode && currencyCode !== "") {
    const currencyName = {
      AED: "United Arab Emirates Dirham", // د.إ
      AFN: "Afghan Afghani", // ؋
      ALL: "Albanian Lek", // L
      AMD: "Armenian Dram", // ֏
      ANG: "Netherlands Antillean Guilder", // ƒ
      AOA: "Angolan Kwanza", // Kz
      ARS: "Argentine Peso", // $
      AUD: "Australian Dollar", // $
      AWG: "Aruban Florin", // ƒ
      AZN: "Azerbaijani Manat", // ₼
      BAM: "Bosnia-Herzegovina Convertible Mark", // КМ
      BBD: "Barbadian Dollar", // $
      BDT: "Bangladeshi Taka", // ৳
      BGN: "Bulgarian Lev", // лв
      BHD: "Bahraini Dinar", // .د.ب
      BIF: "Burundian Franc", // FBu
      BMD: "Bermudian Dollar", // $
      BND: "Brunei Dollar", // $
      BOB: "Bolivian Boliviano", // Bs.
      BRL: "Brazilian Real", // R$
      BSD: "Bahamian Dollar", // $
      BTN: "Bhutanese Ngultrum", // Nu.
      BTC: "Bitcoin", // Nu.
      BWP: "Botswana Pula", // P
      BYN: "Belarusian Ruble", // Br
      CUC: "Cuban Convertible Peso",
      CNH: "Chinese Yuan (Offshore)",
      CLF: "Chilean Unit of Account (UF)",
      KPW: "North Korean Won",
      LTL: "Lithuanian Litas",
      LVL: "Latvian Lats",
      STD: "São Tomé and Príncipe Dobra",
      SLE: "Sierra Leonean Leone",
      SVC: "Salvadoran Colón",
      BYR: "Belarusian Ruble", // Br
      BZD: "Belize Dollar", // $
      CAD: "Canadian Dollar", // $
      CDF: "Congolese Franc", // FC
      CHF: "Swiss Franc", // CHF
      CLP: "Chilean Peso", // $
      CNY: "Chinese Yuan", // ¥
      COP: "Colombian Peso", // $
      CRC: "Costa Rican Colón", // ₡
      CUP: "Cuban Peso", // ₱
      CVE: "Cape Verdean Escudo", // $
      CZK: "Czech Koruna", // Kč
      DJF: "Djiboutian Franc", // Fdj
      DKK: "Danish Krone", // kr
      DOP: "Dominican Peso", // RD$
      DZD: "Algerian Dinar", // دج
      EGP: "Egyptian Pound", // E£
      ERN: "Eritrean Nakfa", // Nfk
      ETB: "Ethiopian Birr", // Br
      EUR: "Euro", // €
      FJD: "Fijian Dollar", // $
      FKP: "Falkland Islands Pound", // £
      FOK: "Falkland Islands Pound", // HK$
      GBP: "British Pound Sterling", // £
      GEL: "Georgian Lari", // ₾
      GGP: "Guernsey Pound", // GGP
      GHS: "Ghanaian Cedi", // GH₵
      GIP: "Gibraltar Pound", // £
      GMD: "Gambian Dalasi", // D
      GNF: "Guinean Franc", // FG
      GTQ: "Guatemalan Quetzal", // Q
      GYD: "Guyanaese Dollar", // $
      HKD: "Hong Kong Dollar", // HK$
      HNL: "Honduran Lempira", // L
      HRK: "Croatian Kuna", // kn
      HTG: "Haitian Gourde", // G
      HUF: "Hungarian Forint", // Ft
      IDR: "Indonesian Rupiah", // Rp
      ILS: "Israeli New Shekel", // ₪
      IMP: "Isle of Man Pound", // IMP
      INR: "Indian Rupee", // ₹
      IQD: "Iraqi Dinar", // ع.د
      IRR: "Iranian Rial", // ﷼
      ISK: "Icelandic Króna", // Íkr
      JEP: "Jersey Pound", // JEP
      JMD: "Jamaican Dollar", // J$
      JOD: "Jordanian Dinar", // JD
      JPY: "Japanese Yen", // ¥
      KES: "Kenyan Shilling", // Ksh
      KGS: "Kyrgystani Som", // с
      KHR: "Cambodian Riel", // ៛
      VEF: "Venezuelan Bolívar",
      XAG: "Silver (troy ounce)",
      ZMK: "Zambian Kwacha (pre-2013)",
      KID: "Kiribati Dollar", // $
      KMF: "Comorian Franc", // CF
      KRW: "South Korean Won", // ₩
      KWD: "Kuwaiti Dinar", // KD
      KYD: "Cayman Islands Dollar", // $
      KZT: "Kazakhstani Tenge", // ₸
      LAK: "Laotian Kip", // ₭
      LBP: "Lebanese Pound", // ل.ل
      LKR: "Sri Lankan Rupee", // රු
      LRD: "Liberian Dollar", // $
      LSL: "Lesotho Loti", // L
      LYD: "Libyan Dinar", // LD
      MAD: "Moroccan Dirham", // MAD
      MDL: "Moldovan Leu", // MDL
      MGA: "Malagasy Ariary", // Ar
      MKD: "Macedonian Denar", // MKD
      MMK: "Myanma Kyat", // K
      MNT: "Mongolian Tugrik", // ₮
      MOP: "Macanese Pataca", // MOP$
      MRU: "Mauritanian Ouguiya", // UM
      MUR: "Mauritian Rupee", // ₨
      MVR: "Maldivian Rufiyaa", // MVR
      MWK: "Malawian Kwacha", // MK
      MXN: "Mexican Peso", // $
      MYR: "Malaysian Ringgit", // RM
      MZN: "Mozambican Metical", // MT
      NAD: "Namibian Dollar", // $
      NGN: "Nigerian Naira", // ₦
      NIO: "Nicaraguan Córdoba", // C$
      NOK: "Norwegian Krone", // kr
      NPR: "Nepalese Rupee", // ₨
      NZD: "New Zealand Dollar", // $
      OMR: "Omani Rial", // OMR
      PAB: "Panamanian Balboa", // B/.
      PEN: "Peruvian Nuevo Sol", // S/.
      PGK: "Papua New Guinean Kina", // K
      PHP: "Philippine Peso", // ₱
      PKR: "Pakistani Rupee", // ₨
      PLN: "Polish Zloty", // zł
      PYG: "Paraguayan Guarani", // ₲
      QAR: "Qatari Rial", // QR
      RON: "Romanian Leu", // lei
      RSD: "Serbian Dinar", // din
      RUB: "Russian Ruble", // ₽
      RWF: "Rwandan Franc", // FR
      SAR: "Saudi Riyal", // SR
      SBD: "Solomon Islands Dollar", // $
      SCR: "Seychellois Rupee", // SR
      SDG: "Sudanese Pound", // SDG
      SEK: "Swedish Krona", // kr
      SGD: "Singapore Dollar", // $
      SHP: "Saint Helena Pound", // £
      SLL: "Sierra Leonean Leone", // Le
      SOS: "Somali Shilling", // S
      SRD: "Surinamese Dollar", // $
      SSP: "South Sudanese Pound", // SSP
      STN: "São Tomé and Príncipe Dobra", // Db
      SYP: "Syrian Pound", // ل.س
      SZL: "Swazi Lilangeni", // L
      THB: "Thai Baht", // ฿
      TJS: "Tajikistani Somoni", // ЅМ
      TMT: "Turkmenistani Manat", // T
      TND: "Tunisian Dinar", // د.ت
      TOP: "Tongan Pa'anga", // T$
      TRY: "Turkish Lira", // ₺
      TTD: "Trinidad and Tobago Dollar", // TT$
      TVD: "Tuvaluan Dollar", // $
      TWD: "New Taiwan Dollar", // NT$
      TZS: "Tanzanian Shilling", // TSh
      UAH: "Ukrainian Hryvnia", // ₴
      UGX: "Ugandan Shilling", // USh
      USD: "United States Dollar", // $
      UYU: "Uruguayan Peso", // $
      UZS: "Uzbekistan Som", // лв
      VES: "Venezuelan Bolívar", // Bs.
      VND: "Vietnamese Dong", // ₫
      VUV: "Vanuatu Vatu", // VT
      WST: "Samoan Tala", // WS$
      XAF: "Central African CFA Franc", // FCFA
      XAU: "Gold", // FCFA
      XCD: "East Caribbean Dollar", // $
      XDR: "Special Drawing Rights", // SDR
      XOF: "West African CFA Franc", // CFA
      XPF: "CFP Franc", // ₣
      YER: "Yemeni Rial", // ﷼
      ZAR: "South African Rand", // R
      ZMW: "Zambian Kwacha", // ZK
      ZWL: "Zimbabwean Dollar", // $
    };

    // Return the currency symbol for the given currency code, or an empty string if not found
    return currencyName[currencyCode] || "";
  }
}
