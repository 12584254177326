import { AppContext } from "../../context/AppContext";
import TipTapEditor from "../shared/TipTapEditor";
import "../styles/blogs.css";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

function EditBlog({
  blogId,
  showEditBlog,
  setShowEditBlog,
  setFetchBlogs,
  fetchBlogs,
}) {
  const {
    setNotifyType,
    currentAdmin,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    panelId,
    siteUrl,
  } = useContext(AppContext);

  const [title, setTitle] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [content, setContent] = useState("");
  const [uid, setUid] = useState("");

  const hideEditBlog = (event) => {
    if (event.target.classList.contains("edseroverlay")) {
      setShowEditBlog(null);
    }
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration >= 0) setNotifyDuration(duration);
  };

  useEffect(() => {
    const getBlogData = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            key: currentAdmin.apiKey,
            panelId: panelId,
            collection: "blogs",
            query: {
              find: { field: "id", operator: "===", value: parseInt(blogId) },
            },
          });
          const blog = response.data;
          setTitle(blog.title);
          setExcerpt(blog.excerpt);
          setContent(blog.content);
          setUid(blog.uid);
        } catch (error) {
          console.error("Error fetching blog data:", error);
        }
      }
    };

    if (blogId) {
      getBlogData();
    }
  }, [blogId, currentAdmin, siteUrl, panelId]);

  const handleUpdateBlog = async () => {
    if (title !== "" && excerpt !== "" && content !== "") {
      try {
        await axios.post(`${siteUrl}/crud/update/doc`, {
          key: currentAdmin.apiKey,
          panelId: panelId,
          collection: "blogs",
          uid: uid,
          data: { title, excerpt, content },
        });
        setFetchBlogs(!fetchBlogs);
        setShowEditBlog(false);
        Notify("success", "Blog updated successfully");
      } catch (error) {
        Notify("error", "Failed to update blog");
        console.error("Error updating blog:", error);
      }
    } else {
      Notify("error", "Please fill in all fields");
    }
  };

  return (
    title !== "" && (
      <div className="edseroverlay" onClick={hideEditBlog}>
        <div className="editservcon">
          <h4 className="adblogheadtxt">Editing Blog #{blogId}</h4>
          <input
            type="text"
            placeholder="Blog Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="adblogTitleInput"
          />
          <textarea
            placeholder="Blog Excerpt"
            value={excerpt}
            onChange={(e) => setExcerpt(e.target.value)}
            className="adblogExcerptInput"
          />
          <TipTapEditor
            content={content}
            setContent={setContent}
            placeholder="Blog Content"
            useage="blogs"
          />
          <button className="adsaveblogbtn" onClick={handleUpdateBlog}>
            Save Changes
          </button>
        </div>
      </div>
    )
  );
}

export default EditBlog;
