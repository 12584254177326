import React, { useEffect, useState, useContext } from "react";
import Loading from "../shared/Loading";
import { AppContext } from "../../context/AppContext";
import Header from "../shared/Header";
import { useNavigate } from "react-router-dom";
import "../styles/blog.css";
import Integrations from "../shared/Integrations";
import LogHeader from "../shared/LogHeader";
import LogNavLinks from "../shared/LogNavLinks";
import TopNav from "../components/TopNav";
import axios from "axios";
import Pagination from "../components/Pagination";
import NotFound from "../components/NotFound";
import ValidTag from "../../utils/ValidTag";

function Blog() {
  const {
    siteTitle,
    myStyles,
    darkMode,
    currentUser,
    panelId,
    siteUrl,
    siteData,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchingBlogs, setFetchingBlogs] = useState(true);

  const itemsPerPage = 10;
  const pageCount = Math.ceil(blogs.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = (currentPage + 1) * itemsPerPage;
  const paginatedBlogs = blogs.slice(startIndex, endIndex);

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(selected);
    navigate(`/blogs?page=${newPage}`);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(pageFromUrl - 1);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchBlogs = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            key: currentUser.apiKey,
            collection: "blogs",
            panelId: panelId,
          });
          const sortedBlogs = response.data.sort((a, b) => b.id - a.id);
          setBlogs(sortedBlogs);
          setFetchingBlogs(false);
        } catch (error) {
          console.error("Error fetching blogs:", error);
        }
      }
    };

    fetchBlogs();
  }, [panelId, siteUrl, currentUser]);

  useEffect(() => {
    document.title = `Blogs | ${siteTitle}`;
  }, [siteTitle]);

  const handleViewMore = (id) => {
    navigate(`/blog/${id}`);
  };

  if (!siteData || fetchingBlogs) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {currentUser ? <LogNavLinks /> : isSmallScreen ? <Header /> : ""}
      <LogHeader />
      <Integrations />
      <div
        className="clblogscon"
        data-theme={darkMode ? "dark" : "light"}
        style={myStyles}
      >
        <TopNav />
        <div className="clblogsmaincon">
          {paginatedBlogs.length === 0 ? (
            <NotFound value="No Blog Found" />
          ) : (
            <div className="clallblogs">
              {paginatedBlogs.map((blog) => (
                <div className="clblogPreview" key={blog.id}>
                  <h3 className="clblogTitle">{blog.title}</h3>
                  <p className="clblogExcerpt">{blog.excerpt}</p>
                  <button
                    className="clblogviewMoreButton"
                    onClick={() => handleViewMore(blog.id)}
                  >
                    View More
                  </button>
                </div>
              ))}
            </div>
          )}
          <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
          <ValidTag />
          </div>
      </div>
    </React.Fragment>
  );
}

export default Blog;
