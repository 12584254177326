import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import HomePage from "./client/pages/HomePage";
import Signin from "./client/pages/SignIn";
import SignUp from "./client/pages/SignUp";
import { Notify } from "./client/components/Notification";
import Order from "./client/pages/Order";
import Menu from "./client/shared/Menu";
import Orders from "./client/pages/Orders";
import Services from "./client/pages/Services";
import Refills from "./client/pages/Refills";
import Account from "./client/pages/Account";
import Support from "./client/pages/Support";
import ViewChat from "./client/pages/ViewChat";
import API from "./client/pages/API";
import Faqs from "./client/pages/Faqs";
import ViewFaq from "./client/pages/ViewFaq";
import AdminLogin from "./admin/pages/AdminLogin";
import Users from "./admin/pages/Users";
import AdminOrders from "./admin/pages/AdminOrders";
import AdminServices from "./admin/pages/AdminServices";
import Pay from "./client/pages/Pay";
import PageNotFound from "./client/pages/PageNotFound";
import PaymentMethod from "./client/pages/PaymentMethod";
import ControlPanel from "./admin/pages/ControlPanel";
import PaymentMethods from "./admin/pages/PaymentMethods";
import Design from "./admin/pages/Design";
import General from "./admin/pages/General";
import Providers from "./admin/pages/Providers";
import Categories from "./admin/pages/Categories";
import AdminFaqs from "./admin/pages/AdminFaqs";
import Pages from "./admin/pages/Pages";
import Integration from "./admin/pages/Integration";
import AdminSupport from "./admin/pages/AdminSupport";
import AdminViewChat from "./admin/pages/AdminViewChat";
import ForgetPassword from "./client/pages/ForgetPassword";
import Dashboard from "./client/pages/Dashboard";
import Blog from "./client/pages/Blog";
import ViewBlog from "./client/pages/ViewBlog";
import Blogs from "./admin/pages/Blogs";
import Affiliate from "./client/pages/Affiliate";
import Statistics from "./admin/pages/Statistics";

function App() {
  return (
    <AppProvider>
      <Notify />
      <Router>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" exact element={<HomePage />} />
          <Route path="/signin" exact element={<Signin />} />
          <Route path="/forget-password" exact element={<ForgetPassword />} />
          <Route path="/signup" exact element={<SignUp />} />
          <Route path="/order" exact element={<Order />} />
          <Route path="/orders/:status" exact element={<Orders />} />
          <Route path="/services" exact element={<Services />} />
          <Route path="/api" exact element={<API />} />
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/pay/:method" exact element={<PaymentMethod />} />
          <Route path="/faqs" exact element={<Faqs />} />
          <Route path="/faq/:id" exact element={<ViewFaq />} />
          <Route path="/blog/:id" exact element={<ViewBlog />} />
          <Route path="/blogs" exact element={<Blog />} />
          <Route path="/menu" exact element={<Menu />} />
          <Route path="/account" exact element={<Account />} />
          <Route path="/pay" exact element={<Pay />} />
          <Route path="/support" exact element={<Support />} />
          <Route path="/affiliate" exact element={<Affiliate />} />
          <Route path="/refills" exact element={<Refills />} />
          <Route path="/viewchat/:id" exact element={<ViewChat />} />
          <Route path="/control-panel/login" exact element={<AdminLogin />} />
          <Route path="/control-panel" exact element={<ControlPanel />} />
          <Route
            path="/control-panel/payment-methods"
            exact
            element={<PaymentMethods />}
          />
          <Route path="/control-panel/faqs" exact element={<AdminFaqs />} />
          <Route path="/control-panel/pages" exact element={<Pages />} />
          <Route path="/control-panel/general" exact element={<General />} />
          <Route
            path="/control-panel/providers"
            exact
            element={<Providers />}
          />
          <Route
            path="/control-panel/users/:status"
            exact
            element={<Users />}
          />
          <Route
            path="/control-panel/orders/:status"
            exact
            element={<AdminOrders />}
          />
          <Route
            path="/control-panel/services/:status"
            exact
            element={<AdminServices />}
          />
          <Route
            path="/control-panel/categories/:status"
            exact
            element={<Categories />}
          />
          <Route path="/control-panel/design" exact element={<Design />} />
          <Route
            path="/control-panel/integration"
            exact
            element={<Integration />}
          />
          <Route
            path="/control-panel/support"
            exact
            element={<AdminSupport />}
          />
          <Route
            path="/control-panel/viewchat/:ticketId"
            exact
            element={<AdminViewChat />}
          />
          <Route path="/control-panel/blogs" exact element={<Blogs />} />
          <Route
            path="/control-panel/statistics"
            exact
            element={<Statistics />}
          />
        </Routes>
      </Router>
    </AppProvider>
  );
}

export default App;
