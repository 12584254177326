import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "../styles/order.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Integrations from "../shared/Integrations";
import LogNavLinks from "../shared/LogNavLinks";
import LogHeader from "../shared/LogHeader";
import TopNav from "../components/TopNav";
import { BsDashLg } from "react-icons/bs";
import parse from "html-react-parser";
import { getData } from "../../utils/indexedDB";
import getNextId from "../shared/IdIncrement";
import { getCurrencySymbol } from "../shared/CurrencySymbol";
import { convertCurrency } from "../shared/ConvertCurrency";
import Loading from "../shared/Loading";
import ValidTag from "../../utils/ValidTag";
import Checkbox from "../components/Checkbox";

function Order() {
  const {
    siteTitle,
    myStyles,
    darkMode,
    loading,
    setLoading,
    panelId,
    fetchCurrentUser,
    setFetchCurrentUser,
    setNotifyType,
    setNotifyDuration,
    setNotifyVisibility,
    setNotifyMessage,
    currentUser,
    siteUrl,
    ratesData,
    siteData,
    selectedRate,
  } = useContext(AppContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedServices, setSelectedServices] = useState(null);
  const [serviceData, setServiceData] = useState({});
  const [services, setServices] = useState([]);
  const [tutorialLink, setTutorialLink] = useState("");
  const [runs, setRuns] = useState(1);
  const [interval, setInterval] = useState(1);
  const [comments, setComments] = useState("");
  const [categories, setCategories] = useState([]);
  const [price, setPrice] = useState(0);
  const [idCounter, setIdCounter] = useState(1);
  const [addingOrder, setAddingOrder] = useState(false);
  const [dripFeed, setDripFeed] = useState(false);
  const [fetchingCat, setFetchingCat] = useState(true);
  const [loadingServices, setLoadingServices] = useState(false);
  const [minAmount, setMinAmount] = useState(0);
  const [serviceCache, setServiceCache] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const [formData, setFormData] = useState({
    category: "",
    service: "",
    url: "",
    number: "",
    timestamp: new Date(),
  });

  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (styles) => ({
      ...styles,
      borderRadius: "20px",
      borderColor: "var(--stbaseactcolor)",
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "var(--stbaseactcolor)"
          : darkMode
          ? "rgb(34, 34, 34)"
          : "white", // Change background color on hover
        color: isFocused ? "white" : darkMode ? "white" : "black",
        fontSize: "var(--selectfs)",
      };
    },
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  const navigate = useNavigate();
  useEffect(() => {
    const getTutorialLink = async () => {
      if (currentUser) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "pages",
            key: currentUser.apiKey,
            panelId: panelId,
            query: { find: { field: "uid", operator: "===", value: "order" } },
          });
          const orderDoc = response.data;
          setTutorialLink(orderDoc.tutorial);
        } catch (error) {}
      }
    };
    getTutorialLink();
  }, [panelId, currentUser, siteUrl]);

  useEffect(() => {
    const onAuth = async () => {
      const currentUser = await getData("user_auth");
      if (!currentUser) {
        navigate("/");
      }
    };
    onAuth();
    setLoading(false);
  }, [navigate, setLoading]);

  useEffect(() => {
    const fetchCategories = async () => {
      if (panelId) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "categories",
            panelId: panelId,
          });
          const filteredCategories = response.data
            .filter((category) => category.status === "active")
            .sort((a, b) => a.position - b.position);
          const categoriesData = filteredCategories.map((doc) => ({
            value: doc.name,
            label: doc.name,
          }));
          setCategories(categoriesData);
          setFetchingCat(false);
        } catch (error) {
          setFetchingCat(false);
        }
      }
    };
    fetchCategories();
  }, [siteUrl, panelId]);

  useEffect(() => {
    document.title = `Order | ${siteTitle}`;
    const IdIncrement = async () => {
      try {
        const newID = await getNextId("orders", panelId, siteUrl);
        setIdCounter(newID);
      } catch (error) {
        setIdCounter(1);
      }
    };
    IdIncrement();
  }, [siteTitle, siteUrl, panelId, fetchCurrentUser]);

  useEffect(() => {
    const fetchServiceData = async () => {
      if (selectedServices && selectedServices.value) {
        const response = await axios.post(`${siteUrl}/service/get`, {
          panelId: panelId,
        });

        const serviceData = response.data.find(
          (serv) => serv.name === selectedServices.value
        );
        setPrice(
          parseFloat(
            convertCurrency(
              serviceData.price,
              serviceData.providerCurrency,
              "USD",
              ratesData
            ).replace(/,/g, "")
          )
        );
        setMinAmount(serviceData.min);
        setMaxAmount(serviceData.max);
        setServiceData(serviceData);
      }
    };
    fetchServiceData();

    if (selectedCategory) {
      if (serviceCache[selectedCategory.value]) {
        setServices(serviceCache[selectedCategory.value]);
        setLoadingServices(false); // Ensure loading state is false if services are cached
        return;
      }
    }

    const fetchServices = async () => {
      try {
        if (selectedCategory && selectedCategory.value) {
          setLoadingServices(true);
          const response = await axios.post(`${siteUrl}/service/get`, {
            panelId: panelId,
          });
          const filteredServices = response.data.filter(
            (service) => service.category === selectedCategory.value
          );
          const servicesData = filteredServices.map((doc) => ({
            value: doc.name,
            label: `${doc.id} - ${doc.name} ≈ ${getCurrencySymbol(
              selectedRate?.label
            )}${convertCurrency(
              doc.price,
              doc.providerCurrency,
              selectedRate?.label,
              ratesData
            )}`,
          }));
          setServices(servicesData);
          setServiceCache((prevCache) => ({
            ...prevCache,
            [selectedCategory.value]: servicesData,
          }));
        } else {
          setServices([]);
          setPrice(0);
          setMinAmount(0);
          setMaxAmount(0);
        }
      } catch (error) {
        setLoadingServices(false);
      } finally {
        setLoadingServices(false);
      }
    };

    fetchServices();
  }, [
    siteUrl,
    panelId,
    selectedCategory,
    selectedServices,
    serviceCache,
    selectedRate,
    ratesData,
  ]);

  if (loading || siteData === null || !currentUser || fetchingCat) {
    return <Loading />;
  }

  const onChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    if (id === "number" && !isNaN(value)) {
      const enteredAmount = parseInt(value);
      setTotalAmount(enteredAmount);

      if (isNaN(enteredAmount) || value === "") {
        setTotalAmount(0);
      } else {
        setTotalAmount(enteredAmount);
      }
    }

    if (serviceData && serviceData.type === "Package") {
      setFormData((prevState) => ({
        ...prevState,
        number: 1,
      }));
      setTotalAmount(1);
      setTotalPrice(price.toFixed(3));
    }

    if (
      id === "number" &&
      selectedServices &&
      selectedServices.value &&
      !dripFeed
    ) {
      const enteredQuantity = parseInt(value);
      if (isNaN(enteredQuantity) || value === "") {
        setTotalPrice(0);
      } else {
        const totalPrice = ((enteredQuantity / 1000) * price).toFixed(3);
        setTotalPrice(totalPrice);
      }
    }
  };

  const handleDripFeedChange = (e) => {
    const value = parseInt(e.target.value);
    setRuns(value);
    if (runs === "") setRuns(1);
    const totalNum = parseInt(formData.number) * value;
    const totalPrice = ((totalNum / 1000) * price).toFixed(3);
    setTotalPrice(totalPrice);
    setTotalAmount(totalNum);
  };

  const handleCommentsChange = (e) => {
    const value = e.target.value;
    setComments(value);
    const commentsLength = value.split("\n").length;
    setFormData((prevState) => ({
      ...prevState,
      number: commentsLength,
    }));
    setTotalAmount(commentsLength);
    const totalPrice = ((commentsLength / 1000) * price).toFixed(3);
    setTotalPrice(totalPrice);
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      category: selectedOption ? selectedOption.label : "",
      number: "",
      url: "",
    }));
    setSelectedServices(null);
  };

  const handleServicesChange = (selectedOption) => {
    setSelectedServices(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      service: selectedOption ? selectedOption.label : "",
      number: "",
      url: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if a category is selected
    if (!formData.category) {
      Notify("warn", "Please select a category");
      return;
    }

    // Check if a service is selected
    if (!formData.service) {
      Notify("warn", "Please select a service");
      return;
    }

    // Check if URL is provided
    if (!formData.url) {
      Notify("warn", "Please provide a link");
      return;
    }

    // Check if amount is provided
    if (!formData.number && serviceData.type !== "Package") {
      Notify("warn", "Please provide the amount");
      return;
    }

    if (dripFeed && (runs === "" || !runs || interval === "" || !interval)) {
      Notify("warn", "Please provide the runs and interval");
      return;
    }
    // Check if the entered amount is within the valid range
    if (
      (minAmount && formData.number < minAmount) ||
      (maxAmount && formData.number > maxAmount)
    ) {
      Notify("error", `Amount should be between ${minAmount} and ${maxAmount}`);
      return;
    }

    const oldBalance = currentUser.balance;
    const newBalance = parseFloat(currentUser.balance) - parseFloat(totalPrice);
    const usdTotalPrice = parseFloat(
      convertCurrency(
        totalPrice,
        serviceData.providerCurrency,
        "USD",
        ratesData
      ).replace(/,/g, "")
    );

    if (newBalance < 0) {
      Notify("error", "Insufficient Funds");
      return;
    }

    const newSpent = parseFloat(currentUser.spent) + parseFloat(totalPrice);
    setAddingOrder(true);
    try {
      const orderData = {
        userUid: currentUser.uid,
        userId: currentUser.id,
        username: currentUser.username,
        id: idCounter,
        category: formData.category,
        service: selectedServices.value,
        status: "Failed",
        userInitialBalance: oldBalance,
        userFinalBalance: newBalance,
        serviceID: serviceData.id,
        currency: "USD",
        remains: 0,
        url: formData.url,
        start: 0,
        synced: false,
        number: parseInt(formData.number),
        price: usdTotalPrice || 0,
        timestamp: new Date(),
      };
      if (serviceData.type === "Custom Comments") {
        orderData.comments = comments;
      }
      if (dripFeed) {
        orderData.dripFeed = true;
        orderData.runs = runs;
        orderData.interval = interval;
      }
      const userBal = {
        balance: newBalance,
        spent: newSpent,
      };
      const reqData = {
        orderData: orderData,
        panelId: panelId,
        userBal: userBal,
        userUid: currentUser.uid,
      };
      await axios.post(`${siteUrl}/order/send`, reqData);
      Notify("success", "Order Created Successfully");
      setFormData({
        url: "",
        number: "",
      });
      setFetchCurrentUser(!fetchCurrentUser);
      setSelectedCategory(null);
      setAddingOrder(false);
      setSelectedServices(null);
      setTotalAmount(0);
      setTotalPrice(0);
    } catch (error) {
      setAddingOrder(false);
    }
  };

  const customNoOptionsMessage = ({ inputValue, selectType }) => {
    if (loadingServices) {
      return "Loading Services...";
    }
    return inputValue
      ? `No matching ${selectType} found`
      : `No ${selectType} available`;
  };

  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      Notify("success", "Copied Successfully");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <Integrations />
      <LogHeader />
      <LogNavLinks />
      <div
        className="gjh"
        data-theme={darkMode ? "dark" : "light"}
        style={myStyles}
      >
        <TopNav />
        <div className="order-container">
          <div className="d-flex justify-content-between flex-wrap">
            <div className="addorder fade-in">
              <h3 className="mb-3">New Order</h3>
              <form
                className="fkjhgf"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <label htmlFor="category" className="fw-600 ms-1">
                  Category
                </label>
                <Select
                  id="category"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  options={categories}
                  placeholder="Select category"
                  noOptionsMessage={(props) =>
                    customNoOptionsMessage({ ...props, selectType: "category" })
                  }
                  isClearable
                  styles={selectStyles}
                  className="mb-4"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      primary: "var(--stbaseactcolor)",
                    },
                  })}
                />
                {selectedCategory && (
                  <React.Fragment>
                    <label htmlFor="service" className="fw-600 ms-1">
                      Service
                    </label>
                    <Select
                      className="mb-4 fade-in"
                      placeholder="Select service"
                      noOptionsMessage={(props) =>
                        customNoOptionsMessage({
                          ...props,
                          selectType: "service",
                        })
                      }
                      value={selectedServices}
                      onChange={handleServicesChange}
                      options={services}
                      isClearable
                      styles={selectStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          primary: "var(--stbaseactcolor)",
                        },
                      })}
                    />
                    {selectedServices && (
                      <React.Fragment>
                        <label htmlFor="link" className="fw-600 ms-1 fade-in">
                          Link
                        </label>
                        <input
                          type="text"
                          name="url"
                          id="url"
                          className="input-field fade-in"
                          autoComplete="off"
                          onChange={onChange}
                          value={formData.url}
                        />
                        {formData.url !== "" && serviceData.id ? (
                          <React.Fragment>
                            {serviceData.type !== "Package" &&
                              serviceData.type !== "Custom Comments" && (
                                <React.Fragment>
                                  <label
                                    htmlFor="number"
                                    className="fw-600 mt-4 ms-1 fade-in"
                                  >
                                    Amount
                                  </label>
                                  <input
                                    type="number"
                                    name="number"
                                    value={formData.number}
                                    id="number"
                                    className="input-field Num fade-in"
                                    autoComplete="off"
                                    onChange={onChange}
                                    placeholder={`From ${minAmount || "0"} to ${
                                      maxAmount || "0"
                                    }`}
                                  />
                                </React.Fragment>
                              )}
                            {serviceData.type === "Custom Comments" && (
                              <React.Fragment>
                                <label
                                  htmlFor="comments"
                                  className="fw-600 mt-4 ms-1 fade-in"
                                >
                                  Comments
                                </label>
                                <textarea
                                  name="comments"
                                  value={comments}
                                  className="input-field fade-in"
                                  onChange={handleCommentsChange}
                                  placeholder={`Enter your comment (from ${minAmount} to ${maxAmount}). Each new comment will start on a new line`}
                                />
                              </React.Fragment>
                            )}
                            {serviceData.dripFeed && (
                              <React.Fragment>
                                <div className="d-flex">
                                  <Checkbox
                                    checked={dripFeed}
                                    onChange={() => setDripFeed(!dripFeed)}
                                  />{" "}
                                  Drip Feed
                                </div>
                                {dripFeed && (
                                  <React.Fragment>
                                    <label
                                      htmlFor="runs"
                                      className="fw-600 mt-4 ms-1 fade-in"
                                    >
                                      Runs
                                    </label>
                                    <input
                                      type="number"
                                      name="runs"
                                      value={runs}
                                      className="input-field Num fade-in"
                                      autoComplete="off"
                                      onChange={handleDripFeedChange}
                                    />
                                    <label
                                      htmlFor="interval"
                                      className="fw-600 mt-4 ms-1 fade-in"
                                    >
                                      Interval (minutes)
                                    </label>
                                    <input
                                      type="number"
                                      name="interval"
                                      value={interval}
                                      className="input-field Num fade-in"
                                      autoComplete="off"
                                      onChange={(e) =>
                                        setInterval(parseInt(e.target.value))
                                      }
                                    />
                                    <label
                                      htmlFor="interval"
                                      className="fw-600 mt-4 ms-1 fade-in"
                                    >
                                      Total Quantity
                                    </label>
                                    <input
                                      type="number"
                                      name="runs"
                                      value={
                                        parseInt(formData.number) * runs || 0
                                      }
                                      className="input-field Num fade-in"
                                      disabled
                                    />
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                            <div className="brd"></div>
                            <div className="prcs d-flex justify-content-between fade-in">
                              <p className="fw-bold ms-2">
                                Amount <BsDashLg /> {totalAmount}
                              </p>
                              <p className="fw-bold me-2">
                                ≈ {getCurrencySymbol(selectedRate?.label)}
                                {convertCurrency(
                                  totalPrice,
                                  serviceData.providerCurrency,
                                  selectedRate?.label,
                                  ratesData
                                )}
                              </p>
                            </div>
                            <button
                              type={addingOrder ? "button" : "submit"}
                              className="btn-order fade-in"
                            >
                              {addingOrder ? (
                                <div className="cldotsanimation"></div>
                              ) : (
                                "Place Order"
                              )}
                            </button>
                          </React.Fragment>
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </form>
            </div>
            {selectedServices && serviceData.id && (
              <div className="desc-container fade-in">
                <div className="desc-item">
                  <strong>{serviceData.category}</strong>
                </div>
                <div className="desc-item">
                  <strong>{serviceData.name}</strong>
                </div>
                <div className="desc-item">
                  <strong
                    className="clorddesid"
                    onClick={() => copyText(serviceData.id)}
                  >
                    ID {serviceData.id}
                  </strong>
                </div>
                <div className="desc-item flex-column-center">
                  <strong className="price-highlight">
                    ≈ {getCurrencySymbol(selectedRate?.label)}
                    {convertCurrency(
                      price,
                      serviceData.providerCurrency,
                      selectedRate?.label,
                      ratesData
                    )}
                  </strong>
                  <strong>
                    Price for {serviceData.type === "Package" ? "1" : "1000"}
                  </strong>
                </div>
                <pre>
                  {parse(serviceData.description.replace(/&&n/g, "\n"))}
                </pre>
              </div>
            )}
          </div>
          {tutorialLink && tutorialLink !== "" ? (
            <div className="tutorial-container">
              <iframe
                className="tutorial-frame"
                src={tutorialLink}
                title="Tutorial"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            ""
          )}
          <ValidTag />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Order;
