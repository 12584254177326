import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";
import NotFound from "../shared/NotFound";
import { MdDelete, MdEdit } from "react-icons/md";
import Pagination from "../shared/Pagination";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import TipTapEditor from "../shared/TipTapEditor";
import "../styles/blogs.css";
import { AppContext } from "../../context/AppContext";
import Loading from "../shared/Loading";
import EditBlog from "../components/EditBlog";

function Blogs() {
  const navigate = useNavigate();
  const {
    currentAdmin,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    siteUrl,
    panelId,
    siteTitle,
    myAdminStyles,
  } = useContext(AppContext);
  const [blogs, setBlogs] = useState([]);
  const [newBlogTitle, setNewBlogTitle] = useState("");
  const [newBlogExcerpt, setNewBlogExcerpt] = useState("");
  const [newBlogContent, setNewBlogContent] = useState("");
  const [idCounter, setIdCounter] = useState(0);
  const [fetchBlogs, setFetchBlogs] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchingBlogs, setFetchingBlogs] = useState(true);
  const [showEditBlog, setShowEditBlog] = useState(false);

  const itemsPerPage = 10;
  const pageCount = Math.ceil(blogs.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = (currentPage + 1) * itemsPerPage;
  const paginatedBlogs = blogs.slice(startIndex, endIndex);

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration && duration > 0) setNotifyDuration(duration);
  };

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(selected);
    navigate(`/blogs?page=${newPage}`);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(pageFromUrl - 1);
  }, []);

  useEffect(() => {
    document.title = `Blogs | ${siteTitle}`;

    const fetchBlogs = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            key: currentAdmin.apiKey,
            collection: "blogs",
            panelId: panelId,
          });
          const sortedBlogs = response.data.sort((a, b) => b.id - a.id);
          setBlogs(sortedBlogs);
          setFetchingBlogs(false);
        } catch (error) {
          console.error("Error fetching blogs:", error);
        }
      }
    };
    const idIncrement = async () => {
      try {
        const url = `${siteUrl}/get/nextid`;
        const data = {
          collection: "blogs",
          panelId: panelId,
          key: currentAdmin.apiKey,
        };
        const response = await axios.post(url, data);
        setIdCounter(response.data.id);
      } catch (error) {
        setIdCounter(1);
      }
    };
    idIncrement();
    fetchBlogs();
  }, [siteTitle, siteUrl, panelId, currentAdmin, fetchBlogs]);

  const handleAddBlog = async () => {
    if (newBlogTitle !== "" && newBlogExcerpt !== "" && newBlogContent !== "") {
      try {
        await axios.post(`${siteUrl}/crud/add/doc`, {
          collection: "blogs",
          key: currentAdmin.apiKey,
          panelId: panelId,
          data: {
            id: idCounter,
            title: newBlogTitle,
            excerpt: newBlogExcerpt,
            content: newBlogContent,
          },
        });
        setNewBlogTitle("");
        setNewBlogExcerpt("");
        setNewBlogContent("");
        setFetchBlogs(!fetchBlogs);
        Notify("success", "Added Successfully");
      } catch (error) {
        Notify("error", error.message);
        console.error("Error adding blog:", error);
      }
    } else {
      Notify("error", "Please fill in the title, excerpt, and content.");
    }
  };

  const handleDeleteBlog = async (uid) => {
    try {
      await axios.post(`${siteUrl}/crud/delete/doc`, {
        collection: "blogs",
        key: currentAdmin.apiKey,
        panelId: panelId,
        uid: uid,
      });
      setFetchBlogs(!fetchBlogs);
      Notify("success", "Deleted Successfully");
    } catch (error) {
      Notify("error", error.message);
      console.error("Error adding blog:", error);
    }
  };

  if (fetchingBlogs) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <AdminHeader />
      <AdminSessionChecker />
      <div className="adblogs" style={myAdminStyles}>
        <div className="adblogsmaincon">
          {paginatedBlogs.length === 0 ? (
            <NotFound value="No Blog Found" />
          ) : (
            <div className="adblogsContainer">
              {paginatedBlogs.map((blog) => (
                <div className="adblogItem" key={blog.id}>
                  <h3 className="adblogTitle">{blog.title}</h3>
                  <p className="adblogExcerpt">{blog.excerpt}</p>
                  <div className="adblogbuttons">
                    <button
                      className="adviewMoreButton"
                      onClick={() => setShowEditBlog(blog.id)}
                    >
                      <MdEdit className="adviewmbtnicon" />
                      Edit Blog
                    </button>
                    <button
                      className="adviewMoreButton"
                      onClick={() => handleDeleteBlog(blog.uid)}
                    >
                      <MdDelete className="adviewmbtnicon" />
                      Delete Blog
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
          <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
          <div className="addBlogContainer">
            <h2>Add New Blog</h2>
            <input
              type="text"
              placeholder="Blog Title"
              value={newBlogTitle}
              onChange={(e) => setNewBlogTitle(e.target.value)}
              className="adblogTitleInput"
            />
            <textarea
              placeholder="Blog Excerpt"
              value={newBlogExcerpt}
              onChange={(e) => setNewBlogExcerpt(e.target.value)}
              className="adblogExcerptInput"
            />
            <TipTapEditor
              content={newBlogContent}
              useage="blogs"
              placeholder="Blog Content"
              setContent={setNewBlogContent}
            />
            <button className="addBlogButton" onClick={handleAddBlog}>
              Add Blog
            </button>
          </div>
        </div>
      </div>
      {showEditBlog && (
        <EditBlog
          showEditBlog={showEditBlog}
          fetchBlogs={fetchBlogs}
          blogId={showEditBlog}
          setFetchBlogs={setFetchBlogs}
          setShowEditBlog={setShowEditBlog}
        />
      )}
    </React.Fragment>
  );
}

export default Blogs;
