import React, { useEffect, useState, useContext } from "react";
import "../styles/faqs.css";
import { AppContext } from "../../context/AppContext";
import Header from "../shared/Header";
import Integrations from "../shared/Integrations";
import ValidTag from "../../utils/ValidTag";
import LogHeader from "../shared/LogHeader";
import LogNavLinks from "../shared/LogNavLinks";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import TopNav from "../components/TopNav";
import Loading from "../shared/Loading";
import parse from "html-react-parser";

function ViewFaq() {
  const {
    siteTitle,
    myStyles,
    darkMode,
    currentUser,
    loading,
    setLoading,
    panelId,
    siteUrl,
    siteData,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [faqsDetails, setFaqsDetails] = useState([]);
  const [fetchingFaqs, setFetchingFaqs] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setLoading(false);
    const fetchFaqsDetails = async () => {
      try {
        const response = await axios.post(`${siteUrl}/crud/get/docs`, {
          collection: "faqs",
          panelId: panelId,
        });
        if (Array.isArray(response.data)) {
          const q = response.data.filter((faq) => faq.id === parseInt(id));
          if (q.length === 1) {
            const faqData = q[0];
            setFaqsDetails(faqData);
          }
          setFetchingFaqs(false);
        }
      } catch (error) {
        setFetchingFaqs(false);
      }
    };
    document.title = `${faqsDetails.title ? faqsDetails.title : ""}${
      faqsDetails.title ? " | " : ""
    }${siteTitle}`;
    fetchFaqsDetails();
  }, [siteTitle, id, faqsDetails, setLoading, navigate, panelId, siteUrl]);

  if (loading || siteData === null || fetchingFaqs) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      {currentUser ? <LogNavLinks /> : isSmallScreen ? <Header /> : ""}
      <LogHeader />
      <Integrations />
      <div className={darkMode ? "darkVfFull" : "vfFull"} style={myStyles}>
        <TopNav />
        <div className="vf-container">
          <div className={darkMode ? "dark-vf-details" : "vf-details"}>
            <div
              className="vf-head"
              id={darkMode ? "mydarkbottomborder" : "mylightbottomborder"}
            >
              <Link to="/faqs" className="backIC">
                <IoArrowBackSharp className="back" />
              </Link>
              <h5 className="ms-4 fw-bold">{faqsDetails.question}</h5>
            </div>
            <div className="vf-body">{parse(faqsDetails.answer)}</div>
          </div>
          <ValidTag />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewFaq;
