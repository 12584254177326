import "../styles/providers.css";
import React, { useContext, useState, useEffect } from "react";
import Switch from "../shared/Switch.jsx";
import { AppContext } from "../../context/AppContext";
import AdminHeader from "../components/AdminHeader";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import FetchingData from "../shared/FetchingData.jsx";
import axios from "axios";
import EditProvider from "../components/EditProvider";
import { MdDelete, MdEdit } from "react-icons/md";
import ControlPanel from "./ControlPanel.jsx";
import Loading from "../shared/Loading.jsx";
import NotFound from "../shared/NotFound.jsx";
import { getCurrencySymbol } from "../../client/shared/CurrencySymbol.jsx";
import { convertCurrency } from "../../client/shared/ConvertCurrency.jsx";

function Providers() {
  const {
    myAdminStyles,
    loading,
    siteData,
    siteUrl,
    panelId,
    ratesData,
    selectedAdminRate,
    siteTitle,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    setLoading,
    currentAdmin,
  } = useContext(AppContext);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [showAddProvider, setShowAddProvider] = useState(false);
  const [sync, setSync] = useState(null);
  const [addingProvider, setAddingProvider] = useState(false);
  const [fetchingProviders, setFetchingProviders] = useState(true);
  const [providerName, setProviderName] = useState("");
  const [providerPercentage, setProviderPercentage] = useState(0);
  const [providerKey, setProviderKey] = useState("");
  const [metaData, setMetaData] = useState({});
  const [providerCurrency, setProviderCurrency] = useState({});
  const [idCounter, setIdCounter] = useState(1);
  const [fetchProv, setFetchProv] = useState(false);

  const hideEditProvider = (event) => {
    if (event.target.classList.contains("edseroverlay")) {
      setShowAddProvider(false);
    }
  };

  useEffect(() => {
    setLoading(false);
    document.title = `Providers | ${siteTitle}`;
  }, [siteTitle, setLoading]);

  useEffect(() => {
    const fetchProviders = async () => {
      if (currentAdmin) {
        try {
          const url = `${siteUrl}/providers/get`;
          const data = {
            adminKey: currentAdmin.apiKey,
            panelId: panelId,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setProviders(response.data);
          const fetchSiteMetaData = async (url) => {
            try {
              const response = await axios.post(
                `${siteUrl}/site/metadata/get`,
                {
                  url: url,
                }
              );
              return response.data;
            } catch (error) {}
          };
          const getCurrency = async (prov) => {
            try {
              const response = await axios.post(
                `${siteUrl}/providers/get/currency`,
                {
                  panelId: panelId,
                  key: currentAdmin.apiKey,
                  provider: prov,
                }
              );
              return response.data;
            } catch (error) {}
          };
          const fetchedCurrency = {};
          for (const provider of response.data) {
            const currency = await getCurrency(provider.url);
            fetchedCurrency[provider.url] = currency;
          }
          setProviderCurrency(fetchedCurrency);
          const fetchedMetaDatas = {};
          for (const provider of response.data) {
            const metadatas = await fetchSiteMetaData(provider.url);
            fetchedMetaDatas[provider.url] = metadatas;
          }
          setMetaData(fetchedMetaDatas);
        } catch (error) {}
        setFetchingProviders(false);
      }
    };
    fetchProviders();
  }, [siteUrl, currentAdmin, panelId, fetchProv]);

  const handleSyncQuantityUpdate = (newValue) => {
    setSync(newValue);
  };

  const toggleSyncQuantity = async () => {
    const newValue = !sync;
    setSync(newValue); // Update local state immediately
    handleSyncQuantityUpdate(newValue); // Update database
  };
  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };
  const idIncrement = async (collectionName) => {
    try {
      const url = `${siteUrl}/get/nextid`;
      const data = {
        collectionName: collectionName,
        panelId: panelId,
      };
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIdCounter(response.data);
    } catch (error) {
      setIdCounter(1);
    }
  };

  if (loading || siteData === null) {
    return <Loading />;
  }

  const handleEditProvider = (provider) => {
    setSelectedProvider(provider);
  };

  const handleShowAddProvider = () => {
    idIncrement("providers");
    setShowAddProvider(true);
  };

  const providerNameChange = (e) => {
    const value = e.target.value;
    setProviderName(value.trim());
  };

  const providerKeyChange = (e) => {
    const value = e.target.value;
    setProviderKey(value.trim());
  };

  const providerPercentageChange = (e) => {
    const value = e.target.value;
    const mainValue = value.trim() === "" ? 0 : parseInt(value);
    setProviderPercentage(mainValue);
  };

  const addProvider = async () => {
    setAddingProvider(true);
    if (providerName === "" || providerKey === "") {
      Notify("error", "Please fill all fields");
      setAddingProvider(false);
      return;
    }
    if (currentAdmin) {
      try {
        const url = `${siteUrl}/providers/create`;
        const data = {
          panelId: panelId,
          adminKey: currentAdmin.apiKey,
          url: providerName,
          sync: sync,
          percentage: providerPercentage,
          key: providerKey,
          idCounter: idCounter,
        };
        await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setAddingProvider(false);
        setShowAddProvider(false);
        Notify("success", "Added Successfully");
        setProviderKey("");
        setProviderName("");
        setFetchProv((prev) => !prev);
      } catch (error) {
        setShowAddProvider(false);
        setAddingProvider(false);
        Notify("error", error.message);
      }
    }
  };

  const deleteProvider = async (provider) => {
    setAddingProvider(true);
    if (currentAdmin) {
      try {
        const url = `${siteUrl}/providers/delete`;
        const data = {
          panelId: panelId,
          adminKey: currentAdmin.apiKey,
          uid: provider.uid,
        };
        await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        Notify("success", "Deleted Successfully");
        setFetchProv((prev) => !prev);
      } catch (error) {
        Notify("error", error.message);
      }
    }
  };

  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="adproviders" style={myAdminStyles}>
        <div className="adprovidersmaincon">
          <ControlPanel />
          <div className="adcpbdycon">
            <div className="adprovthshead">
              <span>Providers</span>
              <button className="adaddprovmet" onClick={handleShowAddProvider}>
                Add Provider
              </button>
            </div>
            {fetchingProviders ? (
              <FetchingData />
            ) : (
              <React.Fragment>
                {providers.length === 0 ? (
                  <NotFound value="No provider found" />
                ) : (
                  <div className="adpaymethsbody">
                    <div className="adpaymethstable">
                      <table>
                        <tbody>
                          {providers.map((provider) => (
                            <React.Fragment key={provider.uid}>
                              <tr className="adoaymethstbtr">
                                <td>
                                  <div className="adoaymethstbtd">
                                    <img
                                      src={metaData[provider.url]?.favicon}
                                      alt="favicon"
                                      className="adprovtrtdimg"
                                    />
                                  </div>
                                </td>
                                <td className="adprovwrapcon">
                                  <div className="adoaymethstbtd">
                                    <span>{provider.url}</span>
                                  </div>
                                </td>
                                <td className="adprovwrapcon">
                                  <div className="adoaymethstbtd">
                                    <span>
                                      {getCurrencySymbol(
                                        selectedAdminRate.label
                                      )}
                                      {convertCurrency(
                                        parseFloat(
                                          providerCurrency[provider.url]
                                            ?.balance
                                        ),
                                        providerCurrency[provider.url]
                                          ?.currency,
                                        selectedAdminRate.label,
                                        ratesData
                                      )}
                                    </span>
                                  </div>
                                </td>
                                <td className="adprovtbtdminwdtg">
                                  <div className="adoaymethstbtd">
                                    <button
                                      className="adusersactions"
                                      onClick={() =>
                                        handleEditProvider(provider)
                                      }
                                    >
                                      <MdEdit className="adpaymentacticon" />
                                      Edit Provider
                                    </button>
                                  </div>
                                </td>
                                <td className="adprovtbtdminwdtg">
                                  <div className="adoaymethstbtd">
                                    <button
                                      className="addeleteProv"
                                      onClick={() => deleteProvider(provider)}
                                    >
                                      <MdDelete className="adpaymentacticon" />
                                      Delete Provider
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {selectedProvider !== null ? (
              <EditProvider
                selectedProvider={selectedProvider}
                setSelectedProvider={setSelectedProvider}
                siteUrl={siteUrl}
                adminApiKey={currentAdmin.apiKey}
              />
            ) : (
              ""
            )}
            {showAddProvider ? (
              <div
                className="edseroverlay"
                onClick={(event) => hideEditProvider(event)}
              >
                <div className="editservcon">
                  <div className="adedmethodhead">
                    <h6>Add Provider</h6>
                  </div>
                  <div className="adedmthbba">
                    <label htmlFor="editservnname" className="fw-semibold">
                      Name
                    </label>
                    <input
                      type="text"
                      name="editservnname"
                      placeholder="example.com"
                      id="editprovselect"
                      onChange={providerNameChange}
                      value={providerName}
                    />
                    <label
                      htmlFor="editservicedescription"
                      className="fw-semibold mt-3"
                    >
                      Key
                    </label>
                    <input
                      type="text"
                      name="editservnname"
                      id="editprovselect"
                      placeholder="Your API Key"
                      onChange={providerKeyChange}
                      value={providerKey}
                    />
                    <div className="adsyncproviders">
                      <strong>Sync services from provider</strong>
                      <Switch syncValue={sync} onToggle={toggleSyncQuantity} />
                    </div>
                    {sync ? (
                      <>
                        <label
                          htmlFor="editservicedescription"
                          className="fw-semibold mt-3"
                        >
                          Percentage Profit
                        </label>
                        <input
                          type="text"
                          name="editservnname"
                          id="editprovselect"
                          placeholder="Your percentage profit"
                          onChange={providerPercentageChange}
                          value={providerPercentage}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="adeditminmaxpaymth">
                    <button className="adeditsavebtn" onClick={addProvider}>
                      {addingProvider ? (
                        <div className="adeditservspinner"></div>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Providers;
