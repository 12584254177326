import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { HiMail } from "react-icons/hi";
import Pagination from "./Pagination";
import { formatDate } from "../shared/FormatDate";

function SupportTable({ ticketsData }) {
  const {
    darkMode,
    setNotifyType,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(1200);
  const [currentPage, setCurrentPage] = useState(0);
  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration > 0) setNotifyDuration(duration);
  };

  // State for managing pagination
  const itemsPerPage = 30; // You can adjust this based on your preference

  // Sort the entire orders array
  const sortedTickets = [...ticketsData].sort((a, b) => b.id - a.id);

  // Calculate the total number of pages based on the sorted array
  const pageCount = Math.ceil(sortedTickets.length / itemsPerPage);

  // Logic to slice orders based on the current page
  const ticketsToDisplay = sortedTickets.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1; // Adjust page number (ReactPaginate uses 0-based index)
    setCurrentPage(selected);
    // Update the URL with the new page number
    navigate(`/support?page=${newPage}`);
  };
  useEffect(() => {
    // Extract page number from the URL and set it as the initial state
    const searchParams = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(pageFromUrl - 1); // Adjust for 0-based index
  }, []); // Run only once on component mount

  useEffect(() => {
    const handleResize = () => {
      const ordersTableCon = document.querySelector(".support-container");
      if (ordersTableCon) {
        setWindowWidth(ordersTableCon.offsetWidth);
      }
    };

    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      Notify("success", "Successfully copied ID");
    } catch (err) {
      Notify("error", "Failed to copy ID to clipboard:" + err.message);
    }
  };
  return (
    <div>
      {windowWidth > 1200 ? (
        <div className="support-tickets mt-3">
          <table className={darkMode ? "dark-st-tb" : "st-tb"}>
            <thead>
              <tr>
                <th className="st-head st-id">ID</th>
                <th className="st-head">Subject</th>
                <th className="st-head">Status</th>
                <th className="st-head st-lu">Created At</th>
              </tr>
            </thead>
            <tbody>
              {ticketsToDisplay.map((ticket) => (
                <React.Fragment key={ticket.uid}>
                  <tr>
                    <td
                      id={darkMode ? "mydarkborder" : "mylightborder"}
                      className="st-data"
                      onClick={() => copyToClipboard(ticket.id)}
                    >
                      <p className={darkMode ? "dark-cp-id" : "cp-id"}>
                        {ticket.id}
                      </p>
                    </td>
                    <td
                      id={darkMode ? "mydarkborder" : "mylightborder"}
                      className="st-data st-malink"
                    >
                      <Link
                        to={`/viewchat/${ticket.id}`}
                        className={darkMode ? "dark-st-link" : "st-link"}
                        style={
                          !ticket.isReadByUser
                            ? {
                                color: "var(--stbaseactcolor)",
                              }
                            : {}
                        }
                      >
                        <HiMail className="st-icon" /> {ticket.subject}{" "}
                        {ticket.request !== undefined && ticket.request !== null
                          ? ` - ${ticket.request}`
                          : ""}
                      </Link>
                    </td>
                    <td
                      id={darkMode ? "mydarkborder" : "mylightborder"}
                      className="st-data"
                    >
                      <p
                        className={
                          ticket.status === "Solved"
                            ? "st-sv-status"
                            : ticket.status === "Answered"
                            ? "st-as-status"
                            : ticket.status === "Pending"
                            ? "st-pd-status"
                            : ticket.status === "Waiting For Response"
                            ? "st-wfr-status"
                            : ticket.status === "Reopened"
                            ? "st-reop-status"
                            : "st-status"
                        }
                      >
                        {ticket.status}
                      </p>
                    </td>
                    <td
                      id={darkMode ? "mydarkborder" : "mylightborder"}
                      className="st-data st-luv"
                    >
                      <strong>{formatDate(ticket.timestamp)}</strong>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="clmborderscon">
          {ticketsData.map((ticket) => (
            <div
              className="clmborderdet"
              key={ticket.id}
              onClick={() => navigate(`/viewchat/${ticket.id}`)}
            >
              <div className="clmborderdethead">
                <span
                  className={darkMode ? "dark-cp-id" : "cp-id"}
                  onClick={(e) => {
                    copyToClipboard(ticket.id);
                  }}
                >
                  {ticket.id}
                </span>
                <strong className="clmbservicena">
                  <HiMail className="st-icon" /> {ticket.subject}
                </strong>
              </div>
              <div className="clmborderdetbody">
                <strong className="clmbservicena">
                  {ticket.request ? ticket.request : ""}
                </strong>
                <strong className={`clmbsupportstatus ${ticket.status}`}>
                  {ticket.status}
                </strong>
                <strong>{formatDate(ticket.timestamp)}</strong>
              </div>
            </div>
          ))}
        </div>
      )}
      <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
    </div>
  );
}

export default SupportTable;
