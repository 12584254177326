import "../styles/tiptapeditor.css";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import axios from "axios";

// Icons
import { FaBold, FaItalic, FaUnderline, FaStrikethrough } from "react-icons/fa";
import { MdFormatListBulleted } from "react-icons/md";
import { FiLink, FiImage } from "react-icons/fi";
import { AiOutlineTable } from "react-icons/ai";

const TipTapEditor = ({
  content,
  setContent,
  stateKey,
  leaveExtenstions = [],
  removeExtenstions = [],
  useage = "general",
  placeholder,
}) => {
  const { darkMode, siteUrl, domain, currentUser, panelId } =
    useContext(AppContext);
  const [showUploadImageCon, setShowUploadImageCon] = useState(false);
  const [showUploadLinkCon, setShowUploadLinkCon] = useState(false);
  const [useImageUrl, setUseImageUrl] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const linkRef = useRef(null);
  const imageRef = useRef(null);
  const [linkUrl, setLinkUrl] = useState("");
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Image,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableCell,
      TableHeader,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
    ],
    content: content,
    onBlur: ({ editor }) => {
      if (stateKey && stateKey !== "") {
        setContent((prevState) => ({
          ...prevState,
          [stateKey]: editor.getHTML(),
        }));
      } else {
        setContent(editor.getHTML());
      }
    },
  });

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  useEffect(() => {
    let handler = (e) => {
      if (!imageRef.current?.contains(e.target)) {
        setShowUploadImageCon(null);
      }
    };
    if (showUploadImageCon) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    let handler = (e) => {
      if (!linkRef.current?.contains(e.target)) {
        setShowUploadLinkCon(null);
      }
    };
    if (showUploadLinkCon) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const uploadImageToServer = async () => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("key", currentUser.apiKey);
    formData.append("domain", domain);
    formData.append("panelId", panelId);
    formData.append("useage", useage);

    const response = await axios.post(`${siteUrl}/upload/image`, formData);

    const url = response.data.url;
    editor.chain().focus().setImage({ src: url }).run();
    return;
  };

  const handleImageChange = async (event) => {
    setImage(event.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (image) {
      await uploadImageToServer();
      setShowUploadImageCon(false);
    }
  };

  const handleAddImageUrl = () => {
    editor.chain().focus().setImage({ src: imageUrl }).run();
    setShowUploadImageCon(false);
    setUseImageUrl(false);
  };

  const handleAddLink = () => {
    editor.chain().focus().setLink({ href: linkUrl }).run();
    setShowUploadLinkCon(false);
  };

  const addImage = () => {
    setShowUploadImageCon(true);
  };

  const addLink = () => {
    setShowUploadLinkCon(true);
  };

  const addTable = () => {
    editor
      .chain()
      .focus()
      .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
      .run();
  };

  const addTaskList = () => {
    editor.chain().focus().toggleTaskList().run();
  };

  const shouldRenderButton = (name) => {
    return (
      (leaveExtenstions.length === 0 || leaveExtenstions.includes(name)) &&
      !removeExtenstions.includes(name)
    );
  };

  const cancelImgPopup = () => {
    setUseImageUrl(false);
    setShowUploadImageCon(false);
  };

  return (
    editor && (
      <React.Fragment>
        <div className="cltoolbar">
          {shouldRenderButton("bold") && (
            <button
              type="button"
              onClick={() => editor.chain().focus().toggleBold().run()}
              className={editor.isActive("bold") ? "iscl-active" : ""}
            >
              <FaBold className="cltoolbarbtnicon" />
            </button>
          )}
          {shouldRenderButton("italic") && (
            <button
              type="button"
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={editor.isActive("italic") ? "iscl-active" : ""}
            >
              <FaItalic className="cltoolbarbtnicon" />
            </button>
          )}
          {shouldRenderButton("strike") && (
            <button
              type="button"
              onClick={() => editor.chain().focus().toggleStrike().run()}
              className={editor.isActive("strike") ? "iscl-active" : ""}
            >
              <FaStrikethrough className="cltoolbarbtnicon" />
            </button>
          )}
          {shouldRenderButton("underline") && (
            <button
              type="button"
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              className={editor.isActive("underline") ? "iscl-active" : ""}
            >
              <FaUnderline className="cltoolbarbtnicon" />
            </button>
          )}
          {shouldRenderButton("link") && (
            <React.Fragment>
              <button
                type="button"
                onClick={addLink}
                className={editor.isActive("link") ? "iscl-active" : ""}
              >
                <FiLink className="cltoolbarbtnicon" />
              </button>
              {showUploadLinkCon && (
                <div className="clrteuplink">
                  <div
                    className="clrteuplinkcon"
                    ref={linkRef}
                    style={{
                      backgroundColor: `${
                        darkMode ? "var(--bgdarkcolor)" : "var(--bglightcolor)"
                      }`,
                    }}
                  >
                    <h4>Enter URL</h4>
                    <input
                      type="text"
                      placeholder="Enter URL"
                      value={linkUrl}
                      autoComplete="false"
                      onChange={(e) => setLinkUrl(e.target.value)}
                    />
                    <button type="button" onClick={handleAddLink}>
                      Add Link
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowUploadLinkCon(false)}
                      className="mt-2"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          {shouldRenderButton("image") && (
            <React.Fragment>
              <button
                type="button"
                onClick={addImage}
                className={editor.isActive("image") ? "iscl-active" : ""}
              >
                <FiImage className="cltoolbarbtnicon" />
              </button>
              {showUploadImageCon && (
                <div className="clrteupimg">
                  <div
                    className="clrteupimgcon"
                    ref={imageRef}
                    style={{
                      backgroundColor: `${
                        darkMode ? "var(--bgdarkcolor)" : "var(--bglightcolor)"
                      }`,
                    }}
                  >
                    <h4>Upload Image</h4>
                    {useImageUrl ? (
                      <React.Fragment>
                        <input
                          type="text"
                          placeholder="Enter Image URL"
                          value={imageUrl}
                          onChange={(e) => setImageUrl(e.target.value)}
                          autoComplete="false"/>
                        <button type="button" onClick={handleAddImageUrl}>
                          Add Image
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        <div
                          className="clrtealturl"
                          onClick={() => setUseImageUrl(true)}
                        >
                          Use Image URL
                        </div>
                        <button
                          type="button"
                          onClick={handleImageUpload}
                          className="mt-1"
                        >
                          Add Image
                        </button>
                      </React.Fragment>
                    )}
                    <button
                      type="button"
                      onClick={cancelImgPopup}
                      className="mt-2"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          {shouldRenderButton("table") && (
            <button type="button" onClick={addTable}>
              <AiOutlineTable className="cltoolbarbtnicon" />
            </button>
          )}
          {shouldRenderButton("taskList") && (
            <button type="button" onClick={addTaskList}>
              <MdFormatListBulleted className="cltoolbarbtnicon" />
            </button>
          )}
        </div>
        <EditorContent editor={editor} placeholder={placeholder} />
      </React.Fragment>
    )
  );
};

export default TipTapEditor;
