import React, { useContext, useEffect, useState } from "react";
import "../styles/faqs.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AppContext } from "../../context/AppContext";
import ControlPanel from "./ControlPanel";
import { MdDelete } from "react-icons/md";
import getNextId from "../shared/idIncrement";
import NotFound from "../shared/NotFound";
import AdminHeader from "../components/AdminHeader";
import { MdModeEditOutline } from "react-icons/md";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import Loading from "../shared/Loading";
import axios from "axios";
import TipTapEditor from "../shared/TipTapEditor";
import EditFaq from "../components/EditFaq";

function AdminFaqs() {
  const [faqs, setFaqs] = useState([]);
  const [faqQuestion, setFaqQuestion] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");
  const [idCounter, setIdCounter] = useState(1);
  const [showAddFaq, setShowAddFaq] = useState(false);
  const [editFaq, setEditFaq] = useState(null);
  const [fetchFaq, setFetchFaq] = useState(false);
  const {
    loading,
    siteData,
    setNotifyType,
    currentAdmin,
    setNotifyMessage,
    setNotifyVisibility,
    setNotifyDuration,
    siteTitle,
    setLoading,
    myAdminStyles,
    panelId,
    siteUrl,
  } = useContext(AppContext);

  useEffect(() => {
    setLoading(false);
    document.title = `FAQS | ${siteTitle}`;
  }, [siteTitle, setLoading]);

  useEffect(() => {
    const fetchFaqs = async () => {
      if (currentAdmin) {
        try {
          const response = await axios.post(`${siteUrl}/crud/get/docs`, {
            collection: "faqs",
            key: currentAdmin.apiKey,
            panelId: panelId,
          });
          let snapshot = [];
          if (response.data.length !== 0) {
            snapshot = response.data.sort((a, b) => a.position - b.position);
          }
          setFaqs(snapshot);
        } catch (error) {}
      }
    };

    const IdIncrement = async () => {
      try {
        const newID = await getNextId("faqs", panelId, siteUrl);
        setIdCounter(newID);
      } catch (error) {
        setIdCounter(1);
      }
    };
    IdIncrement();

    fetchFaqs();
  }, [panelId, siteUrl, fetchFaq, currentAdmin]);

  if (loading || siteData === null) {
    return <Loading />;
  }

  const hideAddFaq = (event) => {
    if (event.target.classList.contains("edseroverlay")) {
      setShowAddFaq(false);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedfaqs = Array.from(faqs);
    const [removed] = reorderedfaqs.splice(result.source.index, 1);
    reorderedfaqs.splice(result.destination.index, 0, removed);

    try {
      for (let i = 0; i < reorderedfaqs.length; i++) {
        const faq = reorderedfaqs[i];
        const faqUid = faq.uid;
        await axios.post(`${siteUrl}/crud/update/doc`, {
          collection: "faqs",
          key: currentAdmin.apiKey,
          panelId: panelId,
          uid: faqUid,
          data: {
            position: i,
          },
        });
      }
      setFetchFaq(!fetchFaq);
    } catch (error) {
      console.error("Error updating faq order:", error);
    }
  };

  const handleTextChange = (e) => {
    const value = e.target.value;
    setFaqQuestion(value);
  };

  const Notify = (type, message, duration) => {
    setNotifyType(type);
    setNotifyMessage(message);
    setNotifyVisibility(true);
    if (duration >= 0) setNotifyDuration(duration);
  };

  const addFaq = async () => {
    if (faqQuestion.trim() === "" || faqAnswer.trim() === "") {
      Notify("error", "Missing question or answer");
      return;
    }
    try {
      const data = {
        question: faqQuestion,
        id: idCounter,
        answer: faqAnswer,
        timestamp: new Date(),
      };
      await axios.post(`${siteUrl}/crud/add/doc`, {
        collection: "faqs",
        panelId: panelId,
        key: currentAdmin.apiKey,
        data: data,
      });
      setFetchFaq(!fetchFaq);
      setShowAddFaq(false);
      Notify("success", "Added Successfully");
      setFaqAnswer("");
      setFaqQuestion("");
    } catch (error) {
      Notify("error", "Error Adding FAQ");
    }
  };

  const handleEditFaq = async (uid) => {
    setEditFaq(uid);
  };

  const deleteFaq = async (uid) => {
    try {
      await axios.post(`${siteUrl}/crud/delete/doc`, {
        collection: "faqs",
        panelId: panelId,
        uid: uid,
      });
      setFetchFaq(!fetchFaq);
      Notify("success", "Deleted Successfully");
    } catch (error) {
      Notify("error", "Error Deleting FAQ");
    }
  };

  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="adfaqs" style={myAdminStyles}>
        <div className="adfaqsmaincon">
          <ControlPanel />
          <div className="adcpbdycon">
            <div className="adfaqhead">
              <span>FAQS</span>{" "}
              <button
                className="adfaqheadadd"
                onClick={() => setShowAddFaq(true)}
              >
                Add FAQ
              </button>
            </div>
            {faqs.length === 0 ? (
              <NotFound value="No FAQ Found" />
            ) : (
              <div className="adfaqstable">
                <table>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="faqs">
                      {(provided) => (
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {faqs.map((faq, index) => (
                            <Draggable
                              key={faq.uid}
                              draggableId={faq.uid}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  className="adfaqrow"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <td>{faq.question}</td>
                                  <td style={{ maxWidth: "70px" }}>
                                    <button
                                      className="adfaqdeletebtn"
                                      onClick={() => handleEditFaq(faq.uid)}
                                    >
                                      <MdModeEditOutline />
                                    </button>
                                  </td>
                                  <td style={{ maxWidth: "70px" }}>
                                    <button
                                      className="adfaqdeletebtn"
                                      onClick={() => deleteFaq(faq.uid)}
                                    >
                                      <MdDelete />
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </table>
              </div>
            )}
            {showAddFaq ? (
              <div
                className="edseroverlay"
                onClick={(event) => hideAddFaq(event)}
              >
                <div className="editservcon">
                  <div className="adfaqaddfrm">
                    <div className="adedmethodhead">
                      <h6>Add Faq</h6>
                    </div>
                    <div>
                      <label htmlFor="title">Question</label>
                      <input
                        type="text"
                        id="editprovselect"
                        name="title"
                        value={faqQuestion}
                        autoComplete="off"
                        onChange={handleTextChange}
                      />
                    </div>
                    <div>
                      <label htmlFor="description">Answer</label>
                      <TipTapEditor
                        content={faqAnswer}
                        setContent={setFaqAnswer}
                        useage="faqs"
                      />
                    </div>
                    <button
                      type="submit"
                      className="adfaqsaddfrmbtn"
                      onClick={addFaq}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {editFaq && (
          <EditFaq
            uid={editFaq}
            showEditFaq={editFaq}
            setShowEditFaq={setEditFaq}
            setFetchFaq={setFetchFaq}
            fetchFaq={fetchFaq}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default AdminFaqs;
