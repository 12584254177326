import { useState, useRef, useEffect } from "react";
import { SketchPicker } from "react-color";
import "../styles/colorpicker.css";

function ColorPicker({ color, setState }) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef(null);

  const handleShowColorPicker = () => {
    setShowColorPicker(true);
  };

  const handleClickOutside = (event) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target)
    ) {
      setShowColorPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={colorPickerRef} className="adcolorpickercon">
      <div
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "10px",
          border: "5px solid white",
          backgroundColor: color,
          cursor: "pointer",
        }}
        onClick={handleShowColorPicker}
      ></div>
      {showColorPicker && (
        <div className="adcolorpicker">
          <SketchPicker
            color={color}
            onChangeComplete={(color) => setState(color.hex)}
          />
        </div>
      )}
    </div>
  );
}

export default ColorPicker;
