import "../styles/checkbox.css";

function Checkbox({ onChange, checked }) {
  return (
    <label className="clcustom-checkbox">
      <input
        name="dummy"
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <span className="clcheckmark"></span>
    </label>
  );
}

export default Checkbox;
