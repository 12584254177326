import { AppContext } from "../../context/AppContext";
import React, { useContext, useEffect, useState, useRef } from "react";
import "../styles/adminorders.css";
import { useLocation, Link, useParams, useNavigate } from "react-router-dom";
import { MdRemoveShoppingCart } from "react-icons/md";
import { BsCartCheckFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import AdminHeader from "../components/AdminHeader";
import AdminSessionChecker from "../shared/AdminSessionChecker";
import { TbShoppingCartBolt, TbShoppingCartPause } from "react-icons/tb";
import { BsFillCartXFill } from "react-icons/bs";
import InProgressOrders from "../components/InProgressOrders";
import FailedOrders from "../components/FailedOrders";
import PartialOrders from "../components/PartialOrders";
import axios from "axios";
import PendingOrders from "../components/PendingOrders";
import CompletedOrders from "../components/CompletedOrders";
import CancelledOrders from "../components/CancelledOrders";
import AllOrders from "../components/AllOrders";
import { MdCancel } from "react-icons/md";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import FetchingData from "../shared/FetchingData";
import Loading from "../shared/Loading";

function AdminOrders() {
  const {
    myAdminStyles,
    siteTitle,
    loading,
    currentAdmin,
    setLoading,
    siteUrl,
    panelId,
    siteData,
  } = useContext(AppContext);
  const { status } = useParams();
  const [showSearchOrder, setShowSearchOrder] = useState(false);
  const [fetchingOrders, setFetchingOrders] = useState(true);
  const [allOrder, setAllOrder] = useState(0);
  const [pendingOrders, setPendingOrders] = useState(0);
  const [activeOrders, setActiveOrders] = useState(0);
  const [partialOrders, setPartialOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [failedOrders, setFailedOrders] = useState(0);
  const [cancelledOrders, setCancelledOrders] = useState(0);
  const [fetchOrders, setFetchOrders] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [mainOrders, setMainOrders] = useState([]);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const containerRef = useRef();
  const tableRef = useRef();
  const bodyRef = useRef();
  const actionsRef = useRef();
  const location = useLocation();
  const remains = status.slice(1);
  const OrderStat = status.charAt(0).toUpperCase() + remains;

  useEffect(() => {
    setLoading(false);
    document.title = `${OrderStat} Orders | ${siteTitle}`;
  }, [siteTitle, setLoading, OrderStat]);
  useEffect(() => {
    let handler = (e) => {
      if (
        !bodyRef.current?.contains(e.target) &&
        !containerRef.current?.contains(e.target) &&
        !tableRef.current?.contains(e.target) &&
        !actionsRef.current?.contains(e.target)
      ) {
        setShowSearchOrder(false);
        setSearchValue("");
        searchParams.delete("search");
        const newUrl = `${window.location.pathname}`;
        navigate(newUrl);
        setAllOrders(mainOrders);
      }
    };
    if (showSearchOrder) {
      document.addEventListener("mousedown", handler);
    }

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  useEffect(() => {
    if (currentAdmin && currentAdmin.apiKey !== "") {
      const fetchAllOrdersSize = async () => {
        try {
          const url = `${siteUrl}/order/all/size`;
          const data = {
            panelId: panelId,
            key: currentAdmin.apiKey,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setAllOrder(response.data.size);
        } catch (error) {
          console.error("Error getting orders:", error);
        }
      };

      const fetchPendingOrdersSize = async () => {
        try {
          const url = `${siteUrl}/order/pending/size`;
          const data = {
            panelId: panelId,
            key: currentAdmin.apiKey,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setPendingOrders(response.data.size);
        } catch (error) {
          console.error("Error getting orders:", error);
        }
      };

      const fetchActiveOrdersSize = async () => {
        try {
          const url = `${siteUrl}/order/active/size`;
          const data = {
            panelId: panelId,
            key: currentAdmin.apiKey,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setActiveOrders(response.data.size);
        } catch (error) {
          console.error("Error getting orders:", error);
        }
      };
      const fetchPartialOrdersSize = async () => {
        try {
          const url = `${siteUrl}/order/partial/size`;
          const data = {
            panelId: panelId,
            key: currentAdmin.apiKey,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setPartialOrders(response.data.size);
        } catch (error) {
          console.error("Error getting orders:", error);
        }
      };

      const fetchCancelledOrdersSize = async () => {
        try {
          const url = `${siteUrl}/order/cancelled/size`;
          const data = {
            panelId: panelId,
            key: currentAdmin.apiKey,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setCancelledOrders(response.data.size);
        } catch (error) {
          console.error("Error getting orders:", error);
        }
      };

      const fetchCompletedOrdersSize = async () => {
        try {
          const url = `${siteUrl}/order/completed/size`;
          const data = {
            panelId: panelId,
            key: currentAdmin.apiKey,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setCompletedOrders(response.data.size);
        } catch (error) {
          console.error("Error getting orders:", error);
        }
      };
      const fetchFailedOrdersSize = async () => {
        try {
          const url = `${siteUrl}/order/failed/size`;
          const data = {
            panelId: panelId,
            key: currentAdmin.apiKey,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setFailedOrders(response.data.size);
        } catch (error) {
          console.error("Error getting orders:", error);
        }
      };
      fetchCancelledOrdersSize();
      fetchPartialOrdersSize();
      fetchAllOrdersSize();
      fetchPendingOrdersSize();
      fetchFailedOrdersSize();
      fetchCompletedOrdersSize();
      fetchActiveOrdersSize();
    }
  }, [currentAdmin, siteUrl, panelId]);

  useEffect(() => {
    const fetchAllOrders = async () => {
      if (currentAdmin) {
        try {
          const url = `${siteUrl}/order/get`;
          const data = {
            panelId: panelId,
            key: currentAdmin.apiKey,
          };
          const response = await axios.post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          setAllOrders(response.data);
          setMainOrders(response.data);
          setFetchingOrders(false);
        } catch (error) {
          setAllOrders([]);
          setFetchingOrders(false);
        }
      }
    };
    fetchAllOrders();
  }, [currentAdmin, siteUrl, panelId, fetchOrders]);

  const searchUrlParams = new URLSearchParams(location.search);
  const SearchValue = searchUrlParams.get("search");

  useEffect(() => {
    if (SearchValue !== null) {
      const handleSearchValue = () => {
        const parsedValue =
          SearchValue.trim() === ""
            ? ""
            : isNaN(SearchValue)
            ? String(SearchValue)
            : parseInt(SearchValue, 10);
        setSearchValue(parsedValue);
        const dupOrders = [...mainOrders];
        setAllOrders(
          dupOrders.filter((order) => {
            if (typeof parsedValue === "string") {
              return (
                order.url.toLowerCase().includes(parsedValue.toLowerCase()) ||
                order.provider
                  .toLowerCase()
                  .includes(parsedValue.toLowerCase()) ||
                order.username.toLowerCase() === parsedValue.toLowerCase()
              );
            } else if (typeof parsedValue === "number") {
              return (
                String(order.id).includes(String(parsedValue)) ||
                String(order.providerOrderId).includes(String(parsedValue))
              );
            }
            return false;
          })
        );
      };
      handleSearchValue();
      setShowSearchOrder(true);
    }
  }, [location.search, mainOrders, SearchValue]);

  if (loading || siteData === null) {
    return <Loading />;
  }
  const patchMatchRoute = (Route) => {
    if (Route === location.pathname) {
      return true;
    }
  };

  const showSearchBar = () => {
    setShowSearchOrder(true);
  };
  const fetchOrdersComponent = (status) => {
    // Define an object mapping statuses to components
    const orderStatusComponents = {
      all: AllOrders,
      active: InProgressOrders,
      pending: PendingOrders,
      partial: PartialOrders,
      failed: FailedOrders,
      cancelled: CancelledOrders,
      completed: CompletedOrders,
    };

    // Use the status parameter to look up the component from the object
    return orderStatusComponents[status]; // DefaultComponent is a placeholder for unknown statuses
  };
  const OrderComponent = fetchOrdersComponent(status);

  const handleCancelSearchOrder = () => {
    setShowSearchOrder(false);
    setSearchValue("");
    searchParams.delete("search");
    const newUrl = `${window.location.pathname}`;
    navigate(newUrl);
    setAllOrders(mainOrders);
  };
  const searchParams = new URLSearchParams(location.search);
  const handleSearchInput = (e) => {
    const value = e.target.value;
    const parsedValue =
      value.trim() === ""
        ? ""
        : isNaN(value)
        ? String(value)
        : parseInt(value, 10);
    if (parsedValue === "") {
      searchParams.delete("search");
      setAllOrders(mainOrders);
    } else {
      searchParams.set("search", parsedValue);
      const dupOrders = [...mainOrders];
      setAllOrders(
        dupOrders.filter((order) => {
          if (typeof parsedValue === "string") {
            return (
              order.url.toLowerCase().includes(parsedValue.toLowerCase()) ||
              order.provider
                .toLowerCase()
                .includes(parsedValue.toLowerCase()) ||
              order.username.toLowerCase() === parsedValue.toLowerCase()
            );
          } else if (typeof parsedValue === "number") {
            return (
              String(order.id).includes(String(parsedValue)) ||
              String(order.providerOrderId).includes(String(parsedValue))
            );
          }
          return false;
        })
      );
    }
    const newSearchParamsString = searchParams.toString();
    const newUrl = `${window.location.pathname}${
      newSearchParamsString ? `?${newSearchParamsString}` : ""
    }`;
    navigate(newUrl);
    setSearchValue(parsedValue);
  };

  return (
    <React.Fragment>
      <AdminSessionChecker />
      <AdminHeader />
      <div className="adorders" style={myAdminStyles}>
        <div className="adordersmaincon">
          <div className="ordersstatcon">
            <Link to="/control-panel/orders/all" className="adordersstatcon">
              <div className="adorstatsecon-main">
                <FaShoppingCart className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/orders/all")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                All Orders
              </strong>
              <h4 className="aduserstat">{allOrder}</h4>
            </Link>
            <Link to="/control-panel/orders/active" className="adordersstatcon">
              <div className="orstatsecon-active">
                <TbShoppingCartBolt className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/orders/active")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Active Orders
              </strong>
              <h4 className="aduserstat">{activeOrders}</h4>
            </Link>
            <Link
              to="/control-panel/orders/pending"
              className="adordersstatcon"
            >
              <div className="orstatsecon-pending">
                <TbShoppingCartPause className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/orders/pending")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Pending Orders
              </strong>
              <h4 className="aduserstat">{pendingOrders}</h4>
            </Link>
            <Link
              to="/control-panel/orders/partial"
              className="adordersstatcon"
            >
              <div className="orstatsecon-partial">
                <MdOutlineRemoveShoppingCart className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/orders/partial")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Partial Orders
              </strong>
              <h4 className="aduserstat">{partialOrders}</h4>
            </Link>
            <Link to="/control-panel/orders/failed" className="adordersstatcon">
              <div className="orstatsecon-dred">
                <BsFillCartXFill className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/orders/failed")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Failed Orders
              </strong>
              <h4 className="aduserstat">{failedOrders}</h4>
            </Link>
            <Link
              to="/control-panel/orders/cancelled"
              className="adordersstatcon"
            >
              <div className="orstatsecon-red">
                <MdRemoveShoppingCart className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/orders/cancelled")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Cancelled Orders
              </strong>
              <h4 className="aduserstat">{cancelledOrders}</h4>
            </Link>
            <Link
              to="/control-panel/orders/completed"
              className="adordersstatcon"
            >
              <div className="orstatsecon-green">
                <BsCartCheckFill className="orderstaticon" />
              </div>
              <strong
                className={
                  patchMatchRoute("/control-panel/orders/completed")
                    ? "adordersrouteactivecolor"
                    : ""
                }
              >
                Completed Orders
              </strong>
              <h4 className="aduserstat">{completedOrders}</h4>
            </Link>
          </div>
          <div className="adsearchorders" ref={containerRef}>
            {showSearchOrder ? (
              <div className="searchorderDiv" ref={bodyRef}>
                <input
                  type="text"
                  name="ordersearch"
                  id="adordersearchfield"
                  value={searchValue}
                  onChange={handleSearchInput}
                  placeholder="Search by ID or URL"
                />
                <MdCancel
                  className="adorcancelsearch"
                  onClick={handleCancelSearchOrder}
                />
              </div>
            ) : (
              <button className="adorderssearchbtn" onClick={showSearchBar}>
                <FaSearch className="adorsearIcon" />
                <span className="fw-semibold">Search Order</span>
              </button>
            )}
          </div>
          {fetchingOrders ? (
            <FetchingData />
          ) : (
            <>
              {OrderComponent ? (
                <OrderComponent
                  allOrders={allOrders}
                  tableRef={tableRef}
                  adminApiKey={currentAdmin.apiKey}
                  setFetchOrders={setFetchOrders}
                  actionsRef={actionsRef}
                />
              ) : null}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminOrders;
