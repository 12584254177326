import ServicesTable from "./ServicesTable";
import NotFound from "../shared/NotFound";
import { useState, useEffect } from "react";

function ActiveServices({
  services,
  fetchServices,
  setFetchServices,
  tableRef,
  bodyRef,
  actionsRef,
}) {
  const [filteredServices, setfilteredServices] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get("search")) {
      const filtered = services.filter(
        (service) => service.status === "active"
      );
      setfilteredServices(filtered);
    } else {
      setfilteredServices(services);
    }
  }, [services]);

  return filteredServices.length === 0 ? (
    <NotFound
      value={
        searchParams.get("search")
          ? "No matching services found with the given name or ID"
          : "No active service found."
      }
    />
  ) : (
    <ServicesTable
      services={filteredServices}
      tableRef={tableRef}
      bodyRef={bodyRef}
      actionsRef={actionsRef}
      fetchServices={fetchServices}
      setFetchServices={setFetchServices}
    />
  );
}

export default ActiveServices;
