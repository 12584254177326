import { AppContext } from "../../context/AppContext";
import { useContext } from "react";
import "../styles/loading.css";

function Loading() {
  const { darkMode, logoUrl } = useContext(AppContext);
  
  return (
    <div className={darkMode ? "darkbfct" : "bfct"}>
      {logoUrl && logoUrl !== "" && (
        <img src={logoUrl} alt="" className="cltloading" />
      )}
    </div>
  );
}

export default Loading;
