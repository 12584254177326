import OrdersTable from "./OrdersTable";
import NotFound from "./NotFound";
import { useState, useEffect } from "react";

function InProgressOrders({ allOrders, tableRef, actionsRef }) {
  const [filteredOrders, setFilteredOrders] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.get("search")) {
      const filtered = allOrders.filter(
        (allOrders) => allOrders.status === "In progress"
      );
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(allOrders);
    }
  }, [allOrders]);

  return filteredOrders.length === 0 ? (
    <NotFound
      value={
        searchParams.get("search")
          ? "No matching orders found with the given URL or ID"
          : "No active order found."
      }
      url={"/order"}
      linkName={"Create an order"}
      dfStyle={"notfoundforordersstyle"}
    />
  ) : (
    <>
      <OrdersTable
        orders={filteredOrders}
        actionsRef={actionsRef}
        tableRef={tableRef}
      />
    </>
  );
}

export default InProgressOrders;
